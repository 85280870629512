
import { scorm } from "@gamestdio/scorm";

export default class ScormTracker {

    constructor() {
        scorm.configure({
            debug: false
        });

        scorm.initialize();

        const lessonStatus = scorm.get('cmi.core.lesson_status');

        if (lessonStatus != 'completed' && lessonStatus != 'passed') {
            scorm.set('cmi.core.score.min', 0);
            scorm.set('cmi.core.score.max', 1);
            scorm.set('cmi.core.lesson_status', 'incomplete');
            scorm.commit();
        }
    }

    trackCompletion() {
        scorm.set('cmi.core.score.raw', 1);
        scorm.set('cmi.core.lesson_status', 'completed');
        // scorm.set('cmi.success_status', 'passed');
        scorm.commit();
    }

    trackProgress(progress) {
        scorm.set('cmi.core.score.raw', progress);
        scorm.commit();
    }

    trackKnowledgeTest(scaledResult, passed) {

        try {
            const scoreRaw = scorm.get('cmi.core.score.raw')
            const currentScore = !scoreRaw || !scoreRaw.length ? 0 : parseFloat(scoreRaw);
            const lessonStatus = scorm.get('cmi.core.lesson_status');

            if (scaledResult > currentScore) {

                scorm.set('cmi.core.score.raw', scaledResult);

                if (lessonStatus != 'completed' && lessonStatus != 'passed' && passed) {
                    scorm.set('cmi.core.lesson_status', 'completed');
                    // scorm.set('cmi.success_status', 'passed');
                } else {
                    scorm.set('cmi.core.lesson_status', 'incomplete');
                }
                scorm.commit();
            }

        } catch (exception) {}
    }

    getSuspendData() {
        let suspendData = { modules: [], entrypoint: null };

        try {

            const rawSuspendDataResult = scorm.get('cmi.suspend_data');
            const suspendDataResult = rawSuspendDataResult;// ? rawSuspendDataResult.replace(/\\/g, "") : '';

            suspendData = suspendDataResult ? JSON.parse(suspendDataResult) : suspendData;

        } catch (exception) {
            console.error('suspend-data error:', exception);
        }

        return suspendData;
    }

    writeSuspendData(data) {
        try {
            scorm.set('cmi.suspend_data', JSON.stringify(data));
            scorm.commit();

            return true;

        } catch (exception) {
            return false;
        }
    }


}
