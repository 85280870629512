/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-up-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 10.1 19.2',
    data: '<style>.st0{fill:#344384}</style><g id="Symbols"><g id="arrow-up-down"><g id="icon_sort_02"><path pid="0" id="Path" class="st0" d="M7.9 13.4L5 16.3l-2.9-2.9c-.5-.5-1.3-.5-1.7 0-.5.5-.5 1.3 0 1.7L4 18.8c.6.6 1.5.6 2.1 0l3.6-3.6c.5-.5.5-1.3 0-1.7-.5-.6-1.3-.6-1.8-.1z"/><path pid="1" id="Path_1_" class="st0" d="M2.1 5.9l2.9-3 2.9 2.9c.5.5 1.3.5 1.7 0 .5-.5.5-1.3 0-1.7L6.1.4C5.5-.1 4.6-.1 4 .4L.4 4.1c-.5.5-.5 1.3 0 1.7.4.4 1.2.4 1.7.1z"/></g></g></g>'
  }
})
