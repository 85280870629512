/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'feedback-negative_big': {
    width: 16,
    height: 16,
    viewBox: '0 0 57 57',
    data: '<g><g _fill="#d70000"><path pid="0" d="M28.5 0C12.8 0 0 12.6 0 28.2c0 15.5 12.8 28.2 28.5 28.2S57 43.7 57 28.2C57 12.6 44.2 0 28.5 0zm0 54.3C13.9 54.3 2 42.6 2 28.2 2 13.7 13.9 2 28.5 2S55 13.7 55 28.2c0 14.4-11.9 26.1-26.5 26.1z"/><path pid="1" d="M41.6 16.4c.8.8.8 2.1 0 2.9L31.9 29l9.7 9.7c.8.8.8 2.1 0 2.9-.8.8-2.1.8-2.9 0L29 31.9l-9.7 9.7c-.8.8-2.1.8-2.9 0-.8-.8-.8-2.1 0-2.9l9.7-9.7-9.7-9.7c-.8-.8-.8-2.1 0-2.9.8-.8 2.1-.8 2.9 0l9.7 9.7 9.7-9.7c.8-.8 2.1-.8 2.9 0z" fill-rule="evenodd" clip-rule="evenodd"/></g></g>'
  }
})
