import Vue from 'vue';

import { sortByProperty } from '@/common/utility/sorting';
import { rank } from '@/common/utility/rankify';

import { GamificationService } from "@/api";
import {
    GAMIFICATION_FETCH,
    LEADERBOARD_SWITCH,
    GAMIFICATION_REFRESH,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_LEADERBOARD,
    SET_ACHIEVEMENTS,
    SET_LEADERBOARD_TYPE,
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    uid: '',
    leaderboards: {},
    achievements: {},
    selectedLeaderboardType: 'national',
});

const actions = {

    async [GAMIFICATION_FETCH]({ commit, state }, { uid, force }) {

        if (!force && state.leaderboards.hasOwnProperty(uid)) {
            return state.leaderboards[uid];
        }

        commit(FETCH_START);

        const { data } = await GamificationService.get(uid);

        commit(SET_LEADERBOARD, {
            items: data.leaderboard,
            uid
        });

        commit(SET_ACHIEVEMENTS, {
            items: data.achievements,
            uid
        });

        return data;
    },

    async [GAMIFICATION_REFRESH]({ commit, state, dispatch }) {

        if (!state.uid || !state.uid.length) {
            return false;
        }

        await dispatch(GAMIFICATION_FETCH, {
            uid: state.uid,
            force: true,
        });

        return true;
    },

    [LEADERBOARD_SWITCH]({ commit, state }, leaderboardType) {
        commit(SET_LEADERBOARD_TYPE, leaderboardType);
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_LEADERBOARD](state, { items, uid}) {

        let leaderboardItems = items
            .sort((entryA, entryB) => sortByProperty(entryA, entryB, 'score', false))
            .map((item, index) => {
                return {
                    ...item,
                    id: 'id_' + index
                }
            });

        state.uid = uid;
        state.isLoading = false;

        Vue.set(state.leaderboards, uid, leaderboardItems);
    },

    [SET_ACHIEVEMENTS](state, { items, uid}) {
        state.uid = uid;
        state.isLoading = false;

        Vue.set(state.achievements, uid, items);
    },

    [SET_LEADERBOARD_TYPE](state, leaderboardType) {
        state.selectedLeaderboardType = leaderboardType;
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getLeaderboard: (state) => {
        if (!state.leaderboards[state.uid]) { return null; }

        let filteredItems = state.leaderboards[state.uid]
        .filter(i => i.nickname);

        if (state.selectedLeaderboardType == 'national') {
            const me = filteredItems.find(i => i.hasOwnProperty('me') && i['me']);

            if (me) {
                filteredItems = filteredItems.filter((item) => {
                    return item.market == me.market;
                });
            }
        }

        return rank(filteredItems);
    },

    getLeaderboardPreview: (state, getters) => {
        if (!state.leaderboards[state.uid]) { return null; }

        let top5 = getters.getLeaderboard.slice(0, 5);
        const top5HasMe = top5.find(i => i.hasOwnProperty('me') && i['me']);
        const meItem = getters.getLeaderboard.find(i => i.hasOwnProperty('me') && i['me']);

        if (!top5HasMe && meItem) {
            top5 = top5.slice(0, 4);
            top5.push(meItem);
        }

        return top5;
    },

    getMeItem: (state, getters) => {
        if (!state.leaderboards[state.uid]) { return null; }

        return getters.getLeaderboard.find(i => i.hasOwnProperty('me') && i['me']);
    },

    getAheadMe: (state, getters) => {
        if (!state.leaderboards[state.uid]) { return null; }

        const meIndex = getters.getLeaderboard
            .map(i => i.hasOwnProperty('me') && i['me'])
            .indexOf(true);
        const aheadIndex = meIndex > 0 ? meIndex - 1 : null;

        return aheadIndex === null ? null : getters.getLeaderboard[aheadIndex];
    },

    getBehindMe: (state, getters) => {
        if (!state.leaderboards[state.uid]) { return null; }

        const meIndex = getters.getLeaderboard
            .map(i => i.hasOwnProperty('me') && i['me'])
            .indexOf(true);
        const behindIndex = meIndex + 1 < (getters.getLeaderboard.length - 1) ? meIndex + 1 : null;

        return behindIndex === null ? null : getters.getLeaderboard[behindIndex];
    },

    getTopScore: (state, getters) => {
        if (!state.leaderboards[state.uid]) { return null; }

        const firstItem = !!getters.getLeaderboard.length && getters.getLeaderboard[0];

        return firstItem.hasOwnProperty('score') ? firstItem.score : 0;
    },

    getSelectedLeaderboard: (state) => {
        return state.selectedLeaderboardType;
    },

    getAchievements: (state) => {
        if (!state.achievements[state.uid]) { return []; }

        return state.achievements[state.uid];
    },

    getAchievedAchievemtCount: (state) => {
        if (!state.achievements[state.uid]) { return 0; }

        return state.achievements[state.uid].filter(i => i.achieved).length;
    },
};


export const gamification = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
