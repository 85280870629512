<template>
    <div class="live-stream">
        <div style="padding:56.25% 0 0 0;position:relative;">
            <iframe :src="src" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'LiveStream',

    components: {

    },

    props: {
        src: String,
    },

    computed: {
    },

    data: () => {
        return {

        }
    },

    mounted() {

    },

    methods: {

    },

    beforeDestroy() {

    },
}
</script>
