<template>
    <div class="stream">

        <div class="stream__list-group nugget-list">

            <stream-keyvisual
                v-if="highlight"
                :inline="true"
                :keyvisual="stream.keyvisual"
                :settings="stream.keyvisual.settings"
                :url="stream.url"
                @clickMore="handleStreamMoreClick" />

            <router-link
                v-else
                :to="{ path: `/${stream.url}` }"
                @click.native="handleStreamClick"
                class="stream__list-group-header"
            >
                <span class="stream__list-group-header-headline">{{ stream.keyvisual.introtext.headline }}</span>

                <svgicon-wrapper v-if="!isLoadingStream" name="arrow-down-fine" class="stream__list-group-header-arrow" />
                <loader-element v-else :loading="isLoadingStream" class="stream__list-group-header-arrow" />
            </router-link>

            <template v-if="renderSlider">
                <div class="stream__slider stream__items">
                    <div class="swiper-container" ref="slider">
                        <div class="swiper-wrapper">
                            <div
                                class="stream__slider-slide swiper-slide"
                                v-for="item in stream.tiles"
                                :key="`stream-nugget-${item.id}`"
                            >
                                <fluid-tile :nugget="item" @media-loaded="handleMediaLoaded" @tileclick="handleFluidTileClick"/>
                            </div>
                        </div>
                    </div>
                    <div ref="slidePrev" class="swiper-no-swiping stream__slider-button-prev swiper-button-prev"><svgicon-wrapper name="arrow-down-fine" dir="up" color="#ffffff"/></div>
                    <div ref="slideNext" class="swiper-no-swiping stream__slider-button-next swiper-button-next"><svgicon-wrapper name="arrow-down-fine" dir="down" color="#ffffff"/></div>
                </div>
            </template>

            <div v-else class="stream__list-items stream__items">
                <router-link
                    v-for="item in stream.tiles.slice(0, 3)"
                    :key="`stream-nugget-${item.id}`"
                    class="nugget-list__list-item"
                    :to="{ path: `/${item.url}` }"
                    @click.native="(event) => handleTileClick(event, item.id)"
                >
                    <div class="nugget-list__list-item-image">
                        <div v-if="loading(item.id)" class="tile__loader">
                            <loader-element :loading="loading(item.id)" />
                        </div>
                        <image-element
                            v-if="item.tile.image"
                            :image="item.tile.image"
                            :settings="item.tile.imageSettings" />
                    </div>

                    <div class="nugget-list__list-item-content">
                        <p ref="itemHeadline" v-if="item.tile.headline" class="nugget-list__list-item-headline" v-html="item.tile.headline" />
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import debounce from 'debounce';

import { lineClamp } from '@/common/utility/lineClamp';

import ImageElement from '@/components/elements/ImageElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';
import StreamKeyvisual from '@/components/elements/hub/StreamKeyvisual.vue';
import FluidTile from '@/components/elements/tiles/FluidTile.vue';

export default {
    name: 'StreamElement',

    components: {
        ImageElement,
        LoaderElement,
        StreamKeyvisual,
        FluidTile,
    },

    props: {
        stream: Object,
        highlight: Boolean,
    },

    data() {
        return {
            slider: null,
            loader: -1,
            isLoadingStream: false,
        }
    },

    computed: {

        renderList() {
            return ['xs'].indexOf(this.$mq) !== -1;
        },

        renderSlider() {
            return !this.renderList;
        },
    },

    watch: {
        renderList(newValue) {
            if (newValue) {
                this.clampHeadlines();
                this.slider && this.slider.destroy();
            }
        },

        renderSlider(newValue) {
            if (newValue) {
                this.createSlider();
                this.clampHeadlines();
            }
        },

    },

    mounted() {
        if(this.renderSlider) {
            this.createSlider();
        }

        this.clampHeadlines();
    },

    methods: {
        loading(id) {
            return this.loader == id;
        },

        async createSlider() {

            await this.$nextTick();

            const { slider, slideNext, slidePrev } = this.$refs;

            this.slider = new Swiper(slider, {
                preventClicks: true,
                slideToClickedSlide: false,
                slidesPerView: 'auto',
                spaceBetween: 5,
                watchOverflow: true,
                navigation: {
                    nextEl: slideNext,
                    prevEl: slidePrev
                },
            });

            window.s = this.slider;

            this.slider.on('resize', () => {
                this.slider.update();
            });
        },

        async clampHeadlines() {

            await this.$nextTick();

            if (!this.$refs.itemHeadline) {
                return;
            }

            let items = [];
            if (!!this.$refs.itemHeadline[0]) {
                items = this.$refs.itemHeadline;
            } else {
                items = [
                    this.$refs.itemHeadline
                ];
            }

            items.forEach(i => lineClamp(i, {clamp: 2}));
        },

        handleTileClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                // this.$emit('navigated');
                this.loader = id;
            }

            this.$emit('nuggetClick');
        },

        handleFluidTileClick() {
            this.$emit('nuggetClick');
        },

        handleStreamMoreClick() {
            this.$emit('streamClick');
        },

        handleStreamClick(event) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                // this.$emit('navigated');
                this.isLoadingStream = true;
            }

            this.$emit('streamClick');
        },

        handleMediaLoaded() {
            this.slider && this.slider.update();
        },
    },

    beforeDestroy() {
        this.slider && this.slider.destroy();
    }
}
</script>
