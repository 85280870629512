
import { RequirementsService } from "@/api";
import {
    REQUIREMENTS_MATCH,
    REQUIREMENTS_COMPLETE,
    CHECK_ENTRY,
    CHECK_ENTRIES,
    CHECK_REQUIREMENTS,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,

} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
});


const actions = {

    async [REQUIREMENTS_MATCH]({ commit, state, dispatch, rootGetters }, slug) {

        commit(FETCH_START);

        try {

            const { data } = await RequirementsService.match(slug);

            commit(FETCH_END);

            return data.success;

        } catch(e) {
            commit(FETCH_END);
        }

        return false;
    },

    async [REQUIREMENTS_COMPLETE]({ commit }, uid) {

        commit(FETCH_START);

        try {

            const { data } = await RequirementsService.complete(uid);

            commit(FETCH_END);

            return data.success;

        } catch(e) {
            commit(FETCH_END);
        }

        return false;
    },

    async [CHECK_ENTRY]({ commit }, uid) {

        commit(FETCH_START);

        try {

            const { data } = await RequirementsService.checkEntry(uid);

            commit(FETCH_END);

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return false;
    },

    async [CHECK_ENTRIES]({ commit }, uids) {

        commit(FETCH_START);

        try {

            const { data } = await RequirementsService.checkEntries(uids);

            commit(FETCH_END);

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return false;
    },

    async [CHECK_REQUIREMENTS]({ commit }, uids) {

        commit(FETCH_START);

        try {

            const { data } = await RequirementsService.checkRequirements(uids);

            commit(FETCH_END);

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return false;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },
};


export const requirements = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
