/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-down-fine': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 17',
    data: '<polygon pid="0" points="2.9,-0.1 1.3,1.5 8.2,8.4 1.3,15.3 2.9,16.9 10.7,9.1 11.3,8.4" _fill="#344384"/>'
  }
})
