
import { ApiService } from '..';

export const TrackingService = {

    trackVisit(uid) {
        const path = `track/visit/${uid}`
        return ApiService.post(path, { visit: true });
    },

    trackCompletion(uid, data) {
        const path = `track/completion/${uid}`
        return ApiService.post(path, data);
    },
};