
import { KnowledgeTestService } from "@/api";

import {
    KNOWLEDGE_TEST_FETCH,
    KNOWLEDGE_TEST_SET,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_KNOWLEDGE_TEST
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    slug: null,
    knowledgeTests: {}
});

const actions = {

    async [KNOWLEDGE_TEST_FETCH]({ commit, state }, slug) {

        commit(FETCH_START);

        try {
            const { data } = await KnowledgeTestService.get(slug);
            commit(SET_KNOWLEDGE_TEST, { ...data, slug });

            return data;

        } catch(e) {
            commit(FETCH_END);
        }
    },

    [KNOWLEDGE_TEST_SET]({ commit }, data) {
        commit(SET_KNOWLEDGE_TEST, data);
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_KNOWLEDGE_TEST](state, { startScreen, maximumInteractions, completionPercentage, randomizeInteractions, randomizeChoices, interactions, conclusion, trackingId, slug}) {

        state.slug = slug;
        state.isLoading = false;
        state.knowledgeTests[slug] = {
            startScreen,
            maximumInteractions,
            completionPercentage,
            randomizeInteractions,
            randomizeChoices,
            conclusion,
            interactions,
            trackingId,
        };
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getStartScreen: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].startScreen;
    },

    getInteractions: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].interactions;
    },

    getMaximumInteractions: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].maximumInteractions;
    },

    getRandomizeInteractions: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].randomizeInteractions;
    },

    getRandomizeChoices: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].randomizeChoices;
    },

    getCompletionPercentage: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].completionPercentage;
    },

    getConclusion: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].conclusion;
    },

    getTrackingId: (state) => {
        if (!state.knowledgeTests[state.slug]) { return null; }

        return state.knowledgeTests[state.slug].trackingId;
    },
};


export const knowledgeTest = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
