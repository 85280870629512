<template>
    <div
        v-if="!isLoadingUiTexts"
        class="knowledge-test"
    >

        <div class="knowledge-test__interactions content-dimensioning">
            <base-section v-if="startScreen" :section="startScreen"></base-section>

            <base-section
                v-for="activeInteraction in interactions"
                :section="activeInteraction"
                :key="`interaction-${activeInteraction.id}`">
                <div slot="component">

                    <choice-element
                        :key="`interaction-index-${activeInteraction.id}`"
                        ref="activeInteraction"
                        v-if="activeInteraction.type == 'section-single-multiple-choice'"
                        :choices="activeInteraction.component.choices"
                        :evaluates-external="true"
                        :settings="activeInteraction.settings.choice"
                        :randomize-choices="randomizeChoices" />

                    <value-slider-element
                        :key="`interaction-index-${activeInteraction.id}`"
                        ref="activeInteraction"
                        v-if="activeInteraction.type == 'section-value-slider'"
                        :evaluates-external="true"
                        :slider="activeInteraction.component.valueSlider" />

                    <sortable-options-element
                        :key="`interaction-index-${activeInteraction.id}`"
                        ref="activeInteraction"
                        v-if="activeInteraction.type == 'section-sortable-options'"
                        :evaluates-external="true"
                        :options="activeInteraction.component.sortableOptions"
                        />

                </div>
            </base-section>

            <base-section v-if="conclusion" :section="conclusion">
                <div slot="component">
                    <conclusion-element :feedback="conclusion.component.knowledgeTestFeedback" type="positive" :score="100" score-unit="%" />
                    <conclusion-element :feedback="conclusion.component.knowledgeTestFeedback" type="negative" :score="0" score-unit="%" />
                </div>
            </base-section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS
} from "@/store/actions.type";

import shuffle from '@/common/utility/shuffleArray';

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';
import ChoiceElement from '@/components/elements/quiz/ChoiceElement.vue';
import ValueSliderElement from '@/components/elements/quiz/ValueSliderElement.vue';
import SortableOptionsElement from '@/components/elements/quiz/SortableOptionsElement.vue';
import ConclusionElement from '@/components/elements/quiz/ConclusionElement.vue';

export default {
    name: 'KnowledgeTest',
    components: {
        TransitionExpand,
        BaseSection,
        ButtonElement,
        FeedbackElement,
        ChoiceElement,
        ValueSliderElement,
        SortableOptionsElement,
        ConclusionElement,
    },
    props: {
        startScreen: Object,
        interactions: Array,
        conclusion: Object,
        randomizeInteractions: {
            type: Boolean,
            default: true
        },
        randomizeChoices: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {

        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),
    },

    async created() {
        await store.dispatch(UITEXTS_FETCH_NS);
    },

    methods: {
    }
}
</script>
