<template>
    <div>
        <div class="loading" v-if="isLoading">loading...</div>
        <transition
            name="page"
            mode="out-in"
        >
            <router-view v-if="!isLoading" />
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import {
    UITEXTS_FETCH_NS,
    META_SET_NS
} from "@/store/actions.type";

import ErrorElement  from '@/components/elements/ErrorElement.vue';
export default {
    name: 'App',

    components: {
        ErrorElement,
    },

    props: {
        alternativeBrand: {
            type: Boolean,
            default: false
        },
        canAccessCp: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            isLoadingUiText: 'isLoading',
            t: 't'
        }),

        ...mapGetters('companion', {
            isLoadingItems: 'isLoading'
        }),

        ...mapGetters('stream', {
            isLoadingStream: 'isLoading'
        }),

        isLoading() {
            return this.isLoadingUiText || this.isLoadingItems || this.isLoadingStream;
        },
    },

    data() {
        return {
            error: false,
        }
    },

    async created() {

        this.$http.interceptors.response.use(undefined, (error) => {

            return new Promise(async (resolve, reject) => {
                if (error.response.status === 403) {
                    this.error = true;
                }

                this.$router.push({ name: 'error' })

                reject(error.response);
            });
        });

        await store.dispatch(UITEXTS_FETCH_NS);

        store.dispatch(META_SET_NS, {
            isAlternativeBrand: this.alternativeBrand,
            canAccessCp: this.canAccessCp,
        });
    },
}
</script>
