
<template>
    <base-section
        :section="getSection"
        :class="{
            'view-quiz-duel-start' : true,
            'view-quiz-duel-start--split' : me && opponent,
        }"
    >
        <div slot="component">
            <div class="quiz-duel-start">
                <div class="quiz-duel-start__logo">
                    <svgicon-wrapper name="quizduel_logo" class="no-rtl" />
                </div>

                <div class="quiz-duel-start__play">

                    <transition name="slide-right">
                        <quiz-duel-player-element
                            v-if="me && opponent"
                            :player="me" />
                    </transition>

                    <span
                        :class="{
                            'quiz-duel-start__play-button': true,
                            'quiz-duel-start__play-button--loading': isLoading,
                        }"
                        @click="handlePlayButtonClick"
                    >
                        <div v-if="isLoading" class="quiz-duel-start__loader">
                            <loader-element :loading="isLoading" />
                        </div>
                        <template v-else>
                            <svgicon-wrapper
                                v-if="!opponent"
                                class="quiz-duel-start__play-button-icon"
                                name="play_big" />

                            <countdown-element
                                v-else
                                :seconds="timerDuration"
                                :start="timerStarted"
                                :pad-zeros="false"
                                class="quiz-duel-start__play-button-countdown"
                                @expired="handleTimerExpired" />
                        </template>
                    </span>

                    <transition name="slide-left">
                        <quiz-duel-player-element
                        v-if="me && opponent"
                        :player="opponent" />
                    </transition>

                </div>
            </div>
        </div>
    </base-section>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";


import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import CountdownElement from '@/components/elements/CountdownElement.vue';
import QuizDuelElement from '@/components/elements/QuizDuelElement.vue';
import QuizDuelPlayerElement from '@/components/elements/quiz-duel/QuizDuelPlayerElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

const TIMER_DURATION = 5;

export default {
    name: 'QuizDuelStartElement',

    components: {
        TransitionExpand,
        BaseSection,
        ButtonElement,
        CountdownElement,
        QuizDuelElement,
        QuizDuelPlayerElement,
        LoaderElement,
    },

    props: {
        me: Object,
        opponent: Object,
        isLoading: Boolean,
    },

    data() {
        return {
            timerDuration: TIMER_DURATION,
            timerStarted: false,
        }
    },

    watch: {
        async opponent(newValue, oldValue) {
            this.timerStarted = false;
            await this.$nextTick();
            this.timerDuration = TIMER_DURATION;
            this.timerStarted = true;
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": null,
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": '',
                        "backgroundImage": null,
                        "textBehavior": null,
                    },
                    "layout": {
                        "columns": "",
                        "size": "content-fit"
                    },
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },
    },

    created() {
    },

    mounted() {
        this.timerStarted = false;

        if (this.opponent) {
            this.timerStarted = true;
        }

        if (this.$router.currentRoute.query.hasOwnProperty('o')) {
            this.$router.replace({
                ...this.$router.currentRoute,
                query: null
            });
        }
    },

    methods: {

        handlePlayButtonClick() {

            if (this.isLoadingOpponent) {
                return;
            }

            if (!this.opponent) {
                this.$emit('requestOpponent');
            } else {
                this.timerStarted = false;
                this.$emit('accept');
            }
        },

        handleAcceptClick() {
            this.timerStarted = false;
            this.$emit('accept');
        },

        handleTimerExpired() {
            this.$emit('accept');
        },
    }
}
</script>
