import Vue from 'vue';

import { sortByProperty } from '@/common/utility/sorting';

import { QuizDuelService } from "@/api";
import {
    QUIZDUEL_FETCH_MATCH,
    QUIZDUEL_FETCH_OPPONENT,
    QUIZDUEL_RESET,
    QUIZDUEL_ANSWER,
    QUIZDUEL_REPORT,
    QUIZDUEL_FETCH_HISTORY,
    QUIZDUEL_FETCH_LEADERBOARD,
    UPDATE_QUIZDUEL_NICKNAME_SEARCH,
    QUIZDUEL_CHECK_ACCESS,
} from "@/store/actions.type";

import {
    FETCH_QUIZDUEL_OPPONENT_START,
    FETCH_QUIZDUEL_OPPONENT_END,

    FETCH_QUIZDUEL_MATCH_START,
    FETCH_QUIZDUEL_MATCH_END,

    FETCH_QUIZDUEL_RESULT_START,
    FETCH_QUIZDUEL_RESULT_END,

    FETCH_QUIZDUEL_HISTORY_START,
    FETCH_QUIZDUEL_HISTORY_END,

    FETCH_QUIZDUEL_LEADERBOARD_START,
    FETCH_QUIZDUEL_LEADERBOARD_END,

    SET_QUIZDUEL_OPPONENT,
    SET_QUIZDUEL_MATCH,
    SET_QUIZDUEL_ANSWER,
    SET_QUIZDUEL_HISTORY,
    SET_QUIZDUEL_LEADERBOARD,
    SET_QUIZDUEL_NICKNAME_SEARCH,
    SET_RESULTS,

    SET_ERROR,
    RESET_QUIZDUEL,
} from "@/store/mutations.type";

const state = () => ({
    error: null,
    isLoadingOpponent: false,
    isLoadingMatch: false,
    isLoadingResult: false,
    isLoadingHistory: false,
    isLoadingLeaderboard: false,
    isLoadingAccess: false,
    me: null,
    opponent: null,
    sessionUid: '',
    matchType: '',
    questions: [],
    history: [],
    leaderboard: [],
    nicknameSearch: '',
});

const actions = {

    async [QUIZDUEL_FETCH_OPPONENT]({ commit, state }, { slug, opponent }) {

        if (state.isLoadingOpponent) {
            return null;
        }

        commit(FETCH_QUIZDUEL_OPPONENT_START);

        try {
            let fetchResult = null;

            if (opponent) {
                fetchResult = await QuizDuelService.getOpponentByNickname(slug, opponent);
            } else {
                fetchResult = await QuizDuelService.getRandomOpponent(slug);
            }

            const { data } = fetchResult;

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
            } else {
                commit(SET_QUIZDUEL_OPPONENT, {
                    ...data
                });
            }

            return data;

        } catch(e) {
            commit(FETCH_QUIZDUEL_OPPONENT_END);
        }

        return null;
    },

    async [QUIZDUEL_FETCH_MATCH]({ commit, state}, { slug }) {

        if (state.isLoadingMatch) {
            return null;
        }

        commit(FETCH_QUIZDUEL_MATCH_START);

        try {
            const { data } = await QuizDuelService.getMatch(slug, state.opponent.nickname);

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
            } else {
                commit(SET_QUIZDUEL_MATCH, {
                    ...data
                });
            }

            return data;

        } catch(e) {
            commit(FETCH_QUIZDUEL_MATCH_END);
        }

        return null;
    },

    async [QUIZDUEL_FETCH_HISTORY]({ commit, state}, { slug }) {

        if (state.isLoadingHistory) {
            return null;
        }

        commit(FETCH_QUIZDUEL_HISTORY_START);

        try {
            const { data } = await QuizDuelService.getHistory(slug);

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
            } else {
                commit(SET_QUIZDUEL_HISTORY, data);
            }

            return data;

        } catch(e) {
            commit(FETCH_QUIZDUEL_HISTORY_END);
        }

        return null;
    },

    async [QUIZDUEL_FETCH_LEADERBOARD]({ commit, state}, { slug }) {

        if (state.isLoadingLeaderboard) {
            return null;
        }

        if (!!state.leaderboard.length) {
            return state.leaderboard;
        }

        commit(FETCH_QUIZDUEL_LEADERBOARD_START);

        try {
            const { data } = await QuizDuelService.getLeaderboard(slug);

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
            } else {
                commit(SET_QUIZDUEL_LEADERBOARD, data);
            }

            return data;

        } catch(e) {
            commit(FETCH_QUIZDUEL_LEADERBOARD_END);
        }

        return null;
    },

    [QUIZDUEL_ANSWER]({ commit, state }, { questionIndex, passed, time }) {
        commit(SET_QUIZDUEL_ANSWER, {
            questionIndex,
            passed,
            time,
        });
    },

    async [QUIZDUEL_REPORT]({ commit, state }, { slug }) {

        if (state.isLoadingResult) {
            return null;
        }

        commit(FETCH_QUIZDUEL_RESULT_START);

        try {
            const { data } = await QuizDuelService.postSession(slug, state.sessionUid, {
                questions: state.questions.reduce((current, q) => {
                    return {
                        ...current,
                        [q.uid]: {
                            passed: q.passed,
                            time: q.time,
                        }
                    };
                }, [])
            });

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
            } else {
                commit(SET_RESULTS, {
                    ...data
                });
            }

            return data;

        } catch(e) {
            commit(FETCH_QUIZDUEL_RESULT_END);
        }

        return null;
    },

    [QUIZDUEL_RESET]({ commit, state }) {
        commit(RESET_QUIZDUEL);
    },

    async [QUIZDUEL_CHECK_ACCESS]({ commit, state }, { slug }) {

        let access = false;
        try {
            const { data } = await QuizDuelService.checkAccess(slug);
            access = data.allowed;

        } catch(e) { }

        return access;
    },

    [UPDATE_QUIZDUEL_NICKNAME_SEARCH]({ commit, state }, nickname) {
        commit(SET_QUIZDUEL_NICKNAME_SEARCH, nickname);
    },
};

const mutations = {

    [SET_ERROR](state, message) {
        state.error = message;
    },

    [FETCH_QUIZDUEL_OPPONENT_START](state) {
        state.isLoadingOpponent = true;
    },

    [FETCH_QUIZDUEL_OPPONENT_END](state) {
        state.isLoadingOpponent = false;
    },

    [SET_QUIZDUEL_OPPONENT](state, { me, opponent }) {
        Vue.set(state, 'me', me);
        Vue.set(state, 'opponent', opponent);
        state.isLoadingOpponent = false;
        state.error = null;
        state.sessionUid = null;
        state.matchType = '';
        state.questions = [];
    },


    [FETCH_QUIZDUEL_MATCH_START](state) {
        state.isLoadingMatch = true;
    },

    [FETCH_QUIZDUEL_MATCH_END](state) {
        state.isLoadingMatch = false;
    },

    [SET_QUIZDUEL_MATCH](state, { session, matchType, questions }) {

        Vue.set(state, 'questions', questions.map(q => {
            return {
                ...q,
                passed: null,
                time: null,
            }
        }));

        state.sessionUid = session;
        state.matchType = matchType;
        state.isLoadingMatch = false;
        state.error = null;
    },


    [FETCH_QUIZDUEL_HISTORY_START](state) {
        state.isLoadingHistory = true;
    },

    [FETCH_QUIZDUEL_HISTORY_END](state) {
        state.isLoadingHistory = false;
    },

    [SET_QUIZDUEL_HISTORY](state, historyItems) {
        Vue.set(state, 'history', historyItems);
        state.isLoadingHistory = false;
        state.error = null;
    },


    [FETCH_QUIZDUEL_LEADERBOARD_START](state) {
        state.isLoadingLeaderboard = true;
    },

    [FETCH_QUIZDUEL_LEADERBOARD_END](state) {
        state.isLoadingLeaderboard = false;
    },

    [SET_QUIZDUEL_LEADERBOARD](state, leaderboard) {

        let leaderboardItems = leaderboard
            .sort((entryA, entryB) => sortByProperty(entryA, entryB, 'score', false))
            .map((item, index) => {
                return {
                    ...item,
                    id: 'id_' + index
                }
            });

        Vue.set(state, 'leaderboard', leaderboardItems);
        state.isLoadingLeaderboard = false;
        state.error = null;
    },


    [SET_QUIZDUEL_ANSWER](state, { questionIndex, passed, time }) {

        const question = state.questions[questionIndex];

        if (question) {
            Vue.set(state.questions, questionIndex, {
                ...question,
                passed,
                time,
            });
        }
    },

    [FETCH_QUIZDUEL_RESULT_START](state) {
        state.isLoadingResult = true;
    },

    [FETCH_QUIZDUEL_RESULT_END](state) {
        state.isLoadingResult = false;
    },

    [SET_RESULTS](state, { me, opponent }) {
        Vue.set(state, 'me', me);
        Vue.set(state, 'opponent', opponent);
        state.error = null;
        state.isLoadingResult = false;
    },


    [SET_QUIZDUEL_NICKNAME_SEARCH](state, nickname) {
        state.nicknameSearch = nickname;
    },

    [RESET_QUIZDUEL](state) {
        state.error = null;
        state.isLoadingOpponent = false;
        state.isLoadingMatch = false;
        state.isLoadingResult = false;
        state.isLoadingHistory = false;
        state.isLoadingLeaderboard = false;
        state.sessionUid = '';
        state.matchType = '';
        state.me = null;
        state.opponent = null;
        state.questions = [];
        state.nicknameSearch = '';
    }
};

const getters = {

    getError: (state) => {
        return state.error;
    },

    isLoadingOpponent: (state) => {
        return state.isLoadingOpponent;
    },

    isLoadingMatch: (state) => {
        return state.isLoadingMatch;
    },

    isLoadingResult: (state) => {
        return state.isLoadingResult;
    },

    isLoadingHistory: (state) => {
        return state.isLoadingHistory;
    },

    isLoadingLeaderboard: (state) => {
        return state.isLoadingLeaderboard;
    },

    getOpponent: (state) => {
        return state.opponent;
    },

    getMe: (state) => {
        return state.me;
    },

    getHistory: (state) => {

        if (!state.history || !state.history.length) {
            return [];
        }

        return state.history;
    },

    getQuestions: (state) => {

        if (!state.questions || !state.questions.length) {
            return null;
        }

        let questions = JSON.parse(JSON.stringify(state.questions));

        return questions;
    },

    getMatchType: (state) => {
        return state.matchType;
    },

    getTopFromAll: (state) => {
        if (!state.leaderboard) { return []; }

        let filteredItems = JSON.parse(JSON.stringify(state.leaderboard))
            .filter((item) => {
                return !item.hasOwnProperty('me') && item.nickname;
            });

        let topList = filteredItems.slice(0, Math.min(100, filteredItems.length));
        topList = topList.sort(() => 0.5 - Math.random());

        return topList.slice(0, Math.min(5, topList.length));
    },

    getRandomFromWorld: (state) => {
        if (!state.leaderboard) { return []; }

        let filteredItems = JSON.parse(JSON.stringify(state.leaderboard))
            .filter((item) => {
                return !item.hasOwnProperty('me') && item.nickname;
            });

        let topList = filteredItems.sort(() => 0.5 - Math.random());

        return topList.slice(0, Math.min(5, topList.length));
    },

    getRandomFromMarket: (state) => {
        if (!state.leaderboard) { return []; }

        let filteredItems = JSON.parse(JSON.stringify(state.leaderboard));

        const me = filteredItems.find(i => i.hasOwnProperty('me') && i['me']);

        filteredItems = filteredItems
            .filter((item) => {
                return !item.hasOwnProperty('me') && item.nickname;
            });

        if (me) {
            filteredItems = filteredItems
                .filter((item) => {
                    return item.market == me.market;
                });
        }

        let topList = filteredItems.sort(() => 0.5 - Math.random());

        return topList.slice(0, Math.min(5, topList.length));
    },

    getNicknameSearch: (state) => {
        return state.nicknameSearch;
    },

    getNicknameResults: (state) => {

        if (!state.leaderboard || !state.nicknameSearch || !state.nicknameSearch.trim().length) {
            return null;
        }

        let filteredItems = JSON.parse(JSON.stringify(state.leaderboard))
            .filter((item) => {
                return !item.hasOwnProperty('me') &&
                    item.hasOwnProperty('nickname') && item.nickname &&
                    item.nickname.toLowerCase().indexOf(state.nicknameSearch.toLowerCase()) !== -1;
            });

        return filteredItems.slice(0, 10);
    },
};


export const quizDuel = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
