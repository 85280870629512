import { ApiService } from '..';

const suffix = window.EXPORT_TYPE_SCORM ? '.json' : ''

export const UITextsService = {

    getTexts() {
        return ApiService.get(`uitexts${suffix}`);
    },

    getLanguages() {
        return ApiService.get(`available-languages${suffix}`, null, false);
    },

    switchLanguage(language) {
        return ApiService.post('switchLanguage', { language });
    }
};
