<template>
    <div
        :class="{
            'feedback': true,
            'feedback--passed': evaluation.passed,
            'feedback--failed': !evaluation.passed,
            'feedback--default': !centered,
            'feedback--centered': centered,
        }"
    >
        <div class="feedback__grid">

            <div class="feedback__grid-column feedback__grid-column--icon">
                <div class="feedback__icon"><svgicon-wrapper :name="activeFeedback.icon" width="26" height="26" /></div>
            </div>

            <div
                v-if="activeFeedback.headline || activeFeedback.copytext"
                class="feedback__grid-column feedback__grid-column--text"
            >
                <span
                    v-if="activeFeedback.headline"
                    class="feedback__headline"
                    v-html="activeFeedback.headline" />

                <span
                    v-if="activeFeedback.copytext"
                    class="feedback__copytext"
                    v-html="activeFeedback.copytext" />
            </div>

            <div
                v-if="!nocontrols && (activeFeedback.retryButton || activeFeedback.solveButton)"
                class="feedback__grid-column feedback__grid-column--buttons"
            >
                <button-element
                    v-if="activeFeedback.retryButton"
                    @click="handleRetryClicked"
                    :button="{
                        'label': t('try-again')
                    }" />

                <button-element
                    v-if="activeFeedback.solveButton"
                    @click="handleSolveClicked"
                    :button="{
                        'label': t('solution')
                    }" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
    name: 'FeedbackElement',

    components: {
        ButtonElement,
    },

    props: {
        feedback: Object,
        evaluation: Object,
        nocontrols: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),
    },

    data() {
        return {
            activeFeedback: {
                headline: null,
                copytext: null,
                icon: null,
                retryButton: false,
                solveButton: false,
            }
        }
    },

    created() {
        let activeFeedback = null;
        const type = this.evaluation.passed ? 'positive' : 'negative';
        const hasSolutionFeedback =
            this.feedback.hasOwnProperty('solution') &&
            this.feedback.solution &&
            (this.feedback.solution.headline || this.feedback.solution.copytext);

        if (this.feedback.hasOwnProperty(type) && this.feedback[type]) {
            activeFeedback = this.feedback[type];
        }

        this.activeFeedback = Object.assign({}, this.activeFeedback, {
            headline: activeFeedback && activeFeedback.headline,
            copytext: activeFeedback && activeFeedback.copytext,
            icon: this.evaluation.passed ? 'checkmark' : 'cross',
            retryButton: !this.evaluation.passed,
            solveButton: !this.evaluation.passed && hasSolutionFeedback,
        });
    },

    methods: {
        handleRetryClicked() {

            this.activeFeedback = Object.assign({}, this.activeFeedback, {
                headline: null,
                copytext: null,
                icon: null,
                retryButton: false,
                solveButton: false,
            });

            this.$emit('retry');
        },
        handleSolveClicked() {

            if (this.feedback.hasOwnProperty('solution') && this.feedback.solution) {
                const { solution } = this.feedback;
                this.activeFeedback = Object.assign({}, this.activeFeedback, {
                    headline: solution && solution.headline,
                    copytext: solution && solution.copytext,
                    icon: 'cross',
                    retryButton: true,
                    solveButton: false,
                });

                this.$emit('solve');
            }
        }
    }
}
</script>
