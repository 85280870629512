
<template>
    <div class="level-indicator">
        <div class="level-indicator__trophy">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                <g fill="none" fill-rule="evenodd">
                    <path
                        d="M198.202 82c-.106 4.247-.828 10.54-2.316 14.52-1.99 5.314-5.06 9.933-8.39 11.682a107.466 107.466 0 0 0 2.604-23.51V82l-81.014.005v2.691c0 8.113.927 16.057 2.604 23.51-2.913-1.53-5.669-5.255-7.67-9.723-2.099-4.684-3.021-11.343-3.028-16.475l-5.399-.003v2.691c0 5.985 1.944 13.25 5.077 18.957 3.474 6.333 7.9 10.011 12.574 10.574 5.48 18.846 15.89 33.638 28.236 38.366V174.41c.077 3.705-2.568 6.895-6.04 7.324l-18.256.024.01 24.229-.015.014H181.963v-26.964l-29.65.006.007 5.39h.013l-.005.005 24.237-.005v16.186l-53.982.003.001-13.488 13.012-.016.276-.014c6.3-.656 11.132-6.278 10.998-12.748l.043-25.685-1.943-.564c-18.193-5.283-29.69-35.042-30.454-60.708l70.154.005c-.763 25.662-12.261 55.417-30.454 60.699l-1.942.565.033 19.597h5.4l-.042-15.656c12.365-4.71 22.791-19.516 28.279-38.386 4.672-.56 9.1-4.24 12.572-10.574 3.133-5.708 5.077-12.97 5.077-18.957V82h-5.391z"
                        fill="#FFF"
                        fill-rule="nonzero" />

                    <text x="150" y="122" text-anchor="middle" class="level-indicator__current-level">{{ getCurrentLevel.level }}</text>
                    <circle
                        class="level-indicator__progress-line"
                        cx="150"
                        cy="150"
                        :r="circleRadius"
                        stroke="#FFF"
                        stroke-width="2" />

                    <circle
                        class="level-indicator__progress-bar"
                        cx="150"
                        cy="150"
                        :r="circleRadius"
                        stroke="#FFF"
                        stroke-linejoin="round"
                        stroke-width="6"
                        :stroke-dashoffset="getDashOffset"
                        :stroke-dasharray="getDashArray"
                        stroke-linecap="round" />
                </g>
            </svg>
        </div>
        <div class="level-indicator__text">
            <span v-if="nickname" class="level-indicator__nickname">{{ nickname }}</span>
            <v-runtime-template
                v-if="pointsToNextLevel"
                class="level-indicator__points-text"
                :template="`<span>${ t('points-to-next-level') }</span>`" />
        </div>
    </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template/dist/v-runtime-template";
import { numberFormat } from '@/common/utility/filters';
import { mapGetters } from "vuex";

export default {
    name: "LevelIndicatorElement",

    components: {
        VRuntimeTemplate,
    },

    props: {
        levelBreakpoints: Array,
        nickname: String,
        score: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            circleRadius: 150
        };
    },

    computed: {
        ...mapGetters("uitexts", {
            t: "t"
        }),

        pointsToNextLevel() {

            const lastLevel = this.levelBreakpoints[this.levelBreakpoints.length - 1];
            if (this.getCurrentLevel.level == lastLevel.level) {
                return 0;
            }

            return numberFormat(this.getNextLevel.scoreNeeded - this.score);
        },

        getNextLevel() {
            let nextLevel = this.getCurrentLevel;
            let found = false;
            this.levelBreakpoints.forEach(levelItem => {
                if (!found && levelItem.level > nextLevel.level) {
                    nextLevel = levelItem;
                    found = true;
                }
            });

            return nextLevel;
        },

        getCurrentLevel() {

            let currentLevel = this.levelBreakpoints[0];

            this.levelBreakpoints.slice(1).forEach(levelItem => {
                if (this.score >= levelItem.scoreNeeded) {
                    currentLevel = levelItem;
                }
            });

            return currentLevel;
        },

        getProgress() {
            return this.score / this.getNextLevel.scoreNeeded * 100;
        },

        getDashArray() {
            return Math.PI * (this.circleRadius * 2);
        },

        getDashOffset() {

            const r = this.circleRadius;
            const c = this.getDashArray;
            let dashOffset = ((100 - this.getProgress) / 100) * c;

            dashOffset = Math.max(0, Math.min(100, dashOffset));

            return dashOffset;
        },
    },

    async created() {},

    async mounted() {},

    methods: {}
};
</script>

