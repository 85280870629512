
module.exports = () => {
    function placeholderPolyfill() {
        this.classList[this.value ? 'remove' : 'add']('placeholder-shown');
    }

    [...document.querySelectorAll('[placeholder]')].forEach(el => {
        el.classList[el.value ? 'remove' : 'add']('placeholder-shown');

        el.addEventListener('change', placeholderPolyfill);
        el.addEventListener('keyup', placeholderPolyfill);
    });
};
