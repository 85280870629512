<template>
    <div class="view-training-hub training-hub">

        <page-header
            :has-search="true"
            :headroom-disabled="true"
        >
            <li slot="usermenuExtraListItems" class="user-menu__item">
                <span class="user-menu__item-link" @click="handleRestartTutorial">
                    <svgicon-wrapper name="start" />{{ t('restart-tutorial') }}
                </span>
            </li>
        </page-header>

        <tile-grid
            :highlight-items="getHighlights"
            :container1-item="getContainer1"
            :container2-item="getContainer2"
            :container3-item="getContainer3"
            :container4-item="getContainer4"
            :container5-item="getContainer5"
            :container6-item="getContainer6"
            :experiences="getExperiences"
            :external-content="getExternalContent" />

        <!-- <hub-tutorial v-if="mustDoHubTutorial" /> -->

        <page-footer />
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    ALTERNATIVE_TRAINING_HUB_FETCH_NS,

    ME_GET_NS,
    ME_FORCE_HUB_TOUR_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';

import TileGrid from '@/components/elements/hub/tile-grid/TileGrid.vue';
import HubTutorial from '@/components/elements/hub/HubTutorial.vue';

export default {
    name: 'AlternativeHubView',
    components: {
        PageHeader,
        PageFooter,
        HubTutorial,
        TileGrid,
    },

    props: {
        content: Object
    },

    computed: {

        ...mapGetters('me', {
            mustDoHubTutorial: 'mustDoHubTutorial',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('common', {
            isHeaderPinned: 'isPinned',
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('alternativeTrainingHub', {
            isLoadingTrainingHub: 'isLoading',
            getHighlights: 'getHighlights',
            getContainer1: 'getContainer1',
            getContainer2: 'getContainer2',
            getContainer3: 'getContainer3',
            getContainer4: 'getContainer4',
            getContainer5: 'getContainer5',
            getContainer6: 'getContainer6',
            getExternalContent: 'getExternalContent',
            getExperiences: 'getExperiences',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": null,
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": '',
                        "backgroundImage": null,
                        "textBehavior": null,
                    },
                    "layout": {
                        "columns": "",
                        "size": "page-fit"
                    },
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },


    },
    data() {
        return {
            search: null,
        }
    },

    async beforeRouteEnter (to, from, next) {
        await store.dispatch(UITEXTS_FETCH_NS);
        await store.dispatch(ME_GET_NS);
        await store.dispatch(ALTERNATIVE_TRAINING_HUB_FETCH_NS);

        next();
    },

    beforeRouteLeave(to, from, next) {

        if (to.name == 'stream' || to.name == 'nugget') {
            this.setBreadcrumbs([
                { text: 'Start', to: { path: from.path }}
            ]);
        }

        next();
    },

    async created() {
        if (this.content) {
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);
            // store.dispatch(TRAINING_HUB_SET_NS, this.content);
        }

        document.title = `Virtual Training Cockpit`;
    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        getStreamSection(headline) {
            const section = {
                ...this.getSection
            };

            section.settings.section.textBehavior = 'centered';

            section.introtext = {
                headline,
                subline: null,
                copytext: null,
            };

            return section;
        },

        handleEventClick() {
            // this.$gtag.event('sth.click.hub.event', {
            //     'event_category': 'click',
            //     'event_label': 'HUB Event click',
            //     'value': 1
            // });
        },

        handleRestartTutorial() {
            store.dispatch(ME_FORCE_HUB_TOUR_NS, true);
        },
    },
}
</script>
