
<template>
    <div class="quiz-duel-start">

        <div class="quiz-duel-start__logo">
            <svgicon-wrapper name="quizduel_logo" class="no-rtl" />
        </div>

        <div class="quiz-duel-start__play">
            <router-link class="quiz-duel-start__play-button" :to="getPlayRoute"  target="_blank">
                <svgicon-wrapper
                    class="quiz-duel-start__play-button-icon"
                    name="play_big" />
            </router-link>
        </div>

        <div v-if="quizDuel.description" class="quiz-duel-start__introduction" v-html="quizDuel.description" />
        <div class="quiz-duel-start__buttons">
            <button-element
                type="secondary"
                @click="openFindOpponentsOverlay"
                :button="{
                    'label': t('find-opponent')
                }" />

            <button-element
                type="secondary"
                @click="openResultsOverlay"
                :button="{
                    'label': t('results')
                }" />
        </div>

        <portal to="modals">
            <find-opponent-element
                use-link
                :open="showFindOpponentOverlay"
                :slug="getQuizDuelSlug"
                @close="closeFindOpponentOverlay" />

            <session-history-element
                :open="showResultsOverlay"
                :slug="getQuizDuelSlug"
                @close="closeResultsOverlay" />
        </portal>
    </div>
</template>

<script>
import ButtonElement from '@/components/elements/ButtonElement.vue';
import FindOpponentElement from '@/components/elements/quiz-duel/FindOpponentElement.vue';
import SessionHistoryElement from '@/components/elements/quiz-duel/SessionHistoryElement.vue';

import { mapGetters } from "vuex";
import store from "@/store";

export default {
    name: 'QuizDuelElement',

    components: {
        ButtonElement,
        FindOpponentElement,
        SessionHistoryElement,
    },

    props: {
        quizDuel: Object,
        settings: Object,
        disabled: Boolean,
    },

    data() {
        return {
            showResultsOverlay: false,
            showFindOpponentOverlay: false,
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        getPlayRoute() {
            return {
                name: 'quiz-duel',
                params: {
                    slug: this.getQuizDuelSlug,
                    findOpponent: true,
                },
                query: {
                    o: true
                }
            };
        },

        getQuizDuelSlug() {
            return this.settings.url ? this.settings.url.split('/')[1] : '';
        },
    },

    async mounted() {

    },

    methods: {
        openResultsOverlay() {
            this.showResultsOverlay = true;

            this.$gtag.event('sth.click.qd.results', {
                'event_category': 'click',
                'event_label': 'QD Results click',
                'value': 1
            });
        },

        closeResultsOverlay() {
            this.showResultsOverlay = false;
        },

        openFindOpponentsOverlay() {
            this.showFindOpponentOverlay = true;

            this.$gtag.event('sth.click.qd.find-opponent', {
                'event_category': 'click',
                'event_label': 'QD Find Opponent click',
                'value': 1
            });
        },

        closeFindOpponentOverlay() {
            this.showFindOpponentOverlay = false;
        },

        choseOpponentFromOverlay() {

        },
    },

    beforeDestroy() {

    }
}
</script>

