<template>
    <div
        v-if="calculation"
        :class="{
            'calculation': true,
            'interaction': true,
            'calculation--disabled': evaluating || disabled,
            'form-dimensioning': true
        }"
    >
        <div class="calculation__grid">
            <div class="calculation__variables">
                <template v-for="(variable, index) in variables">
                    <input-element
                        :key="`calculation-variable-${variable.id}`"
                        :placeholder="placeholder(index)"
                        type="text"
                        :disabled="evaluating || disabled"
                        v-model="variable.value"
                    />
                </template>
                <tooltip-element :label="t('show-formula')" :content="displayFormula">
                    <svgicon-wrapper slot="icon" name="information"/>
                </tooltip-element>
            </div>

            <div class="calculation__result">
                <span class="calculation__result-label">{{ t('label-result') }}:</span>
                <span class="calculation__result-value">{{ calculatedResult }}<span class="calculation__result-unit" v-if="calculation.unit">{{ calculation.unit }}</span></span>
            </div>
        </div>

        <interaction-evaluator
            v-if="!evaluatesExternal"
            :evaluating="evaluating"
            :feedback="feedback"
            :evaluation="evaluation"
            @evaluate="evaluate"
            @retry="reset"
            @solve="solve"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import InteractionEvaluator from "@/components/elements/quiz/InteractionEvaluator.vue";
import InputElement from "@/components/elements/form/InputElement.vue";
import TooltipElement from "@/components/elements/TooltipElement.vue";

export default {
    name: "CalculationElement",
    components: {
        InputElement,
        InteractionEvaluator,
        TooltipElement
    },
    props: {
        calculation: {
            type: Object,
            required: true
        },
        feedback: Object,
        evaluatesExternal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            variables: [],
            evaluating: false,
            evaluation: {
                passed: false
            }
        };
    },
    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        placeholder() {
            return index => {
                const variable = this.variables[index];
                const unit = variable.unit ? `(${variable.unit})` : '';

                return `${variable.label} ${unit}`;
            };
        },

        displayFormula() {
            let formula = this.calculation.formula;

            this.variables.forEach(item => {
                formula = formula.replace(item.variable, `"${item.label}"`);
            });

            return formula;
        },

        calculatedResult() {
            const transformedVariables = this.variables.map(item => {
                return `var ${item.variable} = ${item.value};`;
            });

            const evaluation = `${transformedVariables.join(" ")} ${
                this.calculation.formula
            }`;

            let result = 0;
            try {
                result = eval(evaluation);
            } catch (e) {}

            return this.roundNumber(result, 2);
        }
    },
    created() {
        this.variables = this.calculation.variables.map(item => {
            return {
                ...item,
                value: ""
            };
        });
    },
    mounted() {
        this.reset();
    },
    methods: {

        roundNumber(num, scale) {
            if(!("" + num).includes("e")) {
                return +(Math.round(num + "e+" + scale)  + "e-" + scale);
            } else {
                let arr = ("" + num).split("e");
                let sig = ""

                if(+arr[1] + scale > 0) { sig = "+"; }

                let i = +arr[0] + "e" + sig + (+arr[1] + scale);
                let j = Math.round(i);
                let k = +(j + "e-" + scale);

                return k;
            }
        },

        evaluate() {
            if (this.evaluating || this.disabled) {
                return;
            }

            this.evaluation = Object.assign({}, this.evaluation, {
                passed:
                    this.calculatedResult ==
                    parseFloat(this.calculation.correctValue)
            });

            this.evaluating = true;

            return this.evaluation;
        },

        reset() {
            this.evaluating = false;

            this.evaluation = Object.assign({}, this.evaluation, {
                passed: false
            });
        },

        solve() {}
    }
};
</script>
