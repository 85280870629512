
import { sortAlphabetically, sortByProperty } from '@/common/utility/sorting';

import { NuggetService } from "@/api";
import { NUGGET_FETCH, NUGGET_SET, NUGGET_RESET } from "@/store/actions.type";
import { FETCH_START, FETCH_END, SET_NUGGET, SET_SLUG, RESET_NUGGET } from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    slug: '',
    nuggets: {},
});

const actions = {

    async [NUGGET_FETCH]({ commit, state }, slug) {

        if (state.nuggets.hasOwnProperty(slug)) {
            commit(SET_SLUG, slug);
            return state.nuggets[slug];
        }

        commit(FETCH_START);

        const { data } = await NuggetService.get(slug);

        commit(SET_NUGGET, { ...data, slug });
        return data;
    },

    [NUGGET_SET]({ commit }, data) {

        commit(SET_NUGGET, data);

        return data;
    },

    [NUGGET_RESET]({ commit }) {

        commit(RESET_NUGGET);

        return true;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_SLUG](state, slug) {
        state.slug = slug;
    },

    [SET_NUGGET](state, { meta, topics, categories, sections, recommendations, nuggets, trackingId, finishModule, slug}) {

        state.slug = slug;
        state.isLoading = false;
        state.nuggets[slug] = {
            topics,
            categories,
            sections,
            recommendations,
            nuggets,
            meta,
            trackingId,
            finishModule,
        };
    },

    [RESET_NUGGET](state) {
        state.isLoading = false;
        state.slug = '';
        state.nuggets = {};
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getSections: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        if (state.nuggets[state.slug].nuggets) {

            return state.nuggets[state.slug].nuggets.reduce((carry, current) => {
                return [
                    ...carry,
                    ...current.sections,
                ];
            }, []);
        }

        return state.nuggets[state.slug].sections;
    },

    getJumpmarks: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        if (state.nuggets[state.slug].nuggets) {

            return state.nuggets[state.slug].nuggets.reduce((carry, current) => {

                const navigationItems = current.sections.filter((section) => {
                    return section.type == 'contentNavigationItem';
                });

                return [
                    ...carry,
                    ...navigationItems,
                ];
            }, []);
        }

        return state.nuggets[state.slug].sections.filter((section) => {
            return section.type == 'contentNavigationItem';
        });
    },

    getTopics: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].topics;
    },

    getCategories: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].categories;
    },

    getRecommendations: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].recommendations;
    },

    getMeta: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].meta;
    },

    getTrackingId: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].trackingId;
    },

    getFinishModuleSection: (state) => {
        if (!state.nuggets[state.slug]) { return null; }

        return state.nuggets[state.slug].finishModule;
    },
};


export const nugget = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
