/* eslint-disable */
import './arrow-down-fine'
import './arrow-down'
import './arrow-right'
import './arrow-up-down'
import './avatar-happy'
import './avatar-sad'
import './button-icon_content-fit'
import './button-icon_tip'
import './calendar_icon'
import './checkmark'
import './close-big'
import './close-small'
import './cms'
import './cross'
import './exclamation-mark'
import './feedback-negative_big'
import './feedback-positive_big'
import './filter'
import './information'
import './language'
import './lined-arrow-right'
import './logo-flat'
import './logo'
import './logout'
import './mail'
import './overview'
import './play_big'
import './quizduel_logo'
import './quote'
import './restart'
import './round-cross'
import './search'
import './start'
import './timer'
