
import { UITextsService } from "@/api";
import {
    UITEXTS_FETCH,
} from "@/store/actions.type";
import {
    FETCH_START,
    SET_UITEXTS,
    SET_LANGUAGE,
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    locale: {},
    language: 'en',
    languages: {},
    availableLanguages: [],
});

const actions = {

    async [UITEXTS_FETCH]({ commit, state }, force = false) {

        if (!force && (state.isLoading || (state.languages.hasOwnProperty(state.language) && !!Object.keys(state.languages[state.language]).length))) {
            return state;
        }

        commit(FETCH_START);

        const langData = await UITextsService.getLanguages();
        commit(SET_LANGUAGE, langData.data);

        const textData = await UITextsService.getTexts();

        const result = {
            texts: textData.data,
            // locale: langData.data
        };

        commit(SET_UITEXTS, result);

        return result;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [SET_LANGUAGE](state, { preferredLanguage, availableLanguages}) {
        state.language = preferredLanguage;
        state.availableLanguages = availableLanguages;
        // localStorage.setItem('preflanguage', handle);
    },

    [SET_UITEXTS](state, { texts }) {
        state.languages = {};
        state.languages[state.language] = texts.data.reduce((item, result) => {
            return {
                ...result,
                ...item
            }
        }, {});

        state.isLoading = false;
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getActiveLanguage: (state) => {
        return state.language;
    },

    getAvailableLanguages: (state) => {
        return state.availableLanguages;
    },

    t: (state) => (key) => {
        if (!state.languages.hasOwnProperty(state.language) || !state.languages[state.language].hasOwnProperty(key)) {
            return `MISSING: ${key}`;
        }

        return state.languages[state.language][key];
    }
};


export const uitexts = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
