/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 8.4 13.1',
    data: '<g><g><path pid="0" d="M8.4 6.5l-6.2 6.2c-.5.5-1.3.5-1.8 0s-.5-1.3 0-1.8l4.3-4.3L.4 2.2C-.1 1.7-.1.9.4.4s1.3-.5 1.8 0l6.2 6.1z" _fill="#fff"/></g></g>'
  }
})
