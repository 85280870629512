<template>
    <div v-if="thumbnails" :class="getThumbnailsClasses">

        <div class="thumbnails__page-container">
            <div class="thumbnails__content-container">

                <div class="thumbnails__grid">
                    <div v-if="settings.layout == 'stage'" class="thumbnails__stage-decoration"></div>

                    <div class="thumbnails__grid-column thumbnails__grid-column--image">
                        <div class="thumbnails__images-container">
                            <template v-for="(item, index) in thumbnails">
                                <image-element
                                    :key="`thumbnail-item-image-${index}`"
                                    :image="item.image"
                                    :class="{
                                        'thumbnails__image': true,
                                        'thumbnails__image--active': index == activeIndex,
                                    }"
                                ></image-element>
                            </template>
                        </div>

                        <div v-if="settings.layout == 'split-50-50'" class="thumbnails__thumbs-container">
                            <span
                                v-for="(item, index) in thumbnails"
                                :key="`thumbnail-item-thumbnail-image-${index}`"
                                :class="{
                                    'thumbnails__thumb': true,
                                    'thumbnails__thumb--active': index == activeIndex,
                                }"
                                @click="(event) => handleThumbnailClick(event, index)"
                            >
                                <image-element :image="item.thumbnailImage" ></image-element>
                            </span>
                        </div>
                    </div>

                    <div class="thumbnails__grid-column thumbnails__grid-column--text">
                        <template v-for="(item, index) in thumbnails">
                            <introtext-element
                                v-if="index == activeIndex"
                                :introtext="item.introtext"
                                type="type-2"
                                :key="`thumbnail-item-text-${index}`" />

                            <slot
                                :name="`content-slot-${index}`"
                                v-if="index == activeIndex" />
                        </template>
                    </div>

                    <div
                        v-if="settings.layout == 'stacked'"
                        class="thumbnails__grid-column thumbnails__grid-column--thumbs"
                    >
                        <span
                            v-for="(item, index) in thumbnails"
                            :key="`thumbnail-item-thumbnail-image-${index}`"
                            :class="{
                                'thumbnails__thumb': true,
                                'thumbnails__thumb--active': index == activeIndex,
                            }"
                            @click="(event) => handleThumbnailClick(event, index)"
                        >
                            <image-element :image="item.thumbnailImage"></image-element>
                        </span>
                    </div>
                </div>

                <div v-if="settings.layout == 'stage'" class="thumbnails__thumbs-container">
                    <span
                        v-for="(item, index) in thumbnails"
                        :key="`thumbnail-item-thumbnail-image-${index}`"
                        :class="{
                            'thumbnails__thumb': true,
                            'thumbnails__thumb--active': index == activeIndex,
                        }"
                        @click="(event) => handleThumbnailClick(event, index)"
                    >
                        <image-element :image="item.thumbnailImage" ></image-element>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageElement from '@/components/elements/ImageElement.vue';
import TransitionFade from '@/components/transitions/TransitionFade.vue';

export default {
    name: 'ThumbnailsElement',
    components: {
        ImageElement,
        TransitionFade,
    },
    props: {
        thumbnails: {
            type: Array,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    layout: 'split-50-50'
                }
            }
        }
    },
    computed: {
        getThumbnailsClasses() {
            return {
                'thumbnails': true,
                [`thumbnails--layout-${this.settings.layout}`]: true,
            };
        }
    },
    data() {
        return {
            activeIndex: 0
        }
    },
    methods: {
        handleThumbnailClick(event, index) {
            this.activeIndex = index;
        }
    }
}
</script>
