<template>
    <div class="more-content">
        <div v-if="headline" class="more-content__grid more-content__grid--headline">
            <div class="more-content__grid-column more-content__grid-column--headline">
                <span class="more-content__headline">{{ headline }}</span>
            </div>
        </div>

        <div
            :class="{
                'more-content__grid more-content__grid--items': true,
                'more-content__grid--two': itemLimit == 2,
                'more-content__grid--three': itemLimit == 3,
            }"
        >
            <div
                v-for="item in limitedItems"
                :key="item.id"
                class="more-content__grid-column more-content__grid-column--item"
            >
                <fluid-tile :nugget="item" :disabled="item.disabled" />
                <div v-if="item.messages && !!item.messages.length" class="more-content__requirement-overlay">
                    <template v-for="(message, messageIndex) in item.messages">
                        <div class="more-content__message" :key="messageIndex" v-html="message" />
                    </template>
                </div>
            </div>
        </div>

        <template v-if="!!unlimitedItems.length">
            <div class="more-content__grid more-content__grid--headline">
                <div class="more-content__grid-column more-content__grid-column--more">
                    <button-element
                        class="more-content__button"
                        :class="{
                            'button--active': showMore
                        }"
                        @click="handleToggleMoreClick"
                        :small="true"
                        :button="{
                            'label': showMore ? t('show-less') : t('see-more')
                        }" />
                </div>
            </div>

            <transition-expand class="more-content__grid more-content__grid--items">
                <div v-if="showMore">
                    <div
                        v-for="item in unlimitedItems"
                        :key="item.id"
                        class="more-content__grid-column more-content__grid-column--item"
                    >
                        <fluid-tile :nugget="item" :disabled="item.disabled" />
                    </div>
                </div>
            </transition-expand>
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    CHECK_ENTRIES_NS,
} from "@/store/actions.type";

import FluidTile from '@/components/elements/tiles/FluidTile.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
    name: 'MoreContent',

    components: {
        FluidTile,
        TransitionExpand,
        ButtonElement,
    },

    props: {
        headline: String,
        itemLimit: Number,
        items: Array,
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),
    },

    data: () => {
        return {
            preparedItems: [],
            limitedItems: [],
            unlimitedItems: [],
            showMore: false,
            disabledItems: {},
            intervalId: null,
        }
    },

    async mounted() {

        await this.reloadItems();
    },

    methods: {
        async reloadItems() {

            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }

            let refresh = false;
            const uids = this.items.reduce((carry, current) => {
                return [
                    ...carry,
                    current.uid,
                ];
            }, []);
            const requirementMapping = await store.dispatch(CHECK_ENTRIES_NS, uids);

            this.preparedItems = this.items.map((item) => {
                const disabled = requirementMapping.hasOwnProperty(item.uid) && requirementMapping[item.uid].success === false;
                const messages = requirementMapping.hasOwnProperty(item.uid) ? requirementMapping[item.uid].messages  : null;
                refresh = refresh || disabled;
                return {
                    ...item,
                    disabled,
                    messages,
                }
            })

            if (this.itemLimit > 0 && this.preparedItems.length > this.itemLimit) {
                this.prepareItems();
            } else {
                this.limitedItems = this.preparedItems;
            }

            if (refresh) {
                this.intervalId = setInterval(async () => {
                    await this.reloadItems();
                }, 5000);
            }
        },

        handleToggleMoreClick() {
            this.showMore = !this.showMore;
        },

        prepareItems() {
            const itemCount = Math.min(this.preparedItems.length, this.itemLimit);

            let items = JSON.parse(JSON.stringify(this.preparedItems));
            this.limitedItems = items.slice(0, itemCount);
            this.unlimitedItems = items.slice(itemCount);
        },
    },

    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },
}
</script>
