
<template>
    <div v-if="loading" class="loader">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="45"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'LoaderElement',
    props: {
        loading: {
            type: Boolean,
            default: true
        }
    },
}
</script>
