import { sortByProperty } from '@/common/utility/sorting';

import Vue from 'vue';
import { TrainingHubService } from "@/api";
import {
    ALTERNATIVE_TRAINING_HUB_FETCH,
    ALTERNATIVE_TRAINING_HUB_SET,

    ALTERNATIVE_TRAINING_HUB_FETCH_EVENTS,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_ENTRY,

    FETCH_EVENTS_START,
    FETCH_EVENTS_END,
    SET_EVENT_PREVIEW,
} from "@/store/mutations.type";


const state = () => ({
    isLoading: false,

    highlights: [],
    container1: {},
    container2: {},
    container3: {},
    container4: {},
    container5: {},
    container6: {},
    experiences: [],
    externalContent: {},
});

const actions = {

    async [ALTERNATIVE_TRAINING_HUB_FETCH]({ commit, state }) {

        if (!!state.highlights.length) {
            return;
        }

        commit(FETCH_START);

        try {
            const { data } = await TrainingHubService.getAlternativeTrainingHub();

            commit(SET_ENTRY, {
                ...data
            });

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    [ALTERNATIVE_TRAINING_HUB_SET]({ commit }, data) {

        commit(SET_ENTRY, data);

        return data;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    // [FETCH_EVENTS_START](state) {
    //     state.isLoadingEvents = true;
    // },

    // [FETCH_EVENTS_END](state) {
    //     state.isLoadingEvents = false;
    // },

    [SET_ENTRY](state, { highlights, container1, container2, container3, container4, container5, container6, experiences, externalContent }) {

        state.isLoading = false;
        Vue.set(state, 'highlights', highlights);
        Vue.set(state, 'container1', container1);
        Vue.set(state, 'container2', container2);
        Vue.set(state, 'container3', container3);
        Vue.set(state, 'container4', container4);
        Vue.set(state, 'container5', container5);
        Vue.set(state, 'container6', container6);
        Vue.set(state, 'experiences', experiences);
        Vue.set(state, 'externalContent', externalContent);
    },

    // [SET_EVENT_PREVIEW](state, events) {

    //     state.isLoadingEvents = false;
    //     Vue.set(state, 'events', events);
    // },
};

const getters = {

    isLoading: (state) => {
        return state.isLoading;
    },

    // isLoadingEvents: (state) => {
    //     return state.isLoadingEvents;
    // },

    getHighlights: (state) => {

        if (state.highlights && !!state.highlights.length) {
            return state.highlights.sort((entryA, entryB) => sortByProperty(entryA, entryB, 'postDate', false));;
        }

        return state.highlights;
    },

    getContainer1: (state) => {
        return state.container1;
    },

    getContainer2: (state) => {
        return state.container2;
    },

    getContainer3: (state) => {
        return state.container3;
    },

    getContainer4: (state) => {
        return state.container4;
    },

    getContainer5: (state) => {
        return state.container5;
    },

    getContainer6: (state) => {
        return state.container6;
    },

    getExternalContent: (state) => {
        return state.externalContent;
    },

    getExperiences: (state) => {
        return state.experiences;
    },
};


export const alternativeTrainingHub = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
