import Vue from 'vue';
import { sortAlphabetically, sortByProperty } from '@/common/utility/sorting';
import NProgress from 'nprogress';

import { OverviewService } from "@/api";
import {
    OVERVIEW_PAGE_FETCH,
    OVERVIEW_CONTENT_FETCH ,
    OVERVIEW_CONTENT_SET,
} from "@/store/actions.type";

import {
    FETCH_PAGE_START,
    FETCH_PAGE_END,

    FETCH_CONTENT_START,
    FETCH_CONTENT_END,

    SET_PAGE,
    SET_CONTENT,

    SET_PAGE_SLUG,
    SET_CONTENT_SLUG,
} from "@/store/mutations.type";

const state = () => ({
    isLoadingPage: false,
    pageSlug: '',
    pages: {},

    isLoadingContent: false,
    contentSlug: false,
    contents: {},
});

const actions = {

    async [OVERVIEW_PAGE_FETCH]({ commit, state }, slug) {

        if (state.pages.hasOwnProperty(slug)) {
            commit(SET_PAGE_SLUG, slug);
            return state.pages[slug];
        }

        commit(FETCH_PAGE_START);

        try {
            const { data } = await OverviewService.getPage(slug);

            commit(SET_PAGE, {
                ...data,
                slug
            });

            return data;

        } catch(e) {
            commit(FETCH_PAGE_END);
        }

        return null;
    },

    async [OVERVIEW_CONTENT_FETCH]({ commit, state }, slug) {

        if (state.contents.hasOwnProperty(slug)) {
            commit(SET_CONTENT_SLUG, slug);
            return state.contents[slug];
        }

        commit(FETCH_CONTENT_START);

        try {
            const { data } = await OverviewService.getContent(`/${slug}`);

            commit(SET_CONTENT, {
                ...data,
                slug
            });

            return data;

        } catch(e) {
            commit(FETCH_CONTENT_END);
        }

        return null;
    },

};

const mutations = {

    [FETCH_PAGE_START](state) {
        NProgress.start();
        state.isLoadingPage = true;
    },

    [FETCH_PAGE_END](state) {
        NProgress.done();
        state.isLoadingPage = false;
    },

    [SET_PAGE_SLUG](state, slug) {
        state.pageSlug = slug;
    },

    [FETCH_CONTENT_START](state) {
        NProgress.start();
        state.isLoadingContent = true;
    },

    [FETCH_CONTENT_END](state) {
        NProgress.done();
        state.isLoadingContent = false;
    },

    [SET_CONTENT_SLUG](state, slug) {
        state.contentSlug = slug;
    },

    [SET_PAGE](state, { items, firstEntry, slug }) {
        NProgress.done();

        state.pageSlug = slug;
        state.isLoadingPage = false;
        Vue.set(state.pages, slug, {
            items,
        });

        state.contentSlug = firstEntry.slug;
        Vue.set(state.contents, firstEntry.slug, {
            ...firstEntry.content
        });
    },

    [SET_CONTENT](state, { keyvisual, sections, trackingId, slug }) {
        NProgress.done();

        state.contentSlug = slug;
        state.isLoadingContent = false;
        Vue.set(state.contents, slug, {
            keyvisual,
            sections,
            trackingId,
        });
    },


};

const getters = {
    isLoadingPage: (state) => {
        return state.isLoadingPage;
    },

    isLoadingContent: (state) => {
        return state.isLoadingContent;
    },

    getContentSlug: (state) => {
        return state.contentSlug;
    },

    getNavigationItems: (state) => {
        if (!state.pages[state.pageSlug]) { return null; }

        return state.pages[state.pageSlug].items.map(i => {
            return {
                label: i.label,
                url: i.url,
                image: i.image,
                active: i.url == state.contentSlug,
            };
        })
    },

    getSlides: (state) => {
        if (!state.pages[state.pageSlug]) { return null; }

        const items = state.pages[state.pageSlug].items;

        if (items.length != items.filter(i => i.image != null).length) {
            return null;
        }

        return items.map(i => {
            return i.image;
        });
    },

    getKeyvisual: (state) => {
        if (!state.contents[state.contentSlug]) { return null; }
        return state.contents[state.contentSlug].keyvisual;
    },

    getSections: (state) => {
        if (!state.contents[state.contentSlug]) { return null; }
        return state.contents[state.contentSlug].sections;
    },

};


export const overview = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
