<template>
    <div class="view-stream">

        <page-header
            :has-search="true"
            :headroom-disabled="true"
            :back-button="true" />

        <stream-keyvisual
            :keyvisual="getKeyvisual"
            :settings="getKeyvisual.settings" />

        <sortable-nugget-list
            :nuggets="getNuggets"
            :sorters="getSorters"
            :active-sorter="getActiveSorter" />

        <other-streams-slider
            :streams="getOtherStreams" />

        <page-footer />
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    STREAM_FETCH_NS,
    STREAM_SET_NS,
    STREAM_SORT_NS,
    ME_GET_NS,
    TRACK_VISIT_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import StreamKeyvisual from '@/components/elements/hub/StreamKeyvisual.vue';
import OtherStreamsSlider from '@/components/elements/hub/OtherStreamsSlider.vue';
import SortableNuggetList from '@/components/elements/SortableNuggetList.vue';

export default {
    name: 'StreamView',

    components: {
        PageHeader,
        PageFooter,

        StreamKeyvisual,
        SortableNuggetList,
        OtherStreamsSlider,
    },

    props: {
        content: Object
    },

    data() {
        return {
            loader: -1,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('stream', {
            getKeyvisual: 'getKeyvisual',
            getNuggets: 'getNuggets',
            isLoadingStream: 'isLoading',
            getSorters: 'getSorters',
            getActiveSorter: 'getActiveSorter',
            getOtherStreams: 'getOtherStreams',
            getTrackingId: 'getTrackingId',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingStream;
        },

        renderList() {
            return ['xs'].indexOf(this.$mq) !== -1;
        },

        renderTiles() {
            return !this.renderList;
        },
    },

    async beforeRouteEnter(to, from, next) {

        const [ uitextResult, nuggetResult, meResult ] = await Promise.all([
            await store.dispatch(UITEXTS_FETCH_NS),
            await store.dispatch(STREAM_FETCH_NS, to.params.slug),
            await store.dispatch(ME_GET_NS)
        ]);

        return next();
    },

    async beforeRouteUpdate(to, from, next) {
        await store.dispatch(STREAM_FETCH_NS, to.params.slug);

        return next();
    },

    beforeRouteLeave(to, from, next) {

        if (to.name == 'nugget') {
            this.pushBreadcrumb({
                text: 'Stream',
                to: { path: from.path }
            });
        } else if (to.name == 'training-hub' || to.name == 'home') {
            this.popBreadcrumb();
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(STREAM_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);
        }

        store.dispatch(TRACK_VISIT_NS, {
            entryUid: this.getTrackingId
        });
    },

    async mounted() {

    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        handleTileClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loader = id;
            }
        },

        loading(id) {
            return this.loader == id;
        },
    },
}
</script>
