
import { ApiService } from '..';

export const RequirementsService = {
    match(slug) {
        return ApiService.get('requirements', slug);
    },

    complete(uid) {
        return ApiService.get('requirements/complete', uid);
    },

    checkEntry(uid) {
        return ApiService.get('requirements/check', uid);
    },

    checkEntries(uids) {
        return ApiService.post('requirements/check-all', { uids });
    },

    checkRequirements(uids) {
        return ApiService.post('requirements/check-requirements', { uids });
    },
};