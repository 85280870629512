/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'logout': {
    width: 16,
    height: 16,
    viewBox: '0 0 13.7 14',
    data: '<g><path pid="0" d="M11.4 10c-.3-.1-.7.1-.9.3-.9 1.1-2.3 1.8-3.7 1.8-2.7 0-4.9-2.3-4.9-5.1 0-2.1 1.3-4 3.2-4.8s4.1-.2 5.4 1.4c.3.4.9.4 1.3.1.4-.3.4-.9.1-1.3C10 .2 7-.6 4.4.4 1.8 1.5 0 4.1 0 7c0 2.9 1.8 5.5 4.4 6.5s5.6.2 7.5-2c.2-.3.3-.6.2-.9-.1-.3-.3-.6-.7-.6z"/><path pid="1" d="M13.6 6.7L12 5c-.1-.1-.2-.2-.4-.2h-.2l-.1.2c-.2.2-.2.5 0 .7l.5.6H7.1c-.4 0-.7.3-.7.7s.3.7.7.7h4.7l-.5.6-.1.2v.2c0 .3.2.5.5.5h.2l1.7-1.8c.1-.2.1-.6 0-.7z"/></g>'
  }
})
