<template>
    <div
        v-if="slider"
        :class="{
            'value-slider': true,
            'interaction': true,
            'value-slider--disabled': evaluating || disabled,
            'value-slider--highlight-correct': highlightCorrect,
        }"
    >
        <div class="value-slider__value">
            <span v-text="value" class="value-slider__value-number"/>{{ slider.unit }}
        </div>

        <vue-slider
            :class="{
                'value-slider__slider': true,
                'value-slider__slider--user-wrong': highlightCorrect && !evaluation.passed,
            }"
            v-model="value"
            :tooltip="false"
            :disabled="disabled || evaluating"
            :min="parseFloat(this.slider.valueFrom)"
            :max="parseFloat(this.slider.valueTo)"
            :process-dragable="true"
            :interval="parseFloat(this.slider.steps)"
        />

        <div v-if="customFeedbacked" class="interaction-evaluator">
            <div class="interaction__buttons">
                <button-element
                    :disabled="evaluating"
                    @click="customClick"
                    :button="{
                        'label': t('ok')
                    }"/>
            </div>

            <transition-expand>
                <div v-if="evaluating" >
                    <div
                        class="interaction__feedback feedback feedback--passed feedback--default"
                    >
                        <div class="feedback__grid">

                            <div class="feedback__grid-column feedback__grid-column--icon">
                                <div class="feedback__icon"><svgicon-wrapper name="checkmark" width="26" height="26" /></div>
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--text">
                                <span class="feedback__copytext" v-html="activeFeedback" />
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--buttons">
                                <button-element
                                    @click="reset"
                                    :button="{
                                        'label': t('try-again')
                                    }" />

                                <!-- <button-element
                                    v-if="activeFeedback.solveButton"
                                    @click="handleSolveClicked"
                                    :button="{
                                        'label': t('solution')
                                    }" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </transition-expand>
        </div>

        <interaction-evaluator
            v-if="!evaluatesExternal && !customFeedbacked"
            :evaluating="evaluating"
            :feedback="feedback"
            :evaluation="evaluation"
            @evaluate="evaluate"
            @retry="reset"
            @solve="solve"
        />

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import vueSlider from 'vue-slider-component';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import InteractionEvaluator from '@/components/elements/quiz/InteractionEvaluator.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';

export default {
    name: 'ValueSliderElement',
    components: {
        vueSlider,
        ButtonElement,
        InteractionEvaluator,
        TransitionExpand,
        FeedbackElement,
    },
    props: {
        slider: {
            type: Object,
            required: true,
        },
        feedback: Object,
        evaluatesExternal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        highlightCorrect: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            evaluating: false,
            customFeedbacked: false,
            evaluation: {
                passed: false,
            },
            value: 0,
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        valueWithUnit() {
            const { unit } = this.slider;

            return `${this.value} ${unit}`;
        },

        activeFeedback() {
            const feedback = this.slider.feedback.find((f) => {
                return this.value >= f.from && this.value <= f.to;
            });

            return feedback ? feedback.feedback : null;
        },
    },

    watch: {
        highlightCorrect(newValue) {
            if (newValue) {
                this.solve();
            }
        }
    },

    mounted() {
        this.reset();

        this.customFeedbacked = this.slider.feedback && !!this.slider.feedback.length;
    },
    methods: {
        evaluate() {

            if (this.evaluating || this.disabled) { return; }

            const { valueFromCorrect, valueToCorrect } = this.slider;

            this.evaluation = Object.assign({}, this.evaluation, {
                passed: this.value >= parseFloat(valueFromCorrect) &&
                    this.value <= parseFloat(valueToCorrect)
            });

            this.evaluating = true;

            return this.evaluation;
        },

        reset() {
            this.value = parseInt(this.slider.valueInitial);
            this.evaluating = false;

            this.evaluation = Object.assign({}, this.evaluation, {
                passed: false
            });
        },

        solve() {

            const { valueFromCorrect, valueToCorrect } = this.slider;
            const exactValue = (parseFloat(valueFromCorrect) + parseFloat(valueToCorrect)) / 2;

            this.value = exactValue;
        },

        async customClick() {
            this.evaluating = true;

            await this.$nextTick();

            // const { feedbackContainer } = this.$refs;

            // if (feedbackContainer) {

            //     this.$scrollTo(feedbackContainer, 1000, {
            //         easing: [0.23, 1, 0.32, 1],
            //         offset: () => {
            //             const feedbackRect = feedbackContainer.getBoundingClientRect();
            //             const customOffset = 16;
            //             const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

            //             return Math.min(0, targetTop);
            //         },
            //         force: true,
            //         cancelable: true,
            //         x: false,
            //         y: true
            //     });
            // }
        },
    }
}
</script>
