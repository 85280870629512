import { schema } from 'normalizr';

export const topic = new schema.Entity('topics');
export const category = new schema.Entity('categories');


const entry = new schema.Entity('entries', {
    topics: [ topic ],
    categories: [ category ],
})

// entry.define({
//     recommendedContent: [ entry ]
// });

export const schemaEntry = new schema.Array(entry);
