<template>

    <div class="tile-grid-slider swiper-container" ref="slider">

        <div class="swiper-wrapper">

            <template v-for="(item, index) in slides">

                <div class="tile-grid-slider__slide swiper-slide" :key="`tile-grid-slider-slide-${index}`">
                    <tile-grid-item :large="large" v-bind="item" />
                </div>

            </template>

        </div>

        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Swiper from 'swiper';
import debounce from 'debounce';

import TileGridItem from '@/components/elements/hub/tile-grid/TileGridItem.vue';

export default {
    name: 'TileGridSlider',

    components: {
        TileGridItem,
    },

    props: {
        slides: Array,
        large: Boolean,
    },

    computed: {
        sliderEnabled() {
            return this.slides.length > 1;
        },
    },

    data: () => {
        return {
            slider: null
        }
    },

    async mounted() {
        await this.$nextTick();

        this.createSlider();
    },

    methods: {

        createSlider() {

            const { slider } = this.$refs;

            const autoplay = this.sliderEnabled ? {
                delay: 5000 + (Math.floor(Math.random() * 2000) + 1000),
            } : false;

            this.slider = new Swiper(slider, {
                autoplay: autoplay,
                loop: this.sliderEnabled,
                preventClicks: true,
                watchOverflow: true,
                autoHeight: false,
                allowTouchMove: this.sliderEnabled,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                }
            });

            this.slider.on('resize', () => {
                this.slider.update();
            });
        },

        handleSliderMediaLoaded() {
            this.slider && this.slider.update();
        },
    },

    beforeDestroy() {
        this.slider && this.slider.destroy();
    }
}
</script>
