/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'filter': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 471',
    data: '<g _fill="none" fill-rule="evenodd"><g _fill="#000" fill-rule="nonzero"><g><path pid="0" d="M0 1l204.887 249.48V471l102.228-57.696V250.48L512 1H0zm447.694 30.409l-29.147 35.49H93.453l-29.147-35.49h383.388zM276.725 239.588v155.956l-41.451 23.394v-179.35L118.426 97.308h275.148l-116.849 142.28z"/></g></g></g>'
  }
})
