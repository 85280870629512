
<template>
    <svgicon
        :name="getSVGName"
        :color="color"
        :dir="dir"
        :fill="fill"
        :width="width"
        :height="height"
        v-on="$listeners" />
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'SVGIconWrapper',

    props: {
        name: String,
        color: String,
        dir: {
            type: String,
            default: 'down'
        },
        fill: {
            type: Boolean,
            default: true
        },
        width: Number|String,
        height: Number|String,
    },

    computed: {
        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        getSVGName() {
            return this.isAlternativeBrand ? `cupra/${this.name}` : this.name;
        }
    },
}
</script>

