<template>
    <transition-expand @end="consentAfterLeave">
        <div v-if="isOpen" class="cookie-consent content-dimensioning" :style="{ bottom: getBottomPosition }">
            <div class="cookie-consent__page-container">
                <div class="cookie-consent__close-button" @click="reject">
                    <svgicon-wrapper name="cross" />
                </div>
                <div class="cookie-consent__grid">
                    <div class="cookie-consent__grid-column">
                        <span class="cookie-consent__headline">{{ t('cookie-consent-headline') }}</span>
                    </div>
                    <div class="cookie-consent__grid-column cookie-consent__grid-column--text">
                        <introtext-element
                            class="introtext--small"
                            :introtext="{
                                headline: null,
                                subline: null,
                                copytext: t('cookie-consent')
                            }" />

                        <button-element
                            @click="accept"
                            type="inverted"
                            :small="true"
                            :button="{
                                'label': t('cookie-consent-accept')
                            }" />
                    </div>
                </div>
            </div>
        </div>
    </transition-expand>
</template>

<script>
import { mapGetters } from "vuex";

import * as Cookie from "tiny-cookie";
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
    name: "CookieConsent",

    components: {
        TransitionExpand,
        ButtonElement,
    },

    props: {

        cookieName: {
            type: String,
            default: 'cookie-consent'
        },

        cookieOptions: {
            type: Object,
            default: () => {
                return {
                    expires: '1Y'
                };
            },
            required: false
        },
    },

    computed: {
        ...mapGetters("uitexts", {
            t: "t"
        }),

        ...mapGetters('common', {
            getFooterHeight: 'getFooterHeight',
            getFooterInViewport: 'getFooterInViewport',
        }),

        getBottomPosition() {
            const bottomPosition = this.getFooterInViewport ? this.getFooterHeight : 0;

            return `0`;//``${bottomPosition}px`;
        },
    },

    data() {
        return {
            isOpen: false,
            bottomPosition: 0,
        };
    },

    created() {

        if (!this.getVisited() === true) {
            this.isOpen = true
        }
    },

    methods: {

        consentAfterLeave() {
            this.$emit('hide');
        },

        setVisited() {
            Cookie.set(this.cookieName, true, this.cookieOptions);
        },

        getVisited() {
            return Cookie.get(this.cookieName);
        },

        accept() {
            this.setVisited();
            this.isOpen = false;
        },

        reject() {
            this.isOpen = false;
        },

        close() {
            this.isOpen = false;
        },

        open() {
            if (!this.getVisited() === true) {
                this.isOpen = true;
            }
        },
    }
};
</script>
