import {
    SCORM_COMPLETE_MODULE,
    SCORM_INIT_DATA,
} from "@/store/actions.type";

// import {

// } from "@/store/mutations.type";

const state = () => ({
    modules: {},
    entrypoint: null,
});

const actions = {

    [SCORM_INIT_DATA]({ commit, state }, { modules }) {

        let data = {};
        if (window.EXPORT_TYPE_SCORM) {
            data = window.SCORM_TRACKER.getSuspendData();
        } else {
            data = JSON.parse(localStorage.getItem('scorm'));
        }

        commit('initEntrypoint', data.entrypoint);
        commit('initModules', {
            ...modules,
            ...data.modules,
            ...state.modules,
        });

        if (window.EXPORT_TYPE_SCORM) {
            window.SCORM_TRACKER.writeSuspendData(state);
        } else {
            localStorage.setItem('scorm', JSON.stringify(state));
        }
    },

    [SCORM_COMPLETE_MODULE]({ commit, state }, { moduleId }) {

        let mdls = {};
        if (window.EXPORT_TYPE_SCORM) {
            mdls = window.SCORM_TRACKER.getSuspendData().modules;
        } else {
            mdls = JSON.parse(localStorage.getItem('scorm.modules'));
        }

        commit('initModules', {
            ...mdls,
            ...state.modules,
        });

        commit('completeModule', moduleId);

        if (window.EXPORT_TYPE_SCORM) {
            window.SCORM_TRACKER.writeSuspendData(state);
        } else {
            localStorage.setItem('scorm', JSON.stringify(state));
        }
    },

};

const mutations = {

    completeModule(state, moduleId) {
        state.modules = {
            ...state.modules,
            [moduleId]: true
        };

        const completed = Object.keys(state.modules).filter(i => state.modules[i]);
        const progress = completed.length / Object.keys(state.modules).length;

        if (progress >= 1) {
            window.SCORM_TRACKER.trackCompletion();
        } else {
            window.SCORM_TRACKER.trackProgress(progress);
        }
    },

    initModules(state, modules) {
        state.modules = modules;
    },

    initEntrypoint(state, entrypoint) {
        state.entrypoint = entrypoint;
    },
};

const getters = {
    isModuleCompleted: (state) => (moduleId) => {
        if (!state.modules.hasOwnProperty(moduleId)) {
            return false;
        }

        return state.modules[moduleId];
    }
};


export const scorm = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
