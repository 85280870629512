/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'play_big': {
    width: 16,
    height: 16,
    viewBox: '0 0 91 91',
    data: '<g><g><g><path pid="0" d="M90.6 44c-.3-.6-.8-1.1-1.5-1.5L4.7.3C3.1-.5 1.2.2.4 1.8c-.3.4-.4.9-.4 1.5v84.5C0 89.5 1.5 91 3.3 91c.5 0 1-.1 1.5-.3l84.5-42.2c1.5-.9 2.1-2.9 1.3-4.5zM6.5 82.5v-74l74 37-74 37z"/></g></g></g>'
  }
})
