/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'overview': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<g><g><g><path pid="0" class="st0" d="M1 5h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H1C.4 0 0 .4 0 1v3c0 .6.4 1 1 1z"/><path pid="1" class="st0" d="M8.5 5h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="2" class="st0" d="M16 5h3c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="3" class="st0" d="M1 20h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="4" class="st0" d="M8.5 20h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="5" class="st0" d="M16 20h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="6" class="st0" d="M1 12.5h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="7" class="st0" d="M8.5 12.5h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/><path pid="8" class="st0" d="M16 12.5h3c.6 0 1-.4 1-1v-3c0-.6-.4-1-1-1h-3c-.6 0-1 .4-1 1v3c0 .6.4 1 1 1z"/></g></g></g>'
  }
})
