
<template>
    <div class="accordion">
        <template v-for="(panel, index) in panels">
            <header
                :key="`acccordion-header-${index}`"
                :class="{
                    'accordion__header': true,
                    'accordion__header--active': isOpen(index)
                }"
                    @click="(event) => handlePanelClick(event, index)">
                <span class="accordion__header-title">{{ panel.panelTitle }}</span>
                <span class="accordion__header-icon">
                    <svgicon-wrapper name="arrow-down" />
                </span>
            </header>
            <transition-expand :key="`acccordion-panel-${index}`">
                <div class="accordion__panel" v-if="isOpen(index)">
                    <div class="accordion__panel-content">
                        <div
                            class="accordion__panel-content-wrapper"
                            :key="`acccordion-panel-content-${pIndex}`"
                            v-for="(panelContent, pIndex) in panel.content"
                        >
                            <div>
                                <introtext-element
                                    v-if="panelContent.introtext"
                                    type="type-2"
                                    :introtext="panelContent.introtext"
                                ></introtext-element>

                                <media-element
                                    v-if="panelContent.media"
                                    :media="panelContent.media"
                                    :videoSettings="panelContent.settings.video"
                                    :imageSettings="panelContent.settings.image"
                                ></media-element>
                            </div>
                        </div>
                    </div>
                </div>
            </transition-expand>
        </template>
    </div>
</template>
<script>
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

export default {
    name: 'AccordionElement',
    components: {
        TransitionExpand,
    },
    props: {
        panels: {
            type: Array,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    singlePanelMode: false
                }
            }
        }
    },
    data() {
        return {
            activePanels: {},
        }
    },
    computed: {
    },
    mounted() {

    },
    methods: {
        isExpanded(index) {
            return this.expandedPanels[index] || false;
        },
        isOpen(index) {
            return this.activePanels[index] || false;
        },
        handlePanelClick(event, index) {
            const currentPanelOpen = this.activePanels[index] || false;

            if (this.settings.singlePanelMode) {
                this.activePanels = {};

                this.$emit('activePanelChanged', this.panels[index]);
            }

            this.$set(this.activePanels, index, !currentPanelOpen);
        }
    }
}
</script>

