
import { TrackingService } from "@/api";

import {
    TRACK_COMPLETION,
    TRACK_VISIT,
} from "@/store/actions.type";


const state = () => ({});

const actions = {

    [TRACK_COMPLETION]({ commit, state }, { entryUid, data }) {
        try {
            TrackingService.trackCompletion(entryUid, data);
        } catch(e) {}
    },

    [TRACK_VISIT]({ commit, state }, { entryUid }) {
        try {
            TrackingService.trackVisit(entryUid);
        } catch(e) {}
    },
};

const mutations = {};
const getters = {};


export const tracking = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
