<template>
    <div class="view-training-hub training-hub">

        <page-header
            :has-search="true"
            :headroom-disabled="true"
        >
            <li slot="usermenuExtraListItems" class="user-menu__item">
                <span class="user-menu__item-link" @click="handleRestartTutorial">
                    <svgicon-wrapper name="start" />{{ t('restart-tutorial') }}
                </span>
            </li>
        </page-header>

        <hub-keyvisual :keyvisual="getKeyvisual" @eventClick="handleEventClick" />

        <stream-overview
            v-for="(streamGroup, index) in getStreams"
            :key="`stream-overview-${index}`"
            :headline="streamGroup.headline"
            :streams="streamGroup.streams" />

        <div class="section-dimensioning section-dimensioning--medium section-dimensioning--even content-dimensioning">

            <template v-for="section in getSections">

                <div
                    v-if="section.type =='contentNavigationItem'"
                    :id="`jumpmark-${section.id}`"
                    :key="`jumpmark-${section.id}`"
                ></div>

                <base-section
                    v-else
                    :key="`nugget-section-${section.id}`"
                    :section="section">
                </base-section>
            </template>

        </div>

        <hub-tutorial v-if="!mustSeeIntroduction && mustDoHubTutorial" />

        <portal to="modals">
            <required-action-element v-if="!mustSeeIntroduction" @needsRefresh="handleNeedsRefresh" />

            <modal-element
                v-if="getOverlay"
                :class="{
                    'training-hub__overlay': true,
                }"
                :open="mustSeeIntroduction"
            >
                <template>
                    <div class="modal__grid-column modal__grid-column--text">
                        <introtext-element v-if="getOverlay.introtext" :introtext="getOverlay.introtext"></introtext-element>
                    </div>

                    <div class="modal__grid-column modal__grid-column--media">
                        <media-element
                            v-if="getOverlay.media"
                            :media="getOverlay.media"
                            :image-settings="getOverlay.settings.image"
                            :video-settings="getOverlay.settings.video"
                            :show-notification="false"
                            @video-played-enough="handleVideoPlayedEnough" />
                    </div>

                    <div class="modal__grid-column modal__grid-column--component">
                        <button-element
                            :disabled="!videoPlayedEnough"
                            @click="closeOverlay"
                            type="primary"
                            :button="{
                                'label': 'Weiter'
                            }" />
                    </div>

                </template>
            </modal-element>
        </portal>

        <page-footer />
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    TRAINING_HUB_FETCH_NS,
    TRAINING_HUB_SET_NS,
    ME_GET_NS,
    ME_FORCE_HUB_TOUR_NS,
    GAMIFICATION_REFRESH_NS,
    ME_COMPLETE_INTRODUCTION_NS,
    REQUIREMENTS_COMPLETE_NS,
} from "@/store/actions.type";


import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';

import BaseSection from '@/components/sections/BaseSection.vue';
import HubKeyvisual from '@/components/elements/hub/HubKeyvisual.vue';
import StreamOverview from '@/components/elements/hub/StreamOverview.vue';
import StreamElement from '@/components/elements/hub/StreamElement.vue';
import RequiredActionElement from '@/components/elements/required-actions/RequiredActionElement.vue';
import ModalElement from '@/components/elements/ModalElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

import HubTutorial from '@/components/elements/hub/HubTutorial.vue';

export default {
    name: 'TrainingHubView',
    components: {
        PageHeader,
        PageFooter,
        BaseSection,
        StreamElement,
        HubKeyvisual,
        HubTutorial,
        StreamOverview,
        RequiredActionElement,
        ModalElement,
        ButtonElement,
    },

    props: {
        content: Object
    },

    computed: {

        ...mapGetters('me', {
            mustDoHubTutorial: 'mustDoHubTutorial',
            mustSeeIntroduction: 'mustSeeIntroduction',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('common', {
            isHeaderPinned: 'isPinned',
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('trainingHub', {
            isLoadingTrainingHub: 'isLoading',
            getKeyvisual: 'getKeyvisual',
            getStreams: 'getStreams',
            getSections: 'getSections',
            getOverlay: 'getOverlay',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": null,
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": '',
                        "backgroundImage": null,
                        "textBehavior": null,
                    },
                    "layout": {
                        "columns": "",
                        "size": "page-fit"
                    },
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },
    },
    data() {
        return {
            search: null,
            videoPlayedEnough: false
        }
    },

    async beforeRouteEnter (to, from, next) {
        await store.dispatch(UITEXTS_FETCH_NS);
        await store.dispatch(ME_GET_NS);
        await store.dispatch(TRAINING_HUB_FETCH_NS);

        next();
    },

    beforeRouteLeave(to, from, next) {

        if (to.name == 'stream' || to.name == 'nugget' || to.name == 'collection' || to.name == 'event' || to.name == 'entry') {
            this.setBreadcrumbs([
                { text: 'Start', to: { path: from.path }}
            ]);
        }

        next();
    },

    async created() {
        if (this.content) {
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);
            store.dispatch(TRAINING_HUB_SET_NS, this.content);
        }

        document.title = `Novelis - Virtual Training Cockpit`;
    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        handleEventClick() {
            this.$gtag.event('sth.click.hub.event', {
                'event_category': 'click',
                'event_label': 'HUB Event click',
                'value': 1
            });
        },

        handleRestartTutorial() {
            store.dispatch(ME_FORCE_HUB_TOUR_NS, true);
        },

        async handleNeedsRefresh() {
            await store.dispatch(GAMIFICATION_REFRESH_NS);
        },

        async handleVideoPlayedEnough() {
            this.videoPlayedEnough = true;
        },

        closeOverlay() {
            this.showAchievementsOverlay = false;
            store.dispatch(ME_COMPLETE_INTRODUCTION_NS);

            if (this.getOverlay && this.getOverlay.settings.video) {
                const settings = this.getOverlay.settings.video;
                if (settings.hasOwnProperty('watchToComplete') && settings.watchToComplete) {
                    store.dispatch(REQUIREMENTS_COMPLETE_NS, settings.watchToComplete);
                }
            }
        },
    },
}
</script>
