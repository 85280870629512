<template>
    <div class="content-navigation" ref="root">
        <div class="content-navigation__page-container">
            <scrollactive
                :always-track="true"
                :offset="getOffsetHeight"
                @itemchanged="handleScrollItemChanged"
                active-class="content-navigation__scroll-item--active"
                :modify-url="false"
                class="content-navigation__slider-wrapper"
            >
                <template v-if="renderSlider">
                    <span class="content-navigation__slider-navigation content-navigation__slider-navigation--previous" ref="prevButton">
                        <svgicon-wrapper name="arrow-down-fine" dir="up" />
                    </span>
                    <div class="swiper-container" ref="slider">

                        <div class="swiper-wrapper">
                            <a
                                v-for="(item, index) in items"
                                :key="`content-bavigation-item-${index}`"
                                :href="`${item.anchor}`"
                                class="swiper-slide scrollactive-item content-navigation__scroll-item"
                            >
                                <span :class="{
                                    'content-navigation__item': true
                                }">{{ item.label }}</span>
                            </a>
                        </div>
                    </div>
                    <span class="content-navigation__slider-navigation content-navigation__slider-navigation--next"  ref="nextButton">
                        <svgicon-wrapper name="arrow-down-fine" dir="down" />
                    </span>
                </template>
                <template v-else>
                    <div class="content-navigation__menu">
                        <span
                            class="content-navigation__selected-item"
                            @click="handleMenuClick"
                        >
                            {{ activeItem.label }}
                            <svgicon-wrapper name="arrow-down" />
                        </span>
                        <transition-expand>
                            <ul v-show="menuOpen" class="content-navigation__flyout">
                                <li
                                    v-for="(item, index) in items"
                                    :key="`content-navigation-item-${index}`"
                                    class="content-navigation__menu-item"
                                    @click="() => handleMenuItemClick(index)"
                                >
                                    <a
                                        :href="`${item.anchor}`"
                                        class="scrollactive-item content-navigation__menu-link"
                                    >{{ item.label }}</a>
                                </li>
                            </ul>
                        </transition-expand>
                    </div>
                </template>
            </scrollactive>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swiper from 'swiper';
import debounce from 'debounce';

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

export default {
    name: 'ContentNavigation',
    components: {
        TransitionExpand,
    },
    props: {
        items: {
            type: Array,
            reuired: true
        }
    },
    data() {
        return {
            slider: null,
            containerHeight: 0,
            menuOpen: false,
            activeIndex: 0,
        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
            headerIsPinned: 'isPinned',
        }),

        getOffsetHeight() {

            let extraOffset = 0;

            if (!this.headerIsPinned) {
                extraOffset = this.containerHeight;
            }

            return Math.ceil(this.getHeaderHeight - extraOffset);
        },

        renderMenu() {
            return ['xs', 'sm'].indexOf(this.$mq) !== -1 && this.menuOpen;
        },

        renderSlider() {
            return ['xs', 'sm'].indexOf(this.$mq) === -1;
        },

        activeItem() {
            return this.items[this.activeIndex];
        },
    },

    watch: {
        '$mq': async function(newValue, oldValue) {

            await this.$nextTick();

            if (newValue == 'sm' && this.slider) {
                this.slider.destroy();
                this.slider = null;
            }

            if (newValue != 'sm' && !this.slider) {
                this.initSlider();
            }
        }
    },

    mounted() {
        this.initSlider();

        this.containerHeight = this.$refs.root.getBoundingClientRect().height;
        window.addEventListener('resize', this.handleWindowResize);
    },

    methods: {

        initSlider() {
            const { slider, prevButton, nextButton } = this.$refs;

            if (slider) {
                this.slider = new Swiper(slider, {
                    preventClicks: false,
                    freeMode: true,
                    freeModeSticky: true,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    watchOverflow: true,
                    navigation: {
                        disabledClass: 'content-navigation__slider-navigation--disabled',
                        nextEl: nextButton,
                        prevEl: prevButton,
                    },
                });

                this.slider.on('resize', () => {
                    this.slider.update();
                });
            }
        },

        handleScrollItemChanged(event, item) {

            if (!item) {
                return;
            }

            if (this.renderSlider) {
                if (this.slider && this.slider.slides && !!this.slider.slides.length) {
                    for (let i = 0; i < this.slider.slides.length; i++) {
                        if (this.slider.slides[i].isEqualNode(item)) {
                            this.slider.slideTo(i);
                            break;
                        }
                    }
                }
            } else {
                this.activeIndex = this.getElementIndex(item.parentNode);
            }

        },

        getElementIndex(node) {
            let index = 0;
            while ( (node = node.previousElementSibling) ) {
                index++;
            }
            return index;
        },

        handleWindowResize: debounce(function () {
            this.containerHeight = this.$refs.root.getBoundingClientRect().height;
        }, 100),

        handlePrevClick() {
            this.slider.slidePrev();
        },

        handleNextClick() {
            this.slider.slideNext();
        },

        handleMenuClick() {
            this.menuOpen = !this.menuOpen;
        },

        handleMenuItemClick(index) {
            this.activeIndex = index;
            this.menuOpen = false;
        },

    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
}
</script>
