<template>
    <div class="tutorial hub-tutorial content-dimensioning">
        <transition name="slide-left">
            <div v-if="showIntroduction" ref="introductionElement" class="tutorial__introduction">
                <introtext-element
                    :introtext="{
                        headline: t('hub-tutorial-introduction-headline'),
                        subline: null,
                        copytext: t('hub-tutorial-introduction-text'),
                        buttons: null
                    }"
                >
                    <template slot="extra-button">
                        <button-element
                            @click="handleStartTutorial"
                            :small="true"
                            :button="{
                                'label': t('start-introduction')
                            }" />

                        <button-element
                            @click="finishCallback"
                            :small="true"
                            :button="{
                                'label': t('skip-tutorial')
                            }" />
                    </template>
                </introtext-element>
            </div>
        </transition>

        <portal to="modals">
            <v-tour
                name="trainingHubTour"
                :steps="getSteps"
                :options="tourOptions"
                :callbacks="myCallbacks"
            >
                <template slot-scope="tour">
                    <transition name="fade">
                    <v-step
                        v-if="tour.currentStep === index"
                        v-for="(step, index) of tour.steps"
                        :key="index"
                        :step="step"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :finish="tour.finish"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                        :highlight="tour.highlight"
                        :debug="false"
                    >
                        <div slot="actions" class="v-step__action">
                            <span class="tutorial__skip-button" @click="tour.finish">
                                <svgicon-wrapper name="cross" />
                            </span>
                            <button-element
                                v-if="!tour.isLast"
                                @click="tour.nextStep"
                                :small="true"
                                :button="{
                                    'label': t('next-step')
                                }"
                                :disabled="isLoadingSearchResults"
                            >
                                <span slot="icon" v-if="isLoadingSearchResults">
                                    <loader-element />
                                </span>
                            </button-element>

                            <button-element
                                v-if="tour.isLast"
                                @click="tour.finish"
                                :small="true"
                                :button="{
                                    'label': t('finish')
                                }" />
                        </div>
                    </v-step>
                    </transition>
                </template>
            </v-tour>
        </portal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    SEARCH_TOGGLE_MENU_NS,
    UPDATE_SEARCH_WORD_NS,
    SEARCH_TOGGLE_RESULTS_NS,
    SEARCH_NS,
    ME_COMPLETE_HUB_TUTORIAL_NS,
    ME_FORCE_HUB_TOUR_NS,
    SEARCH_TOGGLE_FILTERS_NS,
} from "@/store/actions.type";

import ButtonElement from '@/components/elements/ButtonElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'HubTutorial',

    components: {
        ButtonElement,
        LoaderElement,
    },

    props: {

    },

    computed: {

        ...mapGetters('search', {
            isLoadingSearchResults: 'isLoadingSearchResults',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('common', {
            isHeaderPinned: 'isPinned',
            getHeaderHeight: 'getHeaderHeight',
        }),

        getSteps() {

            const steps = [];

            steps.push({
                target: '.hub-keyvisual .hub-keyvisual__headline',
                content: this.t('hub-tutorial-step-event'),
                offset: this.getHeaderHeight * -2
            });

            /* HUB */

            steps.push({
                target: '.hub-keyvisual + .section-dimensioning section:nth-child(1) .more-content__grid-column--item:first-child',
                content: this.t('hub-tutorial-step-2'),
                offset: this.getHeaderHeight * -2,
            });

            steps.push({
                target: '.hub-keyvisual + .section-dimensioning section:nth-child(2) .button',
                content: this.t('hub-tutorial-step-3'),
                offset: this.getHeaderHeight * -2,
            });

            steps.push({
                target: '.page-footer__meta-links li:first-child',
                content: this.t('hub-tutorial-step-7'),
                offset: this.getHeaderHeight * -2,
            });

            // steps.push({
            //     target: '.hub-keyvisual + .section-dimensioning section:nth-child(3) .button',
            //     content: this.t('hub-tutorial-step-4'),
            //     offset: this.getHeaderHeight * -2,
            // });

            // steps.push({
            //     target: '.section-quiz-duel .quiz-duel-start__play-button',
            //     content: this.t('hub-tutorial-step-5'),
            //     offset: this.getHeaderHeight * -2,
            // });

            // steps.push({
            //     target: '.hub-keyvisual + .section-dimensioning section:last-child .more-content__grid-column--item:first-child',
            //     content: this.t('hub-tutorial-step-6'),
            //     offset: this.getHeaderHeight * -2,
            //     params: {
            //         placement: 'top'
            //     }
            // });


            /* SEARCH  */

            steps.push({
                target: '.search',
                content: this.t('search-tutorial-step-input'),
            });

            steps.push({
                target: '.search-results__filters',
                content: this.t('search-tutorial-step-filters'),
                params: {
                    placement: 'right-start'
                }
            });

            steps.push({
                target: '.search-results__list',
                content: this.t('search-tutorial-step-results'),
                params: {
                    placement: 'right-start'
                }
            });

            // steps.push({
            //     target: '.search-results__list-item',
            //     content: this.t('search-tutorial-step-result'),
            // });

            steps.push({
                target: '.page-header__floating-button',
                content: this.t('search-tutorial-step-back'),
            });


            /* FINISH */

            steps.push({
                target: '.user-menu__button',
                content: this.t('hub-tutorial-step-user-menu')
            });

            return steps;
        },

        tourOptions() {
            return {
                highlight: true,
                useKeyboardNavigation: false,
                stopOnTargetNotFound: true,
            };
        },
    },

    data() {
        return {
            hasEventStep: false,
            showIntroduction: true,
            myCallbacks: {
                onPreviousStep: this.changeStepCallback,
                onNextStep: this.changeStepCallback,
                onFinish: this.finishCallback,
                onStop: this.stopCallback,
            }
        }
    },

    created() {
        store.dispatch(SEARCH_TOGGLE_MENU_NS, true);
        this.showIntroduction = true;
    },

    mounted() {
        const { introductionElement } = this.$refs;
        this.$scrollTo(introductionElement, 1000, {
            easing: [0.23, 1, 0.32, 1],
            offset: () => {
                const feedbackRect = introductionElement.getBoundingClientRect();
                const customOffset = 16;
                const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

                return Math.min(0, targetTop);
            },
            force: true,
            cancelable: true,
            x: false,
            y: true
        });
    },

    methods: {

        async handleStartTutorial() {
            await this.$nextTick();
            this.showIntroduction = false;
            this.$tours['trainingHubTour'].start();
        },

        stopCallback(currentStep) {
            store.dispatch(ME_FORCE_HUB_TOUR_NS, false);
        },

        finishCallback() {
            this.showIntroduction = false;
            store.dispatch(ME_COMPLETE_HUB_TUTORIAL_NS);
            store.dispatch(ME_FORCE_HUB_TOUR_NS, false);

            this.$scrollTo('.page-header', 1000, {
                easing: [0.23, 1, 0.32, 1],
                offset: () => {
                    const customOffset = 16;
                    const targetTop = -1 * (window.innerHeight - customOffset);

                    return Math.min(0, targetTop);
                },
                force: true,
                cancelable: true,
                x: false,
                y: true
            });
        },

        async changeStepCallback(currentStep) {
            const searchStep = 3;
            const afterFilterStep = 4;
            const finalStep = 7;

            if (currentStep == searchStep) {
                store.dispatch(UPDATE_SEARCH_WORD_NS, 'i20');

                await this.$nextTick();
                store.dispatch(SEARCH_TOGGLE_RESULTS_NS, true);

                await this.$nextTick();
                await store.dispatch(SEARCH_NS);

                store.dispatch(SEARCH_TOGGLE_FILTERS_NS, true);

            } else if(currentStep == afterFilterStep) {
                store.dispatch(SEARCH_TOGGLE_FILTERS_NS, false );

            } else if(currentStep == finalStep) {
                store.dispatch(UPDATE_SEARCH_WORD_NS, '');
                store.dispatch(SEARCH_TOGGLE_RESULTS_NS, false);
            }
        },
    }
}
</script>
