
import { ApiService } from '..';
const suffix = window.EXPORT_TYPE_SCORM ? '.json' : ''

export const TrainingHubService = {
    getTrainingHub() {
        return ApiService.get('entry', 'training-hub');
    },

    getTrainingHubEvents() {
        return ApiService.get('event-tiles');
    },

    getAlternativeTrainingHub() {
        return ApiService.get('entry', 'cupra-master-training-hub');
    },
};