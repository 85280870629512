/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'exclamation-mark': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 34',
    data: '<g _fill="none" fill-rule="evenodd"><g _fill="#000" fill-rule="nonzero"><path pid="0" d="M5.27 25H.73L0 0h6l-.73 25zM6 31c0 .857-.291 1.571-.874 2.143-.583.571-1.3.857-2.15.857-.819 0-1.52-.286-2.102-.857C.291 32.57 0 31.857 0 31a2.9 2.9 0 01.874-2.119A2.855 2.855 0 012.976 28c.85 0 1.567.294 2.15.881A2.9 2.9 0 016 31z"/></g></g>'
  }
})
