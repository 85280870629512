
export const UITEXTS_FETCH = 'uitextsFetch';
export const UITEXTS_FETCH_NS = 'uitexts/uitextsFetch';

export const COMPANION_FETCH = 'companionFetch';
export const COMPANION_FETCH_NS = 'companion/companionFetch';

export const COMPANION_FILTER_TOPIC_CHANGE = 'companionFilterTopicChange';
export const COMPANION_FILTER_TOPIC_CHANGE_NS = 'companionFilter/companionFilterTopicChange';
export const COMPANION_FILTER_CATEGORY_ADD = 'companionFilterCategoryAdd';
export const COMPANION_FILTER_CATEGORY_ADD_NS = 'companionFilter/companionFilterCategoryAdd';
export const COMPANION_FILTER_CATEGORY_REMOVE = 'companionFilterCategoryRemove';
export const COMPANION_FILTER_CATEGORY_REMOVE_NS = 'companionFilter/companionFilterCategoryRemove';
export const COMPANION_FILTER_SEARCH = 'companionFilterSearch';
export const COMPANION_FILTER_SEARCH_NS = 'companionFilter/companionFilterSearch';

export const EVENT_FETCH = 'eventFetch';
export const EVENT_FETCH_NS = 'event/eventFetch';
export const EVENT_IS_ALLOWED = 'eventIsAllowed';
export const EVENT_IS_ALLOWED_NS = 'event/eventIsAllowed';
export const EVENT_SET = 'eventSet';
export const EVENT_SET_NS = 'event/eventSet';
export const EVENT_RESET = 'eventReset';
export const EVENT_RESET_NS = 'event/eventReset';
export const EVENT_RESET_STATE = 'eventResetState';
export const EVENT_FETCH_GALLERY = 'eventFetchGallery';
export const EVENT_FETCH_GALLERY_NS = 'event/eventFetchGallery';

export const GAMIFICATION_FETCH = 'gamificationFetch';
export const GAMIFICATION_FETCH_NS = 'gamification/gamificationFetch';
export const LEADERBOARD_SWITCH = 'leaderboardSwitch';
export const LEADERBOARD_SWITCH_NS = 'gamification/leaderboardSwitch';
export const GAMIFICATION_REFRESH = 'gamificationRefresh';
export const GAMIFICATION_REFRESH_NS = 'gamification/gamificationRefresh';

export const NUGGET_FETCH = 'nuggetFetch';
export const NUGGET_FETCH_NS = 'nugget/nuggetFetch';
export const NUGGET_SET = 'nuggetSet';
export const NUGGET_SET_NS = 'nugget/nuggetSet';
export const NUGGET_RESET = 'nuggetReset';
export const NUGGET_RESET_NS = 'nugget/nuggetReset';
export const NUGGET_RESET_STATE = 'nuggetResetState';

export const GENERAL_ENTRY_FETCH = 'generalEntryFetch';
export const GENERAL_ENTRY_FETCH_NS = 'generalEntry/generalEntryFetch';
export const GENERAL_ENTRY_SET = 'generalEntrySet';
export const GENERAL_ENTRY_SET_NS = 'generalEntry/generalEntrySet';
export const GENERAL_ENTRY_RESET = 'generalEntryReset';
export const GENERAL_ENTRY_RESET_NS = 'generalEntry/generalEntryReset';

export const COLLECTION_FETCH = 'collectionFetch';
export const COLLECTION_FETCH_NS = 'collection/collectionFetch';
export const COLLECTION_SET = 'collectionSet';
export const COLLECTION_SET_NS = 'collection/collectionSet';

export const COLLECTION_OVERVIEW_FETCH = 'collectionOverviewFetch';
export const COLLECTION_OVERVIEW_FETCH_NS = 'collectionOverview/collectionOverviewFetch';
export const COLLECTION_OVERVIEW_SET = 'collectionOverviewSet';
export const COLLECTION_OVERVIEW_SET_NS = 'collectionOverview/collectionOverviewSet';

export const KNOWLEDGE_TEST_FETCH = 'knowledgeTestFetch';
export const KNOWLEDGE_TEST_FETCH_NS = 'knowledgeTest/knowledgeTestFetch';
export const KNOWLEDGE_TEST_SET = 'knowledgeTestSet';
export const KNOWLEDGE_TEST_SET_NS = 'knowledgeTest/knowledgeTestSet';
export const KNOWLEDGE_TEST_REPORT = 'knowledgeTestReport';
export const KNOWLEDGE_TEST_REPORT_NS = 'knowledgeTest/knowledgeTestReport';

export const APP_SWITCH_LANGUAGE = 'appSwitchLanguage';
export const APP_SWITCH_LANGUAGE_NS = 'uitexts/appSwitchLanguage';

export const AUTHENTICATION_CHECK_LOGIN = 'authenticationCheckLogin';
export const AUTHENTICATION_CHECK_LOGIN_NS = 'authentication/authenticationCheckLogin';
export const AUTHENTICATION_LOGIN = 'authenticationLogin';
export const AUTHENTICATION_LOGIN_NS = 'authentication/authenticationLogin';
export const AUTHENTICATION_LOGOUT = 'authenticationLogout';
export const AUTHENTICATION_LOGOUT_NS = 'authentication/authenticationLogout';
export const AUTHENTICATION_REDIRECT_ROUTE = 'authenticationRedirectRoute';
export const AUTHENTICATION_REDIRECT_ROUTE_NS = 'authentication/authenticationRedirectRoute';

export const HEADER_PIN_CHANGED = 'headerPinChanged';
export const HEADER_PIN_CHANGED_NS = 'common/headerPinChanged';
export const HEADER_HEIGHT_CHANGED = 'headerHeightChanged';
export const HEADER_HEIGHT_CHANGED_NS = 'common/headerHeightChanged';
export const ACTUAL_HEADER_HEIGHT_CHANGED = 'actualHeaderHeightChanged';
export const ACTUAL_HEADER_HEIGHT_CHANGED_NS = 'common/actualHeaderHeightChanged';
export const FOOTER_HEIGHT_CHANGED = 'footerHeightChanged';
export const FOOTER_HEIGHT_CHANGED_NS = 'common/footerHeightChanged';
export const FOOTER_VISIBLE_CHANGED = 'footerVisibleChanged';
export const FOOTER_VISIBLE_CHANGED_NS = 'common/footerVisibleChanged';

export const SEARCH_COMPLETIONS = 'searchCompletions';
export const SEARCH_COMPLETIONS_NS = 'search/searchCompletions';
export const SEARCH = 'search';
export const SEARCH_NS = 'search/search';
export const UPDATE_SEARCH_WORD = 'searchWord';
export const UPDATE_SEARCH_WORD_NS = 'search/searchWord';
export const SEARCH_SET_FILTER_TOPIC = 'setFilterTopic';
export const SEARCH_SET_FILTER_TOPIC_NS = 'search/setFilterTopic';
export const SEARCH_SET_FILTER_CATEGORY = 'setFilterCategory';
export const SEARCH_SET_FILTER_CATEGORY_NS = 'search/setFilterCategory';
export const SEARCH_TOGGLE_RESULTS = 'toggleResults';
export const SEARCH_TOGGLE_RESULTS_NS = 'search/toggleResults';
export const SEARCH_TOGGLE_FILTERS = 'searchToggleFilters';
export const SEARCH_TOGGLE_FILTERS_NS = 'search/searchToggleFilters';
export const SEARCH_TOGGLE_MENU = 'searchToggleMenu';
export const SEARCH_TOGGLE_MENU_NS = 'search/searchToggleMenu';

export const UPDATE_QUIZDUEL_NICKNAME_SEARCH = 'updateQuizduelNicknameSearch';
export const UPDATE_QUIZDUEL_NICKNAME_SEARCH_NS = 'quizDuel/updateQuizduelNicknameSearch';
export const QUIZDUEL_FETCH_MATCH = 'quizDuelFetchMatch';
export const QUIZDUEL_FETCH_MATCH_NS = 'quizDuel/quizDuelFetchMatch';
export const QUIZDUEL_CHECK_ACCESS = 'quizDuelCheckAccess';
export const QUIZDUEL_CHECK_ACCESS_NS = 'quizDuel/quizDuelCheckAccess';
export const QUIZDUEL_FETCH_OPPONENT = 'quizDuelFetchOpponent';
export const QUIZDUEL_FETCH_OPPONENT_NS = 'quizDuel/quizDuelFetchOpponent';
export const QUIZDUEL_FETCH_HISTORY = 'quizDuelFetchHistory';
export const QUIZDUEL_FETCH_HISTORY_NS = 'quizDuel/quizDuelFetchHistory';
export const QUIZDUEL_FETCH_LEADERBOARD = 'quizDuelFetchLeaderboard';
export const QUIZDUEL_FETCH_LEADERBOARD_NS = 'quizDuel/quizDuelFetchLeaderboard';
export const QUIZDUEL_RESET = 'quizDuelReset';
export const QUIZDUEL_RESET_NS = 'quizDuel/quizDuelReset';
export const QUIZDUEL_ANSWER = 'quizDuelAnswer';
export const QUIZDUEL_ANSWER_NS = 'quizDuel/quizDuelAnswer';
export const QUIZDUEL_REPORT = 'quizDuelReport';
export const QUIZDUEL_REPORT_NS = 'quizDuel/quizDuelReport';

export const STREAM_FETCH = 'streamFetch';
export const STREAM_FETCH_NS = 'stream/streamFetch';
export const STREAM_SET = 'streamSet';
export const STREAM_SET_NS = 'stream/streamSet';
export const STREAM_RESET = 'streamReset';
export const STREAM_RESET_NS = 'stream/streamReset';
export const STREAM_RESET_STATE = 'streamResetState';
export const STREAM_SORT_NS = 'stream/streamSort';
export const STREAM_SORT = 'streamSort';

export const TRAINING_HUB_FETCH = 'trainingHubFetch';
export const TRAINING_HUB_FETCH_NS = 'trainingHub/trainingHubFetch';
export const TRAINING_HUB_FETCH_EVENTS = 'trainingHubFetchEvents';
export const TRAINING_HUB_FETCH_EVENTS_NS = 'trainingHub/trainingHubFetchEvents';
export const TRAINING_HUB_SET = 'trainingHubSet';
export const TRAINING_HUB_SET_NS = 'trainingHub/trainingHubSet';

export const ALTERNATIVE_TRAINING_HUB_FETCH = 'alternativeTrainingHubFetch';
export const ALTERNATIVE_TRAINING_HUB_FETCH_NS = 'alternativeTrainingHub/alternativeTrainingHubFetch';
export const ALTERNATIVE_TRAINING_HUB_FETCH_EVENTS = 'alternativeTrainingHubFetchEvents';
export const ALTERNATIVE_TRAINING_HUB_FETCH_EVENTS_NS = 'alternativeTrainingHub/alternativeTrainingHubFetchEvents';
export const ALTERNATIVE_TRAINING_HUB_SET = 'alternativeTrainingHubSet';
export const ALTERNATIVE_TRAINING_HUB_SET_NS = 'alternativeTrainingHub/alternativeTrainingHubSet';

export const ME_GET = 'meGet';
export const ME_GET_NS = 'me/meGet';
export const ME_POST_NICKNAME = 'mePostNickname';
export const ME_POST_NICKNAME_NS = 'me/mePostNickname';
export const ME_UPDATE_PROV_NICKNAME = 'meUpdateProvNickname';
export const ME_UPDATE_PROV_NICKNAME_NS = 'me/meUpdateProvNickname';
export const ME_COMPLETE_HUB_TUTORIAL = 'meCompleteHubTutorial';
export const ME_COMPLETE_HUB_TUTORIAL_NS = 'me/meCompleteHubTutorial';
export const ME_COMPLETE_INTRODUCTION = 'meCompleteIntroduction';
export const ME_COMPLETE_INTRODUCTION_NS = 'me/meCompleteIntroduction';
export const ME_FORCE_HUB_TOUR = 'meForceHubTour';
export const ME_FORCE_HUB_TOUR_NS = 'me/meForceHubTour';

export const TRACK_COMPLETION = 'trackCompletion';
export const TRACK_COMPLETION_NS = 'tracking/trackCompletion';
export const TRACK_VISIT = 'trackVisit';
export const TRACK_VISIT_NS = 'tracking/trackVisit';

export const META_SET = 'metaSet';
export const META_SET_NS = 'meta/metaSet';

export const OVERVIEW_PAGE_FETCH = 'pageFetch';
export const OVERVIEW_PAGE_FETCH_NS = 'overview/pageFetch';
export const OVERVIEW_CONTENT_FETCH = 'contentFetch';
export const OVERVIEW_CONTENT_FETCH_NS = 'overview/contentFetch';
export const OVERVIEW_CONTENT_SET = 'contentSet';
export const OVERVIEW_CONTENT_SET_NS = 'overview/contentSet';

export const REQUIREMENTS_MATCH = 'requirementsMatch';
export const REQUIREMENTS_MATCH_NS = 'requirements/requirementsMatch';
export const REQUIREMENTS_COMPLETE = 'requirementsComplete';
export const REQUIREMENTS_COMPLETE_NS = 'requirements/requirementsComplete';
export const CHECK_ENTRY = 'checkEntry';
export const CHECK_ENTRY_NS = 'requirements/checkEntry';
export const CHECK_ENTRIES = 'checkEntries';
export const CHECK_ENTRIES_NS = 'requirements/checkEntries';
export const CHECK_REQUIREMENTS = 'checkRequirements';
export const CHECK_REQUIREMENTS_NS = 'requirements/checkRequirements';

export const SCORM_COMPLETE_MODULE = 'scormCompleteModule';
export const SCORM_COMPLETE_MODULE_NS = 'scorm/scormCompleteModule';
export const SCORM_INIT_DATA = 'scormInitData';
export const SCORM_INIT_DATA_NS = 'scorm/scormInitData';