<template>
    <div
        :class="{
            'select': true,
            'select--active': dropdownOpen
        }"
        v-click-away="closeDropdown"
    >
        <div class="select__selected-item" @click="toggleDropdown">
            <span v-if="placeholder" class="select__selected-item-placeholder">{{ placeholder }}</span>
            <span class="select__selected-item-label">
                <span class="select__selected-item-text">{{ selectedItem.title}}</span>
                <svgicon-wrapper name="arrow-down" />
            </span>
        </div>
        <transition-expand>
            <ul class="select__items" v-if="dropdownOpen">
                <li
                    v-show="index != selectedIndex"
                    class="select__item-label"
                    v-for="(item, index) in items"
                    :key="`select-item-${index}`"
                    @click="() => selectIndex(index)">{{ item.title }}</li>
            </ul>
        </transition-expand>
    </div>
</template>

<script>

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

export default {
    name: 'SelectElement',
    components: {
        TransitionExpand,
    },
    props: {
        items: {
            type: Array
        },
        placeholder: String,
        selectedValue: String,
    },
    computed: {

        selectedItem() {
            return this.items[this.selectedIndex];
        },
    },
    data: () => {
        return {
            selectedIndex: 0,
            dropdownOpen: false,
        }
    },
    created() {
        if (this.selectedValue) {
            this.items.forEach((item, index) => {
                if (item.id == this.selectedValue) {
                    this.selectedIndex = index;
                }
            });
        }

        this.$emit('change', this.items[this.selectedIndex]);
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeyDown);
    },
    methods: {

        selectIndex(index) {

            if (this.selectedIndex != index) {
                this.selectedIndex = index;
                this.$emit('change', this.items[index]);
            }

            this.dropdownOpen = false;
        },

        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },

        closeDropdown() {
            this.dropdownOpen = false;
        },

        handleKeyDown(event) {
            if (this.dropdownOpen && event.keyCode == 27) {
                this.closeDropdown();
            }
        },

        getSelectedId() {
            return this.items[this.selectedIndex].id;
        },

        getSelectedItem() {
            return this.items[this.selectedIndex];
        },
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }
}
</script>
