
<template>
    <span class="flag">
        <svg class="flag__image" viewBox="0 0 40 40">
            <use v-bind="{ 'xlink:href' : '#' + flag }"></use>
        </svg>
    </span>
</template>

<script>
export default {
    name: 'FlagElement',
    props: {
        flag: {
            type: String,
            required: true
        }
    }
}
</script>
