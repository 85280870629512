/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'calendar_icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 19 19',
    data: '<style>.st0{fill:#55c3f0}</style><g id="Symbols"><g id="calendar_icon"><g><path pid="0" class="st0" d="M15.7 1.9h-.9v-.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v.7h-3.2v-.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v.7H5.6v-.7c0-.4-.3-.7-.7-.7s-.7.3-.7.7v.7h-.9C1.7 1.9.5 3.2.5 4.7v11c0 1.6 1.3 2.8 2.8 2.8h12.4c1.4 0 2.8-1.2 2.8-2.5V4.7c0-1.5-1.3-2.8-2.8-2.8zm0 15.2H3.3c-.8 0-1.4-.6-1.4-1.4v-11c0-.8.6-1.4 1.4-1.4h.9V4c0 .4.3.7.7.7s.7-.3.7-.7v-.7h3.2V4c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.7h3.2V4c0 .4.3.7.7.7.4 0 .7-.3.7-.7v-.7h.9c.8 0 1.4.6 1.4 1.4v11c0 .8-.6 1.4-1.4 1.4z"/><circle pid="1" id="Oval" class="st0" cx="14" cy="8" r="1"/><circle pid="2" id="Oval_1_" class="st0" cx="11" cy="8" r="1"/><circle pid="3" id="Oval_2_" class="st0" cx="14" cy="11" r="1"/><circle pid="4" id="Oval_3_" class="st0" cx="11" cy="11" r="1"/><circle pid="5" id="Oval_4_" class="st0" cx="14" cy="14" r="1"/><circle pid="6" id="Oval_5_" class="st0" cx="11" cy="14" r="1"/><circle pid="7" id="Oval_6_" class="st0" cx="5" cy="14" r="1"/><circle pid="8" id="Oval_7_" class="st0" cx="5" cy="8" r="1"/><circle pid="9" id="Oval_8_" class="st0" cx="5" cy="11" r="1"/><circle pid="10" id="Oval_9_" class="st0" cx="8" cy="11" r="1"/><circle pid="11" id="Oval_10_" class="st0" cx="8" cy="8" r="1"/><circle pid="12" id="Oval_11_" class="st0" cx="8" cy="14" r="1"/></g></g></g>'
  }
})
