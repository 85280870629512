/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'avatar-sad': {
    width: 16,
    height: 16,
    viewBox: '0 0 92 91',
    data: '<g><path pid="0" d="M46 0C20.6 0 0 20.4 0 45.5S20.6 91 46 91s46-20.4 46-45.5S71.4 0 46 0zm0 88C22.3 88 3 68.9 3 45.5S22.3 3 46 3s43 19.1 43 42.5S69.7 88 46 88z"/><path pid="1" d="M24.9 36.9c1 0 1.8-.8 1.8-1.8 0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5 0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8c0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5c-.2 1 .6 1.8 1.6 1.8z"/><path pid="2" d="M55 36.9c1 0 1.8-.8 1.8-1.8 0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5 0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8c0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5c-.2 1 .6 1.8 1.6 1.8z"/><path pid="3" d="M46.7 45.1c-14.1 0-25.6 11.5-25.6 25.6 0 1 .8 1.8 1.8 1.8h47.6c1 0 1.8-.8 1.8-1.8 0-14.2-11.5-25.6-25.6-25.6zM33.8 68.8c.4-2.6 1.5-5 3.3-7 3.5-3.8 5.7-4.2 9.6-4.2 4.1 0 6.3 1 9.4 4.2 1.8 1.9 2.9 4.4 3.3 7H33.8zm28.8 0c-.9-8.1-7.7-14.3-16-14.3s-15.1 6.3-16 14.3h-5.8c.4-5 2.5-9.5 5.7-13 5.7-6.2 9.5-7.2 16.3-7.2 6.8 0 10.8 1.6 16.1 7.2 3.2 3.5 5.3 8 5.7 13h-6z"/></g>'
  }
})
