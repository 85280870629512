
import polyfillLoader from '@/common/polyfills/InitPolyfills';

import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import PortalVue from 'portal-vue';
import SvgIcon from 'vue-svgicon';
import { directive as onClickaway } from 'vue-clickaway';
import VueMq from 'vue-mq'
import VueAnimateNumber from 'vue-animate-number';
import VueScrollactive from 'vue-scrollactive';
import VueScrollTo from 'vue-scrollto';
import AsyncComputed from 'vue-async-computed'
import Tooltip from 'vue-directive-tooltip';
import VueGtag from "vue-gtag";
import VueTour from 'vue-tour'

import { ApiService } from '@/api';
import IntrotextElement from '@/components/elements/IntrotextElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import SVGIconWrapper from '@/components/elements/SVGIconWrapper.vue';
import MediaElement from '@/components/elements/MediaElement.vue';
import SearchElement from '@/components/elements/SearchElement.vue';
import NuggetDetail from '@/views/NuggetDetail.vue';
import EventView from '@/views/EventView.vue';
import GeneralEntryView from '@/views/GeneralEntryView.vue';
import TrainingHubView from '@/views/TrainingHubView.vue';
import OverviewView from '@/views/OverviewView.vue';
import StreamView from '@/views/StreamView.vue';
import KnowledgeTest from '@/views/KnowledgeTest.vue';
import NuggetCollection from '@/views/NuggetCollection.vue';
import App from '@/App.vue';

import { numberFormat } from '@/common/utility/filters';
import ScormTracker from '@/common/tracking/ScormTracker';

window.SCORM_TRACKER = new ScormTracker();

import '@/_icons';

Vue.use(SvgIcon, {
    tagName: 'svgicon'
});

Vue.use(PortalVue);
Vue.use(VueAnimateNumber);
Vue.use(Tooltip);
Vue.use(VueTour);

Vue.use(VueScrollactive);
Vue.use(VueScrollTo);
Vue.use(AsyncComputed);
Vue.use(VueMq, {
    breakpoints: {
        xs: 680,
        sm: 768,
        md: 1024,
        lg: Infinity,
    }
});

Vue.component('nugget-detail', NuggetDetail);
Vue.component('training-hub-view', TrainingHubView);
Vue.component('overview-view', OverviewView);
Vue.component('stream-view', StreamView);
Vue.component('event-view', EventView);
Vue.component('general-entry-view', GeneralEntryView);
Vue.component('knowledge-test', KnowledgeTest);
Vue.component('nugget-collection', NuggetCollection);
Vue.component('button-element', ButtonElement);
Vue.component('introtext-element', IntrotextElement);
Vue.component('svgicon-wrapper', SVGIconWrapper);
Vue.component('media-element', MediaElement);
Vue.component('search-element', SearchElement);
Vue.component('app', App);


Vue.directive('click-away', onClickaway);

Vue.filter('numberFormat', (value) => {
    return numberFormat(value);
});

// Vue.use(VueGtag, {
//     config: {
//         id: "GTM-NTFTKS3"
//     }
// });

ApiService.init();

new Vue({
    el: '#app',
    router,
    store,

    async created() {

    },

    async mounted() {
        polyfillLoader();
    }
});
