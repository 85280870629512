<template>
    <div class="user-menu" v-click-away="closeFlyout">
        <span
            :class="{
                'user-menu__button': true,
                'user-menu__button--active': menuActive,
            }"
            @click="handleButtonClick"
        >
            <span class="user-menu__button-icon">
                <span class="user-menu__button-icon-base" />
            </span>
        </span>
        <transition-expand>
            <div v-if="menuActive" class="user-menu__flyout">
                <ul class="user-menu__items">
                    <li v-if="getLanguages.length > 1" class="user-menu__item">
                        <svgicon-wrapper name="language" />{{ t('language') }}
                        <a
                            v-for="(language, index) in getLanguages"
                            :key="`language-${index}`"
                            :href="`switch-language?language=${language.code}&path=${$router.currentRoute.fullPath}`"
                            :class="{
                                'user-menu__language': true,
                                'user-menu__language--active': language.code == getActiveLanguage,
                            }"
                        >{{ language.code.substr(0, 2) }}</a>
                    </li>
                    <slot name="usermenuExtraListItems" />
                    <!-- <li v-if="canAccessCp" class="user-menu__item">
                        <a href="/admin" class="user-menu__item-link" target="_blank">
                            <svgicon-wrapper name="cms" />{{ t('cms-backend') }}
                        </a>
                    </li> -->
                    <li class="user-menu__item">
                        <a href="/sso/logout/request" class="user-menu__item-link">
                            <svgicon-wrapper name="logout" />{{ t('logout') }}
                        </a>
                    </li>
                </ul>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

export default {
    name: 'UserMenu',
    components: {
        TransitionExpand,
    },
    props: {

    },
    data() {
        return {
            menuActive: false
        }
    },
    computed: {

        ...mapGetters('meta', {
            canAccessCp: 'canAccessCp',
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        ...mapGetters('common', {
            headerIsPinned: 'isPinned',
        }),

        ...mapGetters('uitexts', {
            t: 't',
            getLanguages: 'getAvailableLanguages',
            getActiveLanguage: 'getActiveLanguage',
        }),
    },


    mounted() {
    },

    methods: {
        handleButtonClick() {
            this.menuActive = !this.menuActive;
        },

        closeFlyout() {
            this.menuActive = false;
        },
    },
    beforeDestroy() {

    }
}
</script>
