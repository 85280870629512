<template>
    <div class="tile-grid">
        <div class="tile-grid__page-container">
            <div class="tile-grid__main">

                <div class="tile-grid__main-item tile-grid__main-item--a">
                    <tile-grid-slider :slides="highlightItems" :large="true" />
                </div>

                <div class="tile-grid__main-item tile-grid__main-item--b">
                    <tile-grid-item v-bind="container1Item" />
                </div>

                <div class="tile-grid__main-item tile-grid__main-item--c">
                    <tile-grid-item v-bind="container2Item" />
                </div>

                <div class="tile-grid__main-item tile-grid__main-item--d">
                    <tile-grid-item v-bind="container3Item" />
                </div>

                <div class="tile-grid__main-item tile-grid__main-item--e">
                    <tile-grid-item v-bind="container4Item" />
                </div>

                <div class="tile-grid__main-item tile-grid__main-item--f">
                    <tile-grid-slider :slides="experiences" />
                </div>
            </div>

            <div class="tile-grid__extra">
                <div class="tile-grid__extra-item tile-grid__extra-item--social">
                    <tile-grid-item v-bind="externalContent" />
                </div>

                <div class="tile-grid__extra-item tile-grid__extra-item--one">
                    <tile-grid-item v-bind="container5Item" />
                </div>
                <div class="tile-grid__extra-item tile-grid__extra-item--two">
                    <tile-grid-item v-bind="container6Item" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Swiper from 'swiper';
import debounce from 'debounce';

import TileGridSlider from '@/components/elements/hub/tile-grid/TileGridSlider.vue';
import TileGridItem from '@/components/elements/hub/tile-grid/TileGridItem.vue';

export default {
    name: 'TileGrid',

    components: {
        TileGridSlider,
        TileGridItem,
    },

    props: {
        highlightItems: Array,
        container1Item: Object,
        container2Item: Object,
        container3Item: Object,
        container4Item: Object,
        container5Item: Object,
        container6Item: Object,
        externalContent: Object,
        experiences: Array,
    },

    computed: {

    },

    data: () => {
        return {

        }
    },

    async mounted() {
        await this.$nextTick();
    },

    methods: {

    },

    beforeDestroy() {

    }
}
</script>
