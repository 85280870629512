<template>
    <div class="scroll-progress">
        <div
            class="scroll-progress__bar"
            :style="{
                width: `${width}%`
            }"
        ></div>
    </div>
</template>

<script>
export default {
    name: "ScrollProgress",

    data() {
        return {
            width: 0
        }
    },

    methods: {
        handleScroll() {
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            this.width = (window.pageYOffset / height) * 100
            const eventWidth = Math.ceil(this.width)

            if (eventWidth === 0) {
                this.$emit("begin")
            }

            if (eventWidth === 100) {
                this.$emit("complete")
            }
        }
    },

    mounted() {
        window.addEventListener("scroll", this.handleScroll);
        window.dispatchEvent(new CustomEvent("scroll"));
    },

    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
}
</script>
