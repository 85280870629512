<template>
    <component
        :is="getComponent"
        :class="{
            'tile-grid-item': true,
            'tile-grid-item--large': large,
        }"
        v-bind="getComponentAttrs"
        @click.native="(event) => handleClick(event)"
    >

        <image-element
            v-if="image"
            :image="image"
            :settings="{ sizing: 'cover' }" />

        <div v-if="headline || subline || url" class="tile-grid-item__overlay">
            <div class="tile-grid-item__texts">
                <span v-if="subline" class="tile-grid-item__subline" v-html="subline" />
                <span v-if="headline" class="tile-grid-item__headline" v-html="headline" ref="headline" />
                <span v-if="url" class="tile-grid-item__button">{{ t('see-more') }}</span>
            </div>
        </div>

        <div v-if="loading" class="tile__loader">
            <loader-element :loading="loading" />
        </div>
    </component>
</template>

<script>
import { mapGetters } from "vuex";
import { lineClamp } from '@/common/utility/lineClamp';

import ImageElement from '@/components/elements/ImageElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'TileGridItem',

    components: {
        ImageElement,
        LoaderElement,
    },

    props: {
        type: {
            type: String,
            default: 'type-1'
        },
        image: Object,
        subline: String,
        headline: String,
        url: {
            type: String,
            required: true
        },
        external: {
            type: Boolean,
            default: false,
        },
        large: Boolean,
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't'
        }),

        getComponent() {
            return this.external ? 'a' : 'router-link';
        },

        getComponentAttrs() {
            return this.external ?
                {
                    href: this.url,
                    target: '_blank',
                    rel: 'noopener',
                } :
                {
                    to: {
                        path: `/${this.url}`
                    }
                };
        },
    },

    async mounted() {
        await this.clampHeadlines();

        window.addEventListener('resize', this.reClampHeadlines)
    },

    async updated() {
        await this.reClampHeadlines();
    },

    methods: {

        async reClampHeadlines() {

            if (!this.$refs.headline) { return; }

            this.$refs.headline.innerHTML = this.headline;

            await this.$nextTick();

            this.clampHeadlines();
        },

        async clampHeadlines() {

            await this.$nextTick();

            if (!this.$refs.headline) {
                return;
            }

            let items = [];
            if (!!this.$refs.headline[0]) {
                items = this.$refs.headline;
            } else {
                items = [
                    this.$refs.headline
                ];
            }

            items.forEach(i => {
                lineClamp(i, {clamp: 2});
            });
        },

        handleClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loading = true;
            }

            this.$emit('tileclick');
        },
    },
}
</script>
