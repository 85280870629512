
import { ApiService } from '..';
const suffix = window.EXPORT_TYPE_SCORM ? '.json' : ''

export const OverviewService = {

    getPage(slug) {
        return ApiService.get('overview', `${slug}${suffix}`);
    },

    getContent(slug) {
        return ApiService.get(`${slug}${suffix}`);
    },
};