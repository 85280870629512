<template>
    <div class="required-action required-action-nickname">
        <div class="required-action__grid">
            <div class="required-action__grid-column required-action__grid-column--text">
                <introtext-element :introtext="getIntrotext" />

                <input-element
                    :placeholder="t('nickname')"
                    name="nickname"
                    id="nickname"
                    v-model="nickname" />

                <div v-if="getNicknameError" class="required-action-nickname__error">{{ t(getNicknameError) }}</div>

                <button-element
                    @click="handleSubmitNicknameButtonClick"
                    :button="{
                        'label': t('save'),
                    }"
                    :disabled="isLoadingSaveNickname"
                >
                    <span slot="icon" v-if="isLoadingSaveNickname">
                        <loader-element />
                    </span>
                </button-element>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    ME_POST_NICKNAME_NS,
    ME_UPDATE_PROV_NICKNAME_NS,
} from "@/store/actions.type";

import InputElement from '@/components/elements/form/InputElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import IntrotextElement from '@/components/elements/IntrotextElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'ActionNickname',

    components: {
        InputElement,
        ButtonElement,
        IntrotextElement,
        LoaderElement,
    },

    data: () => {
        return {
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('me', {
            getNickname: 'getNickname',
            getProvisionalNickname: 'getProvisionalNickname',
            isLoadingSaveNickname: 'isPostingMe',
            getNicknameError: 'getError',
        }),

        nickname: {
            get() { return this.getProvisionalNickname; },
            set(value) {
                store.dispatch(ME_UPDATE_PROV_NICKNAME_NS, value);
            }
        },

        getIntrotext() {
            return {
                subline: null,
                headline: this.t('set-nickname-headline'),
                copytext: this.t('set-nickname-copytext')
            };
        },
    },

    methods: {
        async handleSubmitNicknameButtonClick() {
            await store.dispatch(ME_POST_NICKNAME_NS);
        },
    }
}
</script>
