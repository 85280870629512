<template>
    <div class="social-wall" ref="container"></div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'SocialWall',

    components: {

    },

    props: {
        src: String,
    },

    computed: {
    },

    data: () => {
        return {

        }
    },

    mounted() {
        const { container } = this.$refs;

        const baseSrc = 'https://walls.io/js/wallsio-widget-1.2.js';

        if(!container || !this.src || document.querySelector("script[src='" + baseSrc + "']")) {
            return;
        }

        let script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('data-wallurl', this.src);
        script.setAttribute('data-title', 'NOVELIS');
        script.setAttribute('data-width', '100%');
        script.setAttribute('data-autoheight', '1');
        script.setAttribute('data-height', '400');
        script.setAttribute('data-lazyload', '1');
        script.setAttribute('src', baseSrc);
        container.appendChild(script);
    },

    methods: {

    },

    beforeDestroy() {

    },
}
</script>
