<script>
    export default {
        name: 'TransitionExpand',
        functional: true,
        props: {
            transitionName: {
                type: String,
                default: 'expand'
            }
        },

        render(createElement, context) {
            const noop = () => {};
            const { props, listeners } = context;
            const onEnd = listeners['end'] || noop;
            const onEntered = listeners['entered'] || noop;

            const data = {
                props: {
                    name: context.props.transitionName,
                },
                on: {
                    afterEnter(element) {
                        element.style.height = 'auto';
                        onEntered();
                    },

                    enter(element) {
                        const width = getComputedStyle(element).width;
                        element.style.width = width;
                        element.style.position = 'absolute';
                        element.style.visibility = 'hidden';
                        element.style.height = 'auto';

                        const height = getComputedStyle(element).height;
                        element.style.width = '';
                        element.style.position = '';
                        element.style.visibility = '';
                        element.style.height = 0;

                        // Force repaint to make sure the
                        // animation is triggered correctly.
                        getComputedStyle(element).height;

                        setTimeout(() => {
                            element.style.height = height;
                        });
                    },

                    leave(element) {
                        const height = getComputedStyle(element).height;
                        element.style.height = height;

                        // Force repaint to make sure the
                        // animation is triggered correctly.
                        getComputedStyle(element).height;

                        setTimeout(() => {
                            element.style.height = 0;
                        });
                    },

                    afterLeave() {
                        onEnd();
                    }
                }
            };

            return createElement('transition', data, context.children);
        }
    };
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>