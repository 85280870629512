/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checkmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 9',
    data: '<g><g><g><path pid="0" d="M4.4 8.8L.6 5c-.4-.4-.4-.9 0-1.3s.9-.4 1.3 0l2.5 2.5L10 .6c.4-.4.9-.4 1.3 0s.4.9 0 1.3L4.4 8.8z" _fill="#55c3f0"/></g></g></g>'
  }
})
