<template>
    <div v-if="!isLoading" class="view-login" :style="loginStyle" id="view-login">
        <page-header
            :headroom-disabled="true"
            :no-usermenu="true"
        ></page-header>

        <div class="login">
            <div class="login__form content-dimensioning form-dimensioning">

                <base-section :section="getSection">
                    <div slot="component" class="login__wrapper">
                        <form accept-charset="UTF-8" @submit.prevent="login" ref="form">

                            <input-element
                                v-if="useCredentials"
                                :placeholder="t('email-address')"
                                name="loginName"
                                id="loginName"
                                v-model="loginName" />

                            <input-element
                                :placeholder="t('password')"
                                name="password"
                                id="password"
                                type="password"
                                v-model="password" />

                            <div class="errors" v-if="error">
                                <span class="error">{{ error }}</span>
                            </div>

                            <div class="buttons">
                                <span v-if="!useCredentials" @click="useCredentials = true">Credential Login</span>
                                <a href="#" v-else @click="useCredentials = false">Reset password</a>

                                <button-element
                                    @click="handleSubmitButtonClick"
                                    :button="{
                                        'label': t('login'),
                                    }"
                                    :disabled="isLoggingIn"
                                >
                                    <span slot="icon" v-if="isLoggingIn">
                                        <loader-element />
                                    </span>
                                </button-element>
                            </div>

                            <div v-if="false" class="login__info">
                                <p>Any Text</p>
                            </div>
                        </form>
                    </div>
                </base-section>
            </div>
        </div>

        <page-footer :language-switch="false" />

    </div>
</template>

<script>
import debounce from 'debounce';
import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    AUTHENTICATION_LOGIN_NS,
    GENERAL_ENTRY_FETCH_NS,
    AUTHENTICATION_REDIRECT_ROUTE_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import InputElement from '@/components/elements/form/InputElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';
import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'LoginView',
    components: {
        PageHeader,
        PageFooter,
        BaseSection,
        InputElement,
        ButtonElement,
        LoaderElement,
    },
    data() {
        return {
            loginName: '',
            password: '',
            useCredentials: false,
            error: '',
        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
            getFooterHeight: 'getFooterHeight',
        }),

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('generalEntry', {
            getSections: 'getSections',
            isLoadingEntry: 'isLoading',
        }),

        ...mapGetters('authentication', {
            isLoggingIn: 'isLoading',
            isLoggedIn: 'isLoggedIn',
            getRedirectRoute: 'getRedirectRoute',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": {
                    "headline": 'Novelis HUB',
                    "subline": 'Welcome to the',
                    "copytext": null,
                    "button": null
                },
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": "",
                        "backgroundImage": null,
                        "textBehavior": "centered"
                    },
                    "layout": null,
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },

        loginStyle() {

            const styles = {
                height: `calc(100vh - ${this.getHeaderHeight}px - ${this.getFooterHeight}px)`
            };

            return styles;
        },

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingEntry;
        }
    },

    async beforeRouteEnter(to, from, next) {
        await store.dispatch(GENERAL_ENTRY_FETCH_NS, 'login');
        return next();
    },

    mounted() {

    },

    methods: {

        handleSubmitButtonClick() {
            this.login();
        },

        async login() {

            const { loginName, password } = this;
            this.error = '';

            let username = null;
            if (this.useCredentials) {
                username = loginName;
            }

            const loginResult = await store.dispatch(AUTHENTICATION_LOGIN_NS, {
                useCredentials: this.useCredentials,
                loginName: username,
                password,
                action: 'users/login'
            });

            if (!loginResult.success) {
                this.error = loginResult.error;
            }
        },
    },
}
</script>
