/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'feedback-positive_big': {
    width: 16,
    height: 16,
    viewBox: '0 0 57 57',
    data: '<g><g><g _fill="#94c11f"><path pid="0" d="M28.5.3C12.8.3 0 13 0 28.5s12.8 28.2 28.5 28.2S57 44 57 28.5 44.2.3 28.5.3zm0 54.4C13.9 54.7 2 42.9 2 28.5S13.9 2.3 28.5 2.3 55 14.1 55 28.5 43.1 54.7 28.5 54.7z"/><path pid="1" d="M43.2 22.7L27.3 38.3c-.9.9-2.4.9-3.3 0l-7.6-7.4c-.9-.9-.9-2.4 0-3.3.9-.9 2.4-.9 3.3 0l5.9 5.7 14.2-14c.9-.9 2.4-.9 3.3 0s1 2.5.1 3.4z" fill-rule="evenodd" clip-rule="evenodd"/></g></g></g>'
  }
})
