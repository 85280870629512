
import { ApiService } from '../';

export const CompanionService = {

    // query(type, params) {
    //     return ApiService.query("articles" + (type === "feed" ? "/feed" : ""), {
    //         params: params
    //     });
    // },

    get(slug) {
        return ApiService.get('companion', slug);
    }
};