<template>
    <div
        v-if="choices"
        :class="{
            'choices': true,
            'choices--single': !isMultipleChoice,
            'choices--multiple': isMultipleChoice,
            'choices--disabled': evaluating || disabled,
            'choices--highlight-correct': highlightCorrect,
            'interaction': true,
        }"
    >
        <div
            v-for="(choice, index) in shuffledChoices"
            @click="(event) => handleChoiceClick(event, index)"
            :key="`choices-choice-${index}`"
            :class="{
                'choices__choice': true,
                'choices__choice--right': highlightCorrect && choice.correct,
                'choices__choice--wrong': highlightCorrect && !choice.correct,
                'choices__choice--user-wrong': highlightCorrect && ((!choice.correct && choice.selected) || (choice.correct && !choice.selected)),
                'choices__choice--user-right': highlightCorrect && ((choice.correct && choice.selected) || (!choice.correct && !choice.selected)),
                'choices__choice--selected': choice.selected,
            }"
        >
            <span class="choices__choice-element">
                <svgicon-wrapper v-if="isAlternativeBrand && isMultipleChoice" name="checkmark" />
            </span>
            <span class="choices__choice-text" v-html="choice.text" />
        </div>

        <div v-if="customFeedbacked" class="interaction-evaluator">
            <div class="interaction__buttons">
                <button-element
                    :disabled="evaluating"
                    @click="customClick"
                    :button="{
                        'label': t('ok')
                    }"/>
            </div>

            <transition-expand>
                <div v-if="evaluating" ref="feedbackContainer">
                    <div
                        class="interaction__feedback feedback feedback--passed feedback--default"
                    >
                        <div class="feedback__grid">

                            <div class="feedback__grid-column feedback__grid-column--icon">
                                <div class="feedback__icon"><svgicon-wrapper name="checkmark" width="26" height="26" /></div>
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--text">
                                <span class="feedback__copytext" v-html="activeFeedback" />
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--buttons">
                                <button-element
                                    @click="reset"
                                    :button="{
                                        'label': t('try-again')
                                    }" />

                                <!-- <button-element
                                    v-if="activeFeedback.solveButton"
                                    @click="handleSolveClicked"
                                    :button="{
                                        'label': t('solution')
                                    }" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </transition-expand>
        </div>

        <interaction-evaluator
            v-if="!evaluatesExternal && !customFeedbacked"
            :evaluating="evaluating"
            :feedback="feedback"
            :evaluation="evaluation"
            @evaluate="evaluate"
            @retry="reset"
            @solve="solve"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import shuffle from '@/common/utility/shuffleArray';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import InteractionEvaluator from '@/components/elements/quiz/InteractionEvaluator.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';

export default {
    name: 'ChoiceElement',
    components: {
        ButtonElement,
        InteractionEvaluator,
        TransitionExpand,
        FeedbackElement,
    },
    props: {
        choices: Array,
        feedback: Object,
        sumFeedbacks: Array,
        evaluatesExternal: {
            type: Boolean,
            default: false
        },
        randomizeChoices: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    forceMultipleChoice: false
                }
            }
        },
        highlightCorrect: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            evaluating: false,
            shuffledChoices: [],
            customFeedbacked: false,
            evaluation: {
                correct: 0,
                incorrect: 0,
                passed: false,
            },
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        isMultipleChoice() {
            return this.settings.forceMultipleChoice || this.choices.filter(c => c.correct).length > 1
        },
        getEvaluatedFeedback() {

            if (!this.feedback) { return null; }

            const positiveFeedback = this.feedback.positive || null;
            const negativeFeedback = this.feedback.negativerFeedback || null;

            return this.evaluating && this.evaluation.passed == true ? positiveFeedback : negativeFeedback;
        },

        activeFeedback() {
            const sumSelected = this.shuffledChoices.filter((choice) => {
                return choice.selected;
            }).length;

            const feedback = this.sumFeedbacks.find((s) => {
                return s.sum == sumSelected;
            });

            return feedback ? feedback.feedback : null;
        },
    },
    created() {
        this.reset();
    },

    mounted() {
        this.customFeedbacked = this.sumFeedbacks && !!this.sumFeedbacks.length;
    },
    methods: {
        initializeChoices() {
            this.shuffledChoices = JSON.parse(JSON.stringify(this.choices));
            if (this.randomizeChoices) {
                this.shuffledChoices = shuffle(this.shuffledChoices);
            }
            this.shuffledChoices.forEach((choice) => {
                choice.selected = false;
            });
        },
        handleChoiceClick(event, choiceIndex) {

            if (this.evaluating || this.disabled) { return; }

            const currentChoice = this.shuffledChoices[choiceIndex];

            if (!this.isMultipleChoice) {

                this.shuffledChoices.forEach((choice) => {
                    choice.selected = false;
                });

                this.$set(this.shuffledChoices, choiceIndex, { ...currentChoice, selected: true });

            } else {
                this.$set(this.shuffledChoices, choiceIndex, { ...currentChoice, selected: !currentChoice.selected });
            }
        },
        evaluate() {

            if (this.evaluating || this.disabled) { return; }

            const result = {
                correct: 0,
                incorrect: 0
            };

            this.shuffledChoices.forEach((choice) => {
                if (choice.correct == choice.selected) {
                    result.correct++;
                } else {
                    result.incorrect++;
                }
            });

            this.evaluation = Object.assign({}, this.evaluation, {
                correct: result.correct,
                incorrect: result.incorrect,
                passed: result.incorrect == 0,
            });

            this.evaluating = true;

            return this.evaluation;
        },
        reset() {
            this.evaluation = Object.assign({}, this.evaluation, {
                correct: 0,
                incorrect: 0,
                passed: false,
            });

            this.evaluating = false;

            this.initializeChoices();
        },
        solve() {

            this.shuffledChoices.forEach((choice, choiceIndex) => {
                this.$set(this.shuffledChoices, choiceIndex, { ...choice, selected: choice.correct });
            });
        },

        async customClick() {
            this.evaluating = true;

            await this.$nextTick();

            // const { feedbackContainer } = this.$refs;

            // if (feedbackContainer) {

            //     this.$scrollTo(feedbackContainer, 1000, {
            //         easing: [0.23, 1, 0.32, 1],
            //         offset: () => {
            //             const feedbackRect = feedbackContainer.getBoundingClientRect();
            //             const customOffset = 16;
            //             const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

            //             return Math.min(0, targetTop);
            //         },
            //         force: true,
            //         cancelable: true,
            //         x: false,
            //         y: true
            //     });
            // }
        },
    }
}
</script>
