<template>
    <div v-if="!isLoading" class="view-knowledge-test">
        <page-header
            :no-usermenu="true"
            :headroom-disabled="true"
        ></page-header>

        <knowledge-test
            v-if="!isPreview"
            :interactions="getInteractions"
            :maximum-interactions="getMaximumInteractions"
            :completion-percentage="getCompletionPercentage"
            :randomize-interactions="getRandomizeInteractions"
            :randomize-choices="getRandomizeChoices"
            :conclusion="getConclusion"
            :trackingId="getTrackingId"
            :start-screen="getStartScreen" />

        <knowledge-test-preview
            v-if="isPreview"
            :interactions="getInteractions"
            :conclusion="getConclusion"
            :randomize-interactions="getRandomizeInteractions"
            :randomize-choices="getRandomizeChoices"
            :start-screen="getStartScreen" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    KNOWLEDGE_TEST_FETCH_NS,
    KNOWLEDGE_TEST_SET_NS,
    ME_GET_NS,
    REQUIREMENTS_MATCH_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import KnowledgeTest from '@/components/elements/quiz/KnowledgeTest.vue';
import KnowledgeTestPreview from '@/components/preview/KnowledgeTestPreview.vue';

export default {
    name: 'KnowledgeTestView',
    components: {
        PageHeader,
        KnowledgeTest,
        KnowledgeTestPreview,
    },
    props: {
        content: Object,
        isPreview: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('knowledgeTest', {
            getStartScreen: 'getStartScreen',
            getInteractions: 'getInteractions',
            getMaximumInteractions: 'getMaximumInteractions',
            getCompletionPercentage: 'getCompletionPercentage',
            getConclusion: 'getConclusion',
            getTrackingId: 'getTrackingId',
            isLoadingKnowledgeTest: 'isLoading',
            getRandomizeInteractions: 'getRandomizeInteractions',
            getRandomizeChoices: 'getRandomizeChoices',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingKnowledgeTest;
        }
    },
    async beforeRouteEnter(to, from, next) {

        if (!window.EXPORT_TYPE_SCORM && !await store.dispatch(REQUIREMENTS_MATCH_NS, to.params.slug)) {
            return next({ name: 'forbidden' });
        }

        await store.dispatch(UITEXTS_FETCH_NS);
        if (!window.EXPORT_TYPE_SCORM) {
            await store.dispatch(ME_GET_NS);
        }
        await store.dispatch(KNOWLEDGE_TEST_FETCH_NS, to.params.slug);

        return next();
    },
    async created() {
        if (this.content) {
            store.dispatch(KNOWLEDGE_TEST_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            if (!window.EXPORT_TYPE_SCORM) {
                await store.dispatch(ME_GET_NS);
            }
        }
    },

    methods: {

    },
}
</script>
