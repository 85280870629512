
<template>
    <span class="countdown">
        <span v-if="time" class="countdown--time">{{ time }}</span>
    </span>
</template>

<script>
import Timer from 'timer.js';

export default {
    name: 'CountdownElement',

    props: {
        start: Boolean,
        seconds: Number,
        padZeros: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            timer: null,
            time: '',
            units: ['seconds'],
        }
    },

    watch: {
        start(newValue, oldValue) {
            if (newValue) {
                this.timer.start(this.seconds);
            } else {
                this.timer.stop();
            }
        },

        seconds(newValue) {
            if (this.start) {
                this.time = this.formatToTime(newValue * 1000);
                this.timer.start(newValue);
            }
        },
    },

    computed: {
    },

    created() {
        this.timer = new Timer({
            tick: 1 / 1000,
            ontick: this.handleTimeChange,
            onend: this.handleTimerExpired
        });

        this.time = this.formatToTime(this.seconds * 1000);

        if (this.start) {
            this.timer.start(this.seconds);
        }
    },

    methods: {
        handleTimeChange(ms) {
            this.time = this.formatToTime(ms);
            this.$emit('tick', Math.ceil((ms / 1000) % 60), ms);
        },

        handleTimerExpired() {
            this.$emit('expired');
        },

        formatToTime(ms) {

            let seconds = Math.ceil((ms / 1000) % 60);
            let minutes = Math.floor((ms / (60 * 1000)) % 60);

            if (this.padZeros) {
                if (seconds < 10) { seconds = `0${seconds}`; }
                if (minutes < 10) { minutes = `0${minutes}`; }
            }

            let format = seconds;

            if (minutes > 0) {
                format = `${minutes}:${seconds}`;
            }

            return format;
        }
    }
}
</script>

