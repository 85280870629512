/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 17 10',
    data: '<polygon pid="0" points="15.3,0 8.5,6.9 1.6,0 0,1.6 7.8,9.4 8.5,10 16.9,1.6" _fill="#344384"/>'
  }
})
