
import { sortAlphabetically, sortByProperty } from '@/common/utility/sorting';

import {
    COMPANION_FILTER_TOPIC_CHANGE,
    COMPANION_FILTER_CATEGORY_ADD,
    COMPANION_FILTER_CATEGORY_REMOVE,
    COMPANION_FILTER_SEARCH,
} from "@/store/actions.type";

import {
    SET_COMPANION_FILTER_TOPIC,
    SET_COMPANION_FILTER_CATEGORY_ADD,
    SET_COMPANION_FILTER_CATEGORY_REMOVE,
    SET_COMPANION_FILTER_SEARCH,
} from "@/store/mutations.type";

import { denormalize } from 'normalizr';
import { schemaEntry } from '@/store/schema/entry.schema';


const state = () => ({
    search: '',
    activeTopic: '*',
    activeCategories: [],
});

const actions = {

    [COMPANION_FILTER_TOPIC_CHANGE]({ commit }, topic) {

        commit(SET_COMPANION_FILTER_TOPIC, topic);

        return topic;
    },

    [COMPANION_FILTER_CATEGORY_ADD]({ commit }, category) {

        commit(SET_COMPANION_FILTER_CATEGORY_ADD, category);

        return category;
    },

    [COMPANION_FILTER_CATEGORY_REMOVE]({ commit }, category) {

        commit(SET_COMPANION_FILTER_CATEGORY_REMOVE, category);

        return category;
    },

    [COMPANION_FILTER_SEARCH]({ commit }, search) {

        commit(SET_COMPANION_FILTER_SEARCH, search);

        return search;
    },
};

const mutations = {

    [SET_COMPANION_FILTER_TOPIC](state, topic) {

        if (state.activeTopic != topic.id) {
            state.activeTopic = topic.id;
            state.activeCategories = [];
            state.search = '';
        }
    },

    [SET_COMPANION_FILTER_CATEGORY_ADD](state, category) {

        if (state.activeCategories.indexOf(category) !== -1) {
            return;
        }

        state.activeCategories.push(category);
    },

    [SET_COMPANION_FILTER_CATEGORY_REMOVE](state, category) {

        const categoryIndex = state.activeCategories.indexOf(category);

        if (categoryIndex === -1) {
            return;
        }

        state.activeCategories.splice(categoryIndex, 1);
    },

    [SET_COMPANION_FILTER_SEARCH](state, search) {
        state.search = search.toLowerCase().trim();
    },
};

const getters = {

    filtersActive: (state) => {
        return !!state.activeCategories.length || !!state.search.length || state.activeTopic != '*';
    },

    getTopics: (state, getters, rootState, rootGetters) => {

        const topics = Object.values(rootState.companion.topics);

        return [
            {
                title: rootGetters['uitexts/t']('all-topics'),
                id: '*'
            },
            ...topics
        ].sort((a, b) => sortAlphabetically(a, b, 'title'))
    },

    getEntriesFilteredByTopic: (state, getters, rootState) => {

        return Object.entries(rootState.companion.entries)
            .filter(([id, entry]) => {
                return !!entry.topics.length && entry.enabled && (
                    state.activeTopic == '*' || entry.topics.indexOf(state.activeTopic) !== -1
                );
            });
    },

    getCategories: (state, getters, rootState) => {

        const categoryIds = getters.getEntriesFilteredByTopic
            .reduce((categories, [id, entry]) => {
                return [
                    ...categories,
                    ...entry.categories
                ]
            },[])
            .filter((v, i, a) => a.indexOf(v) === i);


        return Object.values(rootState.companion.categories)
            .filter(category => {
                return state.activeCategories.indexOf(category.id) === -1 && categoryIds.indexOf(category.id) !== -1;
            })
            .sort((a, b) => sortAlphabetically(a, b, 'title'));
    },

    getActiveCategories: (state, getters, rootState) => {

        return state.activeCategories.map((categoryId) => {
            return rootState.companion.categories[categoryId];
        })
    },

    getEntries: (state, getters, rootState) => {

        const entries = getters.getEntriesFilteredByTopic;

        let filteredEntries = entries.filter(([id, entry]) => {

            const hasCategoryFilter = entry.categories.some((categoryId) => {
                return state.activeCategories.indexOf(categoryId) >= 0;
            });

            const searchIndex = entry.searchIndex.toLowerCase().trim();
            const hasSearchFilter =  !!state.search.length && searchIndex.includes(state.search);

            return (!state.activeCategories.length && !state.search.length)
                || hasCategoryFilter || hasSearchFilter;
        });

        const entryIds = filteredEntries
            .sort(([idA, entryA], [idB, entryB]) => sortByProperty(entryA, entryB, 'postDate', false))
            .map(([id, entry]) => id);

        return denormalize(entryIds, schemaEntry, rootState.companion);
    }
};


export const companionFilter = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
