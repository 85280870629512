
<template>
    <span
        @click="hidden = !hidden"
        class="tooltip"
        v-tooltip.bottom.start.notrigger="vueTooltipSettings"
        v-click-away="hideTooltip"
    >
        <slot name="icon" />
        <span v-if="label" class="tooltip__label">{{ label }}</span>
    </span>
</template>

<script>

export default {
    name: 'TooltipElement',
    components: {
    },
    props: {
        label: String,
        content: {
            type: String,
            required: true
        },
    },
    computed: {
        vueTooltipSettings() {
            return {
                content: this.content,
                delay: 0,
                offset: 0,
                visible: !this.hidden
            };
        }
    },
    data() {
        return {
            hidden: true
        }
    },
    mounted() {

    },
    methods: {
        hideTooltip() {
            this.hidden = true;
        }
    }
}
</script>
