<template>
    <div class="stream-overview">
        <div class="stream-overview__page-container stream-overview__page-container--small">
            <div class="stream-overview__content-container">
                <div class="stream-overview__grid">

                    <div class="stream-overview__grid-column stream-overview__grid-column--headline">
                        <span class="stream-overview__headline">{{ headline }}</span>
                    </div>

                    <div class="stream-overview__grid-column stream-overview__grid-column--streams">
                        <stream-element
                            v-for="(stream, index) in getHighlightStreams"
                            :key="`stream-group-index-${index}`"
                            :stream="stream"
                            :highlight="index == 0"
                            @nuggetClick="handleNuggetClick"
                            @streamClick="handleStreamClick" />
                    </div>

                    <div v-if="getMoreStreams && !!getMoreStreams.length" class="stream-overview__grid-column stream-overview__grid-column--more">
                        <span
                            :class="{
                                'stream-overview__more-button': true,
                                'stream-overview__more-button--active': showingMore,
                            }"
                            @click="toggleShowMore"
                        >
                            <span v-if="showingMore">{{ t('show-less') }}</span>
                            <span v-else>{{ t('show-more') }}</span>
                            <span class="stream-overview__more-button-icon">
                                <svgicon-wrapper name="arrow-down-fine" color="#ffffff"/>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <transition-expand @entered="scrollVisible">
            <div class="stream-overview__page-container" v-if="showingMore" ref="showMoreWrapper">
                <div class="stream-overview__more">
                    <div class="stream-overview__content-container">
                        <div class="stream-overview__grid stream-overview__grid--more">

                            <div
                                class="stream-overview__grid-column stream-overview__grid-column--more-item"
                                v-for="(stream, index) in getMoreStreams"
                                :key="`stream-group-index-${index}`"
                            >
                                <router-link
                                    :to="{ path: `/${stream.url}` }"
                                    @click.native="handleMoreStreamClick"
                                    class="stream-overview__more-item"
                                >
                                    <span class="stream-overview__more-item-headline">
                                        {{ stream.keyvisual.introtext.headline }}
                                        <svgicon-wrapper name="arrow-down-fine" color="#ffffff"/>
                                    </span>
                                    <media-element
                                        class="stream-overview__more-item-media"
                                        :media="stream.keyvisual.thumbnail"
                                        :image-settings="stream.keyvisual.settings.image"
                                        :video-settings="stream.keyvisual.settings.video" />
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import StreamElement from '@/components/elements/hub/StreamElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

// Count of Highlight Streams, before showing it in "more" section
const HIGHLIGHT_STREAMS = 3;

export default {
    name: 'StreamOverview',
    components: {
        StreamElement,
        ImageElement,
        TransitionExpand,
    },

    props: {
        headline: String,
        streams: Array,
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getHighlightStreams() {
            return this.streams.slice(0, HIGHLIGHT_STREAMS);
        },

        getMoreStreams() {
            return this.streams.slice(HIGHLIGHT_STREAMS);
        },

    },

    data() {
        return {
            showingMore: false,
        }
    },

    methods: {

        handleMoreStreamClick() {

        },

        handleNuggetClick() {
            this.$gtag.event('sth.click.hub.nugget', {
                'event_category': 'click',
                'event_label': 'HUB Nugget click',
                'value': 1
            });
        },

        handleStreamClick() {
            this.$gtag.event('sth.click.hub.stream', {
                'event_category': 'click',
                'event_label': 'HUB Stream click',
                'value': 1
            });
        },

        toggleShowMore() {
            this.showingMore = !this.showingMore;
        },

        async scrollVisible() {

            if (this.showingMore) {
                await this.$nextTick();
                const { showMoreWrapper } = this.$refs;

                this.$scrollTo(showMoreWrapper, 1000, {
                    easing: [0.23, 1, 0.32, 1],
                    offset: () => {
                        const rect = showMoreWrapper.getBoundingClientRect();
                        const customOffset = 16;
                        const targetTop = -1 * (window.innerHeight - rect.height - customOffset);

                        return Math.min(0, targetTop);
                    },
                    force: true,
                    cancelable: true,
                    x: false,
                    y: true
                });
            }
        },
    },
}
</script>
