/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'information': {
    width: 16,
    height: 16,
    viewBox: '0 0 30 30',
    data: '<g><path pid="0" d="M15 0C6.7 0 0 6.7 0 15s6.7 15 15 15 15-6.7 15-15S23.3 0 15 0zm0 28.5C7.6 28.5 1.5 22.4 1.5 15S7.6 1.5 15 1.5 28.5 7.6 28.5 15 22.4 28.5 15 28.5z"/><rect pid="1" x="13.3" y="12" width="4" height="12.6"/><path pid="2" d="M16.8 6.7c-.2-.2-.4-.4-.7-.5-.2-.1-.5-.2-.8-.2-.3 0-.6.1-.9.2-.3.1-.5.3-.7.5s-.4.4-.5.7c-.1.2-.2.5-.2.9s.1.6.2.9c.1.3.3.5.5.7.2.2.4.4.7.5.3.1.6.2.9.2.6 0 1.1-.2 1.6-.6.4-.4.7-1 .7-1.6 0-.3-.1-.6-.2-.9-.2-.4-.4-.6-.6-.8z"/></g>'
  }
})
