<template>
    <div v-if="splitter" :class="{ 'splitter': true, 'splitter--small-columns': isSmallColumns}">
        <div class="splitter__page-container">
            <div class="splitter__content-container">
                <div class="splitter__grid">
                    <div
                        class="splitter__grid-column"
                        v-for="(item, index) in splitter"
                        :key="`splitter-item-${index}`"
                    >
                        <introtext-element
                            v-if="item.hasOwnProperty('introtext')"
                            :introtext="item.introtext" />

                        <media-element
                            v-if="item.hasOwnProperty('media')"
                            :media="item.media"
                            :image-settings="item.settings.image"
                            :video-settings="item.settings.video" />

                        <accordion-element
                            v-if="item.hasOwnProperty('panels')"
                            :panels="item.panels"
                            :settings="item.settings" />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccordionElement from '@/components/elements/AccordionElement.vue';

export default {
    name: 'SplitterElement',
    components: {
        AccordionElement,
    },
    props: {
        splitter: {
            type: Array,
            required: true
        }
    },
    computed: {
        isSmallColumns() {
            const bothColumnsText = this.splitter.filter((item) => {
                return item.hasOwnProperty('introtext');
            }).length == 2;

            return bothColumnsText;
        }
    },
}
</script>
