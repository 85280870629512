/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 12 12',
    data: '<g><g><g><path pid="0" d="M4.7 6l-4-4C.3 1.6.3 1 .7.7s1-.4 1.3 0l4 4 4-4c.4-.4 1-.4 1.3 0s.4 1 0 1.3l-4 4 4 4c.4.4.4 1 0 1.3s-1 .4-1.3 0l-4-4-4 4c-.4.4-1 .4-1.3 0s-.4-1 0-1.3l4-4z" _fill="#d70000"/></g></g></g>'
  }
})
