
export const sortAlphabetically = (a, b, property, asc = true, subProperty = null) => {

    const aP = asc ? a : b;
    const bP = asc ? b : a;

    if (!aP || !bP || !aP.hasOwnProperty(property) || !bP.hasOwnProperty(property)){
        return 0;
    }

    if (subProperty && aP[property].hasOwnProperty(subProperty) && bP[property].hasOwnProperty(subProperty)) {
        return aP[property][subProperty].localeCompare(bP[property][subProperty]);
    }

    return aP[property].localeCompare(bP[property]);
};

export const sortByProperty = (a, b, property, asc = true) => {

    if(a[property] == b[property]) { return 0; }
    if(a[property] < b[property]) { return asc ? -1 : 1; }
    if(a[property] > b[property]) { return asc ? 1 : -1; }

    return 0;
};
