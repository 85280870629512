/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'mail': {
    width: 16,
    height: 16,
    viewBox: '0 0 28 20',
    data: '<g><g><g><path pid="0" d="M27 0H1C.4 0 0 .5 0 1v18c0 .6.4 1 1 1h26c.6 0 1-.4 1-1V1c0-.5-.5-1-1-1zm-1.6 2L14 10.7 2.6 2h22.8zm.6 16H2V4l11.4 8.7c.4.3.9.3 1.2 0L26 4v14z"/></g></g></g>'
  }
})
