<template>
    <div class="nugget-compponent section-dimensioning section-dimensioning--medium content-dimensioning">
        <template  v-for="(section, index) in sections">
            <base-section
                :key="`nugget-section-${index}`"
                :section="section">
            </base-section>
        </template>
    </div>
</template>

<script>
import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'NuggetComponent',
    components: {
        BaseSection,
    },
    props: {
        sections: {
            type: Array,
            required: true
        }
    },
}
</script>
