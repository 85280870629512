<template>
    <div
        v-if="!isLoadingUiTexts"
        :class="{
            'knowledge-test': true,
            'knowledge-test--evaluating': state == states.EVALUATING,
            'knowledge-test--conclusion': state == states.CONCLUSION,
        }"
        :style="activeInteractionStyle"
    >

        <div class="knowledge-test__interactions content-dimensioning">

            <transition name="slide-left">
                <base-section v-if="startScreen && state == states.START" :section="startScreen">
                    <button-element
                        slot="extra-button"
                        @click="handleStartButtonClick"
                        :button="{
                            'label': t('start-test'),
                        }"
                    >
                        <svgicon-wrapper slot="icon" name="start" />
                    </button-element>
                </base-section>
            </transition>

            <transition name="slide-left" v-on:after-enter="afterQuestionEnter">
                <base-section v-if="isQuestionState" :section="activeInteraction" :key="`interaction-${activeInteraction.id}`">
                    <div slot="component">

                        <choice-element
                            :key="`interaction-index-${activeInteraction.id}`"
                            ref="activeInteraction"
                            v-if="activeInteraction.type == 'section-single-multiple-choice'"
                            :choices="activeInteraction.component.choices"
                            :evaluates-external="true"
                            :settings="activeInteraction.settings.choice"
                            :randomize-choices="randomizeChoices" />

                        <value-slider-element
                            :key="`interaction-index-${activeInteraction.id}`"
                            ref="activeInteraction"
                            v-if="activeInteraction.type == 'section-value-slider'"
                            :evaluates-external="true"
                            :slider="activeInteraction.component.valueSlider" />

                        <sortable-options-element
                            :key="`interaction-index-${activeInteraction.id}`"
                            ref="activeInteraction"
                            v-if="activeInteraction.type == 'section-sortable-options'"
                            :evaluates-external="true"
                            :options="activeInteraction.component.sortableOptions"
                            />

                    </div>
                </base-section>
            </transition>

            <transition name="fade">
                <base-section v-if="state == states.CONCLUSION" :section="conclusion">
                    <div slot="component">
                        <conclusion-element :feedback="conclusion.component.knowledgeTestFeedback" :type="resultType" :score="resultScore" score-unit="%" />
                    </div>
                </base-section>
            </transition>

        </div>

        <transition-expand>
            <div v-if="state != states.START" class="knowledge-test__footer">
                <div class="knowledge-test__footer-content">
                    <transition-expand>
                        <div
                            v-if="state == states.EVALUATING"
                            class="knowledge-test__area-feedback"
                        >
                            <feedback-element
                                class="knowledge-test__feedback"
                                centered
                                nocontrols
                                :feedback="activeInteraction.component.feedback"
                                :evaluation="activeInteraction.evaluation" />
                        </div>
                    </transition-expand>

                    <div class="knowledge-test__footer-wrapper">
                        <div class="knowledge-test__area-progress">
                            <span class="knowledge-test__progress-text">{{ progressText }}</span>
                            <div class="knowledge-test__progress-items">
                                <span
                                    :class="{
                                        'knowledge-test__progress-item': true,
                                        'knowledge-test__progress-item--active': index == activeInteractionIndex,
                                        'knowledge-test__progress-item--negative': interactionIsFailed(index),
                                        'knowledge-test__progress-item--positive': interactionIsPassed(index),
                                    }"
                                    v-for="(interaction, index) in chosenInteractions"
                                    :key="`interaction-indeicator-${index}`"
                                ></span>
                            </div>
                        </div>
                        <div class="knowledge-test__area-restart">
                            <span
                                class="knowledge-test__restart-button"
                                @click="handleRestartButtonClick"
                            >
                                <svgicon-wrapper name="restart" :fill="false" />
                                {{ t('restart') }}
                            </span>
                        </div>
                        <div class="knowledge-test__area-action">
                            <transition name="fade">
                                <span
                                    v-if="state != states.CONCLUSION"
                                    class="knowledge-test__action-button"
                                    @click="handleActionButtonClick"
                                >
                                    {{ actionText }}
                                    <svgicon-wrapper name="arrow-down-fine" />
                                </span>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    TRACK_COMPLETION_NS,
    SCORM_COMPLETE_MODULE_NS,
} from "@/store/actions.type";

import shuffle from '@/common/utility/shuffleArray';
import ScormTracker from '@/common/tracking/ScormTracker';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';
import ChoiceElement from '@/components/elements/quiz/ChoiceElement.vue';
import ValueSliderElement from '@/components/elements/quiz/ValueSliderElement.vue';
import SortableOptionsElement from '@/components/elements/quiz/SortableOptionsElement.vue';
import ConclusionElement from '@/components/elements/quiz/ConclusionElement.vue';

const states = {
    START: 'state-start',
    QUESTION: 'state-question',
    CONCLUSION: 'state-conclusion',
    EVALUATING: 'state-evaluating',
};

export default {
    name: 'KnowledgeTest',
    components: {
        TransitionExpand,
        BaseSection,
        ButtonElement,
        FeedbackElement,
        ChoiceElement,
        ValueSliderElement,
        SortableOptionsElement,
        ConclusionElement,
    },
    props: {
        startScreen: Object,
        interactions: Array,
        conclusion: Object,
        trackingId: String,
        maximumInteractions: {
            type: Number,
            default: 100
        },
        randomizeInteractions: {
            type: Boolean,
            default: true
        },
        randomizeChoices: {
            type: Boolean,
            default: true
        },
        completionPercentage: {
            type: Number,
            default: 100
        },
    },
    data() {
        return {
            states: states,
            state: states.START,
            activeInteractionIndex: 0,
            chosenInteractions: [],
            scormTracker: null,
        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        isQuestionState() {
            return [
                this.states.QUESTION,
                this.states.EVALUATING,
            ].indexOf(this.state) !== -1;
        },

        activeInteraction() {
            return this.chosenInteractions[this.activeInteractionIndex];
        },

        activeInteractionStyle() {

            const styles = {
                height: `calc(100vh - ${this.getHeaderHeight}px)`
            };

            if (this.activeInteraction.settings.section) {
                const { backgroundColor } = this.activeInteraction.settings.section;
                styles['backgroundColor'] = backgroundColor || false;
            }

            return styles;
        },

        progressText() {
            const step = this.activeInteractionIndex + 1;

            return `${this.t('question')} ${step}/${this.chosenInteractions.length}`;
        },

        actionText() {
            return this.state == this.states.QUESTION ? this.t('ok') : this.t('next');
        },

        resultType() {
            return this.resultScore >= this.completionPercentage ? 'positive' : 'negative';
        },

        resultScore() {
            const allPassedInteractions = this.chosenInteractions.filter(interaction => interaction.evaluation.passed === true);

            return (allPassedInteractions.length / this.chosenInteractions.length) * 100;
        },
    },

    async created() {
        this.reset();

        await store.dispatch(UITEXTS_FETCH_NS);

        // if (window.EXPORT_TYPE_SCORM) {
        //     this.scormTracker = new ScormTracker();
        // }
    },

    methods: {
        handleStartButtonClick() {
            this.state = this.states.QUESTION;
        },

        handleRestartButtonClick() {
            this.reset();
        },

        handleActionButtonClick() {

            if (this.state == this.states.QUESTION) {
                this.evaluateInteraction();
            } else if (this.state == this.states.EVALUATING) {
                this.nextInteraction();
            }
        },

        async handleCompletion() {
            const passed = this.resultScore >= this.completionPercentage;

            if (window.EXPORT_TYPE_SCORM && passed) {
                // this.scormTracker.trackKnowledgeTest(this.resultScore, passed);
                store.dispatch(SCORM_COMPLETE_MODULE_NS, {
                    moduleId: this.trackingId
                });
            }


            // Report the completion to the Server if neccessary
            if (this.trackingId) {
                // store.dispatch(SCORM_COMPLETE_MODULE_NS, {
                //     moduleId: this.trackingId
                // });

                await store.dispatch(TRACK_COMPLETION_NS, {
                    entryUid: this.trackingId,
                    data: {
                        score: this.resultScore,
                        passed,
                    }
                });
            }
        },

        afterQuestionEnter() {
            var resizeEvent = window.document.createEvent('UIEvents');
            resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        },

        evaluateInteraction() {
            this.state = this.states.EVALUATING;
            const evaluationResult = this.$refs.activeInteraction.evaluate();

            this.$set(this.activeInteraction, 'evaluation', {
                passed: evaluationResult.passed
            });
        },

        async nextInteraction() {

            if (this.activeInteractionIndex + 1 > this.chosenInteractions.length - 1) {
                this.state = this.states.CONCLUSION;
                this.handleCompletion();

            } else {
                this.state = this.states.QUESTION;
                this.activeInteractionIndex++;
            }
        },

        interactionIsPassed(index) {
            const currentInteraction = this.chosenInteractions[index];
            return currentInteraction.hasOwnProperty('evaluation') && currentInteraction.evaluation.passed === true;
        },

        interactionIsFailed(index) {
            const currentInteraction = this.chosenInteractions[index];
            return currentInteraction.hasOwnProperty('evaluation') && currentInteraction.evaluation.passed === false;
        },

        reset() {
            this.prepareInitialState();
            this.prepareInteractions();
        },

        prepareInitialState() {
            if (!this.startScreen) {
                this.state = this.states.QUESTION;
            } else {
                this.state = this.states.START;
            }
        },

        prepareInteractions() {
            const interactionCount = Math.min(this.interactions.length, this.maximumInteractions);

            let interactions = JSON.parse(JSON.stringify(this.interactions));
            if (this.randomizeInteractions) {
                interactions = shuffle(interactions);
            }
            interactions = interactions.slice(0, interactionCount);

            this.chosenInteractions = interactions;
            this.activeInteractionIndex = 0;
        },
    }
}
</script>
