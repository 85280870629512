<template>
    <div
        v-if="options"
        :class="{
            'sortable-options': true,
            'sortable-options--disabled': evaluating || disabled,
            'interaction': true,
        }"
        touch-action="none"
    >
        <div v-if="!!shuffledOptions.length" class="sortable-options__list" touch-action="none">

            <draggable
                class="sortable-options__options"
                tag="div"
                v-model="shuffledOptions"
                :animation="150"
                :touch-start-threshold="0"
                group="sortableOptions"
                :force-fallback="true"
                :disabled="evaluating || disabled"
                :handle="dragHandleSelector"
                :bubble-scroll="true"
                drag-class="sortable-options__options-item--drag"
                ghost-class="sortable-options__options-item--ghost"
                chosen-class="sortable-options__options-item--chosen"
                @start="handleDragStart"
                @end="handleDragEnd"
            >
                <transition-group tag="div" :name="!dragging ? 'flip-list' : null">
                    <div
                        :class="{
                            'sortable-options__options-item': true,
                            'sortable-options__options-item--correct': showCorrectItems && (option.order == index),
                        }"
                        v-for="(option, index) in shuffledOptions"
                        :key="`sortable-option-${option.id}`"
                    >
                        <div class="sortable-options__options-item-wrapper">
                            <span class="sortable-options__text">{{ option.text }}</span>
                            <span class="sortable-options__drag-container">
                                <svgicon-wrapper v-if="showCorrectItems && (option.order == index)" name="checkmark" />
                                <svgicon-wrapper v-else name="arrow-up-down" />
                            </span>
                        </div>
                    </div>
                </transition-group>
            </draggable>
        </div>

        <div v-if="customFeedbacked" class="interaction-evaluator">
            <div class="interaction__buttons">
                <button-element
                    :disabled="evaluating"
                    @click="customClick"
                    :button="{
                        'label': t('ok')
                    }"/>
            </div>

            <transition-expand>
                <div v-if="evaluating" ref="feedbackContainer">
                    <div
                        class="interaction__feedback feedback feedback--passed feedback--default"
                    >
                        <div class="feedback__grid">

                            <div class="feedback__grid-column feedback__grid-column--icon">
                                <div class="feedback__icon"><svgicon-wrapper name="checkmark" width="26" height="26" /></div>
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--text">
                                <span class="feedback__copytext" v-html="activeFeedback" />
                            </div>

                            <div class="feedback__grid-column feedback__grid-column--buttons">
                                <button-element
                                    @click="reset"
                                    :button="{
                                        'label': t('try-again')
                                    }" />

                                <!-- <button-element
                                    v-if="activeFeedback.solveButton"
                                    @click="handleSolveClicked"
                                    :button="{
                                        'label': t('solution')
                                    }" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </transition-expand>
        </div>

        <interaction-evaluator
            v-if="!evaluatesExternal && !customFeedbacked"
            :evaluating="evaluating"
            :feedback="feedback"
            :evaluation="evaluation"
            @evaluate="evaluate"
            @retry="reset"
            @solve="solve"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from 'vuedraggable';

import shuffle from '@/common/utility/shuffleArray';
import { sortByProperty } from '@/common/utility/sorting';

import ButtonElement from '@/components/elements/ButtonElement.vue';
import InteractionEvaluator from '@/components/elements/quiz/InteractionEvaluator.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';

export default {
    name: 'SortableOptionsElement',
    components: {
        draggable,
        ButtonElement,
        InteractionEvaluator,
        FeedbackElement,
        TransitionExpand,
    },
    props: {
        options: Array,
        feedback: Object,
        evaluatesExternal: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            dragging: false,
            shuffledOptions: [],
            evaluating: false,
            showCorrectItems: false,
            customFeedbacked: false,
            evaluation: {
                passed: false,
            },
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getEvaluatedFeedback() {

            if (!this.feedback) { return null; }

            const positiveFeedback = this.feedback.positive || null;
            const negativeFeedback = this.feedback.negative || null;

            return this.evaluating && this.evaluation.passed == true ? positiveFeedback : negativeFeedback;
        },
        dragHandleSelector() {
            return ['lg'].indexOf(this.$mq) === -1 ? '.sortable-options__drag-container' : null;
        },

        activeFeedback() {
            return this.shuffledOptions[0].feedback;
        },
    },
    created() {
        this.reset();
    },

    mounted() {
        this.customFeedbacked = this.options.length == this.options.filter(i => i.feedback).length
    },

    methods: {

        initializeOptions() {
            this.shuffledOptions = JSON.parse(JSON.stringify(this.options));

            if (!this.shuffledOptions.length) {
                return;
            }

            // To make sure we definitely get a new order and not the
            // same as before we pop the first element, randomize the
            // list and push the element. so at least one element is
            // FORCED to be reordered
            const firstOption = this.shuffledOptions[0];
            this.shuffledOptions = this.shuffledOptions.slice(1);

            this.shuffledOptions = shuffle(this.shuffledOptions);

            if (firstOption) {
                this.shuffledOptions.push(firstOption);
            }
        },

        evaluate() {

            if (this.evaluating || this.disabled) { return; }

            const result = {};
            let passed = true;

            this.shuffledOptions.forEach((option, index) => {
                if (option.order != index) {
                    passed = false;
                }
            });

            this.evaluation = Object.assign({}, this.evaluation, {
                passed
            });

            this.evaluating = true;
            this.showCorrectItems = true;

            return this.evaluation;
        },

        reset() {
            this.evaluation = Object.assign({}, this.evaluation, {
                passed: false,
            });

            this.evaluating = false;

            if (!this.showCorrectItems) {
                this.initializeOptions();
            }
        },

        solve() {
            this.shuffledOptions = this.shuffledOptions.sort((a, b) => a.order - b.order);
            this.showCorrectItems = false;
        },

        handleDragStart() {
            this.dragging = true;
            this.showCorrectItems = false;
        },

        handleDragEnd() {
            this.dragging = false;
        },

        async customClick() {
            this.evaluating = true;

            await this.$nextTick();
            const { feedbackContainer } = this.$refs;

            // if (feedbackContainer) {

            //     this.$scrollTo(feedbackContainer, 1000, {
            //         easing: [0.23, 1, 0.32, 1],
            //         offset: () => {
            //             const feedbackRect = feedbackContainer.getBoundingClientRect();
            //             const customOffset = 16;
            //             const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

            //             return Math.min(0, targetTop);
            //         },
            //         force: true,
            //         cancelable: true,
            //         x: false,
            //         y: true
            //     });
            // }
        },

    }
}
</script>
