<template>
    <div v-if="!isLoadingUiTexts" class="view-quiz-duel">
        <page-header
            :no-usermenu="true"
            :headroom-disabled="true" />

        <div
            :class="{
                'knowledge-test': true,
                'knowledge-test--evaluating': state == states.EVALUATING,
                'knowledge-test--conclusion': state == states.CONCLUSION,
                'section-dimensioning section-dimensioning--medium': getQuizDuelError,
            }"
            :style="activeInteractionStyle"
        >
            <template v-if="getQuizDuelError">
                <transition name="slide-up">
                    <quiz-duel-error-element
                        @cancel="handleCancelClick"
                        v-if="getQuizDuelError"
                        :error="getQuizDuelError"
                    />
                </transition>
            </template>

            <template v-else>
                <div class="knowledge-test__interactions content-dimensioning">
                    <transition name="slide-left">
                        <quiz-duel-start-element
                            v-if="state == states.START"
                            :is-loading="isLoadingOpponent || isLoadingMatch"
                            :me="getMe"
                            :opponent="getOpponent"
                            @requestOpponent="requestOpponent"
                            @accept="handleStartScreenAccept"  />
                    </transition>

                    <transition name="slide-left" v-on:after-enter="afterQuestionEnter">
                        <base-section
                            v-if="isQuestionState"
                            :section="activeQuestion"
                            :key="`question-${activeQuestion.uid}`"
                        >
                            <div
                                v-if="activeQuestion.topics && activeQuestion.topics.length"
                                class="quiz-duel__pills"
                                slot="before-introtext"
                            >
                                <span
                                    v-for="topic in activeQuestion.topics"
                                    :key="`topic-${topic.id}`"
                                    class="quiz-duel__pill"
                                >{{ topic.title }}</span>
                            </div>

                            <div slot="component">
                                <choice-element
                                    :key="`question-index-${activeQuestion.uid}`"
                                    ref="activeQuestion"
                                    v-if="activeQuestion.type == 'section-single-multiple-choice'"
                                    :choices="activeQuestion.component.choices"
                                    :evaluates-external="true"
                                    :highlight-correct="state == states.EVALUATING"
                                    :settings="activeQuestion.settings.choice" />

                                <value-slider-element
                                    :key="`question-index-${activeQuestion.uid}`"
                                    ref="activeQuestion"
                                    v-if="activeQuestion.type == 'section-value-slider'"
                                    :evaluates-external="true"
                                    :highlight-correct="state == states.EVALUATING"
                                    :slider="activeQuestion.component.valueSlider" />

                                <sortable-options-element
                                    :key="`question-index-${activeQuestion.uid}`"
                                    ref="activeQuestion"
                                    v-if="activeQuestion.type == 'section-sortable-options'"
                                    :evaluates-external="true"
                                    :highlight-correct="state == states.EVALUATING"
                                    :options="activeQuestion.component.sortableOptions" />

                                <div class="view-quiz-duel__action-button-inline">
                                    <button-element
                                        v-if="state == states.QUESTION"
                                        @click="handleActionButtonClick"
                                        :button="{
                                            'label': t('ok')
                                        }" />
                                </div>
                            </div>
                        </base-section>
                    </transition>

                    <transition name="fade">
                        <quiz-duel-result-element
                            v-if="state == states.CONCLUSION"
                            :me="getMe"
                            :opponent="getOpponent" />
                    </transition>
                </div>

                <transition-expand>
                    <div class="knowledge-test__footer">
                        <div v-if="isQuestionState" class="quiz-duel__progress">
                            <div :style="{ width: `${gameTimeProgress}%` }" class="quiz-duel__progress-bar"></div>
                        </div>
                        <div class="knowledge-test__footer-content">

                            <div v-if="state == states.START" class="knowledge-test__footer-wrapper">
                                <template v-if="getMe && getOpponent">
                                    <button-element
                                        type="secondary"
                                        @click="handleCancelClick"
                                        :button="{
                                            'label': t('cancel')
                                        }" />
                                </template>
                                <template v-else>
                                    <button-element
                                        type="secondary"
                                        @click="handleFindOpponentClick"
                                        :button="{
                                            'label': t('find-opponent')
                                        }" />

                                    <button-element
                                        type="secondary"
                                        @click="handleResultsClick"
                                        :button="{
                                            'label': t('results')
                                        }" />
                                </template>
                            </div>

                            <div v-else-if="isQuestionState" class="knowledge-test__footer-wrapper">
                                <div class="knowledge-test__area-progress">
                                    <div class="knowledge-test__progress-items">
                                        <span
                                            :class="{
                                                'knowledge-test__progress-item': true,
                                                'knowledge-test__progress-item--active': index == activeQuestionIndex,
                                                'knowledge-test__progress-item--negative': interactionIsFailed(index),
                                                'knowledge-test__progress-item--positive': interactionIsPassed(index),
                                            }"
                                            v-for="(question, index) in getQuestions"
                                            :key="`interaction-indicator-${index}`"
                                        >
                                            <transition name="fade-up">
                                                <span
                                                    v-if="index == activeQuestionIndex && state == states.EVALUATING"
                                                    class="quiz-duel__feedback-indicator"
                                                >
                                                    <span
                                                        v-if="interactionIsPassed(index)"
                                                        class="quiz-duel__feedback-indicator-icon"
                                                    >
                                                        <svgicon-wrapper name="checkmark" />
                                                    </span>
                                                    <span
                                                        v-else-if="interactionIsFailed(index)"
                                                        class="quiz-duel__feedback-indicator-icon"
                                                    >
                                                        <svgicon-wrapper name="cross" />
                                                    </span>
                                                </span>
                                            </transition>
                                        </span>
                                    </div>
                                </div>
                                <div class="knowledge-test__area-restart">
                                    <div v-if="isQuestionState" class="quiz-duel__game-timer">
                                        <countdown-element
                                            :start="gameTimerStarted"
                                            :seconds="gameTimerDuration"
                                            @expired="handleGameTimerExpired"
                                            @tick="handleGameTimerTick" />
                                        <span>{{ t('seconds') }}</span>
                                    </div>
                                </div>
                                <div class="knowledge-test__area-action">
                                    <transition name="fade">
                                        <span
                                            v-if="state == states.QUESTION"
                                            class="knowledge-test__action-button view-quiz-duel__action-button"
                                            @click="handleActionButtonClick"
                                        >
                                            {{ t('ok') }}
                                            <svgicon-wrapper name="arrow-down-fine" />
                                        </span>
                                    </transition>
                                </div>
                            </div>

                            <div v-else-if="state == states.CONCLUSION" class="knowledge-test__footer-wrapper">
                                <button-element
                                    type="secondary"
                                    @click="handleRestart"
                                    :button="{
                                        'label': t('play-another-round')
                                    }" />
                                <button-element
                                    type="secondary"
                                    @click="handleCancelClick"
                                    :button="{
                                        'label': t('cancel')
                                    }" />
                            </div>
                        </div>
                    </div>
                </transition-expand>
            </template>
        </div>

        <portal to="modals">
            <required-action-element />

            <find-opponent-element
                :open="showFindOpponentOverlay"
                :slug="$router.currentRoute.params.slug"
                @close="closeFindOpponentOverlay"
                @chose="choseOpponentFromOverlay" />

            <session-history-element
                :open="showResultsOverlay"
                :slug="$router.currentRoute.params.slug"
                @close="closeResultsOverlay" />
        </portal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    QUIZDUEL_FETCH_OPPONENT_NS,
    QUIZDUEL_FETCH_MATCH_NS,
    QUIZDUEL_ANSWER_NS,
    QUIZDUEL_REPORT_NS,
    QUIZDUEL_RESET_NS,
    QUIZDUEL_CHECK_ACCESS_NS,
    ME_GET_NS,
} from "@/store/actions.type";

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import PageHeader from '@/components/common/PageHeader.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import ChoiceElement from '@/components/elements/quiz/ChoiceElement.vue';
import ValueSliderElement from '@/components/elements/quiz/ValueSliderElement.vue';
import SortableOptionsElement from '@/components/elements/quiz/SortableOptionsElement.vue';
import CountdownElement from '@/components/elements/CountdownElement.vue';

import QuizDuelStartElement  from '@/components/elements/quiz-duel/QuizDuelStartElement.vue';
import QuizDuelResultElement  from '@/components/elements/quiz-duel/QuizDuelResultElement.vue';
import QuizDuelErrorElement  from '@/components/elements/quiz-duel/QuizDuelErrorElement.vue';
import FindOpponentElement from '@/components/elements/quiz-duel/FindOpponentElement.vue';
import SessionHistoryElement from '@/components/elements/quiz-duel/SessionHistoryElement.vue';
import RequiredActionElement from '@/components/elements/required-actions/RequiredActionElement.vue';

const states = {
    START: 'state-start',
    QUESTION: 'state-question',
    CONCLUSION: 'state-conclusion',
    EVALUATING: 'state-evaluating',
};

const TIMER_QUESTION = 60;
const TIMER_EVALUATING = 5;

export default {
    name: 'QuizDuelView',
    components: {
        TransitionExpand,
        PageHeader,
        BaseSection,
        ButtonElement,
        ChoiceElement,
        ValueSliderElement,
        SortableOptionsElement,
        QuizDuelStartElement,
        QuizDuelResultElement,
        QuizDuelErrorElement,
        CountdownElement,
        FindOpponentElement,
        SessionHistoryElement,
        RequiredActionElement,
    },
    props: {
    },
    data() {
        return {
            states: states,
            state: states.START,
            activeQuestionIndex: 0,
            gameTimerDuration: TIMER_QUESTION,
            gameTimerStarted: false,
            currentGameTime: 0,
            currentGameTimeMs: 0,
            quizDuelHeight: window.innerHeight,

            showFindOpponentOverlay: false,
            showResultsOverlay: false,
        }
    },

    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('quizDuel', {
            isLoadingOpponent: 'isLoadingOpponent',
            isLoadingMatch: 'isLoadingMatch',
            getOpponent: 'getOpponent',
            getMe: 'getMe',
            getQuizDuelError: 'getError',
            getQuestions: 'getQuestions',
            getMatchType: 'getMatchType',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingOpponent || this.isLoadingMatch;
        },

        isQuestionState() {
            return [
                this.states.QUESTION,
                this.states.EVALUATING,
            ].indexOf(this.state) !== -1;
        },

        activeInteractionStyle() {
            const styles = {
                height: `${this.quizDuelHeight - this.getHeaderHeight}px`
            };

            return styles;
        },

        activeQuestion() {
            return this.getQuestions[this.activeQuestionIndex];
        },

        gameTimeProgress() {
            if (this.state == this.states.QUESTION) {
                return 100 - ((this.currentGameTimeMs / 1000) / TIMER_QUESTION * 100);

            } else if (this.state == this.states.EVALUATING) {
                return ((this.currentGameTimeMs / 1000) / TIMER_EVALUATING * 100);
            }

            return 0;
        },
    },

    async beforeRouteEnter(to, from, next) {

        await store.dispatch(UITEXTS_FETCH_NS);
        await store.dispatch(ME_GET_NS);

        if (to.query.hasOwnProperty('o')) {
            const opponent = to.query.o && to.query.o != 'true' ? to.query.o : null;
            await store.dispatch(QUIZDUEL_FETCH_OPPONENT_NS, {
                slug: to.params.slug,
                opponent: opponent
            });

            return next();

        } else {
            const isAllowed = await store.dispatch(QUIZDUEL_CHECK_ACCESS_NS, {
                slug: to.params.slug
            });

            if (isAllowed) {
                return next();
            } else {
                return next({ name: 'home' })
            }
        }


    },

    async created() {
        this.reset();
    },

    mounted() {
        window.addEventListener('resize', this.handleResize);
    },

    methods: {

        handleResize() {
            this.quizDuelHeight = window.innerHeight;
        },

        async fetchMatch() {
            await store.dispatch(QUIZDUEL_FETCH_MATCH_NS);
        },

        reset() {
            this.prepareInitialState();
        },

        prepareInitialState() {
            this.state = this.states.START;
            this.activeQuestionIndex = 0;
            this.gameTimerDuration = TIMER_QUESTION;
            this.gameTimerStarted = false;
            this.currentGameTime = 0;
            this.currentGameTimeMs = 0;
        },

        afterQuestionEnter() {
            var resizeEvent = window.document.createEvent('UIEvents');
            resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        },

        handleActionButtonClick() {
            if (this.state == this.states.QUESTION) {
                this.evaluateQuestion();
            } else if (this.state == this.states.EVALUATING) {
                this.nextQuestion();
            }
        },

        interactionIsPassed(index) {
            const currentInteraction = this.getQuestions[index];
            return currentInteraction.hasOwnProperty('passed') && currentInteraction.passed === true;
        },

        interactionIsFailed(index) {
            const currentInteraction = this.getQuestions[index];
            return currentInteraction.hasOwnProperty('passed') && currentInteraction.passed === false;
        },

        async evaluateQuestion() {

            this.gameTimerStarted = false;
            this.state = this.states.EVALUATING;
            const evaluationResult = this.$refs.activeQuestion.evaluate();

            store.dispatch(QUIZDUEL_ANSWER_NS, {
                questionIndex: this.activeQuestionIndex,
                passed: evaluationResult.passed,
                time: TIMER_QUESTION - this.currentGameTime,
            });

            await this.$nextTick();
            this.gameTimerDuration = TIMER_EVALUATING;
            this.currentGameTime = TIMER_EVALUATING;
            this.gameTimerStarted = true;
        },

        async nextQuestion() {

            if (this.activeQuestionIndex + 1 > this.getQuestions.length - 1) {
                this.state = this.states.CONCLUSION;

                await store.dispatch(QUIZDUEL_REPORT_NS, {
                    slug: this.$router.currentRoute.params.slug
                });

            } else {
                this.gameTimerStarted = false;
                this.state = this.states.QUESTION;
                this.activeQuestionIndex++;

                await this.$nextTick();
                this.gameTimerDuration = TIMER_QUESTION;
                this.currentGameTime = TIMER_QUESTION;
                this.gameTimerStarted = true;
            }
        },

        async requestOpponent() {
            await store.dispatch(QUIZDUEL_FETCH_OPPONENT_NS, {
                slug: this.$router.currentRoute.params.slug
            });
        },

        async handleStartScreenAccept() {
            await store.dispatch(QUIZDUEL_FETCH_MATCH_NS, {
                slug: this.$router.currentRoute.params.slug
            });

            await this.$nextTick();
            this.state = this.states.QUESTION;
            this.gameTimerDuration = TIMER_QUESTION;
            this.currentGameTime = TIMER_QUESTION;
            this.gameTimerStarted = true;
        },

        handleGameTimerExpired() {

            if (this.state == this.states.QUESTION) {
                this.evaluateQuestion();
            } else if (this.state == this.states.EVALUATING) {
                this.nextQuestion();
            }
        },

        handleGameTimerTick(time, timeMs) {
            this.currentGameTime = time;
            this.currentGameTimeMs = timeMs;
        },

        async handleRestart() {
            this.reset();
            await this.requestOpponent();
        },

        handleFindOpponentClick() {
            this.showFindOpponentOverlay = true;

            this.$gtag.event('sth.click.qd.find-opponent', {
                'event_category': 'click',
                'event_label': 'QD Find Opponent click',
                'value': 1
            });
        },

        closeFindOpponentOverlay() {
            this.showFindOpponentOverlay = false;
        },

        async choseOpponentFromOverlay(nickname) {
            this.showFindOpponentOverlay = false;

            await store.dispatch(QUIZDUEL_FETCH_OPPONENT_NS, {
                slug: this.$router.currentRoute.params.slug,
                opponent: nickname
            });
        },

        handleResultsClick() {
            this.showResultsOverlay = true;

            this.$gtag.event('sth.click.qd.results', {
                'event_category': 'click',
                'event_label': 'QD Results click',
                'value': 1
            });
        },

        closeResultsOverlay() {
            this.showResultsOverlay = false;
        },

        handleCancelClick() {
            store.dispatch(QUIZDUEL_RESET_NS);
            this.reset();
        },

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }
}
</script>
