
<template>
    <div v-if="me" class="catchup-bar">
        <div class="catchup-bar__progress">
            <div class="catchup-bar__progress-bar" :style="{ width: `${getProgress}%`}">
                <span class="catchup-bar__label-you">{{ t('you') }}</span>
            </div>
        </div>
        <div class="catchup-bar__opponents">
            <span class="catchup-bar__opponent catchup-bar__opponent--behind">
                <span v-if="behindMe && behindMe.score" class="catchup-bar__opponent-score">{{ getScoreDifference(behindMe.score) | numberFormat }}</span>
                <span v-if="behindMe && behindMe.nickname" class="catchup-bar__opponent-nickname">
                    <flag-element :flag="behindMe.market" />
                    <span class="catchup-bar__opponent-nickname-text">{{ behindMe.nickname }}</span>
                </span>
            </span>
            <span class="catchup-bar__opponent catchup-bar__opponent--ahead">
                <span v-if="aheadMe && aheadMe.score" class="catchup-bar__opponent-score">{{ getScoreDifference(aheadMe.score) | numberFormat }}</span>
                <span v-if="aheadMe && aheadMe.nickname" class="catchup-bar__opponent-nickname">
                    <flag-element :flag="aheadMe.market" />
                    <span class="catchup-bar__opponent-nickname-text">{{ aheadMe.nickname }}</span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import FlagElement from '@/components/elements/event/FlagElement.vue';

export default {
    name: 'CatchupBarElement',

    components: {
        FlagElement,
    },

    props: {
        me: Object,
        behindMe: Object,
        aheadMe: Object,
    },

    data() {
        return {

        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getProgress() {
            if (!this.behindMe) { return 0; }
            if (!this.aheadMe) { return 100; }

            return Math.max(0, Math.min(((this.me.score - this.behindMe.score) / (this.aheadMe.score - this.behindMe.score)) * 100, 100));
        },
    },

    methods: {
        getScoreDifference(score) {
            return score - this.me.score ;
        },
    },
}
</script>
