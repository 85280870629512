<template>
    <div
        :class="{
            'stream-keyvisual': true,
            'stream-keyvisual--inline': inline,
            [`section--foreground-${keyvisual.settings.section.foreground}`]: true,
        }"
    >
        <div class="stream-keyvisual__page-container" :style="getStyle">
            <div class="stream-keyvisual__content-container">
                <div class="stream-keyvisual__grid">
                    <div class="stream-keyvisual__grid-column stream-keyvisual__grid-column--text content-dimensioning">
                       <introtext-element
                            :introtext="keyvisual.introtext"
                            type="type-2"
                        >
                            <button-element
                                v-if="url"
                                slot="extra-button"
                                :disabled="loadingStream"
                                :button="{}"
                            >
                                <router-link
                                    @click.native="handleSeeMoreButtonClick"
                                    :to="{ path: url }"
                                    class="button__label"
                                    slot="routerLink"
                                >
                                    {{ t('see-more') }}
                                    <span slot="icon" v-if="loadingStream">
                                        <loader-element />
                                    </span>
                                </router-link>
                            </button-element>

                        </introtext-element>
                    </div>
                    <div class="stream-keyvisual__grid-column stream-keyvisual__grid-column--media">
                        <media-element
                            :media="keyvisual.media"
                            :image-settings="keyvisual.settings.image"
                            :video-settings="keyvisual.settings.video" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonElement from '@/components/elements/ButtonElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'StreamKeyvisual',

    components: {
        ButtonElement,
        LoaderElement,
    },

    props: {
        keyvisual: {
            type: Object,
            required: true
        },
        url: String,
        settings: {
            type: Object,
            default: () => {
                return {
                }
            }
        },
        inline: Boolean
    },

    data() {
        return {
            loadingStream: false,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getStyle() {
            if (!this.keyvisual.settings.section) {
                return null;
            }

            const { backgroundColor } = this.keyvisual.settings.section;
            const styles = {
                backgroundColor: backgroundColor || false,
            };

            return styles;
        },
    },

    mounted() {

    },

    methods: {
        handleSeeMoreButtonClick(event) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loadingStream = true;
            }

            this.$emit('clickMore');
        },
    },
}
</script>
