
import {
    META_SET,
} from "@/store/actions.type";

import {
    SET_META,
} from "@/store/mutations.type";

const state = () => ({
    isAlternativeBrand: false,
    canAccessCp: false,
});

const actions = {

    async [META_SET]({ commit, state }, meta) {
        commit(SET_META, meta);
    },
};

const mutations = {

    [SET_META](state, { isAlternativeBrand, canAccessCp }) {
        state.isAlternativeBrand = isAlternativeBrand;
        state.canAccessCp = canAccessCp;
    },
};

const getters = {

    isAlternativeBrand: (state) => {
        return state.isAlternativeBrand;
    },

    canAccessCp: (state) => {
        return state.canAccessCp;
    },
};


export const meta = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
