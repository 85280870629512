<template>
    <div class="view-error">
        <page-header
            :headroom-disabled="true"
            :no-usermenu="true"
            :back-button="true"
        ></page-header>

        <div class="section-dimensioning section-dimensioning--large content-dimensioning">
            <base-section :section="getSection" />
        </div>

    </div>
</template>

<script>

import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,

} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'ErrorView',
    components: {
        PageHeader,
        PageFooter,
        BaseSection,
    },
    data() {
        return {
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": {
                    "headline": this.t('error-headline'),
                    "subline": null,
                    "copytext": this.t('error-generic'),
                    "button": null
                },
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": "",
                        "backgroundImage": null,
                        "textBehavior": "default"
                    },
                    "layout": null,
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },
    },

    mounted() {

    },

    methods: {
    },
}
</script>
