<template>
    <div class="required-action required-action-nickname">
        <div class="required-action__grid">
            <div class="required-action__grid-column required-action__grid-column--text">
                <introtext-element :introtext="getIntrotext" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// import store from "@/store";
// import {
//     ME_POST_NICKNAME_NS,
//     ME_UPDATE_PROV_NICKNAME_NS,
// } from "@/store/actions.type";

import InputElement from '@/components/elements/form/InputElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import IntrotextElement from '@/components/elements/IntrotextElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'ActionIntro',

    components: {
        InputElement,
        ButtonElement,
        IntrotextElement,
        LoaderElement,
    },

    data: () => {
        return {
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        // ...mapGetters('me', {
        //     getNickname: 'getNickname',
        //     getProvisionalNickname: 'getProvisionalNickname',
        //     isLoadingSaveNickname: 'isPostingMe',
        //     getNicknameError: 'getError',
        // }),

        // nickname: {
        //     get() { return this.getProvisionalNickname; },
        //     set(value) {
        //         store.dispatch(ME_UPDATE_PROV_NICKNAME_NS, value);
        //     }
        // },

        getIntrotext() {
            return {
                subline: null,
                headline: 'WATCH THIS SHIT',
                copytext: '<p>DO IT NOW!</p>'
            };
        },
    },

    methods: {
        // async handleSubmitNicknameButtonClick() {
        //     await store.dispatch(ME_POST_NICKNAME_NS);
        // },
    }
}
</script>
