<template>
    <section :class="getSectionClass" >
        <div class="section__page-container" :style="getPageContainerStyle">
            <div
                v-if="(isSectionDisabled && section.settings.textWhenDisabled) || !!disabledMessages.length"
                class="section__info-label"
            >
                <template v-if="!!disabledMessages.length">
                    <div class="section__messages" v-for="(message, index) in disabledMessages" :key="index" v-html="message" />
                </template>
                <template v-else>
                    {{ section.settings.textWhenDisabled }}
                </template>
            </div>
            <div class="section__content-container">
                <div class="section__grid">
                    <div v-if="section.introtext" class="section__grid-column section__grid-column--text" :style="getTextContainerStyle">
                        <slot name="before-introtext" />
                        <introtext-element
                            :introtext="section.introtext"
                            :extra-context="extraContext"
                            :type="getIntrotextType"
                            @button-entry="handleButtonEntry"
                        >
                            <template slot="extra-button">
                                <slot name="extra-button" />
                            </template>
                            <template slot="before-button">
                                <achievement-indicator-element
                                    v-if="section.settings.achievementIndicator"
                                    :achievementIndicator="section.settings.achievementIndicator" />
                            </template>
                        </introtext-element>
                    </div>
                    <div
                        v-if="section.media"
                        class="section__grid-column section__grid-column--media"
                        :class="{
                            'section__media-decoration': isAlternativeBrand && section.settings.section.hasOwnProperty('decoration') && section.settings.section.decoration,
                            [`section__media-decoration--${section.settings.section.decoration}`]: isAlternativeBrand && section.settings.section.hasOwnProperty('decoration') && section.settings.section.decoration,
                        }"
                    >
                        <media-element
                            :media="section.media"
                            :image-settings="section.settings.image"
                            :video-settings="section.settings.video" />
                    </div>
                    <div v-if="section.component && !$slots.component" class="section__grid-column section__grid-column--component">
                        <accordion-element
                            v-if="section.type == 'section-accordion'"
                            :panels="section.component.panels"
                            :settings="section.settings.accordion" />

                        <accordion-media-switch-element
                            v-if="section.type == 'section-accordion-media-switch'"
                            :panels="section.component.panels"
                            :settings="section.settings.accordion" />

                        <slideshow-element
                            v-if="section.type == 'section-slideshow'"
                            :slides="section.component.slides"
                            :settings="section.settings.slideshow"/>

                        <blockquote-element
                            v-if="section.type == 'section-blockquote'"
                            :blockquote="section.component.blockquote" />

                        <a-b-slider-element
                            v-if="section.type == 'section-ab-slider'"
                            :abslider="section.component.abslider"
                            :settings="section.settings.abslider" />

                        <image-switch-element
                            v-if="section.type == 'section-image-switch'"
                            :items="section.component.items" />

                        <thumbnails-element
                            v-if="section.type == 'section-thumbnails'"
                            :thumbnails="section.component.thumbnails"
                            :settings="section.settings.thumbnails" />

                        <choice-element
                            v-if="section.type == 'section-single-multiple-choice' || (section.type == 'section-question-reference' && section.component.hasOwnProperty('choices'))"
                            :choices="section.component.choices"
                            :feedback="section.component.feedback"
                            :sum-feedbacks="section.component.hasOwnProperty('sumFeedbacks') ? section.component.sumFeedbacks : null"
                            :settings="section.settings.choice" />

                        <value-slider-element
                            v-if="section.type == 'section-value-slider' || (section.type == 'section-question-reference' && section.component.hasOwnProperty('valueSlider'))"
                            :feedback="section.component.feedback"
                            :slider="section.component.valueSlider" />

                        <comparison-table-element
                            v-if="section.type == 'section-comparison-table'"
                            :table-data="section.component.comparisonTable"
                            :settings="section.settings.comparisonTable" />

                        <calculation-element
                            v-if="section.type == 'section-calculation'"
                            :feedback="section.component.feedback"
                            :calculation="section.component.calculation" />

                        <better-calculation-element
                            v-if="section.type == 'section-better-calculation'"
                            :feedback="section.component.feedback"
                            :calculation="section.component.betterCalculation" />

                        <sortable-options-element
                            v-if="section.type == 'section-sortable-options' || (section.type == 'section-question-reference' && section.component.hasOwnProperty('sortableOptions'))"
                            :feedback="section.component.feedback"
                            :options="section.component.sortableOptions"
                            />

                        <splitter-element
                            v-if="section.type == 'section-splitter'"
                            :splitter="section.component.splitter"
                            />

                        <consumption-calculator-element
                            v-if="section.type == 'section-consumption-calculator'"
                            :consumptionCalculator="section.component.consumptionCalculator" />

                        <gallery-element
                            v-if="section.type == 'section-gallery'"
                            :gallery-images="section.component.galleryImages"
                            :settings="section.settings.gallery"/>

                        <gamification-element
                            v-if="section.type == 'section-gamification'"
                            :settings="section.settings.gamification"
                            :extra-context="extraContext" />

                        <tab-panel-element
                            v-if="section.type == 'section-tabs'"
                            :tabs="section.component.tabs"/>

                        <quiz-duel-element
                            v-if="section.type == 'section-quiz-duel'"
                            :quizDuel="section.component.quizDuel"
                            :settings="section.settings.quizDuel" />

                        <more-content
                            v-if="section.type == 'section-more-content'"
                            v-bind="section.component.moreContent"  />

                        <social-wall
                            v-if="section.type == 'section-social-wall'"
                            v-bind="section.component.socialWall" />

                        <live-stream
                            v-if="section.type == 'section-live-stream'"
                            v-bind="section.component.liveStream" />
                    </div>

                    <div v-if="$slots.component" class="section__grid-column section__grid-column--component">
                        <slot name="component" />
                    </div>
                </div>
            </div>
            <slot />
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    CHECK_REQUIREMENTS_NS,
} from "@/store/actions.type";

import BlockquoteElement from '@/components/elements/BlockquoteElement.vue';
import ABSliderElement from '@/components/elements/ABSliderElement.vue';
import ImageSwitchElement from '@/components/elements/ImageSwitchElement.vue';
import AccordionElement from '@/components/elements/AccordionElement.vue';
import AccordionMediaSwitchElement from '@/components/elements/AccordionMediaSwitchElement.vue';
import SlideshowElement from '@/components/elements/SlideshowElement.vue';
import ThumbnailsElement from '@/components/elements/ThumbnailsElement.vue';
import SplitterElement from '@/components/elements/SplitterElement.vue';
import ComparisonTableElement from '@/components/elements/ComparisonTableElement.vue';
import TabPanelElement from '@/components/elements/TabPanelElement.vue';
import QuizDuelElement from '@/components/elements/QuizDuelElement.vue';

import GalleryElement from '@/components/elements/event/GalleryElement.vue';
import AchievementIndicatorElement from '@/components/elements/event/AchievementIndicatorElement.vue';
import GamificationElement from '@/components/elements/event/GamificationElement.vue';

import ConsumptionCalculatorElement from '@/components/elements/specials/ConsumptionCalculatorElement.vue';
import MoreContent from '@/components/elements/specials/MoreContent.vue';
import SocialWall from '@/components/elements/specials/SocialWall.vue';
import LiveStream from '@/components/elements/specials/LiveStream.vue';

import ChoiceElement from '@/components/elements/quiz/ChoiceElement.vue';
import CalculationElement from '@/components/elements/quiz/CalculationElement.vue';
import BetterCalculationElement from '@/components/elements/quiz/BetterCalculationElement.vue';
import ValueSliderElement from '@/components/elements/quiz/ValueSliderElement.vue';
import SortableOptionsElement from '@/components/elements/quiz/SortableOptionsElement.vue';

export default {
    name: 'BaseSection',
    components: {
        BlockquoteElement,
        AccordionElement,
        AccordionMediaSwitchElement,
        SlideshowElement,
        ImageSwitchElement,
        ABSliderElement,
        ThumbnailsElement,
        ChoiceElement,
        ValueSliderElement,
        SortableOptionsElement,
        SplitterElement,
        ConsumptionCalculatorElement,
        MoreContent,
        CalculationElement,
        BetterCalculationElement,
        ComparisonTableElement,
        GalleryElement,
        GamificationElement,
        TabPanelElement,
        QuizDuelElement,
        AchievementIndicatorElement,
        SocialWall,
        LiveStream,
    },
    props: {
        section: {
            type: Object,
            required: true,
        },
        extraContext: Object,
    },

    data: () => {
        return {
            disabled: false,
            disabledByRequirements: false,
            intervalId: null,
            disabledMessages: [],
        }
    },

    computed: {

        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        getSectionClass() {

            const foregroundModifier = this.getForegroundModifier();
            const backgroundModifier = this.getBackgroundModifier();
            const textBehaviorModifier = this.getTextBehaviorModifier();
            const decorationModifier = this.getDecorationModifier();
            const specialTextModifier = this.getSpecialTextModifier();

            // const sectionDisabled = this.section.settings.disableWithInactiveEntry && (
            //     this.disabledByInactiveButton || this.disabledByInactiveQuizDuel
            // );


            return {
                'section': true,
                [this.section.type]: true,
                'section--has-text': this.section.introtext,
                'section--has-media': this.section.media,
                'section--has-component': this.section.component,
                'section--disabled': this.isSectionDisabled,

                [foregroundModifier]: foregroundModifier ? true : false,
                [backgroundModifier]: backgroundModifier ? true : false,
                [textBehaviorModifier]: textBehaviorModifier ? true : false,
                [decorationModifier]: decorationModifier ? true : false,
                [specialTextModifier]: specialTextModifier ? true : false,

                [this.getLayoutModifier()]: true,
                [this.getContentSizeModifier()]: true,
            };
        },

        isSectionDisabled() {
            return this.disabledByRequirements || this.disabled || (this.section.settings.disableWithInactiveEntry && (
                this.disabledByInactiveButton || this.disabledByInactiveQuizDuel
            ));
        },

        getPageContainerStyle() {

            if (!this.section.settings.section) {
                return null;
            }

            const { backgroundColor, backgroundImage } = this.section.settings.section;
            const styles = {
                backgroundColor: backgroundColor || false,
            };

            if (backgroundImage) {
                styles['backgroundImage'] = `url(${backgroundImage.url})`;
            }

            return styles;
        },

        getTextContainerStyle() {

            if (!this.isAlternativeBrand || !this.section.settings.section) {
                return null;
            }

            const { backgroundColor } = this.section.settings.section;
            const styles = {
                backgroundColor: backgroundColor || false,
            };

            return styles;
        },

        disabledByInactiveButton() {

            if (!this.section.introtext || (this.section.introtext && !this.section.introtext.button)) {
                return false;
            }

            if (this.section.introtext.button.type != 'internalButton') {
                return false;
            }

            return !this.section.introtext.button.enabled;
        },

        disabledByInactiveQuizDuel() {
            if (this.section.type != 'section-quiz-duel') {
                return false;
            }

            return !this.section.settings.quizDuel.enabled;
        },

        getIntrotextType() {

            const type2Sections = [
                'section-single-multiple-choice',
                'section-value-slider',
                'section-sortable-options',
            ];

            if (type2Sections.indexOf(this.section.type) !== -1) {
                return 'type-2';
            }

            return 'type-1';
        },
    },

    async mounted() {
        if (this.section.settings.section.requirements) {
            this.disabledByRequirements = true;
        }

        await this.reloadRequirements();
    },

    methods: {

        async reloadRequirements() {

            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }

            if (this.section.settings.section.requirements) {
                const result = await store.dispatch(CHECK_REQUIREMENTS_NS, this.section.settings.section.requirements);
                this.disabledByRequirements = !result.success;
                this.disabledMessages = result.messages && !!result.messages.length ? result.messages : [];
            }

             if (this.disabledByRequirements) {
                this.intervalId = setInterval(async () => {
                    await this.reloadRequirements();
                }, 5000);
            }
        },

        getDecorationModifier() {

            if (!this.section.settings.section) {
                return null;
            }

            const { decoration } = this.section.settings.section;
            return this.isAlternativeBrand && decoration ? 'section--decorated' : null;
        },

        getSpecialTextModifier() {

            if (!this.section.settings.specialTextStyle) {
                return null;
            }

            return 'section--special-text-style';
        },

        getForegroundModifier() {

            if (!this.section.settings.section) {
                return null;
            }

            const { foreground } = this.section.settings.section;
            const foregroundIsDark = foreground == 'dark';

            return foregroundIsDark ? 'section--foreground-dark' : 'section--foreground-light';
        },

        getBackgroundModifier() {

            if (!this.section.settings.section) {
                return null;
            }

            const { backgroundColor, backgroundImage } = this.section.settings.section;
            const hasBackground = backgroundColor || backgroundImage || this.section.type == 'section-text-background';

            return hasBackground ? 'section--has-background' : 'section--no-background';
        },

        getTextBehaviorModifier() {

            if (!this.section.settings.section) {
                return null;
            }

            const { textBehavior } = this.section.settings.section;

            return `section--text-behavior-${textBehavior}`;
        },

        getLayoutModifier() {

            let layouModifier = 'stacked';
            const { settings } = this.section;

            if (settings.layout && settings.layout.columns) {
                layouModifier = settings.layout.columns;
            }

            return `section--layout-${layouModifier}`;
        },

        getContentSizeModifier() {

            let sizeModifier = 'content-fit';
            const { settings } = this.section;

            if (settings.layout && settings.layout.size) {
                sizeModifier = settings.layout.size;
            }

            return `section--size-${sizeModifier}`;
        },

        handleButtonEntry(data) {
            this.disabled = !data.enabled;
            this.disabledMessages = data.messages && !!data.messages.length ? data.messages : [];
        },
    },

    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },
}
</script>
