
import { ApiService } from '..';

export const QuizDuelService = {

    getRandomOpponent(quizDuelSlug) {
        const path = `${quizDuelSlug}/opponent`
        return ApiService.get('quiz-duel', path);
    },

    getOpponentByNickname(quizDuelSlug, nickname) {
        const path = `${quizDuelSlug}/opponent/${nickname}`
        return ApiService.get('quiz-duel', path);
    },

    getMatch(quizDuelSlug, opponentNickname) {
        const path = `${quizDuelSlug}/match/${opponentNickname}`
        return ApiService.get('quiz-duel', path);
    },

    getHistory(quizDuelSlug) {
        const path = `${quizDuelSlug}/history`
        return ApiService.get('quiz-duel', path);
    },

    getLeaderboard(quizDuelSlug) {
        const path = `${quizDuelSlug}/leaderboard`
        return ApiService.get('quiz-duel', path);
    },

    checkAccess(quizDuelSlug) {
        const path = `${quizDuelSlug}/access`
        return ApiService.get('quiz-duel', path);
    },

    postSession(quizDuelSlug, sessionUid, data) {
        const path = `quiz-duel/${quizDuelSlug}/report/${sessionUid}`
        return ApiService.post(path, data);
    },
};