<template>
    <transition
        name="crossfade"
        @enter="enter"
        @after-enter="afterEnter"
        @leave="leave"
    >
        <slot/>
    </transition>
</template>

<script>
    export default {
        name: 'TransitionCrossFade',

        methods: {
            enter(element) {

            },

            afterEnter(element) {

            },

            leave(element) {

            },
        },
    };
</script>

<style scoped>
* {
  will-change: opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>