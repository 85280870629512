import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import NProgress from 'nprogress';

import NuggetDetail from '@/views/NuggetDetail.vue';
import NuggetCollection from '@/views/NuggetCollection.vue';
import KnowledgeTestView from '@/views/KnowledgeTest.vue';
import GeneralEntryView from '@/views/GeneralEntryView.vue';
import EventView from '@/views/EventView.vue';
import QuizDuelView from '@/views/QuizDuelView.vue';
import StreamView from '@/views/StreamView.vue';
import TrainingHubSplitter from '@/views/TrainingHubSplitter.vue';
import TrainingHubView from '@/views/TrainingHubView.vue';
import AlternativeHubView from '@/views/AlternativeHubView.vue';
import ErrorView from '@/views/ErrorView.vue';
import RequirementErrorView from '@/views/RequirementErrorView.vue';
import OverviewPageView from '@/views/OverviewPageView.vue';
import OverviewView from '@/views/OverviewView.vue';

import LoginView from '@/views/LoginView.vue';

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    // base: window.siteLanguage,
    scrollBehavior(to, from, savedPosition) {

        if (from.meta.scrollPosition) {
            return from.meta.scrollPosition;
        } else if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: TrainingHubView,
            meta: {
                requiresAuth: true
            },
            beforeEnter: (to, from, next) => {
                next('/collection-overview/overview-example');
            },
        },

        {
            path: '/nugget/:slug',
            alias: '/sed-card/:slug',
            name: 'nugget',
            props: true,
            component: NuggetDetail,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/event/:slug',
            name: 'event',
            props: true,
            component: EventView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/knowledge-test/:slug',
            name: 'knowledge-test',
            props: true,
            component: KnowledgeTestView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/collection/:slug',
            name: 'collection',
            props: true,
            component: NuggetCollection,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/collection-overview/:slug',
            name: 'collection-overview',
            props: true,
            component: OverviewView,
            meta: {
                requiresAuth: true
            }
        },

        {
            path: '/stream/:slug',
            name: 'stream',
            props: true,
            component: StreamView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/overview/:slug',
            name: 'overview',
            props: true,
            component: OverviewPageView,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/quiz-duel/:slug',
            name: 'quiz-duel',
            props: true,
            component: QuizDuelView,
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: '/error',
            name: 'error',
            component: ErrorView,
        },

        {
            path: '/forbidden',
            name: 'forbidden',
            component: RequirementErrorView,
        },

        {
            path: '/asdfg',
            name: 'asdfg',
            props: true,
            component: LoginView,
        },

        {
            path: '/:slug',
            name: 'entry',
            props: true,
            component: GeneralEntryView
        },
    ]
});

router.beforeEach(async (to, from, next) => {

    if (window.EXPORT_TYPE_SCORM === true && window.FORCE_SLUG && to.path != window.FORCE_SLUG) {
        return next(window.FORCE_SLUG);
    }

    NProgress.start();

    return next();
});

// router.beforeResolve((to, from, next) => {
//     if (to.name) {
//         NProgress.start();
//     }

//     next();
// })

router.afterEach((to, from) => {
    NProgress.done();
})

export default router;