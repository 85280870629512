/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'button-icon_tip': {
    width: 16,
    height: 16,
    viewBox: '0 0 13 22',
    data: '<path pid="0" d="M10.4 1.8C9.3.8 7.8.3 6.3.3c-1.5 0-3 .6-4.1 1.5C.8 3 .1 4.7.1 6.6c0 1.7.4 2.9 1.5 4.3.1.1.2.3.3.4.2.2.3.3.3.4.4.5.5.9.5 1.3v8.6h7.1V13c0-.3.1-.6.4-1 0-.1.2-.2.4-.5s.3-.4.4-.5c1-1.3 1.5-2.6 1.5-4.4 0-2-.7-3.7-2.1-4.8zM8.5 20.3H4.1v-3h4.3v3zm1.4-10.1s-.1.2-.4.5c-.2.2-.3.4-.4.5-.5.6-.7 1.2-.7 1.8v3.2H7V11l1.5-1.7c.4-.4.4-.7.1-1-.7-.6-1.1.1-1.1.1s-.4.5-1.2 1.3L5.1 8.4c-.3-.4-.7-.4-1-.1-.3.3-.3.7 0 1L5.6 11v5.2H4.1V13c0-.8-.2-1.4-.8-2.1-.1-.1-.2-.2-.3-.4-.2-.3-.3-.4-.3-.4-.9-1.1-1.2-2-1.2-3.5 0-1.6.5-2.9 1.6-3.8.9-.8 2-1.2 3.2-1.2 1.2 0 2.3.4 3.2 1.1 1.1.9 1.6 2.2 1.6 3.8 0 1.6-.3 2.6-1.2 3.7z"/>'
  }
})
