<template>
    <div v-if="abslider" class="abslider" ref="abslider" >
        <div class="abslider__container-a">
            <image-element :image="abslider.aImage" @loaded="handleImageLoaded"></image-element>
        </div>
        <div class="abslider__container-b" ref="cropImage" :style="{ clip: getClipRect }">
            <image-element :image="abslider.bImage" @loaded="handleImageLoaded"></image-element>
        </div>
        <div
            ref="handleContainer"
            class="abslider__handle-container"
            :style="{ left: this.handleLeftPosition }"
        >
            <div class="abslider__drag-handle" ref="handle"></div>
        </div>
    </div>
</template>

<script>
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'ABSliderElement',
    components: {
        ImageElement
    },
    props: {
        abslider: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    initialValue: 50
                }
            }
        }
    },
    data() {
        return {
            cropRectWidth: 0,
            elementHeight: 0,
            handlePosition: 0,
            dragging: false
        }
    },
    computed: {
        getClipRect() {
            return `rect(0 ${this.cropRectWidth}px ${this.elementHeight}px 0)`;
        },
        handleLeftPosition() {
            return `${this.handlePosition * 100}%`;
        }
    },
    mounted() {
        const { abslider } = this.$refs;

        this.handlePosition = this.settings.initialValue / 100;
        this.updateSizes();

        window.addEventListener('resize', this.handleWindowResize);
        abslider.addEventListener('touchstart', this.handlePointerDown);
        abslider.addEventListener('mousedown', this.handlePointerDown);
    },
    methods: {
        handlePointerDown(event) {
            document.addEventListener('touchmove', this.handlePointerMove);
            document.addEventListener('mousemove', this.handlePointerMove);
            document.addEventListener('touchend', this.handlePointerUp);
            document.addEventListener('mouseup', this.handlePointerUp);
            document.documentElement.style.userSelect = 'none';

            this.handlePointerMove(event);
        },
        handlePointerMove(event) {
            const { abslider, handleContainer } = this.$refs;

            const absliderOffsetLeft = abslider.getBoundingClientRect().left;
            const absliderRect = abslider.getBoundingClientRect();
            const pointerCoords = event.pageX;

            this.handlePosition = Math.max(0, Math.min(1, (pointerCoords - absliderOffsetLeft) / absliderRect.width));
            this.updateSizes();
        },
        handlePointerUp(event) {
            document.removeEventListener('touchmove', this.handlePointerMove);
            document.removeEventListener('mousemove', this.handlePointerMove);
            document.removeEventListener('touchend', this.handlePointerUp);
            document.removeEventListener('mouseup', this.handlePointerUp);
            document.documentElement.style.userSelect = '';
        },
        handleWindowResize() {
            this.updateSizes();
        },
        handleImageLoaded() {
            this.updateSizes();
        },
        updateSizes() {
            const { abslider } = this.$refs;
            const absliderRect = abslider.getBoundingClientRect();

            this.elementHeight = absliderRect.height;
            this.cropRectWidth = absliderRect.width * this.handlePosition;
        }
    },
    beforeDestroy() {
        const { abslider } = this.$refs;

        window.removeEventListener('resize', this.handleWindowResize);
        abslider.removeEventListener('touchstart', this.handlePointerDown);
        abslider.removeEventListener('mousedown', this.handlePointerDown);
    }
}
</script>
