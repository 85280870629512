
<template>
    <div class="video">
        <div class="video__wrapper" :style="{ paddingBottom: aspectRatio }">
            <video
                ref="video"
                class="video__player"
                preload="true"
                v-bind="settings"
            >
                <source :src="url" type="video/mp4">
                <track v-if="vtt" :src="vtt" kind="subtitles" default />
            </video>
            <div v-if="poster && !started && !settings.autoplay" class="video__poster" @click="handlePosterClick">
                <image-element :image="poster" :settings="{ sizing: 'cover' }" />
                <div class="video__play-button">
                    <svgicon name="play_big" color="#ffffff" />
                </div>
            </div>
        </div>
        <div v-if="showNotification && settings.watchToComplete" class="video__notification">
            <span v-if="!completed" class="video__notification-label">
                <svgicon-wrapper name="cross" /> Ausstehend
            </span>
            <span v-else class="video__notification-label video__notification-label--completed">
                <svgicon-wrapper name="checkmark" /> Abgeschlossen
            </span>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import store from "@/store";
import {
    REQUIREMENTS_COMPLETE_NS,
    CHECK_REQUIREMENTS_NS,
} from "@/store/actions.type";

import objectFitVideos from 'object-fit-videos';
import ImageElement from '@/components/elements/ImageElement.vue';
import { REQUIREMENTS_COMPLETE } from '../../store/actions.type';

export default {
    name: 'VideoElement',

    components: {
        ImageElement
    },

    props: {
        url: {
            type: String,
            required: true
        },

        vtt: String,

        poster: {
            type: Object,
            default: null
        },

        showNotification: {
            type: Boolean,
            default: true,
        },

        settings: {
            type: Object,
            default: () => {
                return {
                    controls: true,
                    autoplay: false,
                    loop: false,
                    muted: false,
                    playsinline: false,
                    watchToComplete: null,
                }
            }
        }
    },
    data() {
        return {
            aspectRatio: (9/16 * 100) + '%',
            started: false,
            isSeeking: false,
            lastTime: -1,
            marker: -1,
            completed: false,
        }
    },

    async mounted() {

        if (this.settings.hasOwnProperty('watchToComplete') && this.settings.watchToComplete) {
            const result  = await store.dispatch(CHECK_REQUIREMENTS_NS, this.settings.watchToComplete);
            this.completed = result.success;

            if (this.completed) {
                this.$emit('video-played-enough');
            }
        }

        const { video } = this.$refs;
        objectFitVideos();
        video.addEventListener('loadedmetadata', this.setAspectRatio, { once: true });
        video.addEventListener('play', this.setVideoPlaying, { once: true });

        video.addEventListener('timeupdate', this.videoUpdating);

        video.addEventListener('seeking', () => {
            this.isSeeking = true;
        });

        video.addEventListener('seeked', () => {
            this.isSeeking = false;
        });

        var i = setInterval(() => {
            if(video.readyState > 0) {
                this.marker = Math.floor(video.duration * 0.8);

                clearInterval(i);
            }
        }, 200);
    },
    methods: {

        setVideoPlaying() {
            this.started = true;
        },

        setAspectRatio() {
            const { video } = this.$refs;
            this.aspectRatio = `${video.videoHeight / video.videoWidth * 100}%`;
        },

        videoUpdating() {
            const { video } = this.$refs;

            if (!video || this.marker == -1) { return; }

            if (!this.isSeeking) {

                const seconds = Math.floor(video.currentTime);

                if (!this.completed && this.lastTime == this.marker && seconds == (this.marker + 1)) {
                    this.completed = true;
                    if (this.settings.hasOwnProperty('watchToComplete') && this.settings.watchToComplete) {
                        store.dispatch(REQUIREMENTS_COMPLETE_NS, this.settings.watchToComplete);
                    }

                    this.$emit('video-played-enough');
                }

                this.lastTime = seconds;
            }
        },

        handlePosterClick() {
            const { video } = this.$refs;
            video.play();
        },
    }
}
</script>
