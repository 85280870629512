<template>
    <router-link
        v-if="nugget.tile.image"
        :class="{
            'fluid-tile': true,
            'fluid-tile--disabled': disabled,
        }"
        :to="{ path: `/${nugget.url}` }"
        @click.native="(event) => handleClick(event)"
    >
        <span class="fluid-tile__feedback">
            <svgicon name="feedback-positive_big" />
        </span>

        <div class="fluid-tile__image">

            <div v-if="loading" class="tile__loader">
                <loader-element :loading="loading" />
            </div>

            <image-element
                :image="nugget.tile.image"
                :settings="nugget.tile.imageSettings"
                @loaded="$emit('mediaLoaded')" />
        </div>

        <div class="fluid-tile__content">
            <span v-if="nugget.tile.headline" ref="headline" class="fluid-tile__headline" v-html="nugget.tile.headline" />
            <span v-if="nugget.tile.extraline" class="fluid-tile__extraline" v-html="nugget.tile.extraline" />
        </div>

        <span v-if="index" class="fluid-tile__index">{{ index }}</span>
    </router-link>
</template>

<script>
import { mapGetters } from "vuex";

import { lineClamp } from '@/common/utility/lineClamp';

import LoaderElement from '@/components/elements/LoaderElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'FluidTile',

    components: {
        LoaderElement,
        ImageElement,
    },

    props: {
        clamp: {
            type: Boolean,
            default: true
        },
        nugget: {
            type: Object,
            required: true,
        },
        index: String,
        disabled: Boolean,
    },

    computed: {
        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),
    },

    data() {
        return {
            loading: false,
            originalHeadline: null,
        }
    },

    async mounted() {
        this.originalHeadline = this.nugget.tile.headline;
        if (this.clamp) {
            await this.clampHeadlines();
            window.addEventListener('resize', this.reClampHeadlines)
        }
    },

    async updated() {
        if (this.clamp) {
            await this.reClampHeadlines();
        }
    },

    methods: {

        async reClampHeadlines() {

            if (!this.$refs.headline) { return; }

            this.$refs.headline.innerHTML = this.originalHeadline;

            await this.$nextTick();

            this.clampHeadlines();
        },

        async clampHeadlines() {

            await this.$nextTick();

            if (!this.$refs.headline) {
                return;
            }

            let items = [];
            if (!!this.$refs.headline[0]) {
                items = this.$refs.headline;
            } else {
                items = [
                    this.$refs.headline
                ];
            }

            items.forEach(i => {
                lineClamp(i, {clamp: 2});
            });
        },

        handleClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loading = true;
            }

            this.$emit('tileclick');
        },
    },
}
</script>
