import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import qs, { parse } from "qs";
import { API_URL } from "@/common/config";
import { getParameterByName } from '@/common/utility/urlHelper';
import store from "@/store";

const cancellableRequests = {};

export const ApiService = {

    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        Vue.axios.defaults.withCredentials = true;
        Vue.axios.defaults.headers.common = {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': window.csrfTokenValue,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
        };
    },

    query(resource, params) {
        return Vue.axios.get(resource, params)
            .catch(error => {
                throw new Error(`ApiService ${error}`);
            });
    },

    get(resource, slug = "", query = null) {

        const token = getParameterByName('token');
        const xCraftLivePreview = getParameterByName('x-craft-live-preview');

        let requestPath = resource;
        requestPath += slug ? `/${slug}` : '';


        let params = {};

        if (token) { params['token'] = token; }
        if (xCraftLivePreview) { params['x-craft-live-preview'] = xCraftLivePreview; }

        if (query) {
            params = {
                ...params,
                ...query
            };
        }

        return axios.get(requestPath, {
            params: {
                ...params
            }
        });
    },

    cancellableGet(resource, slug = "", query = null) {

        return new Promise((resolve, reject) => {
            let requestPath = resource;
            requestPath += slug ? `/${slug}` : '';

            if (cancellableRequests.hasOwnProperty(requestPath) && cancellableRequests[requestPath]) {
                cancellableRequests[requestPath].abort();
                cancellableRequests[requestPath] = null;
            }

            const xhr = new XMLHttpRequest();
            const method = 'GET';
            const url = `${API_URL}/${requestPath}?q=${query.q}`;
            xhr.open(method, url, true);

            xhr.withCredentials = true;
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            xhr.setRequestHeader('X-CSRF-TOKEN', window.csrfTokenValue)
            xhr.setRequestHeader('Cache-Control', 'no-cache')
            xhr.setRequestHeader('Pragma', 'no-cache')
            xhr.setRequestHeader('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')

            xhr.onload = function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve({
                        data: JSON.parse(xhr.response)
                    });
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };

            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };

            cancellableRequests[requestPath] = xhr;

            xhr.send();
        });
    },

    post(resource, params) {
        var bodyFormData = new FormData();
        bodyFormData.set('payload', JSON.stringify(params));

        return Vue.axios.post(`${resource}`, bodyFormData);
    },

    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    login(params) {

        return Vue.axios.post('/', qs.stringify(params), {
            baseURL: '',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
        });
    },

    logout() {

        return Vue.axios.get('/logout', {
            baseURL: '',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json'
            },
        });
    },
};
