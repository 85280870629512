
<template>
    <modal-element
        class="find-opponent__modal find-opponent__modal--full-width"
        :open="open"
        @close="$emit('close')"
    >
        <template slot="content">
            <div class="find-opponent__search">
                <div class="modal__content-container">
                    <div class="modal__grid">
                        <div class="modal__grid-column find-opponent__grid-column--text">
                            <span class="find-opponent__headline">{{ t('find-opponent') }}</span>
                            <div class="search">
                                <div class="search__input-wrapper">
                                    <input class="search__input" :placeholder="t('nickname')" v-model="searchWord">
                                    <span class="search__input-icon">
                                        <svgicon-wrapper name="search" color="black" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <transition-expand>
                            <div
                                v-if="getNicknameResults && !!getNicknameResults.length"
                                class="modal__grid-column"
                                key="found-nickname"
                            >
                                <span class="find-opponent__list-headline">{{ t('found-players') }}</span>
                                <div class="find-opponent__found">
                                    <router-link
                                        :to="getPlayRoute(player.nickname)"
                                        target="_blank"
                                        v-for="(player, index) in getNicknameResults"
                                        :key="`list-item-${index}`"
                                        class="find-opponent__link"
                                        @click.native="(event) => handlePlayerClick(event, player.nickname)"
                                    >
                                        <flag-element :flag="player.market" />
                                        <span class="find-opponent__nickname">{{ player.nickname }}</span>
                                    </router-link>
                                </div>
                            </div>
                            <div
                                v-else-if="getNicknameResults && !getNicknameResults.length"
                                class="modal__grid-column find-opponent__grid-column--text"
                                key="no-nickname"
                            >
                                <p>
                                    {{ t('no-players-found-by-nickname') }}<br />
                                    {{ t('search-again-use suggestions') }}
                                </p>
                            </div>
                        </transition-expand>
                    </div>
                </div>
            </div>
            <div v-if="!!getTopFromAll.length || !!getRandomFromWorld.length || !!getRandomFromMarket.length" class="find-opponent__suggestions">
                <div class="modal__content-container">
                    <div class="modal__grid ">
                        <div class="modal__grid-column">
                            <span class="find-opponent__headline">{{ t('suggestions') }}</span>
                        </div>

                        <div v-if="!!getTopFromAll.length" class="modal__grid-column find-opponent__grid-column--list">
                            <span class="find-opponent__list-headline">{{ t('from-the-top-100') }}</span>
                            <ul class="find-opponent__list">
                                <li
                                    v-for="(player, index) in getTopFromAll"
                                    :key="`list-item-${index}`"
                                    class="find-opponent__list-item"
                                >
                                    <router-link
                                        :to="getPlayRoute(player.nickname)"
                                        target="_blank"
                                        class="find-opponent__link"
                                        @click.native="(event) => handlePlayerClick(event, player.nickname)"
                                    >
                                        <flag-element :flag="player.market" />
                                        <span class="find-opponent__nickname">{{ player.nickname }}</span>
                                        <svgicon-wrapper class="icon" name="arrow-right" />
                                    </router-link>
                                </li>
                            </ul>
                        </div>

                        <!-- <div v-if="!!getRandomFromWorld.length" class="modal__grid-column find-opponent__grid-column--list">
                            <span class="find-opponent__list-headline">{{ t('from-the-world') }}</span>
                            <ul class="find-opponent__list">
                                <li
                                    v-for="(player, index) in getRandomFromWorld"
                                    :key="`list-item-${index}`"
                                    class="find-opponent__list-item"
                                >
                                    <router-link
                                        :to="getPlayRoute(player.nickname)"
                                        target="_blank"
                                        class="find-opponent__link"
                                        @click.native="(event) => handlePlayerClick(event, player.nickname)"
                                    >
                                        <flag-element :flag="player.market" />
                                        <span class="find-opponent__nickname">{{ player.nickname }}</span>
                                        <svgicon-wrapper class="icon" name="arrow-right" />
                                    </router-link>
                                </li>
                            </ul>
                        </div> -->

                        <div v-if="!!getRandomFromMarket.length" class="modal__grid-column find-opponent__grid-column--list">
                            <span class="find-opponent__list-headline">{{ t('from-your-market') }}</span>
                            <ul class="find-opponent__list">
                                <li
                                    v-for="(player, index) in getRandomFromMarket"
                                    :key="`list-item-${index}`"
                                    class="find-opponent__list-item"
                                >
                                    <router-link
                                        :to="getPlayRoute(player.nickname)"
                                        target="_blank"
                                        class="find-opponent__link"
                                        @click.native="(event) => handlePlayerClick(event, player.nickname)"
                                    >
                                        <flag-element :flag="player.market" />
                                        <span class="find-opponent__nickname">{{ player.nickname }}</span>
                                        <svgicon-wrapper class="icon" name="arrow-right" />
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </modal-element>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    QUIZDUEL_FETCH_LEADERBOARD_NS,
    UPDATE_QUIZDUEL_NICKNAME_SEARCH_NS,
} from "@/store/actions.type";

import ModalElement from '@/components/elements/ModalElement.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import FlagElement from '@/components/elements/event/FlagElement.vue';

export default {
    name: 'FindOpponentElement',

    components: {
        ModalElement,
        TransitionExpand,
        FlagElement,
    },

    props: {
        open: Boolean,
        useLink: Boolean,
        slug: {
            type: String,
            required: true,
        }
    },

    data() {
        return {

        }
    },

    watch: {
        open(newValue) {
            if (newValue) {
                this.handleOpen();
            }
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('quizDuel', {
            isLoadingLeaderboard: 'isLoadingLeaderboard',
            getTopFromAll: 'getTopFromAll',
            getRandomFromWorld: 'getRandomFromWorld',
            getRandomFromMarket: 'getRandomFromMarket',
            getNicknameSearch: 'getNicknameSearch',
            getNicknameResults: 'getNicknameResults',
        }),

        searchWord: {
            get() { return this.getNicknameSearch; },
            set(value) {
                store.dispatch(UPDATE_QUIZDUEL_NICKNAME_SEARCH_NS, value);
            }
        },
    },

    created() {
    },

    mounted() {

    },

    methods: {
        handlePlayerClick(event, nickname) {

            if (this.$listeners.chose) {
                event.preventDefault();
            }

            this.$emit('chose', nickname);
        },

        async handleOpen() {
            await store.dispatch(QUIZDUEL_FETCH_LEADERBOARD_NS, {
                slug: this.slug
            });
        },

        getPlayRoute(nickname) {
            return {
                name: 'quiz-duel',
                params: {
                    slug: this.slug,
                    findOpponent: true,
                },
                query: {
                    o: nickname
                }
            };
        },
    }
}
</script>
