
<template>
    <div
        :class="{
            'leaderboard__data-row': true,
            'leaderboard__data-row--even': even,
            'leaderboard__data-row--odd': odd,
            'leaderboard__data-row--me': item.hasOwnProperty('me') && item.me
        }"
    >
        <div class="leaderboard__content-wrapper">
            <span v-if="hasRankColumn" class="leaderboard__cell leaderboard__cell--first">{{ item.hasOwnProperty('displayRank') ? item.displayRank : item.rank }}</span>
            <span v-if="hasNicknameColumn || hasScoreColumn" class="leaderboard__cell leaderboard__cell--second">
                <span v-if="hasNicknameColumn" class="leaderboard__cell-split">
                    <flag-element :flag="item.market" />
                    {{ item.nickname }}
                </span>
                <span v-if="hasScoreColumn" class="leaderboard__cell-split leaderboard__cell-score">
                    {{ item.score | numberFormat }}
                    <span class="leaderboard__points-label">{{ t('points') }}</span>
                    <div class="leaderboard__score-progress">
                        <div class="leaderboard__score-progress-bar" :style="{ width: `${getProgressForScore(item.score)}%` }"></div>
                    </div>
                </span>
            </span>
            <span v-if="hasLevelColumn" class="leaderboard__cell leaderboard__cell--third">{{ item.level }}</span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import FlagElement from '@/components/elements/event/FlagElement.vue';
export default {
    name: 'LeaderboardRowElement',

    components: {
        FlagElement,
    },

    props: {
        item: Object,
        hasRankColumn: Boolean,
        hasNicknameColumn: Boolean,
        hasScoreColumn: Boolean,
        hasLevelColumn: Boolean,
        even: Boolean,
        odd: Boolean,
        topScore: Number,
    },

    data() {
        return {

        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),
    },

    methods: {
        getProgressForScore(score) {
            return score / this.topScore * 100;
        },
    },
}
</script>
