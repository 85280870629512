<template>
    <div class="streams-slider">
        <div class="streams-slider__page-container">
            <div class="streams-slider__content-container">

                <div class="streams-slider__content-wrapper">

                    <span class="streams-slider__slider-navigation streams-slider__slider-navigation--previous" ref="prevButton">
                        <svgicon-wrapper name="arrow-down-fine" dir="up" />
                    </span>

                    <div class="swiper-container" ref="slider">
                        <div class="swiper-wrapper">
                            <router-link
                                :to="{ path: `/${stream.url}` }"
                                v-for="(stream, index) in streams"
                                :key="`streams-slider-item-${stream.id}`"
                                class="swiper-slide scrollactive-item streams-slider__slide"
                                @click.native="(event) => handleClick(event, index)"
                            >
                                <span class="streams-slider__item">{{ stream.label }}</span>
                                <loader-element :loading="loading(index)" />
                            </router-link>
                        </div>
                    </div>

                    <span class="streams-slider__slider-navigation streams-slider__slider-navigation--next"  ref="nextButton">
                        <svgicon-wrapper name="arrow-down-fine" dir="down" />
                    </span>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';

import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'OtherStreamsSlider',

    components: {
        LoaderElement,
    },

    props: {
        streams: {
            type: Array,
            required: true
        },
    },

    computed: {
    },

    data() {
        return {
            slider: null,
            loader: -1,
        }
    },

    watch: {
        streams() {
            this.loader = -1;
            this.$forceUpdate();
        },
    },

    mounted() {
        this.initSlider();
    },

    methods: {
        loading(id) {
            return this.loader == id;
        },

        handleClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loader = id;
            }
        },

        initSlider() {
            const { slider, prevButton, nextButton } = this.$refs;

            if (slider) {
                this.slider = new Swiper(slider, {
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    watchOverflow: true,
                    spaceBetween: -1,
                    navigation: {
                        disabledClass: 'content-navigation__slider-navigation--disabled',
                        nextEl: nextButton,
                        prevEl: prevButton,
                    },
                });

                this.slider.on('resize', () => {
                    this.slider.update();
                });
            }
        },
    },
}
</script>
