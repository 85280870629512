<template>
    <modal-element
        :open="requiresAction"
        class="modal--align-start"
    >
        <div class="modal__grid-column modal__grid-column--component">
            <action-intro v-if="requiresActionIntro" />
            <action-nickname v-else-if="requiresActionNickname" />
        </div>
    </modal-element>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    ME_POST_NICKNAME_NS,
    ME_UPDATE_PROV_NICKNAME_NS,
} from "@/store/actions.type";

import ModalElement from '@/components/elements/ModalElement.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ActionNickname from '@/components/elements/required-actions/ActionNickname.vue';
import ActionIntro from '@/components/elements/required-actions/ActionIntro.vue';

export default {
    name: 'RequiredActionElement',

    components: {
        BaseSection,
        ModalElement,
        ActionIntro,
        ActionNickname,
    },

    data: () => {
        return {
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('me', {
            getNickname: 'getNickname',
        }),

        requiresAction() {
            return this.requiresActionNickname || this.requiresActionIntro;
        },

        requiresActionNickname() {
            return !this.getNickname;
        },

        requiresActionIntro() {
            return false;
        },
    },

    watch: {
        requiresAction(newValue) {
            if (!newValue) {
                this.$emit('needsRefresh');
            }
        },
    },

    methods: {

    }
}
</script>
