
import { ApiService } from '..';

export const MeService = {
    getMe() {
        return ApiService.get('me');
    },

    postNickname(nickname) {
        return ApiService.post('me/nickname', { nickname });
    },

    completeHubTutorial() {
        return ApiService.post('me/complete-hub-tutorial');
    },

    completeIntroductionOverlay() {
        return ApiService.post('me/complete-introduction-overlay');
    },
};