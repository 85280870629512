<template>
    <picture>
        <source
            v-for="(source, index) in image.sources"
            :key="`image-element-${index}`"
            :media="source.media"
            :srcset="source.url" />
        <img
            :srcset="image.url"
            :class="{
                'image': true,
                [`image--sizing-${settings.sizing}`]: true,
                [imageClass]: true,
            }"
            :style="getImageStyle"
            alt=""
            draggable="false"
            @load="$emit('loaded')" />
    </picture>
</template>

<script>
import picturefill from 'picturefill';
import objectFitImages from 'object-fit-images';

export default {
    name: 'ImageElement',
    props: {
        image: Object,
        imageClass: {
            type: String,
            default: ''
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    sizing: 'fit-column',
                }
            }
        }
    },
    computed: {
        getImageStyle() {

            let focalPoint = this.image.focalPoint;

            if (this.image.sources && !!this.image.sources.length) {
                focalPoint = this.image.sources[0].focalPoint;
            }

            if (focalPoint && (focalPoint.x != 0.5 || focalPoint.y != 0.5)) {

                return {
                    objectPosition: `${(focalPoint.x * 100)}% ${(focalPoint.y * 100)}%`
                }
            }

            return null;
        }
    },
    mounted() {
        if(typeof picturefill === "function") {
            picturefill();
        }
        objectFitImages();
    }
}
</script>
