/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cms': {
    width: 16,
    height: 16,
    viewBox: '0 0 268 264',
    data: '<g><g><g><g><path pid="0" d="M133.822 185.113c-29.9 0-54.3-24.3-54.3-54.2s24.4-54.2 54.3-54.2 54.3 24.3 54.3 54.2-24.4 54.2-54.3 54.2zm0-92.9c-21.4 0-38.8 17.4-38.8 38.7s17.4 38.7 38.8 38.7 38.8-17.4 38.8-38.7-17.4-38.7-38.8-38.7z"/></g><g><path pid="1" d="M150.3 263.7l-8.9-24.6c-5 .3-10 .3-15 0l-8.9 24.6L61 243.2l8.9-24.6c-4.1-2.9-7.9-6.2-11.5-9.7L35.7 222l-30-51.9L28.3 157c-1.2-4.9-2.1-9.8-2.6-14.8L0 137.8l10.4-59 25.8 4.5c2.2-4.5 4.7-8.8 7.5-13l-16.8-20 46-38.5 16.8 20c4.6-2 9.3-3.8 14.2-5.1V.5h60v26.1c4.8 1.4 9.6 3.1 14.2 5.1l16.8-20 46 38.5-16.8 20c2.8 4.2 5.3 8.5 7.5 13l25.8-4.5 10.4 59-25.8 4.5c-.5 5-1.4 10-2.6 14.8l22.6 13.1-30 51.9-22.7-13c-3.6 3.5-7.5 6.7-11.5 9.7l8.9 24.6-56.4 20.4zm1.5-41.3l7.8 21.4 27.3-9.9-7.8-21.4 5.2-3.3c6.6-4.3 12.7-9.3 18-15.1l4.2-4.5 19.8 11.4 14.5-25.1-19.8-11.5 1.8-5.9c2.3-7.5 3.7-15.3 4.1-23.2l.3-6.2 22.5-4-5-28.6-22.5 4-2.4-5.7c-3-7.2-7-14.1-11.8-20.3l-3.8-4.9L219 52.2l-22.2-18.6L182 51l-5.5-2.8c-7-3.6-14.4-6.3-22.1-8l-6-1.4V16h-29v22.8l-6 1.4c-7.7 1.7-15.1 4.4-22.1 8L85.7 51 71 33.5 48.7 52.2l14.7 17.5-3.8 4.9c-4.8 6.3-8.8 13.1-11.8 20.3l-2.4 5.7-22.5-4-5 28.6 22.5 4 .3 6.2c.4 7.9 1.7 15.7 4.1 23.2l1.8 5.9-19.8 11.4L41.3 201l19.8-11.4 4.2 4.5c5.4 5.8 11.4 10.9 18 15.1l5.2 3.3-7.7 21.5 27.3 9.9 7.8-21.4 6.1.8c7.8 1 15.8 1 23.6 0l6.2-.9z"/></g></g></g></g>'
  }
})
