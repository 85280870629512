
import { ApiService } from '..';
const suffix = window.EXPORT_TYPE_SCORM ? '.json' : ''

export const CollectionService = {
    get(slug) {
        return ApiService.get('collection', `${slug}${suffix}`);
    },

    getOverview(slug) {
        return ApiService.get('collection-overview', `${slug}${suffix}`);
    }
};