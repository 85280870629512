
<template>
    <div class="achievement-list">
        <div class="achievement-list__grid">
            <div
                v-for="achievement in achievements"
                :key="`achievement-id-${achievement.id}`"
                class="achievement"
                :class="{
                    'achievement--achieved': achievement.achieved
                }"
            >
                <span class="achievement__icon" v-html="getIcon(achievement)" />
                <div class="achievement__content">
                    <span class="achievement__title">{{ achievement.title }}</span>
                    <span v-if="achievement.score" class="achievement__score">
                        {{ achievement.score | numberFormat }}
                        <span class="leaderboard__points-label">{{ t('points') }}</span>
                    </span>
                    <span class="achievement__description">{{ achievement.description }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: 'AchievementListElement',

    components: {

    },

    props: {
        achievements: Array,
        achievedCount: Number,
        icons: Object,
    },

    data() {
        return {

        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),
    },

    methods: {
        getIcon(achievement) {

            if (achievement.hasOwnProperty('icon')) {
                return achievement.icon;

            } else if(achievement.hasOwnProperty('iconName') && this.icons.hasOwnProperty(achievement.iconName)) {
                return this.icons[achievement.iconName];
            }
        },
    },
}
</script>
