/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'avatar-happy': {
    width: 16,
    height: 16,
    viewBox: '0 0 92 91',
    data: '<g><path pid="0" d="M46 0C20.6 0 0 20.4 0 45.5S20.6 91 46 91s46-20.4 46-45.5S71.4 0 46 0zm0 88C22.3 88 3 68.9 3 45.5S22.3 3 46 3s43 19.1 43 42.5S69.7 88 46 88z"/><path pid="1" d="M24.9 36.9c1 0 1.8-.8 1.8-1.8 0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5 0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8c0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5c-.2 1 .6 1.8 1.6 1.8z"/><path pid="2" d="M55 36.9c1 0 1.8-.8 1.8-1.8 0-2.7 2.2-5 5-5 2.7 0 5 2.2 5 5 0 1 .8 1.8 1.8 1.8s1.8-.8 1.8-1.8c0-4.7-3.8-8.5-8.5-8.5s-8.5 3.8-8.5 8.5c-.2 1 .6 1.8 1.6 1.8z"/><path pid="3" d="M70.5 44.1H22.9c-1 0-1.8.8-1.8 1.8 0 14.1 11.5 25.6 25.6 25.6S72.3 60 72.3 45.9c0-1-.8-1.8-1.8-1.8zM46.7 67.8c-5.1 0-9.8-1.8-13.6-4.7 2.4-3.9 7.7-6.5 13.6-6.5 5.9 0 11.1 2.5 13.6 6.5-3.7 3-8.5 4.7-13.6 4.7zM63 60.7c-3.2-4.6-9.4-7.6-16.2-7.6-6.8 0-13 3-16.2 7.6-3.2-3.5-5.3-8-5.7-13h43.9c-.6 4.9-2.6 9.5-5.8 13z"/></g>'
  }
})
