/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'round-cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<g><g><path pid="0" d="M32.2 1.8c1 1 1 2.5 0 3.5L20.5 17l11.7 11.7c1 1 1 2.5 0 3.5s-2.5 1-3.5 0L17 20.5 5.3 32.2c-1 1-2.5 1-3.5 0s-1-2.5 0-3.5L13.5 17 1.8 5.3c-1-1-1-2.5 0-3.5s2.5-1 3.5 0L17 13.5 28.7 1.8c1-1 2.6-1 3.5 0z" fill-rule="evenodd" clip-rule="evenodd" _fill="#55c3f0"/></g></g>'
  }
})
