/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'start': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 22',
    data: '<g><path pid="0" d="M11 0C4.9 0 0 4.9 0 11s4.9 11 11 11 11-4.9 11-11S17.1 0 11 0zm0 21C5.5 21 1 16.5 1 11S5.5 1 11 1s10 4.5 10 10-4.5 10-10 10z"/><path pid="1" d="M17.1 10.8c0-.1-.1-.2-.2-.2L8.6 5.9c-.3-.1-.6 0-.7.2 0 .1-.1.1-.1.2v9.3c0 .3.2.5.5.5.1 0 .2 0 .2-.1l8.3-4.6c.3-.1.4-.4.3-.6zm-1.6.2l-6.7 3.8V7.2l6.7 3.8z"/></g>'
  }
})
