
export const rank = (items) => {

    const result = [];
    let lastScore = Number.MAX_VALUE;
    let currentRank = 0;
    let staggeredRank = 1;
    let lastRank = 0;

    items.forEach((item, index) => {

        if (item.score < lastScore) {
            currentRank = currentRank + staggeredRank;
            staggeredRank = 1;
        } else if (item.score == lastScore) {
            staggeredRank++;
        }

        result.push({
            ...item,
            rank: currentRank,
            displayRank: lastRank != currentRank ? currentRank : '',
        });

        lastRank = currentRank;
        lastScore = item.score;
    });

    return result;
};
