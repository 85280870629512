export const SET_ERROR = 'setError';

export const SET_UITEXTS = 'setUITexts';

export const SET_COMPANION = 'setCompanion';

export const SET_ENTRY = 'setEntry';
export const SET_FILTER_TOPIC = 'setFilterTopic';
export const SET_FILTER_CATEGORY = 'setFilterCategory';
export const SET_SHOW_RESULTS = 'setShowResults';
export const SET_SHOW_FILTERS = 'setShowFilters';

export const SET_COMPANION_FILTER_TOPIC = 'setCompanionFilterTopic';
export const SET_COMPANION_FILTER_CATEGORY_ADD = 'setCompanionFilterCategoryAdd';
export const SET_COMPANION_FILTER_CATEGORY_REMOVE = 'setCompanionFilterCategoryRemove';
export const SET_COMPANION_FILTER_SEARCH = 'setCompanionFilterSearch';
export const SET_HUB_TUTORIAL_FINISHED = 'SET_HUB_TUTORIAL_FINISHED';

export const SET_NUGGET = 'setNugget';
export const RESET_NUGGET = 'resetNugget';
export const SET_SLUG = 'setSlug';

export const SET_STREAM = 'setStream';
export const RESET_STREAM = 'resetStream';
export const SET_SORTER = 'setSorter';

export const SET_GALLERY = 'setGallery';
export const SET_GALLERY_FOLDER = 'setGalleryFolder';
export const SET_EVENT = 'setEvent';
export const FETCH_GALLERY_START = 'fetchGalleryStart';
export const FETCH_GALLERY_END = 'fetchGalleryEnd';
export const FETCH_EVENTS_START = 'FETCH_EVENTS_START';
export const FETCH_EVENTS_END = 'FETCH_EVENTS_END';
export const SET_EVENT_PREVIEW = 'SET_EVENT_PREVIEW';
export const RESET_EVENT = 'resetEvent';

export const SET_COLLECTION = 'setCollection';


export const SET_QUIZDUEL_NICKNAME_SEARCH = 'setQuizduelNicknameSearch';
export const SET_QUIZDUEL_MATCH = 'setQuizDuelMatch';
export const SET_QUIZDUEL_OPPONENT = 'setQuizDuelOpponent';
export const SET_QUIZDUEL_ANSWER = 'setQuizduelAnswer';
export const SET_QUIZDUEL_HISTORY = 'setQuizduelHistory';
export const SET_QUIZDUEL_LEADERBOARD = 'setQuizduelLeaderboard';

export const FETCH_QUIZDUEL_OPPONENT_START = 'fetchQuizduelOpponentStart';
export const FETCH_QUIZDUEL_OPPONENT_END = 'fetchQuizduelOpponentEnd';
export const FETCH_QUIZDUEL_MATCH_START = 'fetchQuizduelMatchStart';
export const FETCH_QUIZDUEL_MATCH_END = 'fetchQuizduelMatchEnd';
export const FETCH_QUIZDUEL_RESULT_START = 'fetchQuizduelResultStart';
export const FETCH_QUIZDUEL_RESULT_END = 'fetchQuizduelResultEnd';
export const FETCH_QUIZDUEL_HISTORY_START = 'fetchQuizduelHistoryStart';
export const FETCH_QUIZDUEL_HISTORY_END = 'fetchQuizduelHistoryEnd';
export const FETCH_QUIZDUEL_LEADERBOARD_START = 'fetchQuizduelLeaderboardStart';
export const FETCH_QUIZDUEL_LEADERBOARD_END = 'fetchQuizduelLeaderboardEnd';

export const RESET_QUIZDUEL = 'resetQuizDuel';


export const SET_LEADERBOARD = 'setLeaderboard';
export const SET_LEADERBOARD_TYPE = 'setLeaderboardType';
export const SET_ACHIEVEMENTS = 'setAchievements';

export const SET_FORCE_HUB_TOUR = 'setForceHubTour';
export const SET_LOGIN = 'setLogin';
export const SET_SHOW_MENU = 'setShowMenu';
export const SET_LOGIN_STATUS = 'setLoginStatus';
export const RESET_LOGIN = 'resetLogin';
export const SET_AUTHENTICATION_ROUTE = 'setAuthenticationRoute';

export const SET_KNOWLEDGE_TEST = 'setKnowledgeTest';

export const FETCH_START = 'fetchStart';
export const FETCH_END = 'fetchEnd';

export const SET_HEADER_PIN = 'setHeaderPin';
export const SET_HEADER_HEIGHT = 'setHeaderHeight';
export const SET_ACTUAL_HEADER_HEIGHT = 'setActualHeaderHeight';
export const SET_FOOTER_HEIGHT = 'setFooterHeight';
export const SET_FOOTER_VISIBILITY = 'setFooterVisibility';

export const SET_APP_SWITCH_LANGUAGE = 'setAppSwitchLanguage';
export const SET_LANGUAGE = 'setLanguage';

export const SET_COMPLETIONS = 'setCompletions';
export const SET_SEARCH_WORD = 'setSearchWord';
export const SET_RESULTS = 'setResults';
export const FETCH_RESULTS_START = 'fetchResultsStart';
export const FETCH_RESULTS_END = 'fetchResultsEnd';


export const FETCH_ME_START = 'fetchMeStart';
export const FETCH_ME_END = 'fetchMeEnd';
export const POST_ME_START = 'postMeStart';
export const POST_ME_END = 'postMeEnd';
export const SET_ME = 'setMe';
export const SET_PROV_NICKNAME = 'setProvNickname';

export const SET_META = 'SET_META';
export const SET_CONTENT = 'SET_CONTENT';
export const SET_PAGE_SLUG = 'SET_PAGE_SLUG';
export const SET_CONTENT_SLUG = 'SET_CONTENT_SLUG';

export const SET_PAGE = 'SET_PAGE';
export const FETCH_PAGE_START = 'FETCH_PAGE_START';
export const FETCH_PAGE_END = 'FETCH_PAGE_END';
export const FETCH_CONTENT_START = 'FETCH_CONTENT_START';
export const FETCH_CONTENT_END = 'FETCH_CONTENT_END';
export const SET_COMPLETE_INTRO = 'SET_COMPLETE_INTRO';