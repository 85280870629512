<template>
    <div v-if="open" class="search-results" :style="{ 'padding-top':`${getHeaderHeight}px`}">
        <div class="search-results__page-container">
            <transition name="slide-right">
                <div v-if="(!isSearching && hasActiveFilters && showFilters) || (!isSearching && getResults && showFilters && buildUp)" class="search-results__filters section--foreground-light">
                    <div class="search-results__filters-content">
                        <span class="search-results__headline">{{ t('filters') }}</span>
                        <div v-if="getResultTopics && !!getResultTopics.length" class="search-results__filter-group">
                            <span class="search-results__filters-category">{{ t('products-and-services') }}</span>
                            <div class="search-results__filter-wrapper">
                                <div
                                    v-for="(topic, index) in getResultTopics"
                                    @click="() => handleTopicClick(topic)"
                                    :key="`topic-${index}`"
                                    :class="{
                                        'choices__choice': true,
                                        'choices__choice--small': true,
                                        'choices__choice--selected': isTopicActive(topic),
                                    }"
                                >
                                    <span class="choices__choice-element"></span>
                                    <span class="choices__choice-text" v-html="topic" />
                                </div>
                            </div>
                        </div>

                        <div v-if="getResultCategories && !!getResultCategories.length" class="search-results__filter-group">
                            <span class="search-results__filters-category">{{ t('features') }}</span>
                            <div class="search-results__filter-wrapper">
                                <div
                                    v-for="(category, index) in getResultCategories"
                                    @click="() => handleCategoryClick(category)"
                                    :key="`category-${index}`"
                                    :class="{
                                        'choices__choice': true,
                                        'choices__choice--small': true,
                                        'choices__choice--selected': isCategoryActive(category),
                                    }"
                                >
                                    <span class="choices__choice-element"></span>
                                    <span class="choices__choice-text" v-html="category" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="slide-left">
                <div v-if="buildUp" class="search-results__results">
                    <div class="search-results__grid">
                        <div class="search-results__grid-column search-results__grid-column--results">

                            <div class="search-results__result-text">
                                <span v-if="isSearching">{{ t('searching') }}...</span>
                                <span v-else-if="hasActiveFilters && getResults && !getResults.length">{{ t('search-no-results-for-filter') }}</span>
                                <span v-else-if="getResults && !getResults.length">{{ t('search-no-results') }}</span>
                                <v-runtime-template v-else :template="`<span>${ t('found-results') }</span>`" />
                            </div>

                            <div v-if="!isSearching && getResults && !!getResults.length" class="search-results__list">
                                <router-link
                                    v-for="(item, resultIndex) in getResults"
                                    :key="`search-result-${item.id}`"
                                    class="search-results__list-item"
                                    :to="{ path: `/${item.url}` }"
                                    @click.native="(event) => handleSearchResultClick(event, item.id, resultIndex)"
                                >
                                    <div class="search-results__list-item-image">
                                        <div v-if="isLoadingResult(item.id)" class="tile__loader">
                                            <loader-element :loading="isLoadingResult(item.id)" />
                                        </div>
                                        <image-element
                                            v-if="item.tile.image"
                                            :image="item.tile.image"
                                            :settings="item.tile.imageSettings" />
                                    </div>

                                    <div class="search-results__list-item-content">
                                        <p
                                            v-if="item.tile.headline"
                                            class="search-results__list-item-headline"
                                            v-html="item.tile.headline"
                                            ref="itemHeadline"  />
                                        <p
                                            v-if="item.searchPreview"
                                            class="search-results__list-item-text"
                                            v-html="item.searchPreview"
                                            ref="itemText"  />

                                        <div class="nugget-list__list-item-meta">
                                            <div class="nugget-list__pills">
                                                <span
                                                    v-for="topic in item.topics"
                                                    :key="`topic-${topic.id}`"
                                                    class="nugget-list__item-pill nugget-list__item-pill--dark">{{ topic.title }}</span>

                                                <span class="nugget-list__item-pill">{{ item.postDateDisplay }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template/dist/v-runtime-template";

import { mapGetters } from "vuex";
import store from "@/store";
import {
    SEARCH_NS,
    SEARCH_SET_FILTER_TOPIC_NS,
    SEARCH_SET_FILTER_CATEGORY_NS,
    SEARCH_TOGGLE_RESULTS_NS,
} from "@/store/actions.type";

import { numberFormat } from '@/common/utility/filters';
import { lineClamp } from '@/common/utility/lineClamp';

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'SearchResultsElement',
    components: {
        TransitionExpand,
        ButtonElement,
        ImageElement,
        LoaderElement,
        VRuntimeTemplate,
    },
    props: {
        open: Boolean,
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('common', {
            isHeaderPinned: 'isPinned',
            getHeaderHeight: 'getHeaderHeight',
        }),

        ...mapGetters('search', {
            isSearching: 'isLoadingSearchResults',
            getSearchWord: 'getSearchWord',
            getResults: 'getResults',
            getResultTopics: 'getResultTopics',
            getActiveTopics: 'getActiveTopics',
            getResultCategories: 'getResultCategories',
            getActiveCategories: 'getActiveCategories',
            getIsShowingFilters: 'getIsShowingFilters',
            hasActiveFilters: 'hasActiveFilters',
            getIsSearchResultsVisible: 'getIsSearchResultsVisible',
        }),

        showFilters() {
            return (this.getIsShowingFilters && ['xs', 'sm'].indexOf(this.$mq) !== -1) || ['md', 'lg'].indexOf(this.$mq) !== -1;
        },

        getClasses() {
            return {
                'modal': true,
                // 'modal--style-dark': true,
                'search-results': true,
                'content-dimensioning': true,
            }
        },

        countOfSearchResults() {
            return this.getResults ? numberFormat(this.getResults.length) : 0;
        },
    },

    data() {
        return {
            expandedGroups: {},
            buildUp: false,
            resultLoader: -1,
        }
    },

    watch: {
        open(newValue, oldValue) {
            if (newValue == false) {
                this.handleClose();
            } else {
                this.handleOpen();
            }
        },

        getResults(newValue) {
            if (newValue && !!newValue.length) {
                this.clampHeadlines();
                this.clampText();
            }
        },

        getIsSearchResultsVisible(newValue) {
            if (!newValue) {
                this.resultLoader = -1;
            }
        },
    },

    created() {
        document.addEventListener('keydown', this.handleKeyDown);
    },

    async mounted() {
        if (this.open == true) {
            await this.$nextTick();
            this.buildUp = true;

            if (this.getResults && !!this.getResults.length) {
                this.clampHeadlines();
                this.clampText();
            }
        }
    },

    methods: {

        async clampHeadlines() {

            await this.$nextTick();

            if (!this.$refs.itemHeadline) {
                return;
            }

            let items = [];
            if (!!this.$refs.itemHeadline[0]) {
                items = this.$refs.itemHeadline;
            } else {
                items = [
                    this.$refs.itemHeadline
                ];
            }

            items.forEach(i => lineClamp(i, {clamp: 2}));
        },

        async clampText() {

            await this.$nextTick();

            if (!this.$refs.itemText) {
                return;
            }

            let items = [];
            if (!!this.$refs.itemText[0]) {
                items = this.$refs.itemText;
            } else {
                items = [
                    this.$refs.itemText
                ];
            }

            items.forEach(i => lineClamp(i, {clamp: 2}));
        },

        async close() {
            this.$emit('close');
        },

        async handleOpen() {

            document.documentElement.classList.add('scroll-lock');

            await this.$nextTick();
            this.buildUp = true;

            if (this.getResults && !!this.getResults.length) {
                this.clampHeadlines();
                this.clampText();
            }
        },

        // async handleBackButtonClick() {
        //     this.buildUp = false;
        //     await this.$nextTick();

        //     this.close();
        // },

        handleClose() {
            document.documentElement.classList.remove('scroll-lock');
        },

        handleKeyDown(event) {
            if (this.open && event.keyCode == 27) {
                this.close();
            }
        },

        handleSearchResultClick(event, id, index) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.resultLoader = id;
                document.documentElement.classList.remove('scroll-lock');
            }

            this.$gtag.event('sth.click.search-result', {
                'event_category': 'result-click',
                'event_label': this.getSearchWord,
                'value': index + 1
            });
        },

        toggleShowMore(topic) {

            if (!this.expandedGroups.hasOwnProperty(topic)) {
                this.$set(this.expandedGroups, topic, true);
            } else {
                this.$set(this.expandedGroups, topic, !this.expandedGroups[topic]);
            }
        },

        isTopicActive(topic) {
            return this.getActiveTopics.indexOf(topic) !== -1;
        },

        isCategoryActive(topic) {
            return this.getActiveCategories.indexOf(topic) !== -1;
        },

        handleTopicClick(topic) {
            store.dispatch(SEARCH_SET_FILTER_TOPIC_NS, topic);

            this.$gtag.event('sth.click.search-results.filter', {
                'event_category': 'choose-filter',
                'event_label': `Topic: ${topic}`,
                'value': 1
            });
        },

        handleCategoryClick(category) {
            store.dispatch(SEARCH_SET_FILTER_CATEGORY_NS, category);

            this.$gtag.event('sth.click.search-results.filter', {
                'event_category': 'choose-filter',
                'event_label': `Category: ${category}`,
                'value': 1
            });
        },

        isLoadingResult(id) {
            return this.resultLoader == id;
        },
    },
    beforeDestroy() {
        store.dispatch(SEARCH_TOGGLE_RESULTS_NS, false);
        document.removeEventListener('keydown', this.handleKeyDown);
    }
}
</script>
