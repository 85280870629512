<template>
    <div v-if="blockquote" class="blockquote">
        <div class="blockquote__backdrop">
            <image-element
                v-if="blockquote.image"
                :image="blockquote.image"
            ></image-element>
            <div class="blockquote__backdrop-darkener"></div>
        </div>
        <div class="blockquote__content-container">
            <div class="blockquote__grid">
                <span class="blockquote__column--icon">
                    <svgicon-wrapper name="quote" color="#ffffff"/>
                </span>
                <div class="blockquote__column--text">
                    <span v-if="blockquote.quote" class="blockquote__quote" v-html="blockquote.quote" />
                    <span v-if="blockquote.cite" class="blockquote__cite">{{ blockquote.cite }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'BlockquoteElement',
    components: {
        ImageElement,
    },
    props: {
        blockquote: {
            type: Object,
            required: true,
        }
    }
}
</script>
