import Vue from "vue";
import { MeService } from "@/api";
import {
    ME_GET,
    ME_POST_NICKNAME,
    ME_UPDATE_PROV_NICKNAME,
    ME_COMPLETE_HUB_TUTORIAL,
    ME_COMPLETE_INTRODUCTION,
    ME_FORCE_HUB_TOUR,
} from "@/store/actions.type";

import {
    FETCH_ME_START,
    FETCH_ME_END,
    POST_ME_START,
    POST_ME_END,
    SET_ME,
    SET_PROV_NICKNAME,
    SET_ERROR,
    SET_FORCE_HUB_TOUR,
    SET_COMPLETE_INTRO,
    SET_HUB_TUTORIAL_FINISHED,
} from "@/store/mutations.type";

const state = () => ({
    error: null,
    isLoadingMe: false,
    isPostingMe: false,
    me: null,
    provisionalNickname: '',
    hubTutorialFinished: false,
    forceHubTour: false,
    introductionDone: false,
});


const actions = {

    async [ME_GET]({ commit, state }) {

        if (state.isLoadingMe || state.me) {
            return state.me;
        }

        commit(FETCH_ME_START);

        try {

            const { data } = await MeService.getMe();
            commit(SET_ME, data);

        } catch(e) {
            commit(FETCH_ME_END);
        }

        return null;
    },

    async [ME_POST_NICKNAME]({ commit, state }) {
        if (state.isPostingMe) {
            return false;
        }

        commit(POST_ME_START);

        try {
            const { data } = await MeService.postNickname(state.provisionalNickname);

            if (data.hasOwnProperty('error')) {
                commit(SET_ERROR, data.error);
                return false;
            }

            commit(SET_ME, data);

            return true;

        } catch(e) {
            commit(POST_ME_END);
        }

        return null;
    },

    [ME_UPDATE_PROV_NICKNAME]({ commit, state }, nickname) {
        commit(SET_PROV_NICKNAME, nickname);
    },

    [ME_FORCE_HUB_TOUR]({ commit, state }, force) {
        commit(SET_FORCE_HUB_TOUR, force);
    },

    async [ME_COMPLETE_HUB_TUTORIAL]({ commit, state }) {
        const { data } = await MeService.completeHubTutorial();
        commit(SET_HUB_TUTORIAL_FINISHED);
        return data.success;
    },

    async [ME_COMPLETE_INTRODUCTION]({ commit, state }) {
        const { data } = await MeService.completeIntroductionOverlay();
        commit(SET_COMPLETE_INTRO, data.success);

        return data.success;
    },
};

const mutations = {

    [SET_ERROR](state, message) {
        state.error = message;
        state.isLoadingMe = false;
        state.isPostingMe = false;
    },

    [FETCH_ME_START](state) {
        state.isLoadingMe = true;
    },

    [FETCH_ME_END](state) {
        state.isLoadingMe = false;
    },

    [POST_ME_START](state) {
        state.isPostingMe = true;
    },

    [POST_ME_END](state) {
        state.isPostingMe = false;
    },

    [SET_ME](state, me) {
        state.me = me;
        state.provisionalNickname = me.nickname;
        state.isLoadingMe = false;
        state.isPostingMe = false;
    },

    [SET_PROV_NICKNAME](state, nickname) {
        state.provisionalNickname = nickname;
    },

    [SET_HUB_TUTORIAL_FINISHED](state) {
        Vue.set(state.me, 'hubTutorialFinished', true);
    },

    [SET_FORCE_HUB_TOUR](state, force) {
        state.forceHubTour = force;
    },

    [SET_COMPLETE_INTRO](state, introductionDone) {

        state.me.introductionDone = introductionDone;
    },
};

const getters = {

    getError: (state) => {
        return state.error;
    },

    isLoadingMe: (state) => {
        return state.isLoadingMe;
    },

    isPostingMe: (state) => {
        return state.isPostingMe;
    },

    getFirstname: (state) => {
        if (state.me && state.me.hasOwnProperty('firstname') && state.me.firstname) {
            return state.me.firstname;
        }

        return null;
    },

    getNickname: (state) => {
        if (state.me && state.me.hasOwnProperty('nickname') && state.me.nickname) {
            return state.me.nickname;
        }

        return null;
    },

    getProvisionalNickname: (state) => {
        return state.provisionalNickname;
    },

    mustDoHubTutorial: (state) => {

        if (state.forceHubTour) {
            return true;
        }

        if (state.me && state.me.hasOwnProperty('hubTutorialFinished')) {
            return !state.me.hubTutorialFinished;
        }

        return false;
    },

    mustSeeIntroduction: (state) => {

        if (state.me && state.me.hasOwnProperty('introductionDone')) {
            return !state.me.introductionDone;
        }

        return false;
    },


};


export const me = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
