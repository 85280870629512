
<template>
    <modal-element
            class="session-history__modal session-history__modal--full-width"
            :open="open"
            @close="$emit('close')"
        >
            <template slot="content">
                <span class="gamification__modal-headline gamification__modal-headline--shadowed">{{ t('results')}}</span>

                <div v-if="!isLoadingHistory" class="session-history leaderboard">
                    <DynamicScroller
                        class="leaderboard__scroller"
                        :items="getHistory"
                        :min-item-size="65"
                        key-field="id"
                    >
                        <div slot="before" class="leaderboard__header">
                            <div class="leaderboard__content-wrapper">
                                <span class="leaderboard__cell leaderboard__cell--first">{{ t('label-result') }}</span>
                                <span class="leaderboard__cell leaderboard__cell--second">
                                    <span class="leaderboard__cell-split">{{ t('opponent') }}</span>
                                    <span class="leaderboard__cell-split">{{ t('earned-points') }}</span>
                                </span>
                                <span class="leaderboard__cell leaderboard__cell--third">{{ t('date') }}</span>
                            </div>
                        </div>
                        <template v-slot="{ item, active, index }">
                            <DynamicScrollerItem
                                :item="item"
                                :active="active"
                                :data-index="index"
                            >
                                <div
                                    :class="{
                                        'leaderboard__data-row': true,
                                        'leaderboard__data-row--even': (index % 2) == 0,
                                        'leaderboard__data-row--odd': (index % 2) != 0,
                                    }"
                                >
                                    <div class="leaderboard__content-wrapper">
                                        <span class="leaderboard__cell leaderboard__cell--first">
                                            <svgicon-wrapper :name="`avatar-${getAvatar(item.result)}`" />
                                        </span>
                                        <span class="leaderboard__cell leaderboard__cell--second">
                                            <span class="leaderboard__cell-split">
                                                <flag-element :flag="item.opponentMarket" />
                                                {{ item.opponentNickname }}
                                            </span>
                                            <span class="leaderboard__cell-split leaderboard__cell-score">
                                                {{ item.score | numberFormat }}
                                                <span class="leaderboard__points-label">{{ t('points') }}</span>
                                            </span>
                                        </span>
                                        <span class="leaderboard__cell leaderboard__cell--third">{{ item.date }}</span>
                                    </div>
                                </div>
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </div>
            </template>
    </modal-element>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    QUIZDUEL_FETCH_HISTORY_NS,
} from "@/store/actions.type";


import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

import ModalElement from '@/components/elements/ModalElement.vue';
import FlagElement from '@/components/elements/event/FlagElement.vue';

export default {
    name: 'SessionHistoryElement',

    components: {
        ModalElement,
        DynamicScroller,
        DynamicScrollerItem,
        FlagElement,
    },

    props: {
        open: Boolean,
        slug: {
            type: String,
            required: true,
        }
    },

    data() {
        return {

        }
    },

    watch: {
        open(newValue) {
            if (newValue) {
                this.handleOpen();
            }
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('quizDuel', {
            isLoadingHistory: 'isLoadingHistory',
            getHistory: 'getHistory',
        }),
    },

    created() {
    },

    mounted() {
    },

    methods: {
        getAvatar(result) {
            return result == 'win' ? 'happy' : 'sad';
        },

        async handleOpen() {
            await store.dispatch(QUIZDUEL_FETCH_HISTORY_NS, {
                slug: this.slug
            });
        },
    }
}
</script>
