<template>
    <div class="overview-slider">
        <div class="overview-slider__page-container">
            <div class="overview-slider__content-container">
                <div class="overview-slider__grid">

                    <div class="overview-slider__grid-column">
                        <div class="swiper-container" ref="slider">
                            <div class="swiper-wrapper">

                                <template v-for="(image, index) in images">
                                    <div class="overview-slider__slide swiper-slide" :key="`overview-slider-slide-${index}`">
                                        <image-element :image="image" @loaded="handleMediaLoaded" />
                                    </div>
                                </template>
                            </div>

                            <!-- <div class="swiper-pagination"></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'OverviewSlider',

    components: {
        ImageElement,
    },

    props: {
        images: Array,
    },

    computed: {
    },

    data() {
        return {
            slider: null
        }
    },

    computed: {

    },

    async mounted() {
        await this.$nextTick();
        this.createSlider();
    },

    methods: {
        createSlider() {
            const { slider } = this.$refs;

            this.slider = new Swiper(slider, {
                loop: true,
                preventClicks: false,
                watchOverflow: true,
                autoHeight: true,
                slideToClickedSlide: false,
                allowTouchMove: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: false,
                }
            });

            this.slider.on('resize', () => {
                this.slider.update();
            });

            // this.slider.on('slideChange', () => {
            //     console.log('slide changed')
            // });
        },

        handleMediaLoaded() {
            this.slider && this.slider.update();
        },

        slideToImage(image) {

            if (!image) {
                return;
            }

            let indexOfImage = -1;
            this.images.forEach((rootImage, index) => {
                if (image.id == rootImage.id) {
                    indexOfImage = index;
                    return;
                }
            });

            if (indexOfImage != -1) {
                this.slider.slideToLoop(indexOfImage);
            }
        },
    },

    beforeDestroy() {
        this.slider && this.slider.destroy();
    }
}
</script>
