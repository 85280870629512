<template>
    <span v-if="button" :class="getButtonClasses">

        <template v-if="['download'].indexOf(button.type) !== -1">
             <a
                :href="button.url"
                :target="getTarget"
                rel="noopener"
                v-text="button.label"
                class="button__label"
                download
            />
        </template>

        <template v-else-if="['internalButton'].indexOf(button.type) !== -1">
            <router-link
                :to="{ path: `/${button.url}` }"
                :target="getTarget"
                class="button__label"
                v-text="button.label" />
        </template>

        <template v-else-if="['externalButton'].indexOf(button.type) !== -1">
            <a
                :href="button.url"
                :target="getTarget"
                rel="noopener"
                v-text="button.label"
                class="button__label"
            />
        </template>

        <template v-else-if="['contentButton'].indexOf(button.type) !== -1">
            <span
                @click="openModal"
                class="button__label"
            >
                <span class="button__label-text">{{ button.label }}</span>
                <span
                    v-if="button.content.buttonType && button.content.buttonType != 'default'"
                    class="button__label-icon">
                    <svgicon-wrapper :name="`button-icon_${button.content.buttonType}`" />
                </span>
            </span>

            <portal to="modals">
                <modal-element
                    :content="button.content"
                    :open="showModal"
                    :type="button.content.buttonType"
                    @close="closeModal"
                ></modal-element>
            </portal>
        </template>

        <template v-else-if="$slots.routerLink">
            <slot name="routerLink" />
        </template>

        <template v-else>
            <span
                @click="handleDefaultClick"
                class="button__label">
                {{ button.label }}
                <slot name="icon" />
            </span>
        </template>
        <slot />
    </span>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    CHECK_ENTRY_NS,
} from "@/store/actions.type";

import ModalElement from '@/components/elements/ModalElement.vue';

export default {
    name: 'ButtonElement',
    components: {
        ModalElement,
    },
    props: {
        button: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            default: 'primary'
        },
        small: {
            type: Boolean,
            default: false
        },
        disabled: Boolean,
        badgeCount: {
            type: Number,
            default: 0
        },
    },
    computed: {

        getTarget() {
            return this.button.targetBlank ? '_blank' : null;
        },

        getButtonClasses() {

            return {
                'button': true,
                'button--disabled': this.disabled,
                [`button--${this.type}`]: true,
                'button--small': this.small
            }
        }
    },
    data: () => {
        return {
            showModal: false,
            intervalId: null,
        }
    },

    async mounted() {
        await this.reloadRequirements();
    },

    methods: {
        async reloadRequirements() {

            if (this.intervalId) {
                clearInterval(this.intervalId)
                this.intervalId = null;
            }

            let refresh = false;
            if (this.button.type == 'internalButton' && this.button.hasOwnProperty('uid') && this.button.uid) {
                const checkResult = await store.dispatch(CHECK_ENTRY_NS, this.button.uid);
                const entryEnabled = checkResult.success;
                const messages = checkResult.messages;
                refresh = !entryEnabled;


                this.$emit('button-entry', { enabled: entryEnabled, messages })
            }

             if (refresh) {
                this.intervalId = setInterval(async () => {
                    await this.reloadRequirements();
                }, 5000);
            }
        },

        handleDefaultClick() {
            if (this.disabled) { return; }

            this.$emit('click');
        },

        openModal() {
            if (this.disabled) { return; }

            this.showModal = true;
        },

        closeModal() {
            this.showModal = false;
        },
    },

    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },
}
</script>
