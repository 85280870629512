<template>
    <div class="conclusion">
        <div class="conclusion__grid">

            <div class="conclusion__grid-column conclusion__grid-column--icon">
                <div class="conclusion__icon"><svgicon-wrapper :name="iconName" /></div>
            </div>

            <div
                v-if="activeFeedback && (activeFeedback.headline || activeFeedback.copytext)"
                class="conclusion__grid-column conclusion__grid-column--text"
            >
                <span
                    v-if="activeFeedback.headline"
                    class="conclusion__headline"
                    v-html="activeFeedback.headline" />

                <span
                    v-if="activeFeedback.copytext"
                    class="conclusion__copytext"
                    v-html="activeFeedback.copytext" />
            </div>
            <div v-if="score != null" class="conclusion__grid-column conclusion__grid-column--score">
                <span
                    :class="['conclusion__score', `conclusion__score--${type}`]"
                >
                    <animate-number
                        from="0"
                        :to="score"
                        duration="1000"
                        easing="easeOutQuad"
                        :formatter="formatToUnit" />
                </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ConclusionElement',
    props: {
        feedback: Object,
        score: Number,
        scoreUnit: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            activeFeedback: null,
        }
    },
    computed: {
        iconName() {
            return `feedback-${this.type}_big`;
        },
    },
    created() {
        if (this.feedback && this.feedback.hasOwnProperty(this.type) && this.feedback[this.type]) {
            this.activeFeedback = this.feedback[this.type];
        }
    },
    methods: {
        formatToUnit(value) {
            return `${parseInt(value)}${this.scoreUnit}`;
        }
    }
}
</script>
