<template>
    <div
        :class="{
            'search': true,
            'search--active': searchWord,
            'search--focused': focused,
        }">
        <div class="search__input-wrapper">

            <input
                ref="searchInput"
                class="search__input"
                :placeholder="t('search')"
                v-model="searchWord"
                @focus="searchFocused"
                @blur="searchBlured" />

            <span class="search__input-icon">
                <span slot="icon" v-if="isLoadingSearchResults">
                    <loader-element />
                </span>
                <svgicon-wrapper v-else-if="!searchWord" name="search" class="search__toggle" @click.prevent="$emit('toggle')" />
                <svgicon-wrapper v-else name="round-cross" class="search__clear" @click.prevent="clearSearch" />
            </span>

        </div>

        <portal v-if="canOpenResults" to="modals">
            <search-results-element
                :open="getIsSearchResultsVisible"
                @close="closeSearchOverlay" />
        </portal>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    SEARCH_COMPLETIONS_NS,
    UPDATE_SEARCH_WORD_NS,
    SEARCH_TOGGLE_RESULTS_NS,
    SEARCH_NS,
} from "@/store/actions.type";

import debounce from 'debounce';
import SearchResultsElement from '@/components/elements/SearchResultsElement.vue';
import LoaderElement from '@/components/elements/LoaderElement.vue';

export default {
    name: 'SearchElement',
    components: {
        LoaderElement,
        SearchResultsElement,
    },

    props: {
        canOpenResults: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't'
        }),

        ...mapGetters('search', {
            isLoadingCompletions: 'isLoadingCompletions',
            isLoadingSearchResults: 'isLoadingSearchResults',
            getCompletions: 'getCompletions',
            getSearchWord: 'getSearchWord',
            getTempSearchWord: 'getTempSearchWord',
            getIsSearchResultsVisible: 'getIsSearchResultsVisible',
        }),

        searchWord: {

            get() {
                return this.getTempSearchWord;// ? this.getTempSearchWord : this.getSearchWord;
            },

            set: function(value) {
                store.dispatch(UPDATE_SEARCH_WORD_NS, value);

                this.showResults();
            },
        },
    },

    data() {
        return {
            search: null,
            focused: false,
        }
    },

    async created() {
        await store.dispatch(UITEXTS_FETCH_NS);
    },

    methods: {

        searchFocused(event) {

            this.focused = true;
            this.$emit('focus');
            // document.addEventListener('keydown', this.handleKeyDown);

            if (this.searchWord && !!this.searchWord.length && this.canOpenResults) {
                event.stopPropagation();
                store.dispatch(SEARCH_TOGGLE_RESULTS_NS, true);
            }
        },

        searchBlured() {
            this.focused = false;
            this.$emit('blur');

            // document.removeEventListener('keydown', this.handleKeyDown);
        },

        handleKeyDown(e) {
            if (e.keyCode == 13) {
                this.showResults();
            }
        },

        showResults: debounce(async function() {

            if (this.searchWord && this.searchWord.length > 2) {

                if (this.canOpenResults) {
                    store.dispatch(SEARCH_TOGGLE_RESULTS_NS, true);
                    this.$emit('searched');
                }

                this.$gtag.event('sth.search', {
                    'event_category': 'search',
                    'event_label': this.searchWord,
                    'value': 1
                });

                await this.$nextTick();
                await store.dispatch(SEARCH_NS);

                this.$refs.searchInput.focus();
            }
        }, 800),

        closeSearchOverlay() {
            store.dispatch(SEARCH_TOGGLE_RESULTS_NS, false);
        },

        clearSearch() {
            // this.$refs.searchInput.value = '';
            store.dispatch(UPDATE_SEARCH_WORD_NS, '');
            this.$refs.searchInput.focus()
        },

        blur() {
            this.$refs.searchInput.blur();
        },

        focus() {
            this.$refs.searchInput.focus();
        },
    },

    beforeDestroy() {

    }
}
</script>
