import Vue from "vue";

import {
    HEADER_PIN_CHANGED,
    HEADER_HEIGHT_CHANGED,
    ACTUAL_HEADER_HEIGHT_CHANGED,
    FOOTER_HEIGHT_CHANGED,
    FOOTER_VISIBLE_CHANGED,
} from "@/store/actions.type";

import {
    SET_HEADER_PIN,
    SET_HEADER_HEIGHT,
    SET_ACTUAL_HEADER_HEIGHT,
    SET_FOOTER_HEIGHT,
    SET_FOOTER_VISIBILITY,
} from "@/store/mutations.type";

const state = () => ({
    pinned: true,
    headerHeight: 0,
    actualHeaderHeight: 0,
    footerHeight: 0,
    footerInViewport: 0,
});

const actions = {

    [HEADER_PIN_CHANGED]({ commit }, pinned) {
        commit(SET_HEADER_PIN, pinned);

        return pinned;
    },

    [HEADER_HEIGHT_CHANGED]({ commit }, height) {
        commit(SET_HEADER_HEIGHT, height);

        return height;
    },

    [ACTUAL_HEADER_HEIGHT_CHANGED]({ commit }, height) {
        commit(SET_ACTUAL_HEADER_HEIGHT, height);

        return height;
    },

    [FOOTER_HEIGHT_CHANGED]({ commit }, height) {
        commit(SET_FOOTER_HEIGHT, height);

        return height;
    },

    [FOOTER_VISIBLE_CHANGED]({ commit }, inViewport) {
        commit(SET_FOOTER_VISIBILITY, inViewport);

        return inViewport;
    },
};

const mutations = {

    [SET_HEADER_PIN](state, pinned) {
        state.pinned = pinned;
    },

    [SET_HEADER_HEIGHT](state, height) {
        state.headerHeight = height;
    },

    [SET_ACTUAL_HEADER_HEIGHT](state, height) {
        state.actualHeaderHeight = height;
    },

    [SET_FOOTER_HEIGHT](state, height) {
        state.footerHeight = height;
    },

    [SET_FOOTER_VISIBILITY](state, inViewport) {
        state.footerInViewport = inViewport;
    },

};

const getters = {

    isPinned: (state) => {
        return state.pinned;
    },

    getHeaderHeight: (state) => {
        return state.headerHeight;
    },

    getActualHeaderHeight: (state) => {
        return state.actualHeaderHeight;
    },

    getFooterHeight: (state) => {
        return state.footerHeight;
    },

    getFooterInViewport: (state) => {
        return state.footerInViewport;
    },
};


export const common = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
