
import { ApiService } from '..';

export const SearchService = {

    getCompletions() {
        return ApiService.get('search/completions');
    },

    getSearchResults(search) {
        return ApiService.cancellableGet('search', null, search);
        // return ApiService.get('search', null, search);
    },

};

