<template>
    <div v-if="!isLoading" class="view-entry">
        <page-header
            :has-search="true"
            :back-button="true"
        >
            <content-navigation
                v-if="!!getJumpmarks.length"
                slot="sticky"
                :items="getContentNavigationItems"
                />
        </page-header>

        <div class="section-dimensioning section-dimensioning--medium content-dimensioning">
            <template v-for="(section, index) in getSections">
                <div
                    v-if="section.type =='jumpmark'"
                    :id="`jumpmark-${section.id}`"
                    :key="`jumpmark-${section.id}`"
                ></div>

                <base-section
                    v-else
                    :key="`entry-section-${index}`"
                    :section="section"
                    :extra-context="getExtraContext"
                    >
                </base-section>
            </template>
        </div>

        <page-footer />

        <portal to="modals">
            <required-action-element @needsRefresh="handleNeedsRefresh" />
        </portal>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    GAMIFICATION_REFRESH_NS,
    EVENT_FETCH_NS,
    EVENT_SET_NS,
    ME_GET_NS,
    TRACK_VISIT_NS,
    EVENT_IS_ALLOWED_NS,
    REQUIREMENTS_MATCH_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ContentNavigation from '@/components/elements/navigation/ContentNavigation.vue';
import RequiredActionElement from '@/components/elements/required-actions/RequiredActionElement.vue';

export default {
    name: 'EventView',
    components: {
        PageHeader,
        ContentNavigation,
        BaseSection,
        PageFooter,
        RequiredActionElement,
    },
    props: {
        content: Object
    },

    computed: {
        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('event', {
            getSections: 'getSections',
            getJumpmarks: 'getJumpmarks',
            getEndDate: 'getEndDate',
            isLoadingEvent: 'isLoading',
            getStyleOverrides: 'getStyleOverrides',
            getTrackingId: 'getTrackingId',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingEvent;
        },

        getContentNavigationItems() {

            if (!this.getJumpmarks || !this.getJumpmarks.length) {
                return [];
            }

            return this.getJumpmarks.map((item) => {
                return {
                    'label': item.jumpmark,
                    'anchor': `#jumpmark-${item.id}`
                }
            });
        },

        getExtraContext() {
            return {
                event_end_date: this.getEndDate
            };
        },
    },

    watch: {
        getStyleOverrides(newValue) {
            if (newValue) {
                this.appendStyles();
            }
        }
    },

    async beforeRouteEnter(to, from, next) {

        if (!window.EXPORT_TYPE_SCORM && !await store.dispatch(REQUIREMENTS_MATCH_NS, to.params.slug)) {
            return next({ name: 'forbidden' });
        }

        await store.dispatch(UITEXTS_FETCH_NS);
        await store.dispatch(ME_GET_NS);

        const isAllowed = await store.dispatch(EVENT_IS_ALLOWED_NS, {
            slug: to.params.slug
        });

        if (!isAllowed) {
            return next({ name: 'home' })
        }

        await store.dispatch(EVENT_FETCH_NS, {
            slug: to.params.slug,
            force: false
        });

        // if (eventResult === true) {
        return next();
        // }

        // return next({ name: 'home' })
    },

    beforeRouteLeave(to, from, next) {

        if (to.name == 'training-hub' || to.name == 'home' || to.name == 'stream') {
            this.popBreadcrumb();
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(EVENT_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);
        }

        if (this.getStyleOverrides) {
            this.appendStyles();
        }

        store.dispatch(TRACK_VISIT_NS, {
            entryUid: this.getTrackingId
        });
    },

    methods: {

         ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        appendStyles() {
            const head = document.getElementsByTagName('head')[0];
            const linkElement = document.createElement('link');
            linkElement.type = 'text/css';
            linkElement.rel = 'stylesheet';
            linkElement.id = '__CUSTOM_STYLE__';
            head.appendChild(linkElement);

            linkElement.href = this.getStyleOverrides;
        },

        removeStyles() {
            if (document.getElementById('__CUSTOM_STYLE__')) {
                document.getElementById('__CUSTOM_STYLE__').remove();
            }
        },

        async handleNeedsRefresh() {
            await store.dispatch(GAMIFICATION_REFRESH_NS);
        }
    },

    beforeDestroy() {
        this.removeStyles();
    }
}
</script>
