<template>
    <div class="view-overview-page">

        <page-header
            :has-search="true"
            :back-button="true"
        >
            <navigation-element
                v-if="!!getNavigationItems.length && getNavigationItems.length > 1"
                slot="sticky"
                :items="getNavigationItems"
                @navigated="handleNavigation" />
        </page-header>

        <overview-slider
            v-if="getSlides && !!getSlides.length"
            ref="slider"
            :images="getSlides" />

        <transition-expand>
            <overview-content
                v-if="!isLoadingContent"
                :key="getContentSlug"
                :keyvisual="getKeyvisual"
                :sections="getSections" />
        </transition-expand>

        <page-footer />
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    OVERVIEW_PAGE_FETCH_NS,
    OVERVIEW_CONTENT_FETCH_NS,
    OVERVIEW_CONTENT_SET_NS,
    ME_GET_NS,
    TRACK_VISIT_NS,
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import NavigationElement from '@/components/elements/navigation/NavigationElement.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import OverviewContent from '@/components/OverviewContent.vue';
import OverviewSlider from '@/components/OverviewSlider.vue';

export default {
    name: 'OverviewPageView',

    components: {
        PageHeader,
        NavigationElement,
        PageFooter,

        OverviewContent,
        OverviewSlider,
        TransitionExpand,
    },

    props: {
        content: Object
    },

    data() {
        return {
            loader: -1,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('overview', {
            isLoadingContent: 'isLoadingContent',
            getContentSlug: 'getContentSlug',
            getSlides: 'getSlides',
            getKeyvisual: 'getKeyvisual',
            getSections: 'getSections',
            getNavigationItems: 'getNavigationItems',
        }),

        isLoading() {
            return this.isLoadingUiTexts;
        },
    },

    async beforeRouteEnter(to, from, next) {

        const [ uitextResult, nuggetResult, meResult ] = await Promise.all([
            await store.dispatch(UITEXTS_FETCH_NS),
            await store.dispatch(OVERVIEW_PAGE_FETCH_NS, to.params.slug),
            await store.dispatch(ME_GET_NS)
        ]);

        return next();
    },

    async beforeRouteUpdate(to, from, next) {
        await store.dispatch(OVERVIEW_PAGE_FETCH_NS, to.params.slug);

        return next();
    },

    beforeRouteLeave(to, from, next) {

        // if (to.name == 'nugget') {
        //     this.pushBreadcrumb({
        //         text: 'Stream',
        //         to: { path: from.path }
        //     });
        // } else if (to.name == 'training-hub' || to.name == 'home') {
        //     this.popBreadcrumb();
        // }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(STREAM_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            await store.dispatch(ME_GET_NS);
        }

        // store.dispatch(TRACK_VISIT_NS, {
        //     entryUid: this.getTrackingId
        // });
    },

    async mounted() {

    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        handleTileClick(event, id) {
            if (!event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
                this.loader = id;
            }
        },

        loading(id) {
            return this.loader == id;
        },

        async handleNavigation(item) {

            if (this.$refs.slider) {
                this.$refs.slider.slideToImage(item.image);
            }

            await store.dispatch(OVERVIEW_CONTENT_FETCH_NS, item.url);
        },
    },
}
</script>
