<template>
    <div class="breadcrumbs" @click="handleClick">
        <div class="breadcrumbs__page-container">
            <div class="breadcrumbs__content-container">
                <div class="breadcrumbs__button">
                    <svgicon-wrapper name="arrow-right" dir="up" />
                    <span>{{ t('back') }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";

export default {
    name: 'BreadcrumbsElement',

    computed: {
        ...mapState('breadcrumbs', {
            breadcrumbs: state => state.breadcrumbs,
        }),

        ...mapGetters('uitexts', {
            t: 't',
        }),
    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        handleClick(crumb) {
            this.$router.go(-1);
        },
    },
}
</script>
