
<template>
    <div class="achievement-indicator">
        <span class="achievement-indicator__score">
            <span v-if="getScore">{{ getScore | numberFormat }}<span class="achievement-indicator__points-label">{{ t('points') }}</span></span>
            <span v-else>–</span>
        </span>
        <span
            class="achievement-indicator__status"
            :class="{
                'achievement-indicator__status--incomplete': isComplete === false,
                'achievement-indicator__status--complete': isComplete === true,
            }"
        >
            <svgicon-wrapper v-if="getIcon" :name="getIcon" />
            <span>{{ getStatusText }}</span>
        </span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "AchievementIndicatorElement",

    components: {
    },

    props: {
        achievementIndicator: Object,
    },

    data() {
        return {
            circleRadius: 150
        };
    },

    computed: {
        ...mapGetters("uitexts", {
            t: "t"
        }),

        ...mapGetters('gamification', {
            getAchievements: 'getAchievements',
        }),

        isLoading() {
            return !this.getAchievements || !this.getAchievements.length;
        },

        getAchievement() {
            return this.getAchievements.find(a => a.id == this.achievementIndicator.achievement);
        },

        isComplete() {
            if (this.isLoading) {
                return null;
            }

            return this.getAchievement && this.getAchievement.achieved;
        },

        getScore() {
            if (this.isLoading) {
                return 0;
            }

            if (this.getAchievement && this.getAchievement.score > 0) {
                return this.getAchievement.score;
            }

            return null;
        },

        getStatusText() {

            if (this.isLoading) {
                return this.t('loading');
            }

            if (this.getAchievement && this.getAchievement.achieved) {
                return this.t('completed');
            }

            return this.t('not-yet-complete');
        },

        getIcon() {

            if (this.isLoading) {
                return null;
            }

            if (this.getAchievement && this.getAchievement.achieved) {
                return 'checkmark';
            }

            return 'cross';
        },
    },

    async created() {},

    async mounted() {},

    methods: {}
};
</script>

