
import { CollectionService } from "@/api";
import {
    COLLECTION_FETCH,
    COLLECTION_SET
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_COLLECTION,
    SET_SLUG
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    slug: null,
    collections: {}
});

const actions = {

    async [COLLECTION_FETCH]({ commit, state }, slug) {

        if (state.collections.hasOwnProperty(slug)) {
            commit(SET_SLUG, slug);
            return;
        }

        commit(FETCH_START);

        try {
            const { data } = await CollectionService.get(slug);
            commit(SET_COLLECTION, { ...data, slug });

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    [COLLECTION_SET]({ commit }, data) {
        commit(SET_COLLECTION, data);
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_SLUG](state, slug) {
        state.slug = slug;
    },

    [SET_COLLECTION](state, { keyvisual, nuggets, trackingId, slug, type }) {

        state.slug = slug;
        state.isLoading = false;
        state.collections[slug] = {
            keyvisual,
            trackingId,
            nuggets,
            type,
        };
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getType: (state) => {
        if (!state.collections[state.slug]) { return null; }

        return state.collections[state.slug].type;
    },

    getNuggets: (state) => {
        if (!state.collections[state.slug]) { return null; }

        return state.collections[state.slug].nuggets;
    },

    getKeyvisual: (state) => {
        if (!state.collections[state.slug]) { return null; }

        return state.collections[state.slug].keyvisual;
    },

    getTrackingId: (state) => {
        if (!state.collections[state.slug]) { return null; }

        return state.collections[state.slug].trackingId;
    },

    getJumpmarks: (state) => {
        if (!state.collections[state.slug]) { return null; }

        return state.collections[state.slug].nuggets.filter((nugget) => {
            return nugget.hasOwnProperty('jumpmark');
        });
    },
};


export const collection = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
