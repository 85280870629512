<template>
    <div
        v-if="recommendations && !!recommendations.length"
        class="recommendations"
    >
        <div class="recommendations__page-container">
            <div class="recommendations__content-container">
                <div class="recommendations__grid recommendations__grid--headline">
                    <span class="recommendations__headline">{{ this.t('recommended-content') }}</span>
                </div>
                <div class="recommendations__grid recommendations__grid--nuggets">
                    <div
                        v-for="nugget in recommendations"
                        :key="`nugget-index-${nugget.id}`"
                        class="recommendations__grid-column recommendations__grid-column--nugget"
                    >
                        <fluid-tile :nugget="nugget" @tileclick="handleFluidTileClick" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
} from "@/store/actions.type";

import BaseSection from '@/components/sections/BaseSection.vue';
import FluidTile from '@/components/elements/tiles/FluidTile.vue';

export default {
    name: 'RecommendationsComponent',

    components: {
        BaseSection,
        FluidTile,
    },

    props: {
        recommendations: {
            type: Array,
            required: true
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('nugget', {
            isLoadingNugget: 'isLoading',
        }),
    },

    methods: {
        handleFluidTileClick() {
            this.$gtag.event('sth.click.recommended-nugget', {
                'event_category': 'click',
                'event_label': 'Recommended Content click',
                'value': 1
            });
        },
    },
}
</script>
