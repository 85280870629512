<template>
    <label
        :class="{
            'input': true,
        }"
    >
        <input
            :class="{
                'input__element': true,
                'input__element--active': value && !!`${value}`.length,
            }"
            :type="type"
            :name="name"
            :id="id"
            :value="value"
            @input="$emit('input', $event.target.value)"
            :step="step"
            :min="min"
            :max="max"
            ref="inputElement"
            />

        <span class="input__label" >{{ placeholder }}</span>
        <div class="input__bar"></div>
    </label>
</template>

<script>

export default {
    name: 'InputElement',
    components: {

    },
    props: {
        placeholder: String,
        type: {
            type: String,
            default: 'text'
        },
        name: String,
        value: {
            type: String|Number,
            default: ''
        },
        id: String,
        step: Number,
        min: Number,
        max: Number,
    },
}
</script>
