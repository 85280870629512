<template>
    <transition name="slide-up">
        <div
            v-if="(content || $slots.default || $slots.content) && open"
            :class="getClasses"
        >
            <div class="modal__content-wrapper">
                <span v-if="$listeners.close" class="modal__close-button" @click="close">
                    <svgicon-wrapper name="close-big" />
                </span>
                <div class="modal__page-container" ref="scrollContainer">
                    <slot name="content" />
                    <div v-if="!$slots.content" class="modal__content-container">
                        <div class="modal__grid">
                            <div v-if="!$slots.default" class="modal__grid-column modal__grid-column--text">
                                <introtext-element v-if="content.introtext" :introtext="content.introtext"></introtext-element>
                            </div>

                            <div v-if="!$slots.default" class="modal__grid-column modal__grid-column--media">
                                <media-element v-if="content.media" :media="content.media"></media-element>
                            </div>
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { Frame } from "scenejs";
// import Moveable from "vue-moveable";

export default {
    name: 'ModalElement',
    components: {
        // Moveable,
    },
    props: {
        content: Object,
        open: Boolean,
        type: {
            type: String,
            default: 'default'
        },
    },

    computed: {
        getClasses() {
            return {
                'modal': true,
                [`modal--type-${this.type}`]: true,
                'content-dimensioning': true,
            }
        },
    },

    data() {
        return {
            // $frame: null,
            // moveable: {
            //     draggable: true,
            //     throttleDrag: 2,
            //     resizable: false,
            //     throttleResize: 1,
            //     keepRatio: false,
            //     scalable: false,
            //     throttleScale: 0.01,
            //     rotatable: false,
            //     throttleRotate: 0.2,
            //     pinchable: false
            // },
        }
    },

    watch: {
        open(newValue, oldValue) {

            if (newValue == false) {
                this.handleClose();
            } else {
                this.handleOpen();
            }
        },

        // ['$mq'](newValue, oldValue) {
        //     if (this.$mq == 'sm') {
        //         this.removeStyles();
        //         document.documentElement.classList.add('scroll-lock');
        //     } else {
        //         if (this.type == 'content-fit') {
        //             document.documentElement.classList.remove('scroll-lock');
        //         }
        //     }
        // },
    },

    created() {
        document.addEventListener('keydown', this.handleKeyDown);
    },

    mounted() {
        if (this.open) {
            this.handleOpen();
        }
        // this.$frame = new Frame({
        //     left: "0px",
        //     top: "0px",
        // });
    },

    methods: {
        close() {
            this.$emit('close');
        },

        async handleOpen() {

            if (this.type != 'content-fit' || ['xs', 'sm'].indexOf(this.$mq) !== -1) {
                document.documentElement.classList.add('scroll-lock');
            }
        },

        handleClose() {
            document.documentElement.classList.remove('scroll-lock');
        },

        handleKeyDown(event) {
            if (this.$listeners.close && this.open && event.keyCode == 27) {
                this.close();
            }
        },

        handleDrag({ target, left, top }) {

            // const { movable } = this.$refs;

            // if (movable && this.$frame) {
            //     const movableWidth = movable.$el.getBoundingClientRect().width / 2;
            //     const movableHeight = movable.$el.getBoundingClientRect().height / 2;

            //     if ((left - movableWidth) > 0 && (left + movableWidth) < document.documentElement.clientWidth) {
            //         this.$frame.set('left', `${left / document.documentElement.clientWidth * 100}%`);
            //     }

            //     if ((top - movableHeight) > 0 && (top + movableHeight)  < document.documentElement.clientHeight) {
            //         this.$frame.set('top', `${top / document.documentElement.clientHeight * 100}%`);
            //     }
            //     this.setTransform();
            // }
        },

        removeStyles() {

            // if (this.$frame) {
            //     this.$frame.set('left', null);
            //     this.$frame.set('top', null);
            //     this.setTransform();
            //     // this.$frame = null;
            // }
        },

        setTransform() {
            // const { movable } = this.$refs;

            // if (movable && this.$frame) {
            //     movable.$el.style.cssText = this.$frame.toCSS();
            // }
        },
    },
    beforeDestroy() {
        document.documentElement.classList.remove('scroll-lock');
        document.removeEventListener('keydown', this.handleKeyDown);
    }
}
</script>
