/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'restart': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 23',
    data: '<g><g><g><path pid="0" d="M20.8 16.4c2.4-5.2.1-11.4-5.1-13.8l3.7-.6c.3 0 .6-.4.5-.7-.1-.4-.4-.6-.8-.6l-5.4.8c-.2 0-.4.1-.5.3v.1c-.1.1-.1.3 0 .5l1.7 5.3c.1.3.5.5.8.4.4-.1.5-.5.4-.8L15 3.7c4.6 2.1 6.6 7.5 4.5 12.1S12 22.3 7.4 20.1.9 12.6 3 8.1c1-2.2 2.8-3.8 5.1-4.7.3-.1.5-.5.4-.8-.1-.3-.5-.5-.9-.4C5.1 3.1 3 5 1.8 7.5c-2.4 5.2-.1 11.4 5.1 13.8s11.5.3 13.9-4.9z"/></g></g></g>'
  }
})
