<template>
    <thumbnails-element
        class="consumption-calculator form-dimensioning"
        ref="thumbnails"
        :thumbnails="consumptionCalculator"
    >
        <template v-for="(item, index) in consumptionCalculator">
            <div
                class="consumption-calculator__elements"
                :slot="`content-slot-${index}`"
                :key="`consumption-item-${index}`"
            >
                <select-element
                    ref="fuelSelect"
                    :items="getFuel(index)"
                    @change="fuelTypeChanged" />

                <input-element
                    :placeholder="t('kilometres-per-year')"
                    type="number"
                    name="kilometers"
                    :step="1000"
                    :min="100"
                    :max="500000"
                    v-model="kilometers" />

                <div class="consumption-calculator__reduction">
                    <span class="consumption-calculator__reduction-label">{{ t('savings-per-year') }}</span>
                    {{ getReduction }}
                </div>
            </div>
        </template>
    </thumbnails-element>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonElement from '@/components/elements/ButtonElement.vue';
import InputElement from '@/components/elements/form/InputElement.vue';
import ThumbnailsElement from '@/components/elements/ThumbnailsElement.vue';
import SelectElement from '@/components/elements/form/SelectElement.vue';

export default {
    name: 'ConsumptionCalculatorElement',
    components: {
        ThumbnailsElement,
        SelectElement,
        ButtonElement,
        InputElement,
    },
    props: {
        consumptionCalculator: {
            type: Array,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    layout: 'split-50-50'
                }
            }
        }
    },
    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        getReduction() {

            if (!this.loaded) { return ''; }

            const { thumbnails } = this.$refs;
            const thumbIndex = thumbnails.activeIndex;
            const { fuelTypes } = this.consumptionCalculator[thumbIndex];

            const reference = fuelTypes.find(element => element.reference);
            let comparer = null;
            fuelTypes.forEach(element => {
                if (element.label.toLowerCase() == this.selectedFuelType) {
                    comparer = element;
                }
            });

            if (reference && comparer) {
                const x = (parseFloat(reference.consumption) * parseFloat(reference.price)) / 100;
                const y = (parseFloat(comparer.consumption) * parseFloat(comparer.price)) / 100;
                const reduction = (y - x) * this.kilometers;

                return `${Math.round(reduction, 10)}€`;
            }

            return '';
        },
    },

    data: () => {
        return {
            loaded: false,
            selectedFuelType: '',
            kilometers: 15000
        }
    },

    mounted() {
        this.loaded = true;
    },

    methods: {

        getFuel(index) {
            const { fuelTypes } = this.consumptionCalculator[index];

            return fuelTypes
                .filter(element => {
                    return !element.reference;
                })
                .map(element => {
                    return {
                        id: element.label.toLowerCase(),
                        title: element.label
                    };
                });
        },

        fuelTypeChanged(item) {
            this.selectedFuelType = item.id;
        },

        calculate() {

        },
    }
}
</script>
