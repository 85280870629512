import { CompanionService } from "@/api";

import {
    COMPANION_FETCH,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_COMPANION,
} from "@/store/mutations.type";

import { normalize, denormalize } from 'normalizr';
import { schemaEntry } from '@/store/schema/entry.schema';

const state = {
    isLoading: false,
    categories: {},
    topics: {},
    entries: {},
};

const actions = {

    async [COMPANION_FETCH]({ commit, state }, flush = false) {

        if (!flush && !!Object.keys(state.entries).length) {
            return;
        }

        commit(FETCH_START);

        try {
            const { data } = await CompanionService.get();
            commit(SET_COMPANION, data.data);

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_COMPANION](state, data) {

        const normalizedData = normalize(data, schemaEntry).entities;

        state.categories = { ...normalizedData.categories };
        state.topics = { ...normalizedData.topics };
        state.entries = { ...normalizedData.entries };
        state.isLoading = false;
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getEntries: (state) => {
        const elementIds = Object.keys(state.entries);

        return denormalize(elementIds, schemaEntry, state);
    }
};


export const companion = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
