
import { GeneralEntryService } from "@/api";
import {
    GENERAL_ENTRY_FETCH,
    GENERAL_ENTRY_SET
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_ENTRY,
    SET_SLUG
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    slug: '',
    entries: {},
});

const actions = {

    async [GENERAL_ENTRY_FETCH]({ commit, state }, slug) {

        if (state.entries.hasOwnProperty(slug)) {
            commit(SET_SLUG, slug);
            return;
        }

        commit(FETCH_START);

        const { data } = await GeneralEntryService.get(slug);
        commit(SET_ENTRY, { ...data, slug });

        return data;
    },

    [GENERAL_ENTRY_SET]({ commit }, data) {

        commit(SET_ENTRY, data);

        return data;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = true;
    },

    [SET_SLUG](state, slug) {
        state.slug = slug;
    },

    [SET_ENTRY](state, { sections, slug}) {

        state.slug = slug;
        state.isLoading = false;
        state.entries[slug] = {
            sections
        };
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getSections: (state) => {
        if (!state.entries[state.slug]) { return null; }

        return state.entries[state.slug].sections;
    },
};


export const generalEntry = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
