
import { AuthenticationService } from "@/api";
import {
    AUTHENTICATION_LOGIN,
    AUTHENTICATION_LOGOUT,
    UITEXTS_FETCH_NS,
    AUTHENTICATION_REDIRECT_ROUTE,
    AUTHENTICATION_CHECK_LOGIN,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_LOGIN,
    SET_LOGIN_STATUS,
    RESET_LOGIN,
    SET_AUTHENTICATION_ROUTE,
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    token: localStorage.getItem('token') || '',
    redirectRoute: null,
});


const actions = {

    async [AUTHENTICATION_LOGIN]({ commit, state, dispatch, rootGetters }, formData) {

        commit(FETCH_START);

        try {

            const { data: loginResponse } = await AuthenticationService.login(formData);
            if (loginResponse.hasOwnProperty('error')) {
                commit(FETCH_END);
                return {
                    success: false,
                    error: loginResponse.error
                };
            }

            const token = 1;
            localStorage.setItem('token', token);
            commit(SET_LOGIN, token);

            await dispatch(UITEXTS_FETCH_NS, true, { root: true });

            let url = '/';
            if (state.redirectRoute) {
                url = state.redirectRoute;
            }

            const language = rootGetters['uitexts/getActiveLanguage'];
            commit(SET_AUTHENTICATION_ROUTE, null);

            if (window.location.search.indexOf('deep') !== -1) {
                window.location.hash = url;
            } else {
                window.location.href = `/${language}/#${url}`;
            }

            return {
                success: true,
                returnUrl: '/'
            };

        } catch(e) {
            commit(FETCH_END);
        }

        return {
            success: false,
            error: 'Error loggin in.'
        };
    },

    async [AUTHENTICATION_LOGOUT]({ commit }) {

        try {
            localStorage.removeItem('token');
            await AuthenticationService.logout();
            commit(RESET_LOGIN);

        } catch(e) {
            commit(FETCH_END);
        }

    },

    async [AUTHENTICATION_REDIRECT_ROUTE]({ commit }, route) {
        commit(SET_AUTHENTICATION_ROUTE, route);
    },

    async [AUTHENTICATION_CHECK_LOGIN]({ commit }) {

        let loginStatus = false;
        try {
            const { data } = await AuthenticationService.checkLogin();
            // commit(SET_LOGIN_STATUS, data);
            loginStatus = data.timeout > 0;

        } catch(e) {
            // commit(SET_LOGIN_STATUS, true);
        }

        return loginStatus;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_LOGIN](state, token) {
        state.token = token;
        state.isLoading = false;
    },

    [RESET_LOGIN](state, token) {
        state.token = '';
        state.isLoading = false;
    },

    [SET_AUTHENTICATION_ROUTE](state, route) {
        state.redirectRoute = route;
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    isLoggedIn: (state) => {
        return !!state.token;
    },

    getRedirectRoute: (state) => {
        return state.redirectRoute;
    },
};


export const authentication = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
