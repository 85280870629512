import { sortByProperty } from '@/common/utility/sorting';

import Vue from 'vue';
import { TrainingHubService } from "@/api";
import {
    TRAINING_HUB_FETCH,
    TRAINING_HUB_SET,

    TRAINING_HUB_FETCH_EVENTS,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_ENTRY,

    FETCH_EVENTS_START,
    FETCH_EVENTS_END,
    SET_EVENT_PREVIEW,
} from "@/store/mutations.type";


const state = () => ({
    isLoading: false,
    isLoadingEvents: false,

    keyvisual: {},
    overlay: {},
    streams: [],
    sections: [],
    events: [],
});

const actions = {

    async [TRAINING_HUB_FETCH]({ commit, state }) {

        if (!!Object.keys(state.keyvisual).length || !!state.streams.length) {
            return;
        }

        commit(FETCH_START);

        try {
            const { data } = await TrainingHubService.getTrainingHub();

            commit(SET_ENTRY, {
                ...data
            });

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    async [TRAINING_HUB_FETCH_EVENTS]({ commit, state }) {

        if (!!state.events.length) {
            return;
        }

        commit(FETCH_EVENTS_START);

        try {
            const { data } = await TrainingHubService.getTrainingHubEvents();

            commit(SET_EVENT_PREVIEW, data);

            return data;

        } catch(e) {
            commit(FETCH_EVENTS_END);
        }

        return null;
    },

    [TRAINING_HUB_SET]({ commit }, data) {

        commit(SET_ENTRY, data);

        return data;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [FETCH_EVENTS_START](state) {
        state.isLoadingEvents = true;
    },

    [FETCH_EVENTS_END](state) {
        state.isLoadingEvents = false;
    },

    [SET_ENTRY](state, { keyvisual, streams, sections, introductionOverlay }) {

        state.isLoading = false;
        Vue.set(state, 'overlay', introductionOverlay);
        Vue.set(state, 'keyvisual', keyvisual);
        Vue.set(state, 'streams', streams);
        Vue.set(state, 'sections', sections);
    },

    [SET_EVENT_PREVIEW](state, events) {

        state.isLoadingEvents = false;
        Vue.set(state, 'events', events);
    },
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    isLoadingEvents: (state) => {
        return state.isLoadingEvents;
    },

    getOverlay: (state) => {
        return state.overlay;
    },

    getKeyvisual: (state) => {
        return state.keyvisual;
    },

    getStreams: (state) => {

        if (state.streams && !!state.streams.length) {
            return state.streams.map(stream => {
                return {
                    ...stream,
                    streams: stream.streams.sort((a, b) => {

                        if (a.sticky > b.sticky) { return -1; }
                        if (a.sticky < b.sticky) { return 1; }

                        if (a.tiles[0].postDate > b.tiles[0].postDate) return -1;
                        if (a.tiles[0].postDate < b.tiles[0].postDate) return 1;

                        return 0;
                    })
                };
            });
        }

        return state.streams;
    },

    getSections: (state) => {
        return state.sections;
    },

    getEvents: (state) => {
        return state.events;
    },

};


export const trainingHub = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
