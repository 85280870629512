<template>
    <footer class="page-footer" ref="footer">

        <cookie-consent v-if="showConsent" @hide="setFooterHeight" />

        <div class="page-footer__page-container">
            <div class="page-footer__logo">
                <router-link :to="{ name: 'home' }" class="page-footer__logo-link">
                    <svgicon name="logo" class="no-rtl" />
                </router-link>
            </div>
            <div class="page-footer__meta">
                <ul class="page-footer__meta-links">
                    <li>
                        <router-link
                            :to="{ path: getFAQLink}"
                            class="page-footer__meta-link"
                        >{{ t('faqs') }}</router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{ path: getLegalLink}"
                            class="page-footer__meta-link"
                        >{{ t('terms-of-use') }}</router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{ path: getCookieLink}"
                            class="page-footer__meta-link"
                        >{{ t('cookie-policy') }}</router-link>
                    </li>
                    <li>
                        <router-link
                            :to="{ path: getPrivacyLink}"
                            class="page-footer__meta-link"
                        >{{ t('privacy-policy') }}</router-link>
                    </li>
                </ul>

                <div v-if="languageSwitch" class="page-footer__language-switch language-switch">
                    <a href="#en" class="language-switch__language-item language-switch__selected-item">
                        <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#unitedKingdom"></use></svg>
                        <span>{{ t('language') }}</span>
                    </a>

                    <div class="language-switch__languages">
                        <a href="#en" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#en"></use></svg>
                            <span>English</span>
                        </a>
                        <a href="#de" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#de"></use></svg>
                            <span>Deutsch</span>
                        </a>
                        <a href="#fr" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#fr"></use></svg>
                            <span>Français</span>
                        </a>
                        <a href="#es" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#es"></use></svg>
                            <span>Español</span>
                        </a>
                        <a href="#cs" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#cs"></use></svg>
                            <span>Český</span>
                        </a>
                        <a href="#he" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#he"></use></svg>
                            <span>עברי</span>
                        </a>
                        <a href="#pl" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#pl"></use></svg>
                            <span>Polski</span>
                        </a>
                        <a href="#sk" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#sk"></use></svg>
                            <span>Slovak</span>
                        </a>
                        <a href="#sl" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#sl"></use></svg>
                            <span>Slovenski</span>
                        </a>
                        <a href="#tr" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#tr"></use></svg>
                            <span>Türkçe</span>
                        </a>
                        <a href="#da" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#da"></use></svg>
                            <span>Dansk</span>
                        </a>
                        <a href="#pt" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#pt"></use></svg>
                            <span>Português</span>
                        </a>
                        <a href="#ro" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#ro"></use></svg>
                            <span>Românesc</span>
                        </a>
                        <a href="#sv" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#sv"></use></svg>
                            <span>Svenska</span>
                        </a>
                        <a href="#hu" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#hu"></use></svg>
                            <span>Magyar</span>
                        </a>
                        <a href="#el" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#el"></use></svg>
                            <span>Elliniká</span>
                        </a>
                        <a href="#nl" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#nl"></use></svg>
                            <span>Nederlands</span>
                        </a>
                        <a href="#it" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#it"></use></svg>
                            <span>Italiano</span>
                        </a>
                        <a href="#fi" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#fi"></use></svg>
                            <span>Suomalainen</span>
                        </a>
                        <a href="#uk" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#uk"></use></svg>
                            <span>Ukrainian</span>
                        </a>
                        <a href="#hr" class="language-switch__language-item">
                            <svg class="flag__image" viewBox="0 0 40 40"><use xlink:href="#hr"></use></svg>
                            <span>Hrvatski</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    FOOTER_HEIGHT_CHANGED_NS,
    FOOTER_VISIBLE_CHANGED_NS,
} from "@/store/actions.type";
import debounce from 'debounce';

import CookieConsent from '@/components/common/CookieConsent.vue';

export default {
    name: 'PageFooter',

    components: {
        CookieConsent,
    },

    props: {
        languageSwitch: Boolean,
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),

        getLanguage() {
            return window.siteLanguage;
        },

        showConsent() {
            return !window.EXPORT_TYPE_SCORM;
        },

        getFAQLink() {
            return '/faq';
        },

        getLegalLink() {
            return '/legal-note';
        },

        getCookieLink() {
            return '/cookie-policy';
        },

        getPrivacyLink() {
            return '/privacy-policy';
        },
    },

    data() {
        return {
            footerHeight: 0,
            footerInViewport: true,
        }
    },

    async mounted() {

        await this.$nextTick();
        this.setFooterHeight();
        this.setFooterVisibility();

        window.addEventListener('resize', this.handleWindowResize);
    },

    methods: {
        handleWindowResize: debounce(function () {
            this.setFooterHeight();
        }, 100),

        setFooterHeight() {
            if (this.$refs.footer) {
                this.footerHeight = this.$refs.footer.getBoundingClientRect().height;

                // document.body.style.paddingBottom = `${this.footerHeight}px`;
                store.dispatch(FOOTER_HEIGHT_CHANGED_NS, this.footerHeight);
            }
        },

        setFooterVisibility() {
            if (this.$refs.footer) {

                const footerBounding = this.$refs.footer.getBoundingClientRect();

                this.footerInViewport = footerBounding.top >= 0 &&
                    (footerBounding.top - footerBounding.height) <= (window.innerHeight || document.documentElement.clientHeight);

                store.dispatch(FOOTER_VISIBLE_CHANGED_NS, this.footerInViewport);
            }
        },

    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize);
    }
}
</script>
