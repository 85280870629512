<template>
    <div class="overview-content">

        <stream-keyvisual
            v-if="keyvisual"
            :keyvisual="keyvisual"
            :settings="keyvisual.settings" />

        <div class="section-dimensioning section-dimensioning--medium section-dimensioning--even content-dimensioning">

            <template v-for="section in sections">

                <base-section
                    v-if="section.type !='contentNavigationItem'"
                    :key="`nugget-section-${section.id}`"
                    :section="section" />

            </template>

        </div>

    </div>
</template>

<script>
import StreamKeyvisual from '@/components/elements/hub/StreamKeyvisual.vue';
import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'OverviewContent',

    components: {
        StreamKeyvisual,
        BaseSection,
    },

    props: {
        keyvisual: Object,
        sections:  Array,
    },
}
</script>
