<template>
    <div v-if="!isLoading" class="view-entry" >

        <page-header :noUsermenu="true" :back-button="true" />

        <div class="page-section-entry section-dimensioning section-dimensioning--medium content-dimensioning">
            <template  v-for="(section, index) in getSections">
                <base-section
                    :key="`entry-section-${index}`"
                    :section="section">
                </base-section>
            </template>
        </div>

        <!-- <page-footer /> -->
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    UITEXTS_FETCH_NS,
    GENERAL_ENTRY_FETCH_NS,
    GENERAL_ENTRY_SET_NS,
    META_SET_NS
} from "@/store/actions.type";

import PageHeader from '@/components/common/PageHeader.vue';
import PageFooter from '@/components/common/PageFooter.vue';
import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'GeneralEntryView',
    components: {
        PageHeader,
        BaseSection,
        PageFooter,
    },
    props: {
        content: Object,
        alternativeBrand: {
            type: Boolean,
            default: false,
        }
    },
    computed: {

        ...mapGetters('common', {
            getHeaderHeight: 'getHeaderHeight',
            getFooterHeight: 'getFooterHeight',
        }),

        ...mapGetters('uitexts', {
            isLoadingUiTexts: 'isLoading',
            t: 't',
        }),

        ...mapGetters('generalEntry', {
            getSections: 'getSections',
            isLoadingEntry: 'isLoading',
        }),

        isLoading() {
            return this.isLoadingUiTexts || this.isLoadingEntry;
        },

        hideBreadcrumbs() {
            return window.EXPORT_TYPE_SCORM;
        },

        rootStyle() {

            const styles = {
                height: `calc(100vh - ${this.getHeaderHeight}px - ${this.getFooterHeight}px)`
            };

            return styles;
        },
    },

    async beforeRouteEnter(to, from, next) {
        await store.dispatch(UITEXTS_FETCH_NS);
        await store.dispatch(GENERAL_ENTRY_FETCH_NS, to.params.slug);

        return next();
    },

    async beforeRouteUpdate(to, from, next) {

        await store.dispatch(GENERAL_ENTRY_FETCH_NS, to.params.slug);

        return next();
    },

     beforeRouteLeave(to, from, next) {


        if (to.name == 'entry') {
            this.pushBreadcrumb({
                text: 'Forward',
                to: { path: from.path }
            });
        } else {
            this.popBreadcrumb();
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(GENERAL_ENTRY_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);

            store.dispatch(META_SET_NS, {
                isAlternativeBrand: this.alternativeBrand,
                canAccessCp: false,
            });
        }
    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),
    },
}
</script>
