
import createHyphenator from 'hyphen';

const isLoading = [];
const langMapping = {
    'en-GB' : 'en-gb',
    'el-GR' : 'el-monoton',
    'en-IE' : 'en-gb',
    'en' : 'en-us',
};

function getHyphenatorFunction(lang) {
    let camelCase = lang.replace(/^([A-Z])|[\s-_](\w)/g, function(match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });

    camelCase = camelCase.charAt(0).toUpperCase() + camelCase.slice(1)

    return `hyphenationPatterns${camelCase}`;
}

function getHyphenatedText(hyphenatorFunction, text) {

    if (window[hyphenatorFunction]) {
        return createHyphenator(window[hyphenatorFunction])(text);
    }

    return text;
}

function hyphenate(text) {

    return new Promise(function(resolve, reject) {

        if (document.documentElement.classList.contains('hyphens--supported')) {
            return resolve(text);
        }

        const lang = document.documentElement.getAttribute('lang');
        const langToLoad = langMapping.hasOwnProperty(lang) ? langMapping[lang] : lang.split('-')[0];
        const hyphenatorFunction = getHyphenatorFunction(langToLoad);

        if (langToLoad == 'he') {
            return resolve(text);
        }

        if (!window[hyphenatorFunction] && isLoading.indexOf(langToLoad) === -1) {
            isLoading.push(langToLoad);

            let langScript = document.createElement('script');
            let prefix = '';
            if (window.EXPORT_TYPE_SCORM === true) {
                prefix = '.';
            }
            langScript.setAttribute('src', `${prefix}/scripts/hyphen/patterns/${langToLoad}.js`);
            langScript.onload = () => {
                return resolve(getHyphenatedText(hyphenatorFunction, text));
            };
            langScript.onerror = () => {
                return resolve(text);
            };
            document.head.appendChild(langScript);

        } else {
            if (!window[hyphenatorFunction]) {
                setTimeout(function() {
                    resolve(getHyphenatedText(hyphenatorFunction, text));
                }, 300);
            } else {
                resolve(getHyphenatedText(hyphenatorFunction, text));
            }
        }
    });
}

export default hyphenate;