
<template>
    <div class="leaderboard">
       <DynamicScroller
            class="leaderboard__scroller"
            :items="items"
            :min-item-size="65"
            key-field="id"
            ref="scroller"
            :emit-update="hasUpdateListener"
            @update="(startIndex, endIndex) => $emit('update', $refs.scroller)"
        >
            <div slot="before" class="leaderboard__header">
                <div class="leaderboard__content-wrapper">
                    <span v-if="hasRankColumn" class="leaderboard__cell leaderboard__cell--first">{{ t('rank') }}</span>
                    <span v-if="hasNicknameColumn || hasScoreColumn" class="leaderboard__cell leaderboard__cell--second">
                        <span v-if="hasNicknameColumn" class="leaderboard__cell-split">{{ t('nickname') }}</span>
                        <span v-if="hasScoreColumn" class="leaderboard__cell-split">{{ t('points') }}</span>
                    </span>
                    <span v-if="hasLevelColumn" class="leaderboard__cell leaderboard__cell--third">{{ t('level') }}</span>
                </div>
            </div>
            <template v-slot="{ item, active, index }">
                <DynamicScrollerItem
                    :item="item"
                    :active="active"
                    :data-index="index"
                >
                    <leaderboard-row-element
                        :item="item"
                        :has-rank-column="hasRankColumn"
                        :has-nickname-column="hasNicknameColumn"
                        :has-score-column="hasScoreColumn"
                        :has-level-column="hasLevelColumn"
                        :even="(index % 2) == 0"
                        :odd="(index % 2) != 0"
                        :top-score="firstPlaceScore"
                    />
                </DynamicScrollerItem>
            </template>
        </DynamicScroller>
    </div>
</template>

<script>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

import { mapGetters } from "vuex";

import LeaderboardRowElement from '@/components/elements/event/LeaderboardRowElement.vue';

export default {
    name: 'LeaderboardElement',

    components: {
        DynamicScroller,
        DynamicScrollerItem,
        LeaderboardRowElement,
    },

    props: {
        items: Array,
    },

    data() {
        return {

        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        hasRankColumn() {
            return !!this.items.length && this.items[0].hasOwnProperty('rank');
        },

        hasNicknameColumn() {
            return !!this.items.length && this.items[0].hasOwnProperty('nickname');
        },

        hasScoreColumn() {
            return !!this.items.length && this.items[0].hasOwnProperty('score');
        },

        hasLevelColumn() {
            return !!this.items.length && this.items[0].hasOwnProperty('level');
        },

        firstPlaceScore() {
            return !!this.items.length && this.items[0].hasOwnProperty('score') ? this.items[0].score : 0;
        },

        hasUpdateListener() {
            return this.$listeners && typeof this.$listeners.update == 'function';
        },
    },
}
</script>
