
<template>
     <div v-if="!isLoadingGallery" class="gallery">

        <select-element
            v-if="datePickerVisible"
            class="gallery__select"
            :items="getDates"
            :selected-value="getActiveDate"
            :placeholder="t('select-date')"
            ref="selectDate"
            @change="handleDateChanged" />

        <slideshow-element
            v-if="selectedImages"
            :slides="selectedImages"
            class="gallery__slider" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    EVENT_FETCH_GALLERY_NS,
} from "@/store/actions.type";

import SlideshowElement from '@/components/elements/SlideshowElement.vue';
import SelectElement from '@/components/elements/form/SelectElement.vue';

export default {
    name: 'GalleryElement',
    components: {
        SlideshowElement,
        SelectElement,
    },
    props: {
        galleryImages: Array,
        settings: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            selectedImages: null
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('event', {
            isLoadingGallery: 'isLoadingGallery',
            getGallery: 'getGallery',
        }),

        datePickerVisible() {
            return this.getDates && this.getDates.length > 1;
        },

        getDates() {

            return this.getGallery.map(i => {
                return {
                    title: i.title,
                    id: i.id,
                    images: [
                        ...this.galleryImages,
                        ...i.images
                    ]
                };
            })
        },

        getActiveDate() {
            return this.getDates[0].id;
        },
    },

    async created() {
        this.selectedImages = this.galleryImages;
        await store.dispatch(EVENT_FETCH_GALLERY_NS, this.settings.galleryFolder);
    },

    async mounted() {
    },

    methods: {
        handleDateChanged(val) {
            this.selectedImages = val.images;
        },
    },
}
</script>

