<template>
    <alternative-hub-view v-if="isAlternativeBrand" />
    <training-hub-view v-else />
</template>

<script>
import { mapGetters } from "vuex";

import TrainingHubView from '@/views/TrainingHubView.vue';
import AlternativeHubView from '@/views/AlternativeHubView.vue';

export default {
    name: 'TrainingHubSplitter',

    components: {
        TrainingHubView,
        AlternativeHubView
    },

    computed: {
        ...mapGetters('meta', {
            isAlternativeBrand: 'isAlternativeBrand',
        }),
    },
}
</script>
