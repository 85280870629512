
<template>
    <base-section
        :section="getErrorSection"
        :class="{
            'quiz-duel-error' : true,
        }"
    >
        <div slot="before-introtext" class="quiz-duel-error__logo">
            <svgicon-wrapper name="logo" color="#ffffff" />
        </div>
    </base-section>
</template>

<script>
/*
    - General Error "qd-error"
        qd-error-headline
        qd-error-text

    - Session Locked "qd-error-session-locked"
        qd-error-session-locked-headline
        qd-error-session-locked-text

    - No Opponent "qd-error-no-opponent"
        qd-error-no-opponent-headline
        qd-error-no-opponent-text

*/
import { mapGetters } from "vuex";
import store from "@/store";

import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
    name: 'ErrorElement',

    components: {
        ButtonElement,
        BaseSection,
    },

    data() {
        return {

        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        getBaseSection() {
            return {
                "type": "section-text-only",
                "introtext": {
                    extraline: null,
                    headline: null,
                    copytext: null,
                    button: null,
                },
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "light",
                        "backgroundColor": '#000000',
                        "backgroundImage": null,
                        "textBehavior": 'centered',
                    },
                    "layout": {
                        "columns": "",
                        "size": "page-fit"
                    },
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },

        getErrorSection() {
            const section = {
                ...this.getBaseSection
            };

            let errorHeadline = this.t('qd-error-headline');
            let errorText = this.t('qd-error-text');

            section.introtext.headline = errorHeadline;
            section.introtext.copytext = errorText;

            return section;
        },
    },

    created() {
    },

    mounted() {

    },

    methods: {
    }
}
</script>

