
import { EventService } from "@/api";
import {
    EVENT_FETCH,
    EVENT_SET,
    EVENT_RESET,
    EVENT_FETCH_GALLERY,
    EVENT_IS_ALLOWED,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_EVENT,
    RESET_EVENT,
    SET_SLUG,
    SET_GALLERY,
    SET_GALLERY_FOLDER,
    FETCH_GALLERY_START,
    FETCH_GALLERY_END,
} from "@/store/mutations.type";

const state = () => ({
    isLoading: false,
    slug: '',
    events: {},

    isLoadingGallery: false,
    galleryFolder: '',
    gallery: {},
    achievementIcons: {},
});

const actions = {

    async [EVENT_IS_ALLOWED]({ commit, state }, { slug }) {

        try {
            const { data } = await EventService.isAllowed(slug);

            return data.success;

        } catch(e) {
            return false;
        }
    },

    async [EVENT_FETCH]({ commit, state }, { slug, force }) {

        if (!force && state.events.hasOwnProperty(slug)) {
            commit(SET_SLUG, slug);
            return true;
        }

        commit(FETCH_START);

        try {
            const { data } = await EventService.get(slug);
            // if (data.hasOwnProperty('error')) {
            //     return null;
            // }

            commit(SET_EVENT, {
                ...data,
                slug
            });

            return true;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    [EVENT_SET]({ commit }, data) {

        commit(SET_EVENT, data);

        return data;
    },

    async [EVENT_FETCH_GALLERY]({ commit, state }, galleryFolder) {

        if (state.gallery.hasOwnProperty(galleryFolder)) {
            commit(SET_GALLERY_FOLDER, galleryFolder);
            return state.gallery[galleryFolder];
        }

        commit(FETCH_GALLERY_START);

        try {
            const { data } = await EventService.getGallery(galleryFolder);

            commit(SET_GALLERY, {
                gallery: data,
                galleryFolder
            });

            return data;

        } catch(e) {
            commit(FETCH_GALLERY_END);
        }

        return null;
    },

    [EVENT_RESET]({ commit }) {

        commit(RESET_EVENT);

        return true;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [FETCH_GALLERY_START](state) {
        state.isLoadingGallery = true;
    },

    [FETCH_GALLERY_END](state) {
        state.isLoadingGallery = false;
    },

    [SET_SLUG](state, slug) {
        state.slug = slug;
    },

    [SET_GALLERY_FOLDER](state, galleryFolder) {
        state.galleryFolder = galleryFolder;
    },

    [SET_EVENT](state, { sections, expired, endDate, styleOverrides, trackingId, achievementIcons, slug}) {

        state.slug = slug;
        state.isLoading = false;
        state.events[slug] = {
            sections,
            expired,
            endDate,
            styleOverrides,
            trackingId,
            achievementIcons,
        };
    },

    [SET_GALLERY](state, { gallery, galleryFolder}) {

        state.galleryFolder = galleryFolder;
        state.isLoadingGallery = false;
        state.gallery[galleryFolder] = gallery;
    },

    [RESET_EVENT](state) {
        state.isLoading = false;
        state.slug = '';
        state.events = {};
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    isLoadingGallery: (state) => {
        return state.isLoadingGallery;
    },

    getAchievementIcons: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].getAchievementIcons;
    },

    getSections: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].sections;
    },

    getStyleOverrides: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].styleOverrides;
    },

    getTrackingId: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].trackingId;
    },

    getIsExpired: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].expired;
    },

    getEndDate: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].endDate;
    },

    getJumpmarks: (state) => {
        if (!state.events[state.slug]) { return null; }

        return state.events[state.slug].sections.filter((section) => {
            return section.type == 'jumpmark';
        });
    },

    getGallery: (state) => {
        if (!state.gallery[state.galleryFolder]) { return null; }

        return state.gallery[state.galleryFolder];
    },
};


export const event = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
