
<template>
     <div class="gamification">

        <div class="gamification__standings-wrapper">
            <div class="gamification__grid">

                <div
                    v-if="settings.eventExpired && settings.expiredMessage"
                    class="gamification__grid-column gamification__grid-column--warning"
                >
                    <svgicon-wrapper name="exclamation-mark" />
                    <introtext-element :introtext="{
                        headline: null,
                        subline: null,
                        copytext: settings.expiredMessage,
                    }" :extra-context="extraContext" />
                </div>

                <div class="gamification__grid-column gamification__grid-column--level">
                    <level-indicator-element
                        v-if="getMeItem && !!settings.levelBreakpoints.length"
                        :nickname="getMeItem.nickname"
                        :level-breakpoints="settings.levelBreakpoints"
                        :score="getMeItem.score" />
                </div>
                <div v-if="getMeItem" class="gamification__grid-column gamification__grid-column--scoring">
                    <div class="gamification__standing-wrapper">
                        <span class="gamification__rank">{{ getMeItem.rank }}</span>
                        <span class="gamification__label">{{ t('international-rank') }}</span>
                    </div>
                    <div class="gamification__standing-wrapper">
                        <span class="gamification__score">{{ getMeItem.score | numberFormat }}</span>
                        <span class="gamification__label">{{ t('points-collected') }}</span>
                    </div>
                </div>
            </div>
            <div class="gamification__catchup">
                <span class="gamification__catchup-headline">{{ t('your-closest-competitors') }}</span>
                <span class="gamification__catchup-introduction">{{ t('follow-up-introduction') }}</span>
                <div class="gamification__grid">
                    <div class="gamification__grid-column gamification__grid-column--catchup">
                        <catchup-bar-element
                            class="gamification__catchup-bar"
                            :ahead-me="getAheadMe"
                            :me="getMeItem"
                            :behind-me="getBehindMe" />
                    </div>
                </div>
            </div>
        </div>

        <div v-if="achievementsTotal > 0" class="gamification__achievements-wrapper">
            <div class="gamification__headline-wrapper">
                <span class="gamification__headline">{{ t('achievements')}}</span>
            </div>

            <div class="gamification__achievements">
                <span class="gamification__achievements-count">{{ getAchievementCount }}</span>
                <v-runtime-template class="gamification__achievements-explanation" :template="`<span>${ t('x-of-y-achievements') }</span>`" />
            </div>

            <div class="gamification__button">
                <button-element
                    @click="openAchievementsOverlay"
                    :button="{
                        'label': t('all-achievements')
                    }"/>
            </div>
        </div>

        <div class="gamification__leaderboard-wrapper">
            <div v-if="!isLoadingGamification" class="gamification__leaderboard">
                <div class="gamification__headline-wrapper">
                    <span class="gamification__headline">{{ t('leaderboards')}}</span>
                </div>
                <!-- <div class="gamification__switch">
                    <span
                        class="gamification__switch-item"
                        :class="{
                            'gamification__switch-item--active': getSelectedLeaderboard == 'international'
                        }"
                        @click="() => handleSwitchClick('international')">{{ t('international') }}</span>

                    <span
                        class="gamification__switch-item"
                        :class="{
                            'gamification__switch-item--active': getSelectedLeaderboard == 'national'
                        }"
                        @click="() => handleSwitchClick('national')">{{ t('national') }}</span>
                </div> -->
                <leaderboard-element :items="getLeaderboardPreview" />
            </div>

            <div class="gamification__button">
                <button-element
                    @click="openLeaderboardOverlay"
                    :button="{
                        'label': t('view-full-list')
                    }"/>
            </div>
        </div>

        <portal to="modals">
            <modal-element
                class="gamification__modal gamification__modal--full-width"
                :open="showLeaderboardOverlay"
                @close="closeLeaderboardOverlay"
            >
                <template slot="content">
                    <span class="gamification__modal-headline gamification__modal-headline--shadowed">{{ t('leaderboard')}}</span>
                    <leaderboard-element
                        v-if="!isLoadingGamification"
                        :items="getLeaderboard"
                        @update="handleModalScroll" />

                    <leaderboard-row-element
                        v-if="!isLoadingGamification"
                        class="gamification__modal-sticky-row gamification__modal-sticky-row--sticky-bottom"
                        :item="getMeItem"
                        :has-rank-column="true"
                        :has-nickname-column="true"
                        :has-score-column="true"
                        :top-score="getTopScore" />
                </template>
            </modal-element>

            <modal-element
                class="gamification__modal"
                :open="showAchievementsOverlay"
                @close="closeAchievementsOverlay"
            >
                <template>
                    <div class="modal__grid-column">
                        <span class="gamification__modal-headline">{{ t('achievements')}}</span>
                    </div>
                    <div class="modal__grid-column">
                        <achievement-list-element
                            :achievements="getAchievements"
                            :achieved-count="getAchievedAchievemtCount"
                            :icons="getIcons" />
                    </div>
                </template>
            </modal-element>
        </portal>
    </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template/dist/v-runtime-template";

import { mapGetters } from "vuex";
import store from "@/store";
import {
    GAMIFICATION_FETCH_NS,
    LEADERBOARD_SWITCH_NS,
} from "@/store/actions.type";

import IntrotextElement from '@/components/elements/IntrotextElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import ModalElement from '@/components/elements/ModalElement.vue';
import LeaderboardElement from '@/components/elements/event/LeaderboardElement.vue';
import LeaderboardRowElement from '@/components/elements/event/LeaderboardRowElement.vue';
import AchievementListElement from '@/components/elements/event/AchievementListElement.vue';
import CatchupBarElement from '@/components/elements/event/CatchupBarElement.vue';
import LevelIndicatorElement from '@/components/elements/event/LevelIndicatorElement.vue';

const leaderboards = {

};

export default {
    name: 'GamificationElement',
    components: {
        VRuntimeTemplate,
        LeaderboardElement,
        ButtonElement,
        IntrotextElement,
        ModalElement,
        LeaderboardRowElement,
        AchievementListElement,
        CatchupBarElement,
        LevelIndicatorElement,
    },
    props: {
        disabled: Boolean,
        settings: {
            type: Object,
            required: true,
        },
        extraContext: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            showLeaderboardOverlay: false,
            showAchievementsOverlay: false,
        }
    },
    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('event', {
            getAchievementIcons: 'getAchievementIcons',
        }),

        ...mapGetters('gamification', {
            isLoadingGamification: 'isLoading',
            getLeaderboard: 'getLeaderboard',
            getLeaderboardPreview: 'getLeaderboardPreview',
            getSelectedLeaderboard: 'getSelectedLeaderboard',
            getMeItem: 'getMeItem',
            getTopScore: 'getTopScore',
            getAchievements: 'getAchievements',
            getAchievedAchievemtCount: 'getAchievedAchievemtCount',
            getAheadMe: 'getAheadMe',
            getBehindMe: 'getBehindMe',
        }),

        getAchievementCount() {
            return `${this.achievementsAchieved} / ${this.achievementsTotal}`;
        },

        achievementsAchieved() {
            return this.getAchievedAchievemtCount;
        },

        achievementsTotal() {
            return this.getAchievements.length;
        },

        getIcons() {
            return this.getAchievementIcons ? this.getAchievementIcons : this.settings.achievementIcons;
        },
    },

    async created() {
        if (this.settings.eventUid) {
            await store.dispatch(GAMIFICATION_FETCH_NS, {
                uid: this.settings.eventUid,
                force: false
            });
        }
    },

    async mounted() {
    },

    methods: {
        openLeaderboardOverlay() {
            if (this.disabled) { return; }

            this.showLeaderboardOverlay = true;
        },

        closeLeaderboardOverlay() {
            this.showLeaderboardOverlay = false;
        },

        openAchievementsOverlay() {
            if (this.disabled) { return; }

            this.showAchievementsOverlay = true;
        },

        closeAchievementsOverlay() {
            this.showAchievementsOverlay = false;
        },

        handleModalScroll(scroller) {
            const me = document.querySelector('.gamification__modal .leaderboard .leaderboard__data-row--me');
            const stickyMe = document.querySelector('.gamification__modal-sticky-row');

            if (stickyMe) {
                if (me) {
                    const leaderboard = document.querySelector('.gamification__modal .leaderboard').getBoundingClientRect();
                    const leaderBoardBottom = leaderboard.top + leaderboard.height;
                    const meRect = me.getBoundingClientRect();

                    if (meRect.top + meRect.height < leaderBoardBottom) {
                        stickyMe.classList.add('gamification__modal-sticky-row--hidden');
                    } else {
                        stickyMe.classList.remove('gamification__modal-sticky-row--hidden');
                    }
                } else {
                    stickyMe.classList.remove('gamification__modal-sticky-row--hidden');
                }
            }
        },

        handleSwitchClick(leaderboard) {
            store.dispatch(LEADERBOARD_SWITCH_NS, leaderboard);
        },
    },
}
</script>

