<template>
    <div v-if="media" class="media">

        <video-element
            v-if="media.video"
            :url="media.video.url"
            :vtt="media.webvtt"
            :poster="getPoster"
            :settings="videoSettings"
            :show-notification="showNotification"
            @video-played-enough="$emit('video-played-enough')"
        ></video-element>

        <image-element
            v-if="!media.video && media.image"
            :image="media.image"
            :settings="imageSettings"
            @loaded="$emit('loaded')"
        ></image-element>
    </div>
</template>

<script>
import VideoElement from '@/components/elements/VideoElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'MediaElement',
    components: {
        VideoElement,
        ImageElement
    },
    props: {
        media: {
            type: Object,
            default: () => {
                return {
                    video: null,
                    image: null,
                }
            }
        },
        showNotification: {
            type: Boolean,
            default: true,
        },
        imageSettings: Object,
        videoSettings: Object,
    },
    computed: {
        getPoster() {

            const url = this.media && this.media.image ? this.media.image : null;

            return url
        }
    }
}
</script>
