<template>
    <div v-if="!isLoading" class="view-nugget-collection" :class="[ `view-nugget-collection--type-${getType}` ]">
        <page-header
            :use-scroll-progress="getType != 'overview'"
            :back-button="!isScorm"
            :no-usermenu="true"
            :transparent="getType == 'overview'"
        >
            <content-navigation
                v-if="!!getJumpmarks.length"
                slot="sticky"
                :items="getContentNavigationItems"
                />
        </page-header>

        <div class="section-dimensioning section-dimensioning--medium content-dimensioning">
            <base-section
                v-if="getKeyvisual"
                :section="getKeyvisual"
                :id="`jumpmark-start`"
            >
            </base-section>
        </div>

        <div v-if="getType == 'collection'" class="nuggets section-dimensioning section-dimensioning--medium content-dimensioning">
            <template v-for="(nugget, idx) in getNuggets">

                <div
                    v-if="nugget.hasOwnProperty('jumpmark')"
                    :id="`jumpmark-${nugget.id}`"
                    :key="`jumpmark-${idx}`"
                ></div>

                <base-section
                    v-else
                    v-for="(section, index) in getNuggetSections(nugget.sections)"
                    :key="`nugget-${nugget.id}-section-${index}`"
                    :section="section">
                </base-section>
            </template>
        </div>

        <div v-if="getType == 'overview'" >
            <div class="section__page-container">
                <div class="section__content-container">
                    <div class="view-nugget-collection__grid">
                        <div
                            v-for="nugget in getNuggets"
                            :key="`nugget-index-${nugget.id}`"
                            class="view-nugget-collection__tile"
                        >
                            <fluid-tile :nugget="nugget" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import store from "@/store";
import {
    COLLECTION_FETCH_NS,
    COLLECTION_SET_NS,
    UITEXTS_FETCH_NS,
    ME_GET_NS,
    TRACK_VISIT_NS,
    META_SET_NS,
    REQUIREMENTS_MATCH_NS,
} from "@/store/actions.type";

import ScormTracker from '@/common/tracking/ScormTracker';

import PageHeader from '@/components/common/PageHeader.vue';
import NuggetComponent from '@/components/NuggetComponent.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ContentNavigation from '@/components/elements/navigation/ContentNavigation.vue';
import FluidTile from '@/components/elements/tiles/FluidTile.vue';

export default {
    name: 'NuggetCollection',

    components: {
        PageHeader,
        NuggetComponent,
        BaseSection,
        ContentNavigation,
        FluidTile,
    },

    props: {
        content: Object,
        alternativeBrand: {
            type: Boolean,
            default: false,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        ...mapGetters('collection', {
            isLoadingCollection: 'isLoading',
            getType: 'getType',
            getNuggets: 'getNuggets',
            getKeyvisual: 'getKeyvisual',
            getJumpmarks: 'getJumpmarks',
            getTrackingId: 'getTrackingId',
        }),

        isLoading() {
            return this.isLoadingCollection;
        },

        getContentNavigationItems() {

            let jumpmarks = [];
            if (!!this.getJumpmarks.length) {
                jumpmarks = [
                    // {
                    //     id: 'start',
                    //     jumpmark: 'Start'
                    // },
                    ...this.getJumpmarks
                ];
            }

            return jumpmarks.map((item) => {
                return {
                    'label': item.jumpmark,
                    'anchor': `#jumpmark-${item.id}`
                }
            });
        },

        hideUserMenu() {
            return window.EXPORT_TYPE_SCORM;
        },

        isScorm() {
            return window.EXPORT_TYPE_SCORM;
        },
    },

    async beforeRouteEnter(to, from, next) {

        if (!window.EXPORT_TYPE_SCORM && !await store.dispatch(REQUIREMENTS_MATCH_NS, to.params.slug)) {
            return next({ name: 'forbidden' });
        }

        await store.dispatch(UITEXTS_FETCH_NS);
        if (!window.EXPORT_TYPE_SCORM) {
            await store.dispatch(ME_GET_NS);
        }
        const result = await store.dispatch(COLLECTION_FETCH_NS, to.params.slug);

        if (!result) {
            return next({ name: 'home' });
        }

        return next();
    },

    beforeRouteLeave(to, from, next) {


        if (to.name == 'entry') {
            this.pushBreadcrumb({
                text: 'Forward',
                to: { path: from.path }
            });
        } else if (to.name == 'training-hub' || to.name == 'home' || to.name == 'stream' || to.name == 'nugget') {
            this.popBreadcrumb();
        }

        next();
    },

    async created() {
        if (this.content) {
            store.dispatch(COLLECTION_SET_NS, this.content);
            await store.dispatch(UITEXTS_FETCH_NS);
            if (!window.EXPORT_TYPE_SCORM) {
                await store.dispatch(ME_GET_NS);
            }

            store.dispatch(META_SET_NS, {
                isAlternativeBrand: this.alternativeBrand,
                canAccessCp: false,
            });
        }

        if (!window.EXPORT_TYPE_SCORM) {
            store.dispatch(TRACK_VISIT_NS, {
                entryUid: this.getTrackingId
            });
        }
    },

    async mounted() {

        if (window.EXPORT_TYPE_SCORM) {
            const scormTracker = new ScormTracker();
            await this.$nextTick()
            scormTracker.trackCompletion();
        }
    },

    methods: {

        ...mapMutations('breadcrumbs', {
            setBreadcrumbs: 'set',
            pushBreadcrumb: 'push',
            popBreadcrumb: 'pop',
            replaceBreadcrumb: 'replace',
            emptyBreadcrumbs: 'empty'
        }),

        getNuggetSections(sections) {
            return sections.filter(s => s.type != 'contentNavigationItem');
        }
    },
}
</script>
