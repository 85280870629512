<template>
    <div
        v-if="tableData"
        :class="{
            'comparison-table': true,
            'comparison-table--solo-column': isSoloColumnTable()
        }"
        ref="root"
    >
        <div class="comparison-table__scroller" ref="scroller">
            <table ref="table" :style="getTableStyle">
                <tbody>
                    <tr
                        v-for="(row, rowIndex) in tableData.rows"
                        :key="`row-index-${rowIndex}`"
                    >
                        <td
                            v-for="(cell, cellIndex) in row.cells"
                            :key="`cell-index-${rowIndex}-${cellIndex}`"
                            :colspan="cell.span ? cell.span : null"
                            :class="{
                                'comparison-table__cell': true,
                                'comparison-table__cell--fixed': cell.fixed,
                                'comparison-table__cell--spreader': rowIndex == 0 && cellIndex != 0,
                                'comparison-table__cell--size-to-fit': settings.sizeToFit && rowIndex == 0 && cellIndex != 0,
                                [`comparison-table__cell--type-${cell.type}`]: true,
                                [`comparison-table__cell--type-value-${cell.valueType}`]: cell.type == 'value',
                                [`comparison-table__cell--style-${cell.style}`]: cell.type == 'value',
                            }"
                            :style="getCellStyle(rowIndex, cell.fixed)"
                        >
                            <div class="comparison-table__cell-wrapper">
                                <image-element
                                    v-if="cell.hasOwnProperty('image') && cell.image != null"
                                    @loaded="handleImagesLoaded()"
                                    :image="cell.image"
                                    class="comparison-table__image"/>

                                <span
                                    v-else-if="cell.hasOwnProperty('value') && cell.value"
                                    class="comparison-table__value"
                                >{{ cell.value }}</span>

                                <span
                                    v-else-if="cell.hasOwnProperty('headline')"
                                    class="comparison-table__headline"
                                    v-html="cell.headline"
                                />

                                <span v-else class="comparison-table__value">&nbsp;</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageElement from '@/components/elements/ImageElement.vue';

export default {
    name: 'ComparisonTableElement',
    components: {
        ImageElement,
    },
    props: {
        tableData: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    sizeToFit: false,
                    fixedHeaderRows: ''
                }
            }
        },
    },
    computed: {

        ...mapGetters('common', {
            getActualHeaderHeight: 'getActualHeaderHeight',
            headerIsPinned: 'isPinned',
        }),

        getCellStyle() {
            return (rowIndex, fixed) => {

                const style = {};
                if (this.heights.hasOwnProperty(rowIndex)) {
                    let height = this.heights[rowIndex];

                    if (!fixed && rowIndex == 0 && this.settings.sizeToFit && this.sizeToFitWidth && this.isDesktop()) {
                        style['width'] = `${this.sizeToFitWidth}px`;
                        style['minWidth'] = `${this.sizeToFitWidth}px`;
                        style['maxWidth'] = `${this.sizeToFitWidth}px`;
                    } else {
                        style['width'] = '';
                        style['minWidth'] = '';
                        style['maxWidth'] = '';
                    }

                    if (height != 'auto') {
                        height = `${height}px`;
                    }

                    style['height'] = height;
                }

                if (this.tops.hasOwnProperty(rowIndex) && fixed) {
                    let top = this.tops[rowIndex];
                    style['top'] = `${top + 1}px`;
                }

                if (fixed) {
                    style['position'] = this.calculating ? 'relative' : 'absolute';
                    style['z-index'] = this.tableData.rows.length - rowIndex;
                }

                return style;
            }
        },

        getTableStyle() {

            const styles = {
                width: ''
            };

            if (this.settings.sizeToFit && this.tableWidth && this.isDesktop()) {
                styles['width'] = `${this.tableWidth - 1}px`;
            }

            return styles;
        },

        hasFixedHeader() {
            return this.fixedHeaderRows && !!this.fixedHeaderRows.length;
        },
    },

    data: () => {
        return {
            heights: {},
            tops: {},
            sizeToFitWidth: null,
            tableWidth: null,
            fixdHeaderRows: [],
            calculating: false,
            isSyncingTableScroll: false,
            isSyncingHeaderScroll: false,

            cloneOfHeader: null,
        }
    },

    created() {

        if (this.settings.fixedHeaderRows) {
            this.fixedHeaderRows = this.settings.fixedHeaderRows.split(',').map(index => parseInt(index.trim()))
        }
    },

    async mounted() {
        window.setTimeout(() => {
            this.manageTableDimensions();
        }, 0);

        window.addEventListener('resize', this.manageTableDimensions);
        window.addEventListener('scroll', this.handlePageScroll);
        this.$refs.scroller.addEventListener('scroll', this.handleTableScroll);
    },

    methods: {

        isDesktop() {
            return document.documentElement.clientWidth >= 1440;
        },

        handleTableScroll() {
            const headerScroller = document.querySelector('.comparison-table__clone');

            if (headerScroller && !this.isSyncingTableScroll) {
                this.isSyncingTableScroll = true;
                headerScroller.scrollLeft = this.$refs.scroller.scrollLeft;
            }
            this.isSyncingTableScroll = false;
        },

        updateFixedHeader() {
            const { root } = this.$refs;

            if (!this.hasFixedHeader || !root) { return; }

            const tableRect = this.$refs.table.getBoundingClientRect();
            const scrollerRect = this.$refs.scroller.getBoundingClientRect();
            const topValue = this.getActualHeaderHeight;
            let firstCellWidth = 0;
            const firstRowIndex = this.fixedHeaderRows.slice(0, 1);
            const theRow = root.querySelector(`tr:nth-child(${firstRowIndex})`)
            const rowRect = theRow.getBoundingClientRect();

            if (rowRect.top <= topValue && (tableRect.top + tableRect.height - rowRect.height) >= topValue) {

                if (!this.cloneOfHeader) {
                    // Create Parent Element for header fake, which will be scrollable
                    // by translating table horizontal scroll to hits element and
                    // vise versa
                    this.cloneOfHeader = document.createElement('div');
                    this.cloneOfHeader.classList.add('comparison-table__clone');
                    this.cloneOfHeader.onscroll = () => {
                        if (!this.isSyncingHeaderScroll) {
                            this.isSyncingHeaderScroll = true;
                            this.$refs.scroller.scrollLeft = this.cloneOfHeader.scrollLeft;
                        }
                        this.isSyncingHeaderScroll = false;
                    };

                    // Clone the row into the parent Element
                    this.fixedHeaderRows.forEach((rowIndex) => {

                        const tableClone = document.createElement('table');
                        tableClone.style.width = `${tableRect.width}px`;
                        const additionalRow = root.querySelector(`tr:nth-child(${rowIndex})`).cloneNode(true);

                        const firstCell = additionalRow.querySelector('td:first-child');
                        if (!firstCellWidth) {
                            firstCellWidth = firstCell.getBoundingClientRect().width;
                        }
                        additionalRow.removeChild(firstCell);
                        tableClone.appendChild(additionalRow);
                        this.cloneOfHeader.appendChild(tableClone);
                    });

                    // Append Fake header to body

                    document.body.appendChild(this.cloneOfHeader);
                }

                this.cloneOfHeader.style.top = `${topValue}px`;
                this.cloneOfHeader.style.left = `${scrollerRect.left + firstCellWidth + 1}px`;

                let sumTDWidth = 0;

                this.fixedHeaderRows.forEach((rowIndex, idx) => {

                    const tdItems = root.querySelectorAll(`tr:nth-child(${rowIndex}) td`);
                    const cloneOfRow = this.cloneOfHeader.querySelector(`table:nth-child(${idx + 1}) tr:first-child`);

                    for (let i = 0; i < tdItems.length; i++) {
                        const cell = tdItems[i];
                        if (i > 0) {
                            if (idx == 0) {
                                sumTDWidth += cell.getBoundingClientRect().width;
                            }
                            cloneOfRow.querySelectorAll('td')[i - 1].style.width = `${cell.getBoundingClientRect().width}px`;
                        }
                    }
                });

                this.cloneOfHeader.style.width = `${Math.min(sumTDWidth, scrollerRect.width)}px`;

                this.handleTableScroll();

            } else {
                if (this.cloneOfHeader) {
                    this.cloneOfHeader.parentElement.removeChild(this.cloneOfHeader);
                    this.cloneOfHeader = null;
                }
            }
        },

        handlePageScroll() {
            this.updateFixedHeader();
        },

        async manageTableDimensions() {
            const { table, scroller } = this.$refs;

            if (!table || this.calculating) { return; }

            this.calculating = true;

            const allCells = table.querySelectorAll('td');
            for (let i = 0; i < allCells.length; i++) {
                this.sizeToFitWidths = null;
                this.tableWidth = null;
                this.$set(this.tops, i, 'auto');
                this.$set(this.heights, i, 'auto');
            }

            await this.$nextTick();

            if (this.settings.sizeToFit && this.isDesktop()) {
                const firstRow = !!this.tableData.rows.length ? this.tableData.rows[0] : null;
                const columnCount = firstRow && firstRow.cells ? firstRow.cells.length - 1 : null ;
                this.tableWidth = scroller.getBoundingClientRect().width - columnCount;

                if (columnCount) {
                    const columnWidth = Math.floor(this.tableWidth / columnCount);
                    this.sizeToFitWidth = columnWidth - 1;
                }
            }

            const rows = table.querySelectorAll('tr');
            let lastTopPosition = 0;
            this.$set(this.tops, 0, lastTopPosition);
            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                const cells = row.querySelectorAll('td');
                let rowHeight = 0;

                for (let j = 0; j < cells.length; j++) {
                    const cell = cells[j];
                    const cellHeight = cell.offsetHeight;

                    if (cellHeight > rowHeight) {
                        rowHeight = cellHeight;
                    }
                }

                this.$set(this.heights, i, rowHeight);
                lastTopPosition += rowHeight;

                if ((i + 1) < rows.length - 1) {
                    this.$set(this.tops, i + 1, lastTopPosition);
                }
            }

            this.calculating = false;

            await this.$nextTick();

            this.updateFixedHeader();
        },

        handleImagesLoaded() {
            this.manageTableDimensions();
        },

        isSoloColumnTable() {
            if (!this.tableData.rows.length) {
                return false;
            }

            return this.tableData.rows[0].cells.length == 2;
        },
    },
    beforeDestroy() {
        if (this.cloneOfHeader) {
            this.cloneOfHeader.parentElement.removeChild(this.cloneOfHeader);
            this.cloneOfHeader = null;
        }
        window.removeEventListener('resize', this.manageTableDimensions);
        window.removeEventListener('scroll', this.handlePageScroll);
    }
}
</script>
