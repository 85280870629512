
import { ApiService } from '..';
const suffix = window.EXPORT_TYPE_SCORM ? '.json' : ''

export const AuthenticationService = {

    login(data) {
        return ApiService.login(data);
    },

    logout() {
        return ApiService.logout();
    },

    checkLogin() {
        return ApiService.get('auth', `check-login${suffix}`);
    },
};

