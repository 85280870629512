
import { ApiService } from '..';

export const EventService = {
    get(slug) {
        return ApiService.get('event', slug);
    },

    getGallery(folder) {
        return ApiService.get('gallery', folder);
    },

    isAllowed(slug) {
        return ApiService.get('event-allowed', slug);
    },
};