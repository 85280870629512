import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

import {
    companion,
    companionFilter,
    collection,
    nugget,
    uitexts,
    common,
    knowledgeTest,
    authentication,
    generalEntry,
    search,
    event,
    gamification,
    quizDuel,
    stream,
    trainingHub,
    breadcrumbs,
    me,
    tracking,
    meta,
    alternativeTrainingHub,
    overview,
    requirements,
    collectionOverview,
    scorm,
} from "./modules";

export default new Vuex.Store({
    modules: {
        authentication,
        uitexts,
        companion,
        companionFilter,
        collection,
        nugget,
        common,
        knowledgeTest,
        generalEntry,
        search,
        event,
        gamification,
        quizDuel,
        stream,
        trainingHub,
        breadcrumbs,
        me,
        tracking,
        meta,
        alternativeTrainingHub,
        overview,
        requirements,
        collectionOverview,
        scorm,
    }
})
