
function checkCSSHyphensSupport(elm) {
    return (
        elm.style.hyphens === "auto" ||
        elm.style.webkitHyphens === "auto" ||
        elm.style.msHyphens === "auto" ||
        elm.style["-moz-hyphens"] === "auto" ||
        elm.style["-ms-hyphens"] === "auto"
    );
}

function testHyphenSupport() {

    let fakeBody = null;
    const css = [
        "visibility:hidden;",
        "-moz-hyphens:auto;",
        "-webkit-hyphens:auto;",
        "-ms-hyphens:auto;",
        "hyphens:auto;",
        "width:48px;",
        "font-size:12px;",
        "line-height:12px;",
        "border:none;",
        "padding:0;",
        "word-wrap:normal"
    ].join("");

    fakeBody = fakeBody || document.createElement("body");
    const testDiv = document.createElement("div");
    testDiv.id = 'test__de';
    testDiv.lang = 'de';
    testDiv.style.cssText = css;
    testDiv.appendChild(document.createTextNode('Silbentrennungsalgorithmus'));
    fakeBody.appendChild(testDiv);
    document.documentElement.appendChild(fakeBody);

    window.setTimeout(function() {
        const el = document.getElementById('test__de');
        if (checkCSSHyphensSupport(el) && el.getBoundingClientRect().height > 12) {
            document.documentElement.classList.add('hyphens--supported');
        } else {
            document.documentElement.classList.add('hyphens--unsupported');
        }
        fakeBody.parentNode.removeChild(fakeBody);
    }, 0);
}

export default testHyphenSupport;