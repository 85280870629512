/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'timer': {
    width: 16,
    height: 16,
    viewBox: '0 0 13 15',
    data: '<g><g><g><path pid="0" d="M11.5 4.6l.6-.6c.2-.2.2-.6 0-.8-.2-.2-.6-.2-.8 0l-.6.6C9.6 3 8.4 2.5 7.1 2.3V1.2h.6c.3 0 .6-.3.6-.6S8 0 7.7 0H5.3c-.3 0-.6.3-.6.6s.3.6.6.6h.6v1.2C2.6 2.6 0 5.3 0 8.7 0 12.2 2.9 15 6.5 15S13 12.2 13 8.7c0-1.5-.5-3-1.5-4.1zm-5 9.2c-2.9 0-5.3-2.3-5.3-5.2 0-2.9 2.4-5.2 5.3-5.2s5.3 2.3 5.3 5.2c0 2.9-2.4 5.2-5.3 5.2zm2.8-7.9c.2.2.2.6 0 .8L6.9 9.1c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L8.5 6c.2-.3.5-.3.8-.1z"/></g></g></g>'
  }
})
