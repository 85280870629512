<template>
    <div v-if="items" class="image-switch">
        <template v-for="(item, index) in items">
            <div
                :key="`image-switch-item-${index}`"
                :class="{
                    'image-switch__item': true,
                    'image-switch__item--active': index == activeIndex
                }"
            >
                <image-element
                    v-if="item.image"
                    :image="item.image"
                ></image-element>

                <div class="image-switch__text-container">
                    <div class="image-switch__page-container">
                        <div class="image-switch__content-container">
                            <div class="image-switch__grid">
                                <div class="image-switch__grid-column image-switch__grid-column--text">
                                    <button-element
                                        @click="(event) => handleSwitchClick(event, index)"
                                        type="secondary"
                                        :button="{
                                            'label': item.switchText,
                                        }"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import ImageElement from '@/components/elements/ImageElement.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';

export default {
    name: 'ImageSwitchElement',
    components: {
        ImageElement,
        ButtonElement,
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            activeIndex: 0
        }
    },
    methods: {
        handleSwitchClick(event, index) {
            this.activeIndex = (index + 1) % this.items.length;
        }
    }
}
</script>
