/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 10 10',
    data: '<g><g><path pid="0" d="M9.5.5c.3.3.3.7 0 1L6 5l3.4 3.4c.3.3.3.7 0 1-.3.3-.7.3-1 0L5 6 1.6 9.5c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1L4 5 .5 1.6C.2 1.3.2.9.5.6c.3-.3.7-.3 1 0L5 4 8.4.6c.3-.4.8-.4 1.1-.1z" fill-rule="evenodd" clip-rule="evenodd" _fill="#55c3f0"/></g></g>'
  }
})
