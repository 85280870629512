
<template>
    <base-section
        :section="getSection"
        :class="{
            'view-quiz-duel-results' : true,
            'view-quiz-duel-results--split' : me && opponent,
        }"
    >
        <div slot="component">
            <div class="quiz-duel-start">
                <div class="quiz-duel-start__logo">
                    <svgicon-wrapper name="quizduel_logo" class="no-rtl" />
                </div>

                <div class="quiz-duel-start__play">

                    <transition name="slide-right">
                        <quiz-duel-player-element
                            v-if="me && opponent"
                            :player="me" />
                    </transition>

                    <span class="quiz-duel-start__vs">vs</span>

                    <transition name="slide-left">
                        <quiz-duel-player-element
                        v-if="me && opponent"
                        :display-scoring="false"
                        :player="opponent" />
                    </transition>

                </div>
            </div>
        </div>
    </base-section>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import BaseSection from '@/components/sections/BaseSection.vue';
import ButtonElement from '@/components/elements/ButtonElement.vue';
import QuizDuelPlayerElement from '@/components/elements/quiz-duel/QuizDuelPlayerElement.vue';

export default {
    name: 'QuizDuelResultElement',

    components: {
        TransitionExpand,
        ButtonElement,
        BaseSection,
        QuizDuelPlayerElement,
    },

    props: {
        me: Object,
        opponent: Object,
    },

    data() {
        return {
        }
    },

    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),

        getSection() {
            return {
                "type": "section-text-only",
                "introtext": null,
                "media": null,
                "component": null,
                "settings": {
                    "section": {
                        "foreground": "dark",
                        "backgroundColor": '',
                        "backgroundImage": null,
                        "textBehavior": null,
                    },
                    "layout": {
                        "columns": "",
                        "size": "content-fit"
                    },
                    "image": null,
                    "video": null,
                    "slideshow": null,
                    "accordion": null,
                    "abslider": null,
                    "thumbnails": null,
                    "choice": null
                }
            };
        },
    },

    created() {
    },

    mounted() {

    },

    methods: {
    }
}
</script>

