
<template>
    <div class="tabs content-dimensioning">
        <div class="tabs__menu-items">
            <div class="tabs__content-container">
                <div class="swiper-container" ref="slider">
                    <div class="swiper-wrapper">
                        <span
                            v-for="(tab, index) in tabs"
                            :key="`tab-panel-index-${index}`"
                            class="swiper-slide tabs__menu-item"
                            :class="{
                                'tabs__menu-item--active': activeTab == index
                            }"
                            @click="() => handleTabPanelClick(index)"
                        >{{ tab.panelTitle }}</span>
                    </div>
                </div>
            </div>
        </div>
        <template v-for="(tab, index) in tabs">
            <div
                v-if="activeTab == index"
                :key="`tab-index-${index}`"
                class="tabs__content-container tabs__panel-content">

                    <div class="tabs__grid">
                        <template v-for="(tabContent, tcIndex) in tab.content">

                            <div
                                v-if="tabContent.hasOwnProperty('introtext') && tabContent.introtext"
                                class="tabs__grid-column tabs__grid-column--text"
                                :key="`tab-index-content-text-${tcIndex}`"
                            >
                                <introtext-element
                                    type="type-2"
                                    :introtext="tabContent.introtext" />
                            </div>

                            <div
                                v-if="tabContent.hasOwnProperty('media') && tabContent.media"
                                class="tabs__grid-column tabs__grid-column--media"
                                :key="`tab-index-content-media-${tcIndex}`"
                            >
                                <media-element
                                    :media="tabContent.media"
                                    :image-settings="tabContent.settings.image"
                                    :video-settings="tabContent.settings.video" />
                            </div>

                            <div
                                v-if="tabContent.hasOwnProperty('panels') || tabContent.hasOwnProperty('slides')"
                                class="tabs__grid-column tabs__grid-column--component"
                                :class="{
                                    'tabs__grid-column--accordion': tabContent.hasOwnProperty('panels')
                                }"
                                :key="`tab-index-content-component-${tcIndex}`"
                            >
                                <accordion-element
                                    v-if="tabContent.hasOwnProperty('panels')"
                                    :panels="tabContent.panels"
                                    :settings="tabContent.settings" />

                                <slideshow-element
                                    v-if="tabContent.hasOwnProperty('slides')"
                                    :slides="tabContent.slides"
                                    :settings="tabContent.settings.slideshow"/>
                            </div>
                        </template>
                    </div>
            </div>
        </template>
    </div>
</template>

<script>
import Swiper from 'swiper';
import debounce from 'debounce';

import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import AccordionElement from '@/components/elements/AccordionElement.vue';
import SlideshowElement from '@/components/elements/SlideshowElement.vue';

export default {
    name: 'TabPanelElement',

    components: {
        TransitionExpand,
        AccordionElement,
        SlideshowElement,
    },

    props: {
        tabs: Array
    },

    data() {
        return {
            menuSlider: null,
            activeTab: 0,
        }
    },

    computed: {

    },

    mounted() {
        this.initSlider();
    },

    methods: {
        handleTabPanelClick(index) {
            this.activeTab = index;
        },

        initSlider() {
            const { slider } = this.$refs;

            if (slider) {
                this.menuSlider = new Swiper(slider, {
                    freeMode: true,
                    freeModeSticky: true,
                    slidesPerView: 'auto',
                    slideToClickedSlide: true,
                    watchOverflow: true,
                });

                this.menuSlider.on('resize', () => {
                    this.menuSlider.update();
                });
            }
        },
    },

    beforeDestroy() {

    }
}
</script>

