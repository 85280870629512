/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'quote': {
    width: 16,
    height: 16,
    viewBox: '0 0 102 90',
    data: '<g><g><path pid="0" d="M102 25.7c0 5.4-1.1 10.8-3.3 16.1S93.9 52.5 90.8 58c-2.9 5.4-6 10.7-9.2 15.8C78.3 78.9 75 84 71.7 89L55 78.3c2.5-4 5-8.8 7.6-14.4 2.6-5.6 3.9-10.6 3.9-14.7 0-1.9-.4-3.7-1.3-5.5s-1.8-3.7-2.7-5.6c-1-2-1.9-4.2-2.7-6.7-.9-2.5-1.3-5.4-1.3-8.8 0-6.7 2.1-12.1 6.3-16.3C69.1 2.1 74 0 79.5 0c7 0 12.4 2.5 16.5 7.4s6 11 6 18.3zm-55 0c0 5.4-1.1 10.8-3.2 16.1S39.1 52.5 36 58c-2.9 5.4-6 10.7-9.3 15.8S20.1 84 16.9 89L0 78.3c2.5-4 5.1-8.8 7.8-14.4s4.1-10.6 4.1-14.7c0-1.9-.4-3.7-1.3-5.5-.9-1.8-1.8-3.7-2.9-5.8-1.1-2.1-2-4.4-2.9-6.9s-1.3-5.3-1.3-8.5c0-6.7 2.1-12.1 6.4-16.3S19.1 0 24.7 0c7 0 12.4 2.5 16.4 7.4s5.9 11 5.9 18.3z" _fill="#fff"/></g></g>'
  }
})
