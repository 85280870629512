import Vue from 'vue';

import { sortAlphabetically, sortByProperty } from '@/common/utility/sorting';

import { StreamService } from "@/api";
import {
    STREAM_FETCH,
    STREAM_SET,
    STREAM_RESET,
    STREAM_SORT,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    SET_STREAM,
    SET_SLUG,
    RESET_STREAM,
    SET_SORTER,
} from "@/store/mutations.type";


const state = () => ({
    isLoading: false,
    slug: '',
    streams: {},
    sorters: [ 'date_asc', 'date_desc' ],
    activeSorter: 'date_desc',
});

const actions = {

    async [STREAM_FETCH]({ commit, state }, slug) {

        if (state.streams.hasOwnProperty(slug)) {
            commit(SET_SLUG, slug);
            return state.streams[slug];
        }

        commit(FETCH_START);

        try {
            const { data } = await StreamService.getStream(slug);

            commit(SET_STREAM, {
                ...data,
                slug
            });

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    [STREAM_SORT]({ commit }, sorter) {

        commit(SET_SORTER, sorter);

        return sorter;
    },

    [STREAM_SET]({ commit }, data) {

        commit(SET_STREAM, data);

        return data;
    },

    [STREAM_RESET]({ commit }) {

        commit(RESET_STREAM);

        return true;
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoading = true;
    },

    [FETCH_END](state) {
        state.isLoading = false;
    },

    [SET_SLUG](state, slug) {
        state.slug = slug;
    },

    [SET_STREAM](state, { keyvisual, nuggets, otherStreams, trackingId, slug}) {

        state.slug = slug;
        state.isLoading = false;
        Vue.set(state.streams, slug, {
            keyvisual,
            nuggets,
            otherStreams,
            trackingId,
        });

    },

    [SET_SORTER](state, sorter) {
        state.activeSorter = sorter;
    },

    [RESET_STREAM](state) {
        state.isLoading = false;
        state.slug = '';
        state.streams = {};
    }
};

const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },

    getNuggets: (state) => {
        if (!state.streams[state.slug]) { return null; }

        // Order by date oldest or newest

        const nuggets = state.streams[state.slug].nuggets
            .filter(() => { return true; })
        // Desc => new to old
        // Asc => old to new
        return nuggets.sort((entryA, entryB) => sortByProperty(entryA, entryB, 'postDate', state.activeSorter == 'date_asc'));
    },

    getKeyvisual: (state) => {
        if (!state.streams[state.slug]) { return null; }

        return state.streams[state.slug].keyvisual;
    },

    getSorters: (state) => {
        return state.sorters;
    },

    getActiveSorter: (state) => {
        return state.activeSorter;
    },

    getOtherStreams: (state) => {
        if (!state.streams[state.slug]) { return null; }

        return state.streams[state.slug].otherStreams;
    },

    getTrackingId: (state) => {
        if (!state.streams[state.slug]) { return null; }

        return state.streams[state.slug].trackingId;
    },
};


export const stream = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
