<template>
    <div class="interaction-evaluator">
        <div class="interaction__buttons">
            <button-element
                :disabled="evaluating"
                @click="$emit('evaluate')"
                :button="{
                    'label': t('ok')
                }"/>
        </div>

        <transition-expand>
            <div v-if="evaluating">
                <feedback-element
                    ref="feedbackContainer"
                    class="interaction__feedback"
                    @retry="$emit('retry')"
                    @solve="$emit('solve')"
                    :feedback="feedback"
                    :evaluation="evaluation" />
            </div>
        </transition-expand>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonElement from '@/components/elements/ButtonElement.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';
import FeedbackElement from '@/components/elements/quiz/FeedbackElement.vue';

export default {
    name: 'InteractionEvaluator',
    components: {
        ButtonElement,
        TransitionExpand,
        FeedbackElement,
    },
    props: {
        evaluating: {
            type: Boolean,
            required: true
        },
        feedback: {
            type: Object,
            required: true
        },
        evaluation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        }
    },
    watch: {
        async evaluating(newValue, oldValue) {

            await this.$nextTick();
            const { feedbackContainer } = this.$refs;

            if (feedbackContainer && newValue == true) {

                this.$scrollTo(feedbackContainer.$el, 1000, {
                    easing: [0.23, 1, 0.32, 1],
                    offset: () => {
                        const feedbackRect = feedbackContainer.$el.getBoundingClientRect();
                        const customOffset = 16;
                        const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

                        return Math.min(0, targetTop);
                    },
                    force: true,
                    cancelable: true,
                    x: false,
                    y: true
                });
            }
        },
    },
    computed: {
        ...mapGetters('uitexts', {
            t: 't',
        }),
    },
    created() {

    },
    methods: {

    }
}
</script>
