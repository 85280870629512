/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'search': {
    width: 16,
    height: 16,
    viewBox: '0 0 25 25',
    data: '<g><g><g><path pid="0" d="M24.6 22.8l-6.2-6.2c1.6-1.8 2.5-4.1 2.5-6.5C20.9 4.5 16.2 0 10.5 0S0 4.5 0 10.1s4.7 10.1 10.5 10.1c2.2 0 4.2-.6 6-1.8l6.2 6.2c.3.3.6.4 1 .4s.7-.1.9-.4c.5-.5.5-1.3 0-1.8zM10.5 2.6c4.3 0 7.7 3.3 7.7 7.5s-3.5 7.5-7.7 7.5-7.7-3.3-7.7-7.5 3.4-7.5 7.7-7.5z"/></g></g></g>'
  }
})
