/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'close-big': {
    width: 16,
    height: 16,
    viewBox: '0 0 60 60',
    data: '<g _fill="none" fill-rule="evenodd"><g _fill="#55C3F0"><path pid="0" d="M56.899 3.104a4.39 4.39 0 01-.002 6.207L36.207 30l20.69 20.689a4.39 4.39 0 01.002 6.207 4.386 4.386 0 01-6.205.001L30.004 36.21 9.305 56.898a4.387 4.387 0 01-6.205-.002 4.39 4.39 0 01.002-6.207L23.793 30 3.104 9.311a4.39 4.39 0 01-.002-6.207 4.387 4.387 0 016.205-.002l20.697 20.69 20.69-20.69a4.386 4.386 0 016.206.002z"/></g></g>'
  }
})
