
<template>
    <div class="quiz-duel-player">
        <div class="quiz-duel-player__player">
            <div class="quiz-duel-player__icon">
                <svgicon-wrapper :name="`avatar-${avatar}`" />
            </div>
            <div class="quiz-duel-player__nickname">
                <flag-element :flag="player.market" />
                <span class="quiz-duel-player__nickname-text">{{ player.nickname }}</span>
            </div>
        </div>
        <div
            v-if="player.results"
            class="quiz-duel-player__results"
        >
            <div class="quiz-duel-player__questions">
                <span
                    v-for="(question, index) in player.results.questions"
                    :key="`question-${index}`"
                    :class="{
                        'quiz-duel-player__question': true,
                        'quiz-duel-player__question--correct': question,
                        'quiz-duel-player__question--incorrect': !question,
                    }"><svgicon-wrapper :name="getQuestionIcon(question)" /></span>
            </div>

            <div v-if="displayScoring" class="quiz-duel-player__scoring">
                <span class="quiz-duel-player__scoring-points">
                    {{ player.results.score }}
                    <span class="quiz-duel-player__points-label">{{ t('points') }}</span>
                </span>
                <span class="quiz-duel-player__scoring-time">
                    <svgicon-wrapper name="timer" />
                    {{ formatToTime(player.results.time) }}
                </span>
            </div>

            <div v-if="displayScoring && player.results.bonus" class="quiz-duel-player__bonus">
                <span class="quiz-duel-player__bonus-label">{{ t('bonus') }}</span>
                <span class="quiz-duel-player__score-label">{{ player.results.bonus | numberFormat }}</span>
                <span class="quiz-duel-player__points-label">{{ t('points') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";

import FlagElement from '@/components/elements/event/FlagElement.vue';

export default {
    name: 'QuizDuelPlayerElement',

    components: {
        FlagElement,
    },

    props: {
        player: Object,
        displayScoring: {
            type: Boolean,
            default: true,
        }
    },

    data() {
        return {

        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        avatar() {
            if (!this.player.hasOwnProperty('results')) {
                return 'happy';
            }

            return 'happy';
        },
    },

    created() {
    },

    mounted() {
    },

    methods: {

        getQuestionIcon(questionResult) {
            return questionResult ? 'checkmark' : 'cross';
        },

        formatToTime(s) {

            let seconds = Math.ceil(s % 60);
            let minutes = Math.floor((s / 60) % 60);

            if (seconds < 10) { seconds = `0${seconds}`; }
            if (minutes < 10) { minutes = `0${minutes}`; }

            return `${minutes}:${seconds}`;
        }
    }
}
</script>
