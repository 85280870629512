
<template>
     <div :class="this.getSlideshowClasses" ref="slider">
        <div class="swiper-wrapper">
            <template v-for="(slide, index) in slides">
                <div class="slideshow__slide swiper-slide" :key="`slideshow-slide-${index}`">
                    <media-element
                        v-if="slide.media"
                        :media="slide.media"
                        @loaded="handleMediaLoaded"
                    ></media-element>
                    <div v-if="slide.introtext" class="slideshow__text-container" data-swiper-parallax="-100%">
                        <div class="slideshow__page-container">
                            <div class="slideshow__content-container">
                                <div class="slideshow__grid">
                                    <div class="slideshow__grid-column slideshow__grid-column--text">
                                        <introtext-element
                                            type="type-2"
                                            :introtext="slide.introtext"
                                            :no-hyphens="true"
                                        ></introtext-element>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div :style="{ top: navigationTop }" class="swiper-no-swiping slideshow__button-prev swiper-button-prev">
            <svgicon :original="true" name="lined-arrow-right" dir="left" />
        </div>
        <div :style="{ top: navigationTop }" class="swiper-no-swiping slideshow__button-next swiper-button-next">
            <svgicon :original="true" name="lined-arrow-right" dir="right" />
        </div>

        <div class="swiper-pagination"></div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import debounce from 'debounce';

export default {
    name: 'SlideshowElement',
    props: {
        slides: Array,
        settings: {
            type: Object,
            default: () => {
                return {
                    layout: 'text-under-image',
                    loop: false,
                    mode: 'slide'
                }
            }
        }
    },

    data() {
        return {
            slider: null,
            navigationTop: '50%',
        }
    },

    computed: {
        getSlideshowClasses() {
            return {
                'slideshow' : true,
                'swiper-container': true,
                [`slideshow--layout-${this.settings.layout}`]: true,
            }
        }
    },

    async mounted() {
        await this.$nextTick();

        this.createSlider();
    },
    methods: {

        async setPaginationPosition() {

            const currentSlide = this.$el.querySelector('.swiper-slide-active');
            const image = currentSlide.querySelector('.slideshow__slide .image');

            if (image) {
                let topPosition = image.getBoundingClientRect().height;
                this.navigationTop = `${topPosition / 2}px`;
            }
        },

        createSlider() {
            const { slider } = this.$refs;

            this.slider = new Swiper(slider, {
                effect: this.settings.mode,
                loop: this.settings.loop,
                preventClicks: false,
                watchOverflow: true,
                autoHeight: true,
                slideToClickedSlide: false,
                parallax: true,
                fadeEffect: {
                    crossFade: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                on: {
                    init: () => {
                        this.setPaginationPosition();
                    },
                }
            });

            this.slider.on('resize', () => {
                this.setPaginationPosition();
                this.slider.update();
            });
        },

        handleMediaLoaded() {
            this.setPaginationPosition();
            this.slider && this.slider.update();
        },
    },
    beforeDestroy() {
        this.slider && this.slider.destroy();
    }
}
</script>

