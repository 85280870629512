/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'language': {
    width: 16,
    height: 16,
    viewBox: '0 0 14 14',
    data: '<g><g><g><path pid="0" d="M13.6 9.3c.2-.7.4-1.5.4-2.3s-.1-1.6-.4-2.3v-.1C12.6 1.9 10 0 7 0S1.4 1.9.4 4.6v.1C.1 5.4 0 6.2 0 7s.1 1.6.4 2.3v.1C1.4 12 4 14 7 14s5.6-2 6.6-4.7zM7 13c-.3 0-.7-.5-1.1-1.6-.2-.5-.3-1.1-.4-1.8h2.9c-.1.6-.2 1.2-.4 1.8-.3 1.1-.8 1.6-1 1.6zM5.4 8.6c0-.5-.1-1.1-.1-1.7s0-1.1.1-1.7h3.2c0 .5.1 1.1.1 1.7s0 1.1-.1 1.7H5.4zM1 7c0-.6.1-1.1.2-1.7h3.2c0 .5-.1 1.1-.1 1.7s0 1.1.1 1.7H1.2C1.1 8.1 1 7.6 1 7zm6-6c.2 0 .7.5 1 1.6.2.5.3 1.1.4 1.8H5.5c.1-.6.2-1.2.4-1.8C6.3 1.5 6.7 1 7 1zm2.6 4.3h3.2c.1.5.2 1.1.2 1.7s-.1 1.1-.2 1.7H9.6c0-.5.1-1.1.1-1.7s-.1-1.1-.1-1.7zm2.7-1H9.4C9.3 3.1 9 2 8.5 1.2c1.7.5 3.1 1.6 3.8 3.1zM5.4 1.2c-.4.8-.7 1.9-.9 3.1H1.6c.8-1.5 2.2-2.6 3.8-3.1zM1.6 9.6h2.9c.2 1.3.5 2.4.9 3.1-1.6-.4-3-1.6-3.8-3.1zm6.9 3.1c.5-.7.8-1.8.9-3.1h2.9c-.7 1.5-2.1 2.7-3.8 3.1z"/></g></g></g>'
  }
})
