import Vue from 'vue';
import axios from "axios";

import { SearchService } from "@/api";

import {
    SEARCH_COMPLETIONS,
    UPDATE_SEARCH_WORD,
    SEARCH,
    SEARCH_SET_FILTER_TOPIC,
    SEARCH_SET_FILTER_CATEGORY,
    SEARCH_TOGGLE_RESULTS,
    SEARCH_TOGGLE_FILTERS,
    SEARCH_TOGGLE_MENU,
} from "@/store/actions.type";

import {
    FETCH_START,
    FETCH_END,
    FETCH_RESULTS_START,
    FETCH_RESULTS_END,

    SET_COMPLETIONS,
    SET_SEARCH_WORD,
    SET_RESULTS,
    SET_FILTER_TOPIC,
    SET_FILTER_CATEGORY,
    SET_SHOW_RESULTS,
    SET_SHOW_FILTERS,
    SET_SHOW_MENU,

} from "@/store/mutations.type";

const state = () => ({
    isLoadingCompletions: false,
    isLoadingSearchResults: false,
    searchWord: '',
    tempSearchWord: '',
    completions: [],
    results: {},
    activeTopics: [],
    activeCategories: [],
    isSearchResultsOpen: false,
    isFiltersOpen: false,

    searchMenuVisible: false,
});

const actions = {

    async [SEARCH_COMPLETIONS]({ commit, state, dispatch, rootGetters }) {

        if (!!state.completions.length) {
            return state.completions;
        }

        commit(FETCH_START);

        try {
            const { data } = await SearchService.getCompletions();
            commit(SET_COMPLETIONS, data.data);

            return data;

        } catch(e) {
            commit(FETCH_END);
        }

        return null;
    },

    async [SEARCH]({ commit, state }) {

        if (state.results.hasOwnProperty(state.tempSearchWord)) {
            return state.results[state.tempSearchWord];
        }

        if (state.pendingRequest != null) {
            state.pendingRequest.cancel();
            commit(FETCH_RESULTS_END);
        }

        commit(FETCH_RESULTS_START);

        try {
            const { data } = await SearchService.getSearchResults({
                q: encodeURI(state.tempSearchWord)
            });

            commit(SET_RESULTS, data.data);

            return data;

        } catch(e) {
            console.log(e)
            commit(FETCH_RESULTS_END);
        }

        return null;
    },

    [UPDATE_SEARCH_WORD]({ commit }, searchWord) {
        commit(SET_SEARCH_WORD, searchWord);
    },

    [SEARCH_SET_FILTER_TOPIC]({ commit }, topic) {
        commit(SET_FILTER_TOPIC, topic);
    },

    [SEARCH_SET_FILTER_CATEGORY]({ commit }, category) {
        commit(SET_FILTER_CATEGORY, category);
    },

    [SEARCH_TOGGLE_RESULTS]({ commit }, open) {
        commit(SET_SHOW_RESULTS, open);
    },

    [SEARCH_TOGGLE_FILTERS]({ commit }, open) {
        commit(SET_SHOW_FILTERS, open);
    },

    [SEARCH_TOGGLE_MENU]({ commit }, open) {
        commit(SET_SHOW_MENU, open);
    },
};

const mutations = {

    [FETCH_START](state) {
        state.isLoadingCompletions = true;
    },

    [FETCH_END](state) {
        state.isLoadingCompletions = false;
    },

    [FETCH_RESULTS_START](state) {
        state.isLoadingSearchResults = true;
    },

    [FETCH_RESULTS_END](state) {
        state.isLoadingSearchResults = false;
    },

    [SET_COMPLETIONS](state, completions) {

        const allTopics = completions
            .filter(i => i.hasOwnProperty('topic'))
            .map(i => i.topic);

        const allCategories = completions
            .filter(i => i.hasOwnProperty('category'))
            .map(i => i.category);

        const allNuggets = completions
            .filter(i => i.hasOwnProperty('headline') || i.hasOwnProperty('subline'))
            .reduce((c, i) => {
                const words = [];
                for (let k in i) {
                    words.push(i[k]);
                }
                return [
                    ...c,
                    ...words
                ];
            }, []);

        const allMerged = [
            ...allTopics,
            ...allCategories,
            ...allNuggets,
        ];

        const mergedCompletions = new Map(
            [].concat.apply([], allMerged)
            .map(s => [s.toLowerCase(), s])
        );
            // .filter((value, index, self) => {
            //     return self.indexOf(value.toLowerCase()) === index;
            // })

        state.completions = [...mergedCompletions.values()];
    },

    [SET_RESULTS](state, results) {

        const sw = state.tempSearchWord;
        Vue.set(state, 'searchWord', sw);

        const searchResults = results.filter(i => i.hasOwnProperty('id'))
        Vue.set(state, 'results', {
            [sw]: searchResults
        });

        state.activeTopics = [];
        state.activeCategories = [];
        state.isLoadingSearchResults = false;
    },

    [SET_SEARCH_WORD](state, searchWord) {
        Vue.set(state, 'tempSearchWord', searchWord);
    },

    [SET_FILTER_TOPIC](state, topic) {
        const indexOfTopic = state.activeTopics.indexOf(topic);

        if (indexOfTopic !== -1) {
            state.activeTopics.splice(indexOfTopic, 1);
        } else {
            Vue.set(state, 'activeTopics', [
                ...state.activeTopics,
                topic
            ]);
        }
    },

    [SET_FILTER_CATEGORY](state, category) {
        const indexOfCategory = state.activeCategories.indexOf(category);

        if (indexOfCategory !== -1) {
            state.activeCategories.splice(indexOfCategory, 1);
        } else {
            Vue.set(state, 'activeCategories', [
                ...state.activeCategories,
                category
            ]);
        }
    },

    [SET_SHOW_RESULTS](state, open) {
        state.isSearchResultsOpen = open;
    },

    [SET_SHOW_FILTERS](state, open) {
        state.isFiltersOpen = open;
    },

    [SET_SHOW_MENU](state, open) {
        state.searchMenuVisible = open;
    }
};

const getters = {
    isLoadingSearchResults: (state) => {
        return state.isLoadingSearchResults;
    },

    isLoadingCompletions: (state) => {
        return state.isLoadingCompletions;
    },

    hasActiveFilters: (state) => {
        return !!state.activeTopics.length || !!state.activeCategories.length;
    },

    getCompletions: (state) => {
        return state.completions;
    },

    getSearchWord: (state) => {
        return state.searchWord;
    },

    getTempSearchWord: (state) => {
        return state.tempSearchWord;
    },

    getResults: (state) => {

        if (state.searchWord && !!state.searchWord.length && state.results.hasOwnProperty(state.searchWord)) {

            const results = state.results[state.searchWord]
            .filter((nugget) => {
                return !!nugget.topics.length && (
                    !state.activeTopics.length || nugget.topics.some(t => state.activeTopics.indexOf(t.title) >= 0)
                );
            })
            .filter((nugget) => {
                return !nugget.categories.length || (!!nugget.categories.length &&
                    (
                        !state.activeCategories.length || nugget.categories.some(c => state.activeCategories.indexOf(c.title) >= 0)
                    )
                );
            });

            return results;
        }

        return null;
    },

    getResultTopics: (state, getters) => {

        if (!!state.searchWord.length && state.results.hasOwnProperty(state.searchWord)) {
            const topics = state.results[state.searchWord].map(i => {
                return i.topics.map(t => t.title);
            })
            .reduce((res, t) => {
                return res.concat(t);
            }, [])
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
            .sort((a, b) => a.localeCompare(b));

            return topics;
        }

        return null;
    },

    getActiveTopics: (state) => {
        return state.activeTopics;
    },

    getResultCategories: (state, getters) => {

        if (!!state.searchWord.length && state.results.hasOwnProperty(state.searchWord)) {
            const categories = state.results[state.searchWord].map(i => {
                return i.categories.map(c => c.title);
            })
            .reduce((res, c) => {
                return res.concat(c);
            }, [])
            .filter((value, index, self) => {
                return self.indexOf(value) === index;
            })
            .sort((a, b) => a.localeCompare(b));

            return categories;
        }

        return null;
    },

    getActiveCategories: (state) => {
        return state.activeCategories;
    },

    getIsSearchResultsVisible: (state) => {
        return state.isSearchResultsOpen;
    },

    getIsShowingFilters: (state) => {
        return state.isFiltersOpen;
    },

    getIsSearchMenuVisible: (state) => {
        return state.searchMenuVisible;
    },
};


export const search = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
