import 'intersection-observer';
import 'idempotent-babel-polyfill';
import 'array-foreach';
import 'promise-polyfill';
import 'whatwg-fetch';
import 'classlist-polyfill';
import 'vue-svgicon/dist/polyfill';
import 'custom-event-polyfill';
import 'formdata-polyfill';

import testHyphenSupport from '@/common/polyfills/TestHyphenSupport'

import placeholderShown from '@/common/polyfills/placeholder-shown'

(() => {
    var htmlElement = document.querySelector('.can-hover');

    function touchStart () {
        htmlElement.classList.remove('can-hover');
        htmlElement.removeEventListener('touchstart', touchStart);
    }

    htmlElement.addEventListener('touchstart', touchStart);
})();

window.addEventListener("load", () => {
});

export default () => {
    placeholderShown();
    testHyphenSupport();
}