<template>
    <div v-if="nuggets && nuggets.length" class="sortable-nugget-list">
        <div class="sortable-nugget-list__page-container">
            <div class="sortable-nugget-list__content-container">

                <div v-if="sorters && sorters.length && getNuggets.length > 1" class="sortable-nugget-list__grid">

                    <div class="sortable-nugget-list__grid-column sortable-nugget-list__grid-column--sorters">
                        <div class="sortable-nugget-list__sort-buttons">
                            <span
                                :class="{
                                    'sortable-nugget-list__sort-button': true,
                                    'sortable-nugget-list__sort-button--desc': activeSorter.endsWith('desc'),
                                    'sortable-nugget-list__sort-button--asc': activeSorter.endsWith('asc'),
                                }"
                                @click="handleSorterSelect"
                            >
                                <svgicon-wrapper name="calendar_icon" class="sortable-nugget-list__prefix-icon" />
                                <span class="sortable-nugget-list__sort-button-icon">
                                    <svgicon-wrapper name="arrow-down-fine" />
                                </span>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="sortable-nugget-list__grid sortable-nugget-list__grid--nuggets">
                    <transition-group name="sortable-nugget-list__transition" tag="div" class="sortable-nugget-list__grid-wrapper">
                        <div
                            v-for="nugget in getNuggets"
                            :key="`nugget-index-${nugget.id}`"
                            class="sortable-nugget-list__grid-column sortable-nugget-list__grid-column--nugget"
                        >
                            <fluid-tile :nugget="nugget" />
                        </div>
                    </transition-group>
                </div>

                <div v-if="getNuggets.length < nuggets.length" class="sortable-nugget-list__grid sortable-nugget-list__grid--more">
                    <div class="sortable-nugget-list__grid-column">
                        <span
                            :class="{
                                'stream-overview__more-button': true,
                                'stream-overview__more-button--active': showingMore,
                            }"
                            @click="toggleShowMore"
                        >
                            <span>{{ t('show-more') }}</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import store from "@/store";
import {
    STREAM_SORT_NS,
} from "@/store/actions.type";

import LoaderElement from '@/components/elements/LoaderElement.vue';
import ImageElement from '@/components/elements/ImageElement.vue';
import FluidTile from '@/components/elements/tiles/FluidTile.vue';
import TransitionExpand from '@/components/transitions/TransitionExpand.vue';

const MAX_NUGGETS_IN_LIST_VIEW = 7;

export default {
    name: 'SortableNuggetList',

    components: {
        LoaderElement,
        ImageElement,
        FluidTile,
        TransitionExpand,
    },

    props: {
        nuggets: {
            type: Array,
            required: true,
        },
        sorters: Array,
        activeSorter: String,
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't',
        }),

        isMobileView() {
            return ['xs'].indexOf(this.$mq) !== -1;
        },

        getNuggets() {
            return this.isMobileView ? this.nuggets.slice(0, this.currentNuggetCount) : this.nuggets;
        },

        getMoreNuggets() {
            return this.isMobileView ? this.nuggets.slice(MAX_NUGGETS_IN_LIST_VIEW) : [];
        },
    },

    data() {
        return {
            showingMore: false,
            currentNuggetCount: MAX_NUGGETS_IN_LIST_VIEW,
        }
    },

    methods: {

        handleSorterSelect() {
            const sortOrder = this.activeSorter.endsWith('desc') ? 'asc' : 'desc';

            let sorter = null;
            if (this.activeSorter.endsWith('desc')) {
                sorter = this.activeSorter.replace('desc', 'asc');
            } else {
                sorter = this.activeSorter.replace('asc', 'desc');
            }

            store.dispatch(STREAM_SORT_NS, sorter);
        },

        toggleShowMore() {
            this.currentNuggetCount += MAX_NUGGETS_IN_LIST_VIEW;
        },
    },
}
</script>
