<template>
    <base-section
        :class="{
            'finish-module--done': moduleFinished
        }"
        :section="finishModule"
    >
        <template slot="extra-button">
            <button-element
                @click="handleFinish"
                :button="{
                    'label': t('end-module')
                }" />
        </template>

        <template #component>
            <transition name="slide-up">
                <div
                    v-if="moduleFinished"
                    ref="container"
                    :class="{
                        'finish-module': true,
                    }"
                >
                    <div class="finish-module__page-container">
                        <div class="finish-module__content-container">
                            <div class="finish-module__introduction">
                                <introtext-element
                                    :introtext="{
                                        headline: t('finish-module__headline'),
                                        subline: t('finish-module__subline'),
                                        copytext: t('finish-module__copytext'),
                                        buttons: null
                                    }"
                                >
                                    <template slot="extra-button">
                                        <button-element
                                            class="finish-module__overview-button"
                                            v-if="overviewUri"
                                            :button="{}"
                                        >
                                            <router-link
                                                :to="{ path: `/${overviewUri}` }"
                                                class="button__label"
                                                slot="routerLink"
                                            >
                                                <span class="button__label-text">{{ t('back-to-overview') }}</span>
                                                <span class="button__label-icon">
                                                    <svgicon name="overview" />
                                                </span>
                                            </router-link>
                                        </button-element>
                                    </template>
                                </introtext-element>
                            </div>

                            <div class="finish-module__navigation">
                                <div class="finish-module__navigation--prev">
                                    <router-link
                                        v-if="previousItem"
                                        :to="{ path: `/${previousItem.uri}` }"
                                        class="finish-module__navigation-button"
                                    >
                                        <svgicon :original="true" name="lined-arrow-right" dir="left" />
                                        <div class="finish-module__navigation-headline-wrapper">
                                            <span class="finish-module__navigation-subline">{{ t('fn-previous') }}</span>
                                            <span class="finish-module__navigation-headline">{{ previousItem.label }}</span>
                                        </div>
                                    </router-link>
                                </div>
                                <div class="finish-module__navigation--next">
                                    <router-link
                                        v-if="nextItem"
                                        :to="{ path: `/${nextItem.uri}` }"
                                        class="finish-module__navigation-button"
                                    >
                                        <div class="finish-module__navigation-headline-wrapper">
                                            <span class="finish-module__navigation-subline">{{ t('fn-next') }}</span>
                                            <span class="finish-module__navigation-headline">{{ nextItem.label }}</span>
                                        </div>
                                        <svgicon :original="true" name="lined-arrow-right" dir="right" />
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </base-section>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import {
    SCORM_COMPLETE_MODULE_NS,
} from "@/store/actions.type";

import BaseSection from '@/components/sections/BaseSection.vue';

export default {
    name: 'FinishModule',

    components: {
        BaseSection,
    },

    props: {
        finishModule: Object,
        trackingId: String,
    },

    data() {
        return {
            // moduleFinished: false,
        }
    },

    computed: {

        ...mapGetters('uitexts', {
            t: 't'
        }),

        ...mapGetters('scorm', {
            isModuleCompleted: 'isModuleCompleted'
        }),

        overviewUri() {
            return this.finishModule.component.finishModule.overviewUri;
        },

        previousItem() {
            return this.finishModule.component.finishModule.previousItem;
        },

        nextItem() {
            return this.finishModule.component.finishModule.nextItem;
        },

        moduleFinished() {
            return this.isModuleCompleted(this.trackingId);
        },
    },

    watch: {
        // async visible(newValue, oldValue) {

        //     await this.$nextTick();
        //     const { container } = this.$refs;

        //     if (container && newValue == true) {

        //         this.$scrollTo(container.$el, 1000, {
        //             easing: [0.23, 1, 0.32, 1],
        //             offset: () => {
        //                 const feedbackRect = container.$el.getBoundingClientRect();
        //                 const customOffset = 16;
        //                 const targetTop = -1 * (window.innerHeight - feedbackRect.height - customOffset);

        //                 return Math.min(0, targetTop);
        //             },
        //             force: true,
        //             cancelable: true,
        //             x: false,
        //             y: true
        //         });

        //         this.handleFinish();
        //     }
        // },
    },

    created() {
        // this.moduleFinished = ;
    },

    methods: {

        handleFinish() {
            if (this.moduleFinished) {
                return;
            }

            // this.forceModuleFinished = true;

            store.dispatch(SCORM_COMPLETE_MODULE_NS, {
                moduleId: this.trackingId
            });
        },
    },
}
</script>
